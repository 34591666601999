<script setup lang="ts" generic="T extends Nullable<ShortTimeString>">
  import { computed, ref, watchEffect } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable, ShortTimeString, useIsMobile } from '@algorh/shared'

  import { AlgErrors } from '../../../feedback'
  import { AlgIcon } from '../../../media'
  import { AlgPopper } from '../../../popover'
  import { AlgLabel } from '../../label'
  import { InputSize, InputVariant } from '../Input.type'

  type Props = {
    readonly id: string
    readonly modelValue: T
    readonly label?: string
    readonly sublabel?: string
    readonly placeholder?: string
    readonly size?: InputSize
    readonly inline?: boolean
    readonly required?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    readonly centered?: boolean
    readonly searchable?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
    readonly minValue?: ShortTimeString
    readonly maxValue?: ShortTimeString
    readonly step?: number
    readonly closeOnSelect?: boolean
    readonly variant?: InputVariant
  }

  defineOptions({
    name: 'AlgTimeInput',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 'm',
    inline: false,
    required: false,
    disabled: false,
    readonly: false,
    centered: false,
    searchable: false,
    errored: false,
    minValue: '00:00',
    maxValue: '23:59',
    step: 15,
    closeOnSelect: true,
    variant: 'primary',
  })

  const emit = defineEmits<{
    (e: 'update:model-value', value: T): void
  }>()

  // Composables
  const { t } = useI18n()

  const isMobile = useIsMobile()

  // Refs
  const inputRef = ref <Nullable<HTMLInputElement>>(null)

  const hourList = ref<HTMLUListElement>()
  const minuteList = ref<HTMLUListElement>()

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  const timeStep = computed(() => props.step * 60)

  const modelValueHour = computed<Nullable<number>>(() =>
    props.modelValue ? +props.modelValue.split(':')[0] : null,
  )

  const modelValueMinute = computed(() =>
    props.modelValue ? +props.modelValue.split(':')[1] : null,
  )

  const minHour = computed(() => +props.minValue.split(':')[0])
  const maxHour = computed(() => +props.maxValue.split(':')[0])

  const minMinute = computed(() =>
    modelValueHour.value === minHour.value ? +props.minValue.split(':')[1] : 0,
  )

  const maxMinute = computed(() =>
    modelValueHour.value === maxHour.value ? +props.maxValue.split(':')[1] : 59,
  )

  const hours = computed(() => {
    const hours = Array.from({ length: maxHour.value - minHour.value + 1 }, (_, i) =>
      (i + minHour.value).toString().padStart(2, '0'),
    )
    return hours.filter((_, i) => i % (timeStep.value / 3600) === 0)
  })

  const minutes = computed(() => {
    const minutes = Array.from({ length: maxMinute.value - minMinute.value + 1 }, (_, i) =>
      (i + minMinute.value).toString().padStart(2, '0'),
    )

    return minutes.filter((_, i) => i % (timeStep.value / 60) === 0)
  })

  // Watch
  watchEffect(() => {
    if (!hourList.value || !minuteList.value) {
      return
    }

    const selectedHour = hourList.value.querySelector('li.selected') as Nullable<HTMLLIElement>
    const selectedMinute = minuteList.value.querySelector('li.selected') as Nullable<HTMLLIElement>

    if (selectedHour) {
      hourList.value.scrollTop = selectedHour.offsetTop
    }

    if (selectedMinute) {
      minuteList.value.scrollTop = selectedMinute.offsetTop
    }
  })

  // Methods
  function handleHourChange(hour: string) {
    const minute
      = modelValueMinute.value === null ? '00' : modelValueMinute.value.toString().padStart(2, '0')

    emit('update:model-value', `${hour}:${minute}` as T)
  }

  function handleMinuteChange(minute: string) {
    const hour
      = modelValueHour.value === null ? '00' : modelValueHour.value.toString().padStart(2, '0')

    emit('update:model-value', `${hour}:${minute}` as T)
  }

  function handleInput(event: Event) {
    const { value } = event.target as HTMLInputElement

    emit('update:model-value', value as T)
  }

  function handleClear(close: () => void) {
    close()
    emit('update:model-value', null as T)
  }

  function handleClick(toggle: () => void) {
    try {
      if (isMobile.value && inputRef.value?.showPicker) {
        inputRef.value.showPicker()
      } else {
        toggle()
      }
    } catch {
      toggle()
    }
  }
</script>

<template>
  <div
    class="field-wrapper"
    :class="{ inline: props.inline}"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :html-for="props.id"
      :inline="props.inline"
      :input-size="props.size"
      :required="props.required"
      :errored="hasErrors"
    />
    <div class="field-content">
      <AlgPopper
        placement="bottom-start"
      >
        <template #reference="{ close, toggle, isOpen }">
          <div class="input-wrapper">
            <input
              :id="props.id"
              ref="inputRef"
              type="time"
              class="input has-suffix"
              :class="[
                `size-${props.size}`,
                `variant-${props.variant}`,
                {
                  errored: hasErrors,
                  clearable: !props.disabled && !props.required && props.modelValue,
                  centered: props.centered,
                }
              ]"
              :name="props.id"
              :placeholder="props.placeholder ?? t('datetime.placeholder.time')"
              :min="props.minValue"
              :max="props.maxValue"
              :required="props.required"
              :disabled="props.disabled"
              :readonly="props.readonly"
              :value="props.modelValue"
              :step="timeStep"
              pattern="[0-9]{2}:[0-9]{2}"
              @input="handleInput"
            >
            <span
              v-if="!props.disabled && !props.required && !props.readonly && props.modelValue"
              class="input-suffix clear-button"
            >
              <button
                type="button"
                :title="t('common.Clear')"
                @click="() => handleClear(close)"
              >
                <AlgIcon
                  name="cancel"
                  size="s"
                />
              </button>
            </span>
            <span class="input-suffix">
              <button
                type="button"
                :title="isOpen ? t('common.Close selector') : t('common.Open selector')"
                :disabled="props.disabled || props.readonly"
                @click="() => handleClick(toggle)"
              >
                <AlgIcon
                  name="schedule"
                  :color="
                    props.disabled
                      ? 'var(--alg-color-icon-unselected)'
                      : 'var(--alg-color-icon-highlight)'
                  "
                  size="s"
                />
              </button>
            </span>
          </div>
        </template>
        <template #content>
          <div class="selector">
            <ul ref="hourList">
              <li
                v-for="(hour, k) in hours"
                :key="k"
                :class="{ selected: modelValueHour === +hour }"
              >
                <button
                  type="button"
                  @click="() => handleHourChange(hour)"
                >
                  {{ hour }}
                </button>
              </li>
            </ul>
            <div class="separator" />
            <ul ref="minuteList">
              <li
                v-for="(minute, k) in minutes"
                :key="k"
                :class="{ selected: modelValueMinute === +minute }"
              >
                <button
                  type="button"
                  @click="() => handleMinuteChange(minute)"
                >
                  {{ minute }}
                </button>
              </li>
            </ul>
          </div>
        </template>
      </AlgPopper>
      <AlgErrors
        v-if="props.errors && props.errors.length"
        :errors="props.errors"
      />
    </div>
  </div>
</template>

<style src="../index.css" scoped />

<style scoped>
  .input::-webkit-calendar-picker-indicator {
    display: none;
  }

  .selector {
    position: relative;
    z-index: 2;
    display: flex;
    height: 180px;
    padding: var(--alg-spacing-xs);
    border-radius: var(--alg-effect-radius-m);
    background-color: var(--alg-color-surface-primary);
    box-shadow: var(--alg-effect-shadow-l);

    ul {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      gap: var(--alg-spacing-xs);
      list-style-type: none;
      overflow-y: auto;
      scroll-snap-type: y proximity;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        padding: 0;
        scroll-snap-align: start;

        button {
          height: 36px;
          padding: var(--alg-spacing-s) var(--alg-spacing-m);
          border-radius: var(--alg-effect-radius-s);
          color: var(--alg-color-text-primary);
          font-size: var(--alg-font-size-s);
          font-weight: var(--alg-font-weight-bold);
          user-select: none;

          &:hover {
            background-color: var(--alg-color-surface-background-highlight);
          }

          &:active {
            background-color: var(--alg-color-button-primary);
            color: var(--alg-color-text-on-color);
          }
        }

        &.selected {
          button {
            background-color: var(--alg-color-button-primary);
            color: var(--alg-color-text-on-color);

            &:hover {
              background-color: var(--alg-color-button-primary-hover);
            }
          }
        }

        &:last-child {
          margin-bottom: calc(180px - 36px); /* List height - Button height */
        }
      }
    }

    .separator {
      width: 1px;
      margin: 0 var(--alg-spacing-xs);
      background-color: var(--alg-color-surface-border);
    }
  }
</style>
