import { getLocale } from './locale'

/**
 * Returns a formatted number with the specified number of digits
 * @param value number to format
 * @param digits number of digits
 * @returns formatted number
 */
export const decimalFormat = (value: number, digits = 1) =>
  new Intl.NumberFormat(getLocale(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: digits,
  }).format(value)
