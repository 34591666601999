<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgButton } from '../button'

  interface Props {
    loading?: boolean
    current?: number
    total?: number
    from?: number
    to?: number
  }

  defineOptions({
    name: 'AlgPagination',
  })

  const props = withDefaults(defineProps<Props>(), {
    loading: false,
    current: 0,
    total: 0,
    from: 0,
    to: 0,
  })

  const emit = defineEmits<{
    (e: 'change', p: number): void
  }>()

  const { t } = useI18n()

  function previous() {
    if (props.current === 1) {
      return
    }

    emit('change', props.current - 1)
  }

  function next() {
    if (props.to === null || props.to === props.total) {
      return
    }

    emit('change', props.current + 1)
  }
</script>

<template>
  <div class="pagination">
    <AlgButton
      :inactive="props.loading"
      label="Précédent"
      variant="link"
      size="s"
      :disabled="props.current <= 1"
      @click="previous"
    />
    <div class="current">
      {{
        t('common.{n} of {total}', {
          n: t('common.{0}, {1}', [props.from, props.to]),
          total: props.total,
        })
      }}
    </div>
    <AlgButton
      :inactive="props.loading"
      label="Suivant"
      variant="link"
      size="s"
      :disabled="props.to === props.total"
      @click="next"
    />
  </div>
</template>

<style scoped>
  .pagination {
    position: relative;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;

    .current {
      flex: 1;
      font-size: var(--alg-font-size-xs);
      text-align: center;
    }
  }
</style>
