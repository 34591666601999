import { fetchJson } from '@algorh/shared'

import { InternalTaskState } from '@/core/enums/InternalTask'
import { PaginatedDto } from '@/core/types'
import { UserInstanceFull } from '@/core/types/InternalTask'

import { MeetingStatistics } from '../types/InternalTask'

const BASE_URI = '/api/scheduling/meetings'

const SchedulingMeetingsApiService = {
  getMeetingsCustomerAdvisers(meetingId: number, { search, page = 1, sort_by, direction, ...filters }: PaginatedDto) {
    // Only send sort params if direction is not null
    const sortParams = direction ? { sort_by, direction } : {}
    return fetchJson<UserInstanceFull[], null, PaginatedDto>(
      'GET', `${BASE_URI}/${meetingId}/customer-advisers`, null, { search, page, ...sortParams, ...filters },
    )
  },
  getMeetingsState(meetingId: number) {
    return fetchJson<{ id: number, state: InternalTaskState }>(
      'GET', `${BASE_URI}/${meetingId}/state`,
    )
  },
  getMeetingsStatistics(meetingId: number) {
    return fetchJson<MeetingStatistics>(
      'GET', `${BASE_URI}/${meetingId}/statistics`,
    )
  },
  postMeetingValidation(meetingId: number) {
    return fetchJson(
      'POST', `${BASE_URI}/${meetingId}/validate`,
    )
  },
}

export { SchedulingMeetingsApiService }
