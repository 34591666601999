<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { decimalFormat, round, ShortTimeString, TimeString } from '@algorh/shared'

  import { AlgIcon } from '../../media'

  type Props = {
    readonly start: ShortTimeString | TimeString
    readonly end: ShortTimeString | TimeString
    readonly duration?: boolean
    readonly grayed?: boolean
  }

  defineOptions({
    name: 'AlgTimePeriod',
  })

  const props = withDefaults(defineProps<Props>(), {
    duration: true,
    grayed: false,
  })

  const { t } = useI18n()

  const durationInHours = computed(() => {
    const [startHours, startMinutes] = props.start.split(':')
    const [endHours, endMinutes] = props.end.split(':')

    const hours = parseInt(endHours) - parseInt(startHours)
    const minutes = parseInt(endMinutes) - parseInt(startMinutes)

    return decimalFormat(round(hours + minutes / 60, 1))
  })
</script>

<template>
  <div
    class="time-period"
    :class="{
      'grayed': props.grayed,
    }"
  >
    <AlgIcon
      name="schedule"
      size="s"
      class="icon"
    />
    <span class="period">
      {{ t('datetime.{start}, {end}', { start: props.start, end: props.end }) }}
    </span>
    <span
      v-if="props.duration"
      class="duration"
    >
      ({{ t('units.h', { count: durationInHours }, durationInHours) }})
    </span>
  </div>
</template>

<style scoped>
  .time-period {
    display: inline-flex;
    height: var(--alg-spacing-xl);
    align-items: center;
    font-size: var(--alg-font-size-s);
    gap: var(--alg-spacing-xs);
    white-space: nowrap;

    .icon {
      color: var(--alg-color-icon-highlight);
    }

    .period {
      color: var(--alg-color-text-primary);
      font-weight: var(--alg-font-weight-bold);
    }

    &.grayed {
      .icon {
        color: var(--alg-color-icon-secondary);
      }

      .period {
        color: var(--alg-color-text-secondary);
        font-weight: var(--alg-font-weight-regular);
      }

      .duration {
        color: var(--alg-color-text-secondary);
      }
    }
  }
</style>
