import { fetchJson } from '@algorh/shared'

import { InternalTaskState } from '@/core/enums/InternalTask'
import { PaginatedDto } from '@/core/types'
import { UserInstanceFull } from '@/core/types/InternalTask'

import { TrainingSolution, TrainingStatistics } from '../types/InternalTask'
const BASE_URI = '/api/scheduling/trainings'

const SchedulingTrainingsApiService = {
  getTrainingsCustomerAdvisers(trainingId: number, { search, page = 1, sort_by, direction, ...filters }: PaginatedDto) {
    // Only send sort params if direction is not null
    const sortParams = direction ? { sort_by, direction } : {}

    return fetchJson<UserInstanceFull[], undefined, PaginatedDto>(
      'GET', `${BASE_URI}/${trainingId}/customer-advisers`, null, { search, page, ...sortParams, ...filters },
    )
  },
  getTrainingsState(trainingId: number) {
    return fetchJson<{ id: number, state: InternalTaskState }>(
      'GET', `${BASE_URI}/${trainingId}/state`,
    )
  },
  getTrainingsStatistics(trainingId: number) {
    return fetchJson<TrainingStatistics>(
      'GET', `${BASE_URI}/${trainingId}/statistics`,
    )
  },
  getTrainingsSummary(trainingId: number) {
    return fetchJson<TrainingSolution>(
      'GET', `${BASE_URI}/${trainingId}/summary`,
    )
  },
  postTrainingValidation(trainingId: number) {
    return fetchJson(
      'POST', `${BASE_URI}/${trainingId}/validate`,
    )
  },
}

export { SchedulingTrainingsApiService }
