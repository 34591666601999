<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable } from '@algorh/shared'

  import { Gradient } from '#/types'

  import { AlgErrors } from '../../../feedback'
  import { AlgIcon } from '../../../media'
  import { AlgLabel } from '../../label'
  import { AlgColorInput } from '../color-input'
  import { InputSize, InputVariant } from '../Input.type'

  type Props = {
    readonly id: string
    readonly modelValue: Gradient
    readonly label?: string
    readonly sublabel?: string
    readonly size?: InputSize
    readonly required?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
    readonly variant?: InputVariant
  }

  defineOptions({
    name: 'AlgColorInput',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 'm',
    required: false,
    disabled: false,
    readonly: false,
    errored: false,
    variant: 'primary',
  })

  const emit = defineEmits<{
    (e: 'update:model-value', value: Gradient): void
  }>()

  const { t } = useI18n()

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  const linked = ref(false)

  // Methods
  function handleToggleLink() {
    linked.value = !linked.value

    if (linked.value) {
      handleInput()
    }
  }

  function handleInput({
    primaryColor,
    secondaryColor,
  }: {
    primaryColor?: Nullable<string>
    secondaryColor?: Nullable<string>
  } = {}) {
    emit('update:model-value', {
      primaryColor: primaryColor ?? props.modelValue?.primaryColor ?? null,
      secondaryColor: linked.value
        ? primaryColor ?? props.modelValue?.primaryColor ?? null
        : secondaryColor ?? props.modelValue?.secondaryColor ?? null,
    })
  }
</script>

<template>
  <div
    class="field-wrapper"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :html-for="props.id"
      :input-size="props.size"
      :required="props.required"
      :errored="hasErrors"
    />
    <div class="gradient-input-wrapper">
      <div class="branch">
        <button
          type="button"
          class="color-link-button"
          :title="linked ? t('common.Unlink') : t('common.Link')"
          :disabled="props.disabled"
          @click="handleToggleLink"
        >
          <AlgIcon
            :name="linked ? 'link' : 'link-off'"
            size="s"
          />
        </button>
      </div>
      <div class="gradient-inputs">
        <AlgColorInput
          :id="`${id}-primary-color`"
          hidden-label
          :disabled="props.disabled"
          :errored="(props.errors && props.errors.length > 0) || props.errored"
          :label="t('common.Primary color')"
          :model-value="props.modelValue?.primaryColor ?? null"
          :required="props.required"
          :readonly="props.readonly"
          :size="props.size"
          :variant="props.variant"
          @update:model-value="(v) => handleInput({ primaryColor: v })"
        />
        <AlgColorInput
          :id="`${id}-secondary-color`"
          hidden-label
          :disabled="linked || props.disabled"
          :errored="(props.errors && props.errors.length > 0) || props.errored"
          :label="t('common.Secondary color')"
          :model-value="props.modelValue?.secondaryColor ?? null"
          :required="props.required"
          :readonly="props.readonly"
          :size="props.size"
          :variant="props.variant"
          @update:model-value="(v) => handleInput({ secondaryColor: v })"
        />
      </div>
    </div>
    <AlgErrors
      v-if="props.errors && props.errors.length"
      :errors="props.errors"
    />
  </div>
</template>

<style src="../index.css" scoped />

<style scoped>
  .gradient-input-wrapper {
    position: relative;
    align-self: stretch;
    padding-left: 32px;

    .branch {
      position: absolute;
      top: 30%;
      bottom: 12%;
      left: 8px;
      display: flex;
      width: 2px;
      flex-direction: column;
      justify-content: space-between;
      background-color: var(--alg-color-surface-border);

      &::before,
      &::after {
        position: absolute;
        width: var(--alg-spacing-s);
        height: var(--alg-spacing-s);
        border: 1px solid  var(--alg-color-surface-border);
        border-radius: 50%;
        background-color: var(--alg-color-surface-border);
        content: '';
      }

      &::before {
        top: -2px;
        left: 50%;
        transform: translateX(-50%);
      }

      &::after {
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
      }

      .color-link-button {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 50%;
        background-color: var(--alg-color-surface-primary);
        color: var(--alg-color-icon-secondary);
        transform: translate(-50%, -50%);
        transition: color 150ms ease-in-out;

        &:hover {
          color: var(--alg-color-icon-primary);
        }

        &:focus-visible {
          outline: 2px solid  var(--alg-color-icon-highlight);
        }

        &[disabled] {
          cursor: not-allowed;
        }
      }
    }

    .gradient-inputs {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
</style>
