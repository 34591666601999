import { DotNestedKeys, getNestedValue, isNull, isString, isUndefined } from '../types'

/**
 * Flattens an array of arrays with a callback
 * @param callback
 * @param key
 * @returns The flattened array
 */
const gather
  = <T extends { [key in keyof T]: T[key] }, R>(callback: (value: T) => R, key: keyof T) =>
    (value: T): R[] => [callback(value), ...(value[key] || []).flatMap(gather(callback, key))]

/**
 * Flattens an array of arrays recursively with a callback
 * @param value
 * @param key
 * @param callback
 * @returns The flattened array
 */
export const recursiveFlatMap = <T, R>(value: T, key: keyof T, callback: (value: T) => R) => {
  if (!Array.isArray(value[key])) {
    throw new Error('Property of key is not an array')
  }

  return gather(callback, key)(value)
}

/**
 * Removes all duplicates from an array
 * @param array The array to remove duplicates from
 * @returns The array without duplicates
 */
export function unique<T>(array: T[]) {
  return [...new Set(array)]
}

/**
 * Sort array by a given getter function and alphabetical sorting
 * The function takes three parameters: a number value, a minimum value, and a maximum allowed value.
 * @param value The given number value
 * @param min The minimum value is the smallest number
 * @param max The maximum value is the largest number
 * @returns The clamped value
 */
export const orderByKey = <T>(
  arr: T[],
  key: DotNestedKeys<T>,
  order: 'asc' | 'desc' = 'asc',
): T[] => {
  return arr.toSorted((a, b) => {
    if (!key)
      return 0

    const aValue = getNestedValue(a, key)
    const bValue = getNestedValue(b, key)

    let comparison = 0

    switch (true) {
      case aValue === bValue:
        comparison = 0
        break
      case isString(aValue) && isString(bValue):
        comparison = aValue.toString().localeCompare(bValue.toString())
        break
      case isNull(bValue) || isUndefined(bValue):
        comparison = 1
        break
      case isNull(aValue) || isUndefined(aValue):
        comparison = -1
        break
      default:
        comparison = (aValue as number) - (bValue as number)
        break
    }

    return order === 'desc' ? -comparison : comparison
  })
}
