<script setup lang="ts">
  import { RouteLocation, RouteLocationRaw } from 'vue-router'

  import { Nullable } from '@algorh/shared'
  import { AlgLink, AlgSearchInput } from '@algorh/ui'

  type Props = {
    allowSearch?: boolean
    disabled?: boolean
    goBackLink?: RouteLocation | RouteLocationRaw | string
    loading?: boolean
    searchPlaceholder?: string
    searchQuery?: Nullable<string>
    title?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    allowSearch: false,
    disabled: false,
    loading: false,
    searchPlaceholder: '',
    searchQuery: '',
  })

  const emit = defineEmits<{
    (e: 'search', query: Nullable<string>): void
  }>()

  function handleSearch(query: Nullable<string>) {
    emit('search', query)
  }
</script>

<template>
  <header class="header">
    <div class="header-title-wrapper">
      <div
        v-if="props.goBackLink"
        class="go-back-link"
      >
        <AlgLink
          :to="props.goBackLink"
          variant="secondary"
          icon-start="arrow-back"
        />
      </div>
      <h2
        v-if="props.title"
        class="header-title"
        v-html="props.title"
      />
    </div>
    <div class="header-actions-wrapper">
      <AlgSearchInput
        v-if="props.allowSearch"
        id="search"
        class="search-input"
        :disabled="props.disabled"
        :loading="props.loading"
        :placeholder="props.searchPlaceholder"
        :model-value="props.searchQuery"
        @update:model-value="handleSearch"
      />
      <slot name="actions" />
    </div>
  </header>
</template>

<style scoped>
  .header {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 40px;
    flex-flow: column wrap;
    flex-shrink: 0;
    margin-bottom: var(--alg-spacing-s);
    gap: var(--alg-spacing-s);

    .header-title-wrapper {
      display: flex;
      align-items: center;
      gap: var(--alg-spacing-s);

      .header-title {
        color: var(--alg-color-text-secondary);
        font-size: var(--alg-font-size-l);
        font-weight: var(--alg-font-weight-bold);
      }
    }

    .header-actions-wrapper {
      display: flex;
      flex: 1 1 auto;
      flex-flow: column wrap;
      justify-content: end;
      gap: var(--alg-spacing-s);

      .search-input {
        width: 100%;
      }
    }

    @media (width >= 720px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .header-actions-wrapper {
        flex-direction: row;
        align-items: center;

        .search-input {
          width: 300px;
        }
      }
    }
  }
</style>
