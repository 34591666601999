import { FetchMethod, FetchResult, Nullable } from '../types'

import { round } from './math'

let callback: Nullable<(status: number) => void> = null

type ParamsObjTypes = string | number | boolean | null | undefined
type Params = Record<string, ParamsObjTypes | ParamsObjTypes[] | Record<string, ParamsObjTypes | ParamsObjTypes[]>[]>

interface FetchFile<A, B> {
  method?: FetchMethod
  url: string
  body?: Nullable<A>
  params?: Nullable<B>
  name?: string
  visualize?: boolean
  localCallback?: () => void
}

const setCallback = (fn: typeof callback) => {
  callback = fn
}

const getXSRFValueInCookie = (cookie: string) => {
  const match = cookie.match(new RegExp('(^| )XSRF-TOKEN=([^;]+)'))
  return match ? match[2].replace('%3D', '=') : null
}

/**
 *
 * @param param0
 * @returns
 */
async function fetchFile<A = undefined, B extends Params = Params>({
  method,
  url,
  params,
  body,
  name,
  visualize = false,
  localCallback,
}: FetchFile<A, B>) {
  let fullUrl = `${url}`
  if (params) {
    fullUrl += `?${getStringParams(params)}`
  }
  const request = new Request(fullUrl, {
    credentials: 'include',
    method: method ?? 'GET',
    body: body ? JSON.stringify(body) : null,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getXSRFValueInCookie(document.cookie) ?? '',
    },
  })

  try {
    const response = await fetch(request)

    if (callback) {
      callback(response.status)
    }

    if (response.ok) {
      const header = response.headers.get('Content-Disposition')

      const filename = name ?? header?.match(/filename="(.+)"/)?.[1] ?? 'untitled_file'

      const a = document.createElement('a')

      const blob = await response.blob()

      const objectUrl = URL.createObjectURL(blob)

      a.setAttribute('target', '_blank')
      a.setAttribute('href', objectUrl)
      a.setAttribute(visualize ? 'name' : 'download', decodeURIComponent(filename))

      a.click()
      URL.revokeObjectURL(objectUrl)
      return { data: null, meta: null }
    } else {
      const error = await response.json()

      const allowedErrors = [404, 417, 422]

      if (allowedErrors.includes(response.status)) {
        throw { ...error, status: response.status }
      } else {
        return { data: null, meta: null }
      }
    }
  } finally {
    if (localCallback) {
      localCallback()
    }
  }
}

/**
 *
 * @param method
 * @param url
 * @param body
 * @returns
 */
async function fetchUpload<T>(method: FetchMethod, url: string, body?: FormData): Promise<FetchResult<T>> {
  const request = new Request(url, {
    credentials: 'include',
    method,
    body,
    headers: {
      'Accept': 'application/json',
      'X-XSRF-TOKEN': getXSRFValueInCookie(document.cookie) ?? '',
    },
  })

  const response = await fetch(request)

  if (response.ok) {
    try {
      if (response.status === 204) {
        return { data: null, meta: null }
      }
      const parsed = await response.json()
      return parsed
    } catch (e) {
      throw new Error(`Failed parsing server response: ${e}`)
    }
  } else {
    if (response.status) {
      const err = await response.json()
      throw typeof err === 'object'
        ? { ...err, status: response.status }
        : { err: err, status: response.status }
    } else {
      throw await response.json()
    }
  }
}

/**
 *
 * @param params
 * @param parentKey
 * @returns
 */
function getStringParams(params: Params, parentKey?: string): string {
  const excludedValues: (ParamsObjTypes | Record<string, ParamsObjTypes | ParamsObjTypes[]>)[] = [undefined, null]

  const isValidValue = (value: Params[string]): boolean => {
    if (Array.isArray(value)) {
      return value.length > 0 && !value.every((i) => excludedValues.includes(i))
    }
    return !excludedValues.includes(value as undefined | null)
  }

  const buildQueryString = (value: Params[string], key: string) => {
    if (Array.isArray(value)) {
      return value.map((v, i) => typeof v === 'object' && v !== null
        ? getStringParams(v, `${key}[${i}]`)
        : `${parentKey ?? key}[${i}]=${v}`).join('&')
    }
    const baseKey = parentKey ? `${parentKey}[${key}]` : `${key}`
    return `${baseKey}=${value}`
  }

  return Object.entries(params)
    .filter(([, value]) => isValidValue(value))
    .map(([key, value]) => buildQueryString(value, key))
    .join('&')
}

/**
 *
 * @param method
 * @param url
 * @param body
 * @param params
 * @param signal
 * @returns
 */
async function fetchJson<T = void, B = undefined, C = Params>(
  method: FetchMethod,
  url: string,
  body?: Nullable<B>,
  params?: Nullable<C>,
  signal?: AbortSignal,
): Promise<FetchResult<T>> {
  let fullUrl = `${url}`
  if (params) {
    fullUrl += `?${getStringParams(params)}`
  }

  const request = new Request(fullUrl, {
    credentials: 'include',
    method,
    body: body ? JSON.stringify(body) : null,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getXSRFValueInCookie(document.cookie) ?? '',
    },
  })

  const response = await fetch(request, { signal })

  if (callback) {
    callback(response.status)
  }

  if (response.ok) {
    try {
      if (response.status === 204) {
        return { data: null, meta: null }
      }

      const parsed = await response.json()
      return parsed as FetchResult<T>
    } catch (e) {
      throw new Error(`Failed parsing server response: ${e}`)
    }
  } else {
    const error = await response.json()

    const allowedErrors = [403, 404, 417, 422]

    if (allowedErrors.includes(response.status)) {
      throw { ...error, status: response.status }
    } else {
      return { data: null, meta: null }
    }
  }
}

/**
 *
 * @param method
 * @param url
 * @param body
 * @param onProgress
 * @returns
 */
function futch<T = unknown>(
  method: FetchMethod,
  url: string,
  body?: FormData,
  onProgress?: (loaded: number) => void,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(method, url, true)

    xhr.setRequestHeader('Accept', 'application/json')
    xhr.setRequestHeader('X-XSRF-TOKEN', getXSRFValueInCookie(document.cookie) ?? '')

    xhr.onloadend = () => {
      if (![200, 201, 204, 206].includes(xhr.status)) {
        reject({ ...JSON.parse(xhr.response), status: xhr.status })

        if (onProgress) {
          onProgress(0)
        }
      } else {
        resolve(xhr.response)
      }
    }

    if (xhr.upload && onProgress)
      xhr.upload.onprogress = (event) => {
        if (xhr.status !== 204) {
          onProgress(round((event.loaded / event.total) * 100))
        }
      }

    xhr.send(body)
  })
}

export { setCallback, fetchFile, fetchUpload, getStringParams, futch, fetchJson }
