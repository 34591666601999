<script lang="ts" setup>
  import { computed } from 'vue'

  import { HumanizeAmbition, humanizeAmbition } from './Ambition'
  import { HumanizeRule, humanizeRule } from './rule'

  type Props = {
    item: HumanizeAmbition | HumanizeRule
    shouldShowEditable?: boolean
    isForOverride?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    shouldShowEditable: false,
    isForOverride: true,
  })

  const getRuleHtml = computed(() => {
    if ('action' in props.item) {
      return props.isForOverride
        ? humanizeAmbition.ambitionOverridedHtml(props.item)
        : humanizeAmbition.ambitionHtml(props.item)
    } else {
      return props.isForOverride
        ? humanizeRule.ruleOverridedHtml(props.item)
        : humanizeRule.ruleHtml(props.item)
    }
  })
</script>

<template>
  <div
    class="simple-rule"
    :class="{ editable: shouldShowEditable }"
    v-html="getRuleHtml"
  />
</template>

<style scoped>
  .simple-rule {
    cursor: default;

    &::first-letter {
      text-transform: uppercase;
    }

    &:deep(.has-dot) {
      &:nth-child(1)::first-letter {
        text-transform: uppercase;
      }

      margin: 0;

      &::after {
        visibility: hidden;
      }
    }

    &.editable {
      margin-top: 10px;
      counter-reset: section;
      line-height: 20px;
      text-align: center;

      &:deep(.has-dot) {
        display: inline-block;
        margin: 35px 4px 8px;

        &::after {
          margin-left: calc(50% - 10px);
          visibility: visible;
        }
      }
    }

    &::after {
      content: '.';
    }
  }
</style>
