import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'

import { UserPermissions } from '@/core/enums'
import { ActivityCategory } from '@/core/enums/Activity'
import { RoleType } from '@/core/enums/User'
import { CommonApiService, MeApiService } from '@/core/services'
import { queryClient } from '@/queryClient'

import { SchedulingSubdivisionsApiService } from '@/sections/scheduling/services'

const staleTime = 24 * 60 * 60 * 1000 // 24H
export function useAppInit() {
  // Computed
  const permissions = computed(() => me.value?.data?.permissions ?? null)

  const roles = computed(() => me.value?.data?.roles ?? [])

  const isAuthenticated = computed(() => me.value ? me.value.data !== null : false)

  const isTrainer = computed(() => (me.value?.data?.roles.find((r) => r.type === RoleType.TRAINER) ?? null) !== null)

  const isCustomerAdviser = computed(() => (me.value?.data?.roles.find((r) => r.type === RoleType.CUSTOMER_ADVISER) ?? null) !== null)

  const canFetchOperationalSubdivisions = computed(() =>
    permissions.value?.[UserPermissions.ACCESS_SCHEDULING] ?? false,
  )

  const isLoadingApp = computed(() => isLoadingProjectActivities || isLoadingMe || isLoadingOperationalSubdivisions)
  const meId = computed(() => me.value?.data?.id ?? null)

  const projectActivitiesEditable = computed(() => projectActivities.value.data
    ?.filter(({ selectable, raw_planifiable, manually_planifiable }) =>
      [manually_planifiable, selectable, raw_planifiable].some((v) => v)) ?? [])

  const projectActivitiesAutomaticallySchedulable = computed(() => projectActivities.value.data
    ?.filter(({ schedulable, raw_planifiable }) => [schedulable, raw_planifiable].some((v) => v)) ?? [])

  const projectActivitiesProduction = computed(() => projectActivities.value.data
    ?.filter(({ category }) => category === ActivityCategory.PRODUCTION) ?? [])

  const projectActivitiesNonProduction = computed(() => projectActivities.value.data
    ?.filter(({ category }) => category === ActivityCategory.NON_PRODUCTION) ?? [])

  // Composable
  const { data: me, isLoading: isLoadingMe } = useQuery({
    enabled: true,
    queryKey: ['me'],
    queryFn: MeApiService.getMe,
    staleTime,
  })

  const {
    data: projectActivities,
    isFetching: isLoadingProjectActivities,
  } = useQuery({
    queryKey: [meId, 'common', 'project-activities'],
    queryFn: CommonApiService.getProjectActivities,
    staleTime,
    enabled: isAuthenticated,
    initialData: { data: [] },
    initialDataUpdatedAt: 0,
  })

  const { data: operationalSubdivisions, isLoading: isLoadingOperationalSubdivisions } = useQuery({
    enabled: canFetchOperationalSubdivisions,
    queryKey: ['scheduling', 'operational-subdivisions'],
    queryFn: SchedulingSubdivisionsApiService.getSubdivisions,
    staleTime,
    initialData: { data: [] },
    initialDataUpdatedAt: 0,
  })

  function invalidateProjectActivities() {
    queryClient.invalidateQueries({ queryKey: [meId, 'common', 'project-activities'] })
  }

  return {
    projectActivitiesAutomaticallySchedulable,
    projectActivitiesEditable,
    projectActivitiesNonProduction,
    projectActivitiesProduction,
    operationalSubdivisions,
    isAuthenticated,
    roles,
    projectActivities,
    me,
    isTrainer,
    isCustomerAdviser,
    permissions,
    isLoadingApp,
    invalidateProjectActivities,
  }
}
