<script setup lang="ts">
  type Props = {
    color: string
  }

  const props = defineProps<Props>()
</script>

<template>
  <span
    class="color-indicator"
    :style="{
      backgroundColor: props.color
    }"
  />
</template>

<style scoped>
  .color-indicator {
    display: inline-block;
    width: var(--alg-font-size-m);
    height: var(--alg-font-size-m);
    flex-shrink: 0;
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-effect-radius-s);
  }
</style>
