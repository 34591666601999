import { fetchJson, Nullable } from '@algorh/shared'

import { PaginatedDto } from '@/core/types'

import { DetailedInternalTaskInstance, InternalTask, UserInstance } from '../types/InternalTask'
import { CustomerAdviser } from '../types/Planning'

const BASE_URI = '/api/scheduling/meeting-instances'

const SchedulingMeetingInstancesApiService = {
  getMeetingDetails(meetingId: number) {
    return fetchJson<{ task: InternalTask, instance: DetailedInternalTaskInstance }>(
      'GET', `${BASE_URI}/${meetingId}`,
    )
  },
  putMeeting(meetingId: number, payload: { location: Nullable<string>, link: Nullable<string> }) {
    return fetchJson(
      'PUT', `${BASE_URI}/${meetingId}`, payload,
    )
  },
  deleteMeeting(meetingId: number) {
    return fetchJson(
      'DELETE', `${BASE_URI}/${meetingId}`,
    )
  },
  getMeetingCustomerAdvisers(meetingId: number, dto: PaginatedDto) {
    return fetchJson<UserInstance[], null, PaginatedDto>(
      'GET', `${BASE_URI}/${meetingId}/customer-advisers`, null, dto,
    )
  },
  deleteMeetingCustomerAdviser(meetingId: number, customerAdviserId: number) {
    return fetchJson(
      'DELETE', `${BASE_URI}/${meetingId}/customer-advisers/${customerAdviserId}`,
    )
  },
  getMeetingAvailableCustomerAdvisers(meetingId: number, dto: PaginatedDto) {
    return fetchJson<CustomerAdviser[], null, PaginatedDto>(
      'GET', `${BASE_URI}/${meetingId}/customer-advisers/available`, null, dto,
    )
  },
  postMeetingCustomerAdvisersBatch(meetingId: number, userIds: number[]) {
    return fetchJson(
      'POST', `${BASE_URI}/${meetingId}/customer-advisers/batch`, { user_ids: userIds },
    )
  },
}

export { SchedulingMeetingInstancesApiService }
