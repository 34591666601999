<script lang="ts" setup>
  import { onBeforeMount, ref, watch } from 'vue'

  import { AlgIcon } from '../media'

  defineOptions({
    name: 'AlgDisclosure',
  })

  const props = withDefaults(defineProps<Props>(), {
    open: false,
    disabled: false,
    droppable: false,
    bordered: false,
    rounded: false,
    backgroundColor: 'var(--alg-color-surface-background)',
    variant: 'secondary',
  })

  const emit = defineEmits<{
    (e: 'update:model-value', p: boolean): void
  }>()

  interface Props {
    readonly open?: boolean
    readonly disabled?: boolean
    readonly droppable?: boolean
    readonly bordered?: boolean
    readonly rounded?: boolean
    readonly backgroundColor?: string
    readonly variant?: 'primary' | 'secondary'
  }

  // Refs
  const el = ref<HTMLDetailsElement>()

  const hovered = ref(false)

  const openState = ref(false)

  // Methods
  function handleToggle() {
    if (!props.disabled) {
      openState.value = !openState.value
      emit('update:model-value', openState.value)
    }
  }

  onBeforeMount(() => {
    openState.value = props.open
  })

  watch(() => props.open, (value) => {
    openState.value = value
  })
</script>

<template>
  <details
    ref="el"
    class="disclosure"
    :class="[
      `variant-${props.variant}`,
      {
        bordered: props.bordered,
        rounded: props.rounded,
        droppable: props.droppable,
      }
    ]"
    :style="{
      backgroundColor: props.backgroundColor
    }"
    :open="openState"
  >
    <summary
      class="summary"
      role="button"
      tabindex="0"
      :class="{
        disabled: disabled,
        hovered: hovered,
      }"
      @click.prevent.stop="handleToggle"
      @keydown.enter="handleToggle"
      @mouseenter="hovered = true"
      @mouseleave="hovered = false"
      @focusin="hovered = true"
      @focusout="hovered = true"
    >
      <slot name="trigger" />
      <div class="chevron-wrapper">
        <AlgIcon
          name="chevron-right"
          size="s"
          :rotate="openState ? -90 : 90"
        />
      </div>
    </summary>
    <div class="details-wrapper">
      <slot
        v-if="openState"
        name="default"
      />
    </div>
  </details>
</template>

<style scoped>
.disclosure {
  position: relative;
  display: inline-flex;
  min-height: fit-content;
  flex-direction: column;
  border: 1px solid var(--alg-color-transparent);

  .summary {
    position: sticky;
    z-index: 2;
    top: 0;
    display: inline-flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--alg-spacing-s) var(--alg-spacing-m);
    background-color: var(--alg-color-surface-primary);
    cursor: pointer;
    font-size: var(--alg-font-size-m);
    font-weight: var(--alg-font-weight-bold);
    gap: var(--alg-spacing-m);
    list-style: none;
    transition: background-color 150ms ease-in-out;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &::-webkit-details-marker {
      display: none;
    }

    .chevron-wrapper {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
    }
  }

  &.variant-primary {
    .summary {
      background-color: var(--alg-color-surface-background-highlight);
      color: var(--alg-color-text-highlight);

      &.hovered {
        background-color: var(--alg-color-surface-primary);
      }
    }
  }

  &.variant-secondary {
    background-color: var(--alg-color-surface-primary);
    color: var(--alg-color-text-primary);

    summary {
      &.hovered{
        background-color: var(--alg-color-surface-background-highlight);
      }
    }
  }

  &.bordered {
    overflow: hidden;
    border: 1px solid var(--alg-color-surface-border);
  }

  &.rounded {
    border-radius: var(--alg-effect-radius-m);

    .summary {
      border-radius: var(--alg-effect-radius-m);
    }

    &[open] {
      .summary {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &.droppable {
    border-style: dashed;
    border-color: var(--alg-color-surface-highlight);
  }

  .details-wrapper {
    padding: var(--alg-spacing-m);
    overflow-y: auto;
  }
}
</style>
