<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgIconButton } from '../icon-button'

  import type { ChipSize, ChipVariant } from './Chip.types'

  type Props = {
    readonly id: string | number
    readonly label?: string
    readonly variant?: ChipVariant
    readonly color?: string
    readonly size?: ChipSize
    readonly deletable?: boolean
    readonly disabled?: boolean
  }

  defineOptions({
    name: 'AlgChip',
  })

  const props = withDefaults(defineProps<Props>(), {
    variant: 'primary',
    size: 'm',
    deletable: false,
    disabled: false,
  })

  const emit = defineEmits<{
    (e: 'delete'): void
  }>()

  const { t } = useI18n()

  function handleDelete() {
    emit('delete')
  }
</script>

<template>
  <div
    :id="`chip-${props.id}`"
    class="chip"
    :class="[
      {
        disabled: props.disabled
      },
      `variant-${props.variant}`,
      `size-${props.size}`,
    ]"
    :style="props.color ? {'background-color': props.color, 'border-color': props.color} : {}"
  >
    <slot>
      {{ props.label }}
    </slot>
    <AlgIconButton
      v-if="props.deletable"
      :id="`delete-chip-${props.id}`"
      variant="transparent"
      size="s"
      icon="close"
      :icon-color="props.disabled ? 'var(--alg-color-text-light)' : 'var(--alg-color-text-on-color)'"
      tooltip
      tooltip-placement="top"
      :label="t('common.Delete')"
      :disabled="props.disabled"
      @click.stop="handleDelete"
    />
  </div>
</template>

<style scoped>
  .chip {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    border-radius: 1rem;
    font-weight: var(--alg-font-weight-regular);
    user-select: none;
    white-space: nowrap;

    &.disabled {
      cursor: not-allowed;
    }

    &.size-xs {
      height: var(--alg-font-size-s);
      font-size: var(--alg-font-size-xxs);
      line-height: var(--alg-font-size-s);
      padding-inline: var(--alg-spacing-xs);
    }

    &.size-s {
      height: var(--alg-font-size-m);
      font-size: var(--alg-font-size-s);
      line-height: var(--alg-font-size-m);
      padding-inline: var(--alg-spacing-xs);
    }

    &.size-m {
      height: var(--alg-font-size-3xl);
      font-size: var(--alg-font-size-s);
      line-height: var(--alg-font-size-3xl);
      padding-inline: var(--alg-spacing-s);
    }

    &.variant-primary {
      border-color: var(--alg-color-text-highlight);
      background-color: var(--alg-color-text-highlight);
      color: var(--alg-color-text-on-color);
    }

    &.variant-primary.disabled {
      border-color: var(--alg-color-surface-background-highlight);
      background-color: var(--alg-color-surface-background-highlight);
      color: var(--alg-color-text-light);
    }

    &.variant-secondary {
      border-color: var(--alg-color-icon-unselected);
      background-color: var(--alg-color-icon-unselected);
      color: var(--alg-color-text-primary);
    }
  }
</style>
