<script setup lang="ts" generic="T extends Nullable<DateString | ShortTimeString>">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { DateString, Nullable, ShortTimeString } from '@algorh/shared'

  import { AlgCounter, AlgErrors } from '../../feedback'
  import { AlgDateInput, AlgTimeInput } from '../../form'
  import { AlgIcon } from '../../media'
  import { AlgPopper } from '../../popover'

  type Props = {
    id: string
    label: string
    mode: 'date' | 'time'
    modelValue: [T, T]
    readonly?: boolean
    errors?: string[]
    disabled?: boolean
    color?: string
  }

  defineOptions({
    name: 'AlgPeriodFilter',
  })

  const props = withDefaults(defineProps<Props>(), {
    readonly: false,
    disabled: false,
  })

  const emit = defineEmits<{
    (e: 'update:model-value', p: typeof props.modelValue): void
  }>()

  // Composables
  const { t } = useI18n()

  // Computed
  const count = computed(() => {
    return props.modelValue.filter((v) => v !== null).length
  })

  // Methods
  function handleToggle(toggle: () => void) {
    if (props.disabled) {
      return
    }

    toggle()
  }
</script>

<template>
  <div class="period-filter">
    <AlgPopper
      class="period-filter-popper"
      placement="bottom"
      full-width
    >
      <template #reference="{ isOpen, toggle }">
        <button
          type="button"
          class="period-filter-reference"
          :class="{
            open: isOpen,
            disabled: props.disabled,
            errored : props.errors && props.errors.length > 0
          }"
          @click="() => handleToggle(toggle)"
        >
          <span class="label-wrapper">
            <span class="label">
              {{ props.label }}
            </span>
            <AlgCounter
              v-if="count > 0"
              :value="count"
              size="xs"
              :color="props.color"
            />
          </span>
          <AlgIcon
            size="s"
            :name="isOpen ? 'expand-less' : 'expand-more'"
            :color="props.disabled ? 'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-primary)'"
          />
        </button>
      </template>
      <template #content>
        <div
          class="period-filter-content"
        >
          <template v-if="props.mode === 'date'">
            <AlgDateInput
              id="period-start"
              class="period-start"
              :label="t('common.Start')"
              :errors="props.errors"
              :model-value="(props.modelValue[0] as DateString)"
              :disabled="props.readonly"
              @update:model-value="(v) => emit('update:model-value', [v as T, props.modelValue[1]])"
            />
            <AlgDateInput
              id="period-end"
              class="period-end"
              :errors="props.errors"
              :label="t('common.End')"
              :model-value="(props.modelValue[1] as DateString)"
              :disabled="props.readonly"
              @update:model-value="(v) => emit('update:model-value', [props.modelValue[0], v as T])"
            />
          </template>
          <template v-if="props.mode === 'time'">
            <AlgTimeInput
              id="period-start"
              class="period-start"
              :errors="props.errors"
              :label="t('common.Start')"
              :model-value="(props.modelValue[0] as ShortTimeString)"
              :disabled="props.readonly"
              @update:model-value="(v) => emit('update:model-value', [v as T, props.modelValue[1]])"
            />
            <AlgTimeInput
              id="period-end"
              class="period-end"
              :errors="props.errors"
              :label="t('common.End')"
              :model-value="(props.modelValue[1] as ShortTimeString)"
              :disabled="props.readonly"
              @update:model-value="(v) => emit('update:model-value', [props.modelValue[0], v as T])"
            />
          </template>
        </div>
      </template>
    </AlgPopper>
    <AlgErrors :errors="props.errors" />
  </div>
</template>

<style scoped>
.period-filter {
  position: relative;

  .period-filter-reference {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    padding: var(--alg-spacing-s) 0;
    border-bottom: 1px solid var(--alg-color-surface-border);
    cursor: pointer;
    user-select: none;

    &.errored {
      border-color: var(--alg-color-state-danger)
    }

    .label-wrapper {
      display: flex;
      align-items: center;
      gap: var(--alg-spacing-xs);

      .label {
        color: var(--alg-color-text-primary);
        font-weight: var(--alg-font-weight-bold);
      }
    }

    &.open {
      border-bottom-color: var(--alg-color-text-secondary);
    }

    &.disabled {
      cursor: not-allowed;

      .label {
        color: var(--alg-color-text-light);
      }
    }
  }

  .period-filter-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: var(--alg-spacing-s);
    border-radius: 0 0 var(--alg-effect-radius-s) var(--alg-effect-radius-s);
    background-color: var(--alg-color-surface-background);
    gap: var(--alg-spacing-s);

    .period-start,
    .period-end {
      flex: 1 1 auto
    }
  }
}
</style>
