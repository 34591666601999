<script setup lang="ts">
  import { Nullable } from '@algorh/shared'
  import { AlgIcon, IconName } from '@algorh/ui'

  type Props = {
    readonly name: string
    readonly icon?: Nullable<IconName>
    readonly abbreviation?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    icon: 'person',
  })
</script>

<template>
  <div class="list-title">
    <AlgIcon
      v-if="props.icon"
      :name="props.icon"
      class="dot-icon"
      color="var(--alg-color-icon-secondary)"
      size="s"
    />
    <div
      v-else
      class="dot-icon"
    >
      {{ props.abbreviation }}
    </div>
    <div>{{ props.name }}</div>
    <slot />
  </div>
</template>

<style scoped>
  .list-title {
    position: sticky;
    z-index: 1;
    top: 0;
    display: flex;
    align-items: center;
    padding: var(--alg-spacing-m) 0;
    background-color: var(--alg-color-surface-primary);
    color: var(--alg-color-text-primary);
    font-size: var(--alg-font-size-l);
    font-weight: var(--alg-font-weight-bold);
    gap: var(--alg-spacing-s);

    .dot-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--alg-spacing-xs);
      border-radius: 50%;
      background-color: var(--alg-color-surface-border);
    }
  }
</style>
