<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable } from '@algorh/shared'

  import { AlgTextInput } from '../../form'
  import { AlgModal } from '../../modal'

  interface Props {
    filter: Nullable<{ label: string, value: number }>
  }

  const props = defineProps<Props>()

  const emit = defineEmits<{
    (e: 'close'): void
    (e: 'confirm', label: string): void
  }>()

  const { t } = useI18n()

  const label = ref('')

  const errors = ref<Record<string, string[]>>({})

  const isLoading = ref(false)

  function handleClose() {
    emit('close')
  }

  function handleConfirm() {
    emit('confirm', label.value)
  }

  onMounted(() => {
    if (props.filter) {
      label.value = props.filter?.label ?? ''
    }
  })
</script>

<template>
  <AlgModal
    name="create-update-filter"
    size="xs"
    :confirm-button-loading="isLoading"
    @close="handleClose"
    @cancel="handleClose"
    @confirm="handleConfirm"
  >
    <template #title>
      {{
        filter
          ? t('common.Rename custom filters')
          : t('common.New custom filters')
      }}
    </template>
    <template #content>
      <div class="content-wrapper">
        <AlgTextInput
          id="combination-name"
          v-model="label"
          required
          :label="t('common.Custom filters name')"
          :placeholder="t('common.Custom filters name')"
          :errors="errors.label"
        />
      </div>
    </template>
  </AlgModal>
</template>

<style scoped>
  .content-wrapper {
    flex: 1 1 auto;
  }
</style>
