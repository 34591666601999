<script setup lang="ts">
  type Props = {
    readonly size?: 'xs' | 's' | 'm'
    readonly variant?: 'primary' | 'secondary'
  }

  defineOptions({
    name: 'AlgDotLoader',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 'm',
    variant: 'primary',
  })
</script>

<template>
  <div
    class="dot-loader-wrapper"
    :class="[
      `size-${props.size}`,
      `variant-${props.variant}`,
    ]"
  >
    <div
      class="dot-loader"
    />
  </div>
</template>

<style scoped>
@keyframes dots {
  0% {
    background-color: var(--primary-color);
    box-shadow: var(--offset) 0 var(--primary-color), calc(var(--offset) * -1) 0 var(--secondary-color)
  }

  33% {
    background-color: var(--secondary-color);
    box-shadow: var(--offset) 0 var(--primary-color), calc(var(--offset) * -1) 0 var(--secondary-color)
  }

  66% {
    background-color: var(--secondary-color);
    box-shadow: var(--offset) 0 var(--secondary-color), calc(var(--offset) * -1) 0 var(--primary-color)
  }

  100% {
    background-color: var(--primary-color);
    box-shadow: var(--offset) 0 var(--secondary-color), calc(var(--offset) * -1) 0 var(--primary-color)
  }
}

.dot-loader-wrapper {
  --offset: calc(var(--size) * 1.25);
  --secondary-color: var(--alg-color-icon-unselected);

  width: calc(var(--offset) * 3 - 0.25 * var(--size));

  &.size-xs {
    --size: var(--alg-spacing-xs);
  }

  &.size-s {
    --size: var(--alg-spacing-s);
  }

  &.size-m {
    --size: var(--alg-spacing-m);
  }

  &.variant-primary {
    --primary-color: var(--alg-color-icon-primary);
  }

  &.variant-secondary {
    --primary-color: var(--alg-color-surface-background);
  }

  .dot-loader {
    width: var(--size);
    border-radius: 50%;
    animation: dots 1s infinite linear alternate;
    aspect-ratio: 1;
    transform: translateX(var(--offset));
  }
}
</style>
