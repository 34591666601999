<script lang="ts" setup>
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import {
    CriticalPeriod,
    CriticalPeriodWithQuota,
    dayjs,
    isNumber,
    round,
    useClickOutside,
  } from '@algorh/shared'

  import { AlgBadge } from '../../badge'
  import { AlgIconButton } from '../../icon-button'
  import { AlgPopper } from '../../popover'
  import { AlgPlaceCalculationCard } from '../place-calculation-card'
  import { AlgQuotaCard } from '../quota-card'
  import { AlgAbsencePeriod } from '../shared'

  defineOptions({
    name: 'AlgCriticalPeriodLightCard',
  })

  const props = defineProps<Props>()

  type Props = {
    readonly criticalPeriod: CriticalPeriod
    readonly quotas?: (CriticalPeriodWithQuota & { id: number })
  }

  enum Availability {
    Incoming = 'incoming',
    Ongoing = 'ongoing',
    Finished = 'finished',
  }

  const quotasCard = ref<HTMLElement>()
  const quotasBtn = ref<HTMLElement>()
  const quotasPopper = ref<InstanceType<typeof AlgPopper>>()

  const { t } = useI18n()

  useClickOutside(quotasCard, () => {
    if (quotasPopper.value) {
      quotasPopper.value.close()
    }
  }, [quotasBtn])

  const availability = computed(() => {
    const now = dayjs()

    const start = dayjs(props.criticalPeriod.input_start_date)

    if (now.isBefore(start, 'day')) {
      return Availability.Incoming
    }

    if (now.isAfter(start) && (now.isSameOrBefore(dayjs(props.criticalPeriod.input_end_date), 'day'))) {
      return Availability.Ongoing
    }

    if (now.isAfter(dayjs(props.criticalPeriod.input_end_date), 'day')) {
      return Availability.Finished
    }

    return null
  })

  const availabilityBadgeVariant = computed(() => {
    switch (availability.value) {
    case Availability.Incoming:
      return 'info'
    case Availability.Ongoing:
      return 'success'
    case Availability.Finished:
      return 'warning'
    default:
      return 'secondary'
    }
  })

  const availabilityBadgeLabel = computed(() => {
    switch (availability.value) {
    case Availability.Incoming:
      return t('common.Incoming')
    case Availability.Ongoing:
      return t('common.Ongoing')
    case Availability.Finished:
      return t('common.Finished (m)')
    default:
      return ''
    }
  })

  const remainingDays = computed(() => {
    if (!props.quotas) {
      return 0
    }

    const endDate = dayjs(props.quotas.critical_period?.input_end_date).startOf('day')

    const today = dayjs().startOf('day')

    return endDate.diff(today, 'day')
  })

  const computedPlaceCount = computed(() => {
    if (isNumber(props.quotas?.quotas) && isNumber(props.quotas?.absences)) {
      const total = round(props.quotas.quotas / (60 * 8.5), 1)

      if (props.quotas.absences === 0) {
        return {
          total: total,
          count: total,
          percentage: 0,
        }
      } else if (props.quotas.absences === props.quotas.quotas) {
        return {
          total: total,
          count: 0,
          percentage: 100,
        }
      } else {
        const count = round((props.quotas.quotas - props.quotas.absences) / (60 * 8.5), 1)
        return {
          total,
          count,
          percentage: (1 - (count / total)) * 100,
        }
      }
    }
    return {
      total: 0,
      count: 0,
      percentage: 0,
    }
  })

  const computedCalculation = computed(() => {
    if (isNumber(props.quotas?.desiderata) && isNumber(props.quotas?.quotas)) {
      const desiderata = round((props.quotas.desiderata) / (60 * 8.5), 1)
      const initial = round((props.quotas.quotas) / (60 * 8.5), 1)

      return {
        desiderata,
        initial,
        percentage: props.quotas.quotas > 0
          ? round((props.quotas.desiderata * 100) / props.quotas.quotas, 2)
          : 0,
      }
    }
    return {
      total: 0,
      count: 0,
      percentage: 0,
    }
  })
</script>

<template>
  <div
    class="critical-period-light-card"
    :class="{ disabled: availability !== Availability.Ongoing }"
  >
    <div class="content-wrapper">
      <div class="request-period-wrapper">
        <AlgAbsencePeriod
          :start-date="criticalPeriod.input_start_date"
          :end-date="criticalPeriod.input_end_date"
        />
        <div class="badge">
          <AlgBadge
            v-if="availability"
            :variant="availabilityBadgeVariant"
            :label="availabilityBadgeLabel"
          />
        </div>
      </div>
      <div class="absence-period-wrapper">
        <AlgAbsencePeriod
          with-icon
          :start-date="criticalPeriod.start_date"
          :end-date="criticalPeriod.end_date"
        />

        <AlgPopper
          v-if="props.quotas && availability !== Availability.Incoming"
          ref="quotasPopper"
          placement="bottom-start"
        >
          <template #reference="{ toggle, isOpen }">
            <div ref="quotasBtn">
              <AlgIconButton
                :id="`display-critical-period-card-${criticalPeriod.id}`"
                :label="isOpen ? t('common.Hide statistics') : t('common.Show statistics')"
                icon="bar-chart-4-bars"
                variant="transparent"
                size="s"
                tooltip
                @click="toggle"
              />
            </div>
          </template>
          <template #content>
            <div
              ref="quotasCard"
              class="quotas-card"
            >
              <AlgPlaceCalculationCard
                v-if="props.quotas.desiderata !== undefined"
                :desiderata="computedCalculation.desiderata"
                :initial="computedCalculation.initial"
                :percentage="computedCalculation.percentage"
              />
              <AlgQuotaCard
                :progress="round(computedPlaceCount.percentage, 0)"
                :remaining-days="remainingDays"
                :quota-count="computedPlaceCount.count"
                :quota-total="computedPlaceCount.total"
                :display-remaining-days="(availability !== Availability.Incoming as Availability)"
                subdivision-name="Subdivision"
              />
            </div>
          </template>
        </AlgPopper>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .critical-period-light-card {
    display: flex;
    height: 2.5rem;
    box-sizing: border-box;
    gap: var(--alg-spacing-s);

    .content-wrapper {
      display: flex;
      overflow: hidden;
      flex: 1;
      flex-direction: column;
      gap: var(--alg-spacing-xs);

      .title {
        font-size: var(--alg-font-size-m);
        font-weight: var(--alg-font-weight-regular);
      }

      .request-period-wrapper {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        gap: var(--alg-spacing-s);
      }

      .absence-period-wrapper {
        position: relative;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        gap: var(--alg-spacing-m);
      }

      .absence-period-multiple {
        margin-top: calc(-1 * var(--alg-spacing-xs) / 2);
      }

      .badge {
        display: flex;
        flex: 1 1 auto;
        align-items: baseline;
        justify-content: flex-end;
      }
    }

    .quotas-card {
      display: flex;
      flex-direction: column;
      padding: var(--alg-spacing-m);
      border: 1px solid var(--alg-color-surface-border);
      border-radius: var(--alg-effect-radius-m);
      margin-top: calc(-1 * var(--alg-spacing-s));
      background-color: var(--alg-color-surface-primary);
      gap: var(--alg-spacing-xl);

      > * + * {
        position: relative;

        &::before {
          position: absolute;
          height: 1px;
          background-color: var(--alg-color-surface-border);
          content: "";
          inset-inline: 0;
          transform: translateY(calc(-1 * var(--alg-spacing-xl) / 2))
        }
      }
    }

    &.disabled {
      color: var(--alg-color-text-secondary);
    }
  }
</style>
