<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { DateString, dayjs, DTF } from '@algorh/shared'

  import { AlgIcon } from '../../../media'

  interface Props {
    readonly startDate: DateString
    readonly endDate: DateString
    readonly withIcon?: boolean
  }

  const props = defineProps<Props>()

  const { t } = useI18n()
</script>

<template>
  <div
    class="absence-period"
    :class="{ 'with-icon': props.withIcon }"
  >
    <AlgIcon
      v-if="props.withIcon"
      size="xs"
      name="beach-access"
    />
    {{
      t('datetime.{start} to {end}', {
        start: dayjs(props.startDate).format(t(`datetime.${DTF.DATE_SHORT}`)),
        end: dayjs(props.endDate).format(t(`datetime.${DTF.DATE_SHORT}`))
      })
    }}
  </div>
</template>

<style scoped>
  .absence-period {
    display: flex;
    width: max-content;
    flex-direction: row;
    align-items: center;
    font-size: var(--alg-font-size-m);
    font-weight: var(--alg-font-weight-bold);
    gap: var(--alg-spacing-xs);
    line-height: var(--alg-font-line-height);

    &.with-icon {
      font-size: var(--alg-font-size-s);
      font-weight: var(--alg-font-weight-regular);
      line-height: var(--alg-font-line-height);
    }
  }
</style>
