<script setup lang="ts">
  import { AlertVariant, AlgAlert } from '@algorh/ui'

  import { useAlertsStore } from '@/composables'

  interface Props {
    id: string | number
    variant?: AlertVariant
    title?: string
    message: string
    autoHide?: boolean
    closable?: boolean
    onClose?: () => void
  }

  const props = withDefaults(defineProps<Props>(), {
    variant: 'success',
    autoHide: true,
    closable: true,
  })

  const alertsStore = useAlertsStore()

  const handleClose = () => {
    alertsStore.removeAlert(props.id)
    props.onClose?.()
  }
</script>
<template>
  <AlgAlert
    :id="props.id"
    :variant="props.variant"
    :title="props.title"
    :message="props.message"
    :auto-hide="props.autoHide"
    :closable="props.closable"
    :on-close="handleClose"
  />
</template>
