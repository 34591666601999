<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { PeriodMode } from '#/types'

  type Props = {
    readonly id: string
    readonly modelValue?: PeriodMode
    readonly disabled?: boolean
    readonly periods?: PeriodMode[]
  }

  defineOptions({
    name: 'AlgPeriodSelector',
  })

  const props = withDefaults(defineProps<Props>(), {
    modelValue: PeriodMode.DAY,
    periods: () => Object.values(PeriodMode),
  })

  const emit = defineEmits<{
    (e: 'update:modelValue', value: PeriodMode): void
  }>()
  const { t } = useI18n()
  function handleChange(e: Event) {
    emit('update:modelValue', (e.target as HTMLInputElement).value as PeriodMode)
  }
</script>

<template>
  <div class="period-selector">
    <template
      v-for="period in props.periods"
      :key="period"
    >
      <input
        :id="period"
        type="radio"
        :name="props.id"
        :value="period"
        :checked="props.modelValue === period"
        :disabled="disabled"
        @change="handleChange"
      >
      <label :for="period">{{ t(`units.${period}`) }}</label>
    </template>
  </div>
</template>

<style scoped>
.period-selector {
  display: flex;
  align-items: center;
  gap: var(--alg-spacing-xs);

  input[type='radio'] {
    display: none;

    + label {
      display: inline-flex;
      height: 2rem;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      padding: var(--alg-spacing-s);
      border-radius: var(--alg-effect-radius-s);
      color: var(--alg-color-text-primary);
      cursor: pointer;
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-bold);
      text-transform: capitalize;
    }

    &:checked + label {
      background-color: var(--alg-color-surface-border);
    }

    &:disabled + label {
      cursor: not-allowed;
      opacity: .5;
    }
  }
}
</style>
