/**
 * Check if HTML element is overflowing
 * @param element The HTML element to check
 * @param ignoreHeightOverflow Ignore height overflow
 * @param tolerance The offset tolerance
 * @returns boolean
 */
export function checkOverflow(element?: HTMLElement, ignoreHeightOverflow = false, tolerance = 0) {
  if (!element)
    return false

  const curOverflow = element.style.overflow

  if (!curOverflow || curOverflow === 'visible')
    element.style.overflow = 'hidden'

  const isOverflowing = element.clientWidth < element.scrollWidth
    || (!ignoreHeightOverflow && element.clientHeight + tolerance < element.scrollHeight)
  element.style.overflow = curOverflow

  return isOverflowing
}
