import { type Ref, watch } from 'vue'

import { Nullable } from '../types'

export function useResizeObserver(
  element: Ref<HTMLElement | undefined>,
  callback: () => void,
) {
  let observer: Nullable<ResizeObserver> = null

  function disconnect() {
    if (observer !== null) {
      observer.disconnect()
      observer = null
    }
  }

  function connect(element: HTMLElement) {
    observer = new ResizeObserver(callback)
    observer.observe(element)
  }

  watch(
    element,
    (el) => {
      if (el) connect(el)
      else disconnect()
    },
  )

  return { disconnect }
}
