<script setup lang="ts">
  import { computed } from 'vue'

  import { Nullable } from '@algorh/shared'

  import { AlgColorIndicator } from '../../../color-indicator'
  import { AlgErrors } from '../../../feedback'
  import { AlgLabel } from '../../label'
  import { InputSize, InputVariant } from '../Input.type'

  type Props = {
    readonly id: string
    readonly modelValue: Nullable<string>
    readonly label?: string
    readonly sublabel?: string
    readonly size?: InputSize
    readonly inline?: boolean
    readonly required?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
    readonly variant?: InputVariant
  }

  defineOptions({
    name: 'AlgColorInput',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 'm',
    inline: false,
    required: false,
    disabled: false,
    readonly: false,
    errored: false,
    variant: 'primary',
  })

  const emit = defineEmits<{
    (e: 'update:modelValue', value: Nullable<string>): void
  }>()

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  const currentColor = computed(() => {
    return props.modelValue ?? '#000000'
  })

  // Methods
  function handleInput(event: Event) {
    const { value } = event.target as HTMLInputElement
    emit('update:modelValue', value)
  }
</script>

<template>
  <div
    class="field-wrapper"
    :class="{ inline: props.inline}"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :html-for="props.id"
      :inline="props.inline"
      :input-size="props.size"
      :required="props.required"
      :errored="hasErrors"
    />
    <div class="field-content">
      <div class="input-wrapper">
        <input
          :id="props.id"
          type="color"
          class="input has-prefix"
          :class="[
            `size-${props.size}`,
            `variant-${props.variant}`,
            { errored: hasErrors }
          ]"
          :name="props.id"
          :required="props.required"
          :disabled="props.disabled"
          :readonly="props.readonly"
          :value="props.modelValue"
          @input="handleInput"
        >
        <div class="color-value">
          {{ currentColor }}
        </div>
        <span
          class="input-prefix"
        >
          <AlgColorIndicator :color="currentColor" />
        </span>
      </div>
      <AlgErrors
        v-if="props.errors && props.errors.length"
        :errors="props.errors"
      />
    </div>
  </div>
</template>

<style src="../index.css" scoped />

<style scoped>
.field-wrapper {
  .field-content {
    .input-wrapper {
      position: relative;

      .input {
        cursor: pointer;

        &::-webkit-color-swatch {
          display: none;
        }

        &::-moz-color-swatch {
          display: none;
        }

        & ~ .input-prefix {
          pointer-events: none;

          .color-preview {
            z-index: 1;
            width: var(--alg-spacing-m);
            height: var(--alg-spacing-m);
            border: 1px solid var(--alg-color-surface-border);
            border-radius: var(--alg-effect-radius-s);
          }
        }

        & ~ .color-value {
          position: absolute;
          display: flex;
          align-items: center;
          color: var(--alg-color-text-primary);
          font-size: var(--alg-font-size-m);
          inset: 0 var(--alg-spacing-s) 0 calc(var(--alg-spacing-xl) + var(--alg-spacing-xs));
          pointer-events: none;
          text-transform: uppercase;
        }

        &[disabled] {
          & ~ .input-prefix {
            .color-preview {
              opacity: 0.5;
            }
          }

          & ~ .color-value {
            color: var(--alg-color-text-light);
          }
        }
      }
    }
  }
}
</style>
