import { minuteToStringDuration, minuteToStringTime, Nullable, ShortTimeString, timeToMinutes } from '@algorh/shared'

import { RuleRelationTiming } from '@/core/enums/Rule'
import { i18n } from '@/plugins/i18n'

import { HumanizeRule } from './HumanizeRule.type'

const { t } = i18n.global

const RulesTemplate = {
  ACTIVITY_DURATION: 1,
  MAX_NUMBER_OF_SEQUENCES: 2,
  MIN_GAP_BETWEEN_SLOTS: 3,
  DAY_COUNT: 4,
  ACTIVITY_RELATION: 5,
}

const RuleTiming = {
  BEFORE: 'before',
  AFTER: 'after',
  BETWEEN: 'between',
  DURING: 'during',
  CONSECUTIVELY: 'consecutively',
}

export const humanizeRule = {
  // Rule
  wrapWithDot(value: string, hasDots = true): string {
    if (!hasDots) {
      return value
    }
    return `<span class="has-dot">${value}</span>`
  },
  humanizedValue(rule: HumanizeRule): string {
    if (!rule.rule_template_id) {
      return minuteToStringDuration(rule.value)
    }
    if ([RulesTemplate.DAY_COUNT, RulesTemplate.MAX_NUMBER_OF_SEQUENCES].includes(rule.rule_template_id)) {
      return rule.value.toString()
    }
    return minuteToStringDuration(rule.value)
  },
  humanizedDuration(rule: HumanizeRule, hasDots = true): string {
    if (rule.config?.duration == null || rule.config?.duration === 0) {
      return this.wrapWithDot(t('rules.parts.any_duration'), hasDots)
    }
    return this.wrapWithDot(minuteToStringDuration(rule.config?.duration ?? 0), hasDots)
  },
  humanizedTiming(rule: HumanizeRule, hasDots = true): string {
    switch (rule.config?.timing) {
      case RuleTiming.BEFORE:
      case RuleTiming.AFTER:
        return `${this.wrapWithDot(t(`rules.parts.${rule.config.timing}`), hasDots)} ${this.humanizedReference(
          rule.config.first_reference || null,
          rule.config.first_time || null,
          hasDots,
        )}`
      case RuleTiming.BETWEEN:
        return `${this.wrapWithDot(t('rules.parts.between'), hasDots)} ${this.humanizedReference(
          rule.config.first_reference || null,
          rule.config.first_time || null,
          hasDots,
        )} ${t('rules.parts.and')} ${this.humanizedReference(
          rule.config.second_reference || null,
          rule.config.second_time || null,
          hasDots,
        )}`
      case RuleTiming.DURING:
        return `${this.wrapWithDot(t('rules.parts.during'), hasDots)} ${this.wrapWithDot(
          t(`rules.parts.${rule.config.mesh}`),
          hasDots,
        )}`
      case RuleTiming.CONSECUTIVELY:
        return this.wrapWithDot(t('rules.parts.consecutively'), hasDots)
      default:
        return ''
    }
  },
  humanizedReference(reference: Nullable<string>, time: Nullable<ShortTimeString>, hasDots = true): string {
    if (reference === 'lunch') {
      return this.wrapWithDot(t('rules.parts.lunch'), hasDots)
    }

    if (time === null) {
      return this.wrapWithDot('', hasDots)
    }

    const formattedTime = minuteToStringTime(timeToMinutes(time))

    return this.wrapWithDot(formattedTime, hasDots)
  },
  relationRuleTimingPrefix(rule: HumanizeRule): string {
    switch (rule.config?.relation_timing) {
      case RuleRelationTiming.HalfDayBefore:
      case RuleRelationTiming.HalfDayAfter: return t('rules.parts.prefix.half-day')
      case RuleRelationTiming.DayBefore:
      case RuleRelationTiming.DayAfter: return t('rules.parts.prefix.day')
      default: return ''
    }
  },
  humanizedSubject(rule: HumanizeRule): string {
    if (rule.subject === null) {
      return t('rules.parts.any_activity')
    }
    if (rule.rule_template_id !== 5) {
      return rule.subject
    }
    const prefix = this.relationRuleTimingPrefix(rule)
    return `${prefix} ${rule.subject}`
  },
  ruleOverridedHtml(rule: HumanizeRule): string {
    return t(`rules.templates.template_${rule.rule_template_id}`, {
      value: this.wrapWithDot(this.humanizedValue(rule)),
      subject: this.wrapWithDot(this.humanizedSubject(rule)),
      comparison: rule.config?.comparison
        ? t(`rules.parts.${rule.config.comparison}`)
        : '',
      operator: rule.config?.operator
        ? t(`rules.parts.${rule.config.operator}`)
        : '',
      duration: this.humanizedDuration(rule, false),
      timing: this.humanizedTiming(rule, false),
      relation: rule.config?.relation
        ? t(`rules.parts.${rule.config.relation}`)
        : '',
      relation_timing: rule.config?.relation_timing
        ? t(`rules.parts.relation-timing.${rule.config.relation_timing}`)
        : '',
      other: rule.other,
    }, 0)
  },
  ruleHtml(rule: HumanizeRule): string {
    return t(`rules.templates.template_${rule.rule_template_id}`, {
      value: this.wrapWithDot(this.humanizedValue(rule)),
      subject: this.wrapWithDot(this.humanizedSubject(rule)),
      comparison: rule.config?.comparison
        ? this.wrapWithDot(t(`rules.parts.${rule.config?.comparison}`))
        : '',
      operator: rule.config?.operator
        ? this.wrapWithDot(t(`rules.parts.${rule.config?.operator}`))
        : '',
      duration: this.humanizedDuration(rule),
      timing: this.humanizedTiming(rule),
      relation: rule.config?.relation
        ? this.wrapWithDot(t(`rules.parts.${rule.config.relation}`))
        : '',
      relation_timing:
        rule.config?.relation_timing
          ? this.wrapWithDot(t(`rules.parts.relation-timing.${rule.config.relation_timing}`))
          : '',
      other: this.wrapWithDot(rule.other ?? ''),
    }, 0)
  },
}
