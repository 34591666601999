import { DateTimeString, Dto, fetchJson } from '@algorh/shared'

import { ManualScheduling } from '@/core/types/ManuelScheduling'
import { GlobalPlanningSlot } from '@/core/types/Schedule'

import { PostSchedulePlanDto } from './scheduling.service.type'

const BASE_URI = '/api/scheduling/schedules'

const SchedulingSchedulesApiService = {
  // Aide à la planification en masse via planning global pour un CC
  postSchedulePlanPeriod: (scheduleId: number, params: PostSchedulePlanDto) => {
    return fetchJson<GlobalPlanningSlot[], PostSchedulePlanDto>(
      'POST', `${BASE_URI}/${scheduleId}/plan-period`, params,
    )
  },
  // Ajout d'une activité planifiée manuellement dans un schedule CC
  postScheduleManualSchedulings: (scheduleId: number, dto: Dto<{ description: string, end_at: DateTimeString, start_at: DateTimeString }>) => {
    return fetchJson<ManualScheduling, typeof dto>(
      'POST', `${BASE_URI}/${scheduleId}/manual-schedulings`, dto,
    )
  },
}

export { SchedulingSchedulesApiService }
