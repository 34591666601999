export enum RuleRelationTiming {
  Day = 'day',
  HalfDay = 'half-day',
  JustBefore = 'just-before',
  JustAfter = 'just-after',
  HalfDayBefore = 'half-day-before',
  HalfDayAfter = 'half-day-after',
  DayBefore = 'day-before',
  DayAfter = 'day-after',
}
