<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { IconName } from '#/types'

  import { AlgIcon } from '../../media'

  import { MessageVariant } from './Message.types'

  defineOptions({
    name: 'AlgMessage',
  })

  const props = withDefaults(defineProps<Props>(), {
    variant: 'primary',
    closable: false,
  })

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  const { t } = useI18n()

  interface Props {
    title?: string
    variant?: MessageVariant
    message?: string
    closable?: boolean
    icon?: IconName
  }

  const backgroundColor = computed(() => {
    switch (props.variant) {
    case 'primary':
      return 'var(--alg-color-light-blue)'
    case 'secondary':
      return 'var(--alg-color-light-grey)'
    case 'success':
      return 'var(--alg-color-light-green)'
    case 'warning':
      return 'var(--alg-color-light-orange)'
    case 'danger':
      return 'var(--alg-color-light-red)'
    default:
      return 'var(--alg-color-surface-primary)'
    }
  })

  const color = computed(() => {
    switch (props.variant) {
    case 'primary':
      return 'var(--alg-color-state-info)'
    case 'secondary':
      return 'var(--alg-color-text-secondary)'
    case 'success':
      return 'var(--alg-color-state-success)'
    case 'warning':
      return 'var(--alg-color-state-warning)'
    case 'danger':
      return 'var(--alg-color-state-danger)'
    default:
      return 'var(--alg-color-text-primary)'
    }
  })

  const computedTitle = computed(() => {
    if (props.title) {
      return props.title
    }

    switch (props.variant) {
    case 'primary':
    case 'secondary':
      return t('common.Information')
    case 'success':
      return t('common.Success')
    case 'warning':
      return t('common.Warning')
    case 'danger':
      return t('common.Error')
    default:
      return ''
    }
  })

  function handleClose() {
    emit('close')
  }
</script>

<template>
  <div
    class="message"
    :style="{
      backgroundColor
    }"
  >
    <div
      class="header"
      :style="{
        color
      }"
    >
      <span class="title">
        <AlgIcon
          v-if="props.icon"
          :name="props.icon"
          size="s"
        />
        {{ computedTitle }}
      </span>
      <button
        v-if="props.closable"
        :style="{ color: 'var(--alg-color-text-primary)' }"
        class="close-button"
        type="button"
        :title="t('common.Close')"
        @click="handleClose"
      >
        <AlgIcon
          name="close"
          size="s"
        />
      </button>
    </div>
    <div class="content">
      <slot>
        {{ props.message }}
      </slot>
    </div>
  </div>
</template>

<style scoped>
  .message {
    display: flex;
    flex-direction: column;
    padding: var(--alg-spacing-s);
    border-radius: var(--alg-effect-radius-m);
    gap: var(--alg-spacing-s);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;
        font-size: var(--alg-font-size-xs);
        font-weight: var(--alg-font-weight-bold);
        gap: var(--alg-spacing-xs);
        line-height: 1;
        text-transform: uppercase;
      }

      .close-button {
        padding: 0;
      }
    }

    .content {
      align-items: baseline;
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-regular);
      line-height: var(--alg-font-line-height);
    }
  }
</style>
