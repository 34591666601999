import type { DateString, DateTimeString, Nullable, ShortTimeString, TimeString } from '../types'

import { dayjs } from './dayjs'

const DAY_IN_MS = 1000 * 60 * 60 * 24

/**
 * Format date to YYYY-MM-DD string
 * @param date
 * @returns
 */
const formatDate = (date: Date = new Date()): DateString =>
  `${date.getFullYear().toString()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`

/**
 * Add days to a specific date
 * @param days
 * @param date
 * @returns
 */
const addDays = (days: number, date: Date = new Date()) => {
  const newDate = new Date(date.valueOf())
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

/**
 * Substract days to a specific date
 * @param days
 * @param date
 * @returns
 */
const substractDays = (days: number, date: Date = new Date()) => {
  const newDate = new Date(date.valueOf())
  newDate.setDate(newDate.getDate() - days)
  return newDate
}

/**
 * Return the day count between two date
 * @param start
 * @param end
 * @returns
 */
const getDaysLength = (start: Date, end: Date) => {
  const diff = end.getTime() - start.getTime()
  const length = Math.round(diff / DAY_IN_MS)
  return length + 1
}

/**
 *
 * @param date1
 * @param date2
 */
const isSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getDate() === date2.getDate()
    && date1.getMonth() === date2.getMonth()
    && date1.getFullYear() === date2.getFullYear()
  )
}

type CommonTimeString = DateTimeString | ShortTimeString | TimeString

function timeStringToMinutesRegex(timeString: CommonTimeString): number {
  var match = timeString.match(/(\d+):(\d+)/)
  if (match) {
    var hours = parseInt(match[1], 10)
    var minutes = parseInt(match[2], 10)
    return hours * 60 + minutes
  }
  return NaN // Invalid input format
}

function isBetweenTimes(time: CommonTimeString, start: CommonTimeString, end: CommonTimeString): boolean {
  const timeMinutes = timeStringToMinutesRegex(time)
  const startMinutes = timeStringToMinutesRegex(start)
  const endMinutes = timeStringToMinutesRegex(end)
  return timeMinutes >= startMinutes && timeMinutes < endMinutes
}

/**
 *
 * @param time
 */
const timeToHour = (time: Nullable<ShortTimeString>, fallback = 0): number => {
  if (time == null) {
    return fallback
  }
  const [h, m] = time.split(':')
  return parseInt(h) + parseInt(m) / 60
}

/**
 * Convert time to minutes
 * @param time time to convert
 * @returns minutes of time
 */
const timeToMinutes = (time: Nullable<ShortTimeString>, fallback = 0): number => {
  if (time === null) {
    return fallback
  }
  if (!/^\d{2}:\d{2}$/.test(time)) {
    throw new Error('Invalid time format')
  }
  const [h, m] = time.split(':')
  return parseInt(h) * 60 + parseInt(m)
}

const minutesToTime = (minutes: number): ShortTimeString => {
  const h = Math.floor(minutes / 60).toString().padStart(2, '0')
  const min = (minutes % 60).toString().padStart(2, '0')
  return `${h}:${min}`
}

const minuteToDuration = (minutes: number): string => {
  const h = Math.floor(minutes / 60)
  const m = (minutes % 60).toString().padStart(2, '0')
  return `${h}h${m}`
}

/**
 *
 * @param hour
 */
const hourToTime = (hour = 0): ShortTimeString => {
  const h = Math.floor(hour).toString().padStart(2, '0')
  const min = ((hour * 60) % 60).toString().padStart(2, '0')
  return `${h}:${min}`
}

/**
 *
 * @param value
 * @returns
 */
const minuteToStringTime = (value: number): string => {
  const format = dayjs.locale() === 'fr' ? 'HH:mm' : 'hh:mm A'
  const hours = Math.floor(value / 60)
  const minutes = value % 60
  return dayjs().hour(hours).minute(minutes).format(format)
}

/**
 *
 * @param value
 * @returns
 */
const minuteToStringDuration = (value: number): string => {
  const hours = Math.floor(value / 60)
  const minutes = value % 60
  return dayjs().hour(hours).minute(minutes).format('HH:mm')
}

export {
  minuteToStringDuration,
  minuteToStringTime,
  addDays,
  isSameDay,
  formatDate,
  substractDays,
  getDaysLength,
  timeToHour,
  hourToTime,
  timeToMinutes,
  minutesToTime,
  minuteToDuration,
  timeStringToMinutesRegex,
  isBetweenTimes,
}
