<script lang="ts" setup>
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { DateString, dayjs, DTF, FetchErrorObj, Nullable } from '@algorh/shared'
  import { AlgCheckbox, AlgErrors, AlgToggle, AlgWeekInput } from '@algorh/ui'

  import { useConfigs } from '@/composables'

  import { RuleDto } from '@/sections/settings/services'

  import RuleFormSection from './RuleFormSection.vue'

  type Model = Pick<RuleDto, 'applicable_from' | 'applicable_to' | 'activated' | 'mandatory'>
  type Props = {
    readonly errors?: Nullable<FetchErrorObj<Model>>
    readonly isIndividual: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    isIndividual: false,
  })

  const model = defineModel<Model>({ required: true })

  const { t } = useI18n()

  const { planificationConfig } = useConfigs()

  const hasActivationPeriod = computed(() => model.value.applicable_from !== null && model.value.applicable_to !== null)

  const isOutdated = computed(() => hasActivationPeriod.value ? dayjs(model.value.applicable_to).isBefore(dayjs()) : false)

  const applicableFrom = computed(() => model.value.applicable_from ?? dayjs().isoWeekday(1).format(DTF.DATE))

  const applicableTo = computed(() => model.value.applicable_to ?? dayjs().isoWeekday(7).format(DTF.DATE))

  function toggleActivationPeriod() {
    if (hasActivationPeriod.value) {
      model.value = {
        ...model.value,
        applicable_from: null,
        applicable_to: null,
      }
    } else {
      const applicable_from = dayjs().isoWeekday(1).format(DTF.DATE)
      const applicable_to = dayjs().isoWeekday(7).format(DTF.DATE)
      model.value = {
        ...model.value,
        applicable_from,
        applicable_to,
      }
    }
  }

  function toggleActivation() {
    model.value = {
      ...model.value,
      activated: !model.value.activated,
    }
  }

  function toggleMandatory() {
    model.value = {
      ...model.value,
      mandatory: !model.value.mandatory,
    }
  }

  function handleChangeApplicableFrom(date: DateString | null) {
    model.value = {
      ...model.value,
      applicable_from: dayjs(date).isoWeekday(1).format(DTF.DATE),
    }
  }

  function handleChangeApplicableTo(date: DateString | null) {
    model.value = {
      ...model.value,
      applicable_to: dayjs(date).isoWeekday(7).format(DTF.DATE),
    }
  }
</script>

<template>
  <div class="form-wrapper">
    <RuleFormSection
      illustration="run"
      :title="t('rules.Rule activation')"
      :infos="t('rules.Do not forget to activate the rule so it can be taken into account for arbitration')"
    >
      <AlgToggle
        id="activation"
        :disabled="isOutdated"
        :model-value="model.activated"
        @update:model-value="toggleActivation"
      />
    </RuleFormSection>
    <RuleFormSection
      v-if="!props.isIndividual && planificationConfig?.mandatory_collective_rules"
      illustration="lock"
      :title="t('rules.Unbreakable rule')"
      :infos="t('rules.The rule will behave as an individual rule')"
    >
      <AlgToggle
        id="mandatory"
        :disabled="isOutdated"
        :model-value="model.mandatory"
        @update:model-value="toggleMandatory"
      />
      <template #errors>
        <AlgErrors
          class="error-message"
          :errors="errors?.errors?.mandatory"
        />
      </template>
    </RuleFormSection>
    <RuleFormSection
      illustration="alarm-clock"
      :title="t('rules.Activation period')"
      :infos="t('rules.These dates cannot be overrided')"
    >
      <div class="checkbox-container">
        <AlgCheckbox
          id="activation-period"
          size="m"
          :model-value="hasActivationPeriod"
          @update:model-value="toggleActivationPeriod"
        />
      </div>
    </RuleFormSection>
    <div
      v-if="hasActivationPeriod"
      class="period-inputs"
    >
      <div class="period-input-wrapper">
        <AlgWeekInput
          id="applicable-from"
          :label="t('common.Start')"
          :errors="errors?.errors?.applicable_from"
          required
          :model-value="applicableFrom"
          @update:model-value="handleChangeApplicableFrom"
        />
      </div>
      <div class="period-input-wrapper">
        <AlgWeekInput
          id="applicable-to"
          :label="t('common.End')"
          :errors="errors?.errors?.applicable_to"
          required
          :model-value="applicableTo"
          @update:model-value="handleChangeApplicableTo"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .form-wrapper {
    display: flex;
    flex-direction: column;

    .checkbox-container {
      position: relative;
    }
  }

  .period-inputs {
    position: relative;
    z-index: 60;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 24px;
  }

  .period-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .error-message {
      text-align: center;
    }
  }

  .fake-input-label {
    display: flex;
    margin-bottom: 4px;
    color: var(--alg-color-text-primary);
    font-size: 14px;
    font-weight: var(--alg-font-weight-bold);
  }
</style>
