<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable } from '@algorh/shared'
  import { AlgIllustration } from '@algorh/ui'

  import Loader from './Loader.vue'

  type CustomError = { message: string }

  interface Props {
    readonly updateKey?: string
    readonly spinner?: boolean
    readonly fn: () => Promise<unknown>
  }

  const props = withDefaults(defineProps<Props>(), {
    spinner: false,
  })

  const { t } = useI18n()

  const loaded = ref<boolean>(false)
  const fading = ref<boolean>(false)
  const error = ref<Nullable<string>>()
  const errorMessage = ref<Nullable<CustomError | string>>()

  load()

  watch(
    () => props.updateKey,
    async () => {
      await load()
    },
  )

  async function load() {
    if (props.fn && typeof props.fn === 'function') {
      try {
        fading.value = true
        loaded.value = false
        error.value = null
        errorMessage.value = null
        await props.fn()
        loaded.value = true
        fading.value = false
      } catch (e) {
        fading.value = false
        loaded.value = false
        error.value = t('errors.An error occured')
        if (import.meta.env.PROD) {
          errorMessage.value = (e as CustomError).message ?? (e as string)
        }
        throw e
      }
    } else {
      loaded.value = true
      fading.value = false
    }
  }
</script>

<template>
  <div class="wrapper-loader">
    <div
      v-if="loaded"
      :class="{ blur: fading }"
      class="content"
    >
      <slot />
    </div>
    <div
      v-if="error"
      class="error-wrapper"
    >
      <AlgIllustration
        name="error"
        :size="148"
      />
      <span class="error">
        {{ error }}
        <template v-if="errorMessage">
          {{ errorMessage }}
        </template>
      </span>
    </div>
    <div
      v-if="!loaded && props.spinner && !error"
      :class="{ 'fade-out': loaded }"
      class="loader-wrapper"
    >
      <Loader :size="148" />
    </div>
  </div>
</template>

<style>
  .wrapper-loader {
    position: relative;
    display: flex;
    min-width: 0;
    min-height: 0;
    flex: 1 1 auto;

    .error-wrapper {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .error {
        margin-top: 24px;
        color: var(--alg-color-text-secondary);
        font-size: 18px;
        font-weight: var(--alg-font-weight-regular);
      }
    }

    .loader-wrapper {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
    }

    .content {
      display: flex;
      min-height: 0;
      flex: 1 1 auto;
      flex-direction: column;

      &.blur {
        &::before {
          position: absolute;
          z-index: 100;
          content: '';
          inset: 0;
          opacity: 0.6;
        }
      }
    }
  }
</style>
