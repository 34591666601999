<script lang="ts" setup>
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable } from '@algorh/shared'
  import { AlgErrors, AlgSelect, SelectOption } from '@algorh/ui'

  import { RuleAmbitionSubjectType } from '@/core/enums/RuleAmbition'

  interface Props {
    nullable?: boolean
    errors?: string[]
    readonly activityFamilies: { id: number, name: string }[]
    readonly activities: { id: number, name: string }[]
  }

  const props = defineProps<Props>()

  const model = defineModel<{ type: Nullable<RuleAmbitionSubjectType>, id: Nullable<number> }>({ required: true })

  const { t } = useI18n()

  const subjectTypeOptions: { value: Nullable<RuleAmbitionSubjectType>, label: string }[] = [
    {
      value: RuleAmbitionSubjectType.ACTIVITY_FAMILY,
      label: t('activities.Activity family'),
    },
    {
      value: RuleAmbitionSubjectType.ACTIVITY,
      label: t('activities.Activity'),
    },
  ]

  if (props.nullable) {
    subjectTypeOptions.unshift({
      value: null,
      label: t('rules.parts.any_activity'),
    })
  }

  const selectedSubjectType = computed(() => {
    if (!model.value.type) {
      return props.nullable ? null : subjectTypeOptions[0].value
    }
    return model.value.type
  })

  const subjectValueOptions = computed((): SelectOption<number>[] => {
    if (selectedSubjectType.value === null) {
      return []
    }

    const state = selectedSubjectType.value === RuleAmbitionSubjectType.ACTIVITY ? props.activities : props.activityFamilies

    return state.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  })

  const selectedSubjectValue = computed(() => {
    if (selectedSubjectType.value === null) {
      return 0
    }

    if (!model.value.id) {
      return subjectValueOptions.value?.[0]?.value
    }

    return model.value.id
  })

  function updateSelectedSubjectType(value: Nullable<RuleAmbitionSubjectType>) {
    switch (value) {
    case RuleAmbitionSubjectType.ACTIVITY:{
      model.value = {
        type: RuleAmbitionSubjectType.ACTIVITY,
        id: props.activities[0]?.id ?? null,
      }
      break // !important
    }
    case RuleAmbitionSubjectType.ACTIVITY_FAMILY: {
      model.value = {
        type: RuleAmbitionSubjectType.ACTIVITY_FAMILY,
        id: props.activityFamilies[0]?.id ?? null,
      }
      break
    }
    case null:
    default:{
      model.value = {
        type: null,
        id: null,
      }
      break
    }
    }
  }

  function updateSelectedSubject(subjectId: number, subjectType: RuleAmbitionSubjectType) {
    model.value = {
      type: subjectType,
      id: subjectId,
    }
  }
</script>

<template>
  <div class="rule-activity-input-wrapper">
    <AlgSelect
      id="subject-type"
      :key="`${selectedSubjectType}`"
      centered
      required
      :options="subjectTypeOptions"
      :errored="errors && errors.length > 0"
      :model-value="selectedSubjectType"
      @update:model-value="updateSelectedSubjectType"
    />
    <AlgSelect
      v-if="selectedSubjectType !== null"
      id="subject"
      :key="`${selectedSubjectValue}`"
      class="subject-select"
      centered
      required
      searchable
      :options="subjectValueOptions"
      :errored="errors && errors.length > 0"
      :model-value="selectedSubjectValue"
      @update:model-value="v => updateSelectedSubject(v, selectedSubjectType!)"
    />
    <AlgErrors :errors="errors" />
  </div>
</template>

<style scoped>
  .rule-activity-input-wrapper {
    position: relative;
    width: 100%;

    .subject-select {
      margin-top: 0.5rem;
    }
  }
</style>
