<script setup lang="ts">
  import { useSlots } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { FloatingPlacement, Nullable } from '@algorh/shared'

  import { AlgIconButton } from '../icon-button'
  import { AlgPopper } from '../popover'

  import { DropdownMenuOption } from './Dropdown.types'
  import DropdownMenu from './DropdownMenu.vue'

  interface Props {
    readonly id: string
    readonly modelValue?: Nullable<string | number>
    readonly options: DropdownMenuOption[]
    readonly placement?: FloatingPlacement
    readonly title?: string
    readonly defaultGroupLabel?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    modelValue: null,
    placement: 'bottom-end',
  })

  const emit = defineEmits<{
    (e: 'update:model-value', value: Nullable<string | number>): void
  }>()

  // Composables
  const { t } = useI18n()

  const slots = useSlots()

  // Methods
  function handleSelect(value: Nullable<string | number>, close: () => void) {
    emit('update:model-value', value)
    close()
  }
</script>

<template>
  <AlgPopper
    :placement="props.placement"
  >
    <template #reference="{ isOpen, open, close, toggle }">
      <slot
        name="reference"
        v-bind="{ isOpen, open, close, toggle }"
      >
        <AlgIconButton
          :id="props.id"
          :label="props.title ?? t('common.Actions')"
          icon="more-vert"
          icon-color="var(--alg-color-text-primary)"
          variant="transparent"
          @click="toggle"
        />
      </slot>
    </template>
    <template #content="{ close }">
      <DropdownMenu
        :options="options"
        :model-value="props.modelValue"
        :default-group-label="props.defaultGroupLabel"
        @update:model-value="(value) => handleSelect(value, close)"
      >
        <template
          v-if="slots.option"
          #option="{ option, active }"
        >
          <slot
            name="option"
            :option="option"
            :active="active"
          />
        </template>
      </DropdownMenu>
    </template>
  </AlgPopper>
</template>
