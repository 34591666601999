<script setup lang="ts">
  import { computed } from 'vue'

  import { IconName, TagSize } from '#/types'

  import { AlgIcon } from '../media'

  type Props = {
    label?: string
    size?: TagSize
    color?: string
    icon?: IconName
    uppercase?: boolean
    badge?: string
    badgeTitle?: string
  }

  defineOptions({
    name: 'AlgTag',
  })

  const props = withDefaults(defineProps<Props>(), {
    color: 'var(--alg-color-purple-100)',
    size: 'm',
    uppercase: false,
  })
  const iconSize = computed(() => {
    switch (props.size) {
    case 'l':
      return 's'
    case 's':
    case 'm':
    default:
      return 'xs'
    }
  })
</script>

<template>
  <div
    :class="[
      'tag',
      `size-${props.size}`,
      {
        uppercase: props.uppercase
      },
    ]"
    :style="{
      color: props.color,
      borderColor: props.color
    }"
  >
    <AlgIcon
      v-if="props.icon"
      :name="props.icon"
      :size="iconSize"
    />
    {{ props.label }}
    <span
      v-if="props.badge"
      :title="props.badgeTitle"
      class="badge"
    >
      {{ props.badge }}
    </span>
  </div>
</template>

<style scoped>
.tag {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 1rem;
  font-weight: var(--alg-font-weight-bold);
  gap: calc(var(--alg-spacing-xs) / 2);
  white-space: nowrap;

  &.uppercase {
    text-transform: uppercase;
  }

  &.size-m {
    height: var(--alg-font-size-l);
    font-size: var(--alg-font-size-xs);
    line-height: var(--alg-font-size-l);
    padding-inline: var(--alg-spacing-s);
  }

  &.size-l {
    height: var(--alg-font-size-3xl);
    font-size: var(--alg-font-size-m);
    line-height: var(--alg-font-size-l);
    padding-inline: var(--alg-spacing-s);
  }

  .badge {
    position: absolute;
    z-index: 1;
    top: -8px;
    left: calc(100% - 8px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--alg-spacing-xs);
    border-radius: var(--alg-effect-radius-m);
    background-color: var(--alg-color-surface-highlight);
    color: var(--alg-color-text-on-color);
    font-size: var(--alg-font-size-xxs);
    font-weight: var(--alg-font-weight-bold);
  }
}
</style>
