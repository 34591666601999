<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { debounce, Nullable } from '@algorh/shared'

  import { AlgErrors, AlgSpinner } from '../../../feedback'
  import { AlgIconButton } from '../../../icon-button'
  import { AlgIcon } from '../../../media'
  import { AlgLabel } from '../../label'
  import { InputSize, InputVariant } from '../Input.type'

  type Props = {
    readonly id: string
    readonly modelValue: Nullable<string>
    readonly label?: string
    readonly sublabel?: string
    readonly placeholder?: string
    readonly autocomplete?: string
    readonly autocapitalize?: string
    readonly spellcheck?: boolean
    readonly size?: InputSize
    readonly inline?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
    readonly debounced?: boolean
    readonly loading?: boolean
    readonly counterIndex?: number
    readonly counterTotal?: number
    readonly variant?: InputVariant
  }

  defineOptions({
    name: 'AlgSearchInput',
  })

  const props = withDefaults(defineProps<Props>(), {
    autocapitalize: 'off',
    autocomplete: 'off',
    spellcheck: true,
    size: 'm',
    inline: false,
    disabled: false,
    readonly: false,
    debounced: true,
    loading: false,
    counterIndex: 0,
    counterTotal: 0,
    errored: false,
    variant: 'primary',
  })

  const emit = defineEmits<{
    (e: 'update:model-value', value: typeof props.modelValue): void
  }>()

  const { t } = useI18n()

  // Data
  const debouncedInput = debounce((value: string) => emit('update:model-value', value === '' ? null : value), 600)

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  const showCounter = computed(() => props.modelValue && props.counterIndex > 0 && props.counterTotal > 0)

  // Methods
  function handleInput(event: Event) {
    const { value } = event.target as HTMLInputElement
    props.debounced ? debouncedInput(value) : emit('update:model-value', value === '' ? null : value)
  }

  function handlePaste(event: ClipboardEvent) {
    let value = event.clipboardData?.getData('text') ?? ''
    value = value.trim().split('\n').filter((v) => v).join('|')
    emit('update:model-value', value === '' ? null : value)
  }

  function handleClear() {
    emit('update:model-value', null)
  }
</script>

<template>
  <div
    class="field-wrapper"
    :class="{ inline: props.inline }"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :html-for="props.id"
      :inline="props.inline"
      :input-size="props.size"
      :errored="hasErrors"
    />
    <div class="field-content">
      <div class="input-wrapper">
        <input
          :id="props.id"
          type="search"
          class="input has-prefix"
          :class="[
            `size-${props.size}`,
            `variant-${props.variant}`,
            {
              errored: hasErrors,
              'has-counter': showCounter,
              'has-suffix': props.modelValue,
            }
          ]"
          :name="props.id"
          :placeholder="props.placeholder ?? t('common.Search')"
          :autocomplete="props.autocomplete"
          :autocapitalize="props.autocapitalize"
          :spellcheck="props.spellcheck"
          :disabled="props.disabled"
          :readonly="props.readonly"
          :value="props.modelValue"
          @input="handleInput"
          @paste.prevent="handlePaste"
        >
        <span class="input-prefix">
          <AlgSpinner
            v-if="props.modelValue && props.loading"
            color="var(--alg-color-icon-unselected)"
            size="s"
          />
          <AlgIcon
            v-else
            name="search"
            :color="
              props.disabled ?
                'var(--alg-color-icon-unselected)' :
                'var(--alg-color-icon-secondary)'
            "
            size="s"
          />
        </span>
        <span
          v-if="showCounter"
          class="input-counter has-suffix"
        >
          {{
            t('common.{n} of {total}', {
              n: props.counterIndex,
              total: props.counterTotal
            })
          }}
        </span>
        <span
          v-if="props.modelValue && !props.readonly"
          class="input-suffix"
        >
          <AlgIconButton
            :id="`${props.id}-clear-button`"
            icon="cancel"
            icon-color="var(--alg-color-icon-secondary)"
            variant="transparent"
            @click="handleClear"
          />
        </span>
      </div>
      <AlgErrors
        v-if="props.errors && props.errors.length"
        :errors="props.errors"
      />
    </div>
  </div>
</template>

<style src="../index.css" scoped />

<style scoped>
  .input {
    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }
  }
</style>
