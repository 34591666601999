<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { AlgIcon, AlgModal, AlgNoData } from '@algorh/ui'

  import { Alert } from '@/sections/users/types'

  import BrokenRules from './BrokenRules.vue'

  interface Props {
    readonly collectiveAlerts: Alert[]
    readonly individualAlerts: Alert[]
    readonly pendingAcceptAlerts?: Record<number, boolean>
    readonly notice?: boolean
    readonly readonly?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    notice: true,
    readonly: false,
  })

  const emit = defineEmits<{
    (e: 'acceptCollectiveAlert', alertId: number): void
    (e: 'close'): void
  }>()

  // Composables
  const { t } = useI18n()

  // Computed
  const alertsCount = computed(() => props.collectiveAlerts.length + props.individualAlerts.length)

  // Methods
  function handleClose() {
    emit('close')
  }

  function handleAcceptCollectiveAlert(alertId: number) {
    emit('acceptCollectiveAlert', alertId)
  }
</script>

<template>
  <AlgModal
    name="broken-rules"
    size="xl"
    :footer="false"
    @close="handleClose"
  >
    <template #title>
      <div class="title-wrapper">
        <AlgIcon
          name="warning"
          :badge="alertsCount > 0"
          badge-state="danger"
          size="m"
        />
        <template v-if="alertsCount > 0">
          {{ t('calendar.{n} broken input rules', { n: alertsCount }) }}
        </template>
        <template v-else>
          {{ t('rules.Broken rules') }}
        </template>
      </div>
    </template>
    <template #content>
      <div class="content-wrapper">
        <BrokenRules
          :collective-alerts="props.collectiveAlerts"
          :individual-alerts="props.individualAlerts"
          :pending-accept-alerts="pendingAcceptAlerts"
          :notice="props.notice"
          :readonly="props.readonly"
          @accept-collective-alert="handleAcceptCollectiveAlert"
        />
        <AlgNoData
          v-if="alertsCount === 0"
          illustration="congrats"
          class="no-data"
          :title="
            props.readonly ? t('rules.No broken rules') : t('rules.You have not broken any rules')
          "
        />
      </div>
    </template>
  </AlgModal>
</template>

<style scoped>
  .title-wrapper {
    display: flex;
    align-items: center;
    gap: var(--alg-spacing-s);
  }

  .content-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: var(--alg-spacing-xl);
  }
</style>
