export enum PlanningState {
  CLOSED = 'closed',
  EDITABLE = 'editable',
  LOAD_CURVES_GENERATED = 'load-curves-generated',
  RAW_PLANNING_RUNNING = 'raw-planning-running',
  RAW_PLANNING_SCHEDULED = 'raw-planning-scheduled',
  RUNNING = 'running',
  SCHEDULED = 'scheduled',
  SUCCESS = 'success',
  WAITING = 'waiting',
}
