<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { addDays, DateString, dayjs, DTF, Nullable } from '@algorh/shared'

  import { AlgDot } from '#/components/dot'

  import { AlgIconButton } from '../../icon-button'
  import { AlgPopper } from '../popper'

  interface Props {
    readonly modelValue: Nullable<DateString>
    readonly minValue?: DateString
    readonly maxValue?: DateString
    readonly allowedDays?: number[] // 1-7
    readonly mode?: 'day' | 'week'
    readonly closeOnSelect?: boolean
    readonly dailyCounts?: Nullable<Record<DateString, {
      absence: boolean
      day_off: boolean
    }>>
  }

  const props = withDefaults(defineProps<Props>(), {
    allowedDays: () => [0, 1, 2, 3, 4, 5, 6],
    mode: 'day',
    closeOnSelect: true,
  })

  const emit = defineEmits<{
    (e: 'update:modelValue', value: DateString): void
    (e: 'update:month', value: DateString): void
  }>()

  // Composables
  const { t } = useI18n()

  // Refs
  const currentDate = ref(new Date())

  // Computed
  const weekDays = dayjs.weekdays(true).map((day: string) => day.substring(0, 1))

  const weeks = computed(() => getMondaysInMonth(currentDate.value))

  const startOf = computed(() => dayjs(currentDate.value).startOf('month').weekday(0))

  const days = computed(() =>
    Array.from({ length: 7 * weeks.value.length }, (_, i) =>
      addDays(i, new Date(startOf.value.format(DTF.DATE))),
    ),
  )

  const currentMonthWithYear = computed(() => dayjs(currentDate.value).format(DTF.DATE_MONTH_YEAR))

  const currentWeekNumber = computed(() => dayjs(props.modelValue).isoWeek())

  const convertedAllowedDays = computed(() => props.allowedDays.map((day: number) => day === 7 ? 0 : day))

  // Methods
  function getMondaysInMonth(date: Date) {
    const firstMonday = dayjs(date).startOf('month').startOf('week')
    return Array.from({ length: 6 }, (_, i) => firstMonday.add(7 * i, 'day').toDate())
  }

  function computeDays(index: number) {
    const computedDays = days.value.map((day: Date) => ({
      day,
      formattedDay: dayjs(day).format(DTF.DAY_NUMBER),
      isToday: dayjs(day).isSame(dayjs(), 'day'),
      isCurrentMonth: dayjs(day).isSame(dayjs(currentDate.value), 'month'),
      isDayOff: props.dailyCounts ? Object.entries(props.dailyCounts).some(([key, value]) => dayjs(day).isSame(dayjs(key), 'day') && value.day_off) : false,
      isAbsence: props.dailyCounts ? Object.entries(props.dailyCounts).some(([key, value]) => dayjs(day).isSame(dayjs(key), 'day') && value.absence) : false,
      disabled: (props.minValue && dayjs(day).isBefore(dayjs(props.minValue)))
        || (props.maxValue && dayjs(day).isAfter(dayjs(props.maxValue)))
        || !convertedAllowedDays.value.includes(dayjs(day).day()),
    }))

    return computedDays.slice(index * 7, (index + 1) * 7)
  }

  function handlePreviousMonth() {
    currentDate.value = dayjs(currentDate.value).subtract(1, 'month').toDate()
    emit('update:month', dayjs(currentDate.value).format(DTF.DATE))
  }

  function handleNextMonth() {
    currentDate.value = dayjs(currentDate.value).add(1, 'month').toDate()
    emit('update:month', dayjs(currentDate.value).format(DTF.DATE))
  }

  function handleToday() {
    currentDate.value = new Date()
    emit('update:month', dayjs().format(DTF.DATE))
  }

  function handleWeekClick(monday: Date, close: () => void) {
    emit('update:modelValue', dayjs(monday).format(DTF.DATE))
    if (props.closeOnSelect) {
      close()
    }
  }

  function handleDayClick(day: Date, close: () => void) {
    currentDate.value = day
    emit('update:modelValue', dayjs(currentDate.value).format(DTF.DATE))
    if (props.closeOnSelect) {
      close()
    }
  }

  function handlePopperOpen() {
    if (props.modelValue !== null) {
      currentDate.value = dayjs(props.modelValue).toDate()
    }
  }
</script>

<template>
  <AlgPopper
    placement="bottom-start"
    @open="handlePopperOpen"
  >
    <template #reference="{ toggle, isOpen }">
      <slot
        name="reference"
        v-bind="{ toggle, isOpen }"
      />
    </template>
    <template #content="{ close }">
      <div class="selector">
        <div class="selector-header">
          <div class="selector-header-month-switcher">
            <AlgIconButton
              id="selector-header-prev"
              class="selector-header-prev"
              variant="transparent"
              icon="chevron-left"
              :title="t('common.Previous')"
              @click="handlePreviousMonth"
            />
            <span class="selector-header-title">
              {{ currentMonthWithYear }}
            </span>
            <AlgIconButton
              id="selector-header-next"
              class="selector-header-next"
              variant="transparent"
              icon="chevron-right"
              :title="t('common.Next')"
              @click="handleNextMonth"
            />
          </div>
          <AlgIconButton
            id="selector-header-today"
            class="selector-header-today"
            variant="transparent"
            icon="today"
            :title="t('datetime.Today')"
            @click="handleToday"
          />
        </div>
        <div class="selector-month">
          <div class="selector-week selector-week-header">
            <span class="selector-cell selector-week-number">
              {{ t('datetime.Wk') }}
            </span>
            <span
              v-for="(day, i) in weekDays"
              :key="i"
              class="selector-cell selector-day"
            >
              {{ day }}
            </span>
          </div>
          <template v-if="props.mode === 'week'">
            <button
              v-for="(week, i) in weeks"
              :key="i"
              type="button"
              class="selector-week selector-week-body selector-button"
              :class="{
                'selector-week-selected': dayjs(week).isoWeek() === currentWeekNumber
                  && dayjs(week).isoWeekYear() === dayjs(props.modelValue).isoWeekYear(),
              }"
              :disabled="
                (props.minValue && dayjs(week).isBefore(dayjs(props.minValue)))
                  || (props.maxValue && dayjs(week).isAfter(dayjs(props.maxValue)))"
              @click="() => handleWeekClick(week, close)"
            >
              <span class="selector-cell selector-week-number">
                {{ dayjs(week).isoWeek() }}
              </span>
              <span
                v-for="(day, k) in computeDays(i)"
                :key="k"
                :class="{
                  'is-today': day.isToday,
                  'is-previous-or-next-month': !day.isCurrentMonth
                }"
                class="selector-cell selector-day"
              >
                {{ day.formattedDay }}
              </span>
            </button>
          </template>
          <template v-if="props.mode === 'day'">
            <span
              v-for="(week, i) in weeks"
              :key="i"
              class="selector-week selector-week-body"
            >
              <span class="selector-cell selector-week-number">
                {{ dayjs(week).isoWeek() }}
              </span>
              <button
                v-for="(day, k) in computeDays(i)"
                :key="k"
                type="button"
                class="selector-cell selector-day selector-button"
                :class="{
                  'is-today': day.isToday,
                  'is-previous-or-next-month': !day.isCurrentMonth,
                  'selector-day-selected': dayjs(day.day).isSame(dayjs(props.modelValue), 'day'),
                }"
                :disabled="day.disabled"
                @click="() => handleDayClick(day.day, close)"
              >
                {{ day.formattedDay }}
                <div
                  v-if="day.isDayOff || day.isAbsence"
                  class="dot-container"
                >
                  <AlgDot
                    v-if="day.isDayOff"
                    size="xxs"
                    color="var(--alg-color-pink-100)"
                  />
                  <AlgDot
                    v-if="day.isAbsence"
                    size="xxs"
                    color="var(--alg-color-cyan-100)"
                  />
                </div>
              </button>
            </span>
          </template>
        </div>
        <div
          v-if="props.dailyCounts && Object.values(props.dailyCounts).some(value => value.day_off || value.absence)"
          class="selector-footer"
        >
          <ul class="legend">
            <li class="item">
              <AlgDot
                size="xs"
                color="var(--alg-color-pink-100)"
                :label="t('scheduling.Day off')"
              />
            </li>
            <li class="item">
              <AlgDot
                size="xs"
                color="var(--alg-color-cyan-100)"
                :label="t('absences.Absence')"
              />
            </li>
          </ul>
        </div>
      </div>
    </template>
  </AlgPopper>
</template>

<style scoped>
.selector {
  padding: var(--alg-spacing-s) var(--alg-spacing-m) var(--alg-spacing-m);
  border-radius: var(--alg-effect-radius-s);
  background-color: var(--alg-color-surface-primary);
  box-shadow: var(--alg-effect-shadow-l);

  .selector-header {
    display: flex;
    height: var(--alg-spacing-xl);
    justify-content: space-between;
    margin-bottom: var(--alg-spacing-s);

    .selector-header-month-switcher {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: var(--alg-spacing-s);

      .selector-header-title {
        width: 128px;
        color: var(--alg-color-text-primary);
        font-size: var(--alg-font-size-m);
        font-weight: var(--alg-font-weight-bold);
        text-align: center;
        text-transform: capitalize;
      }
    }

    .selector-header-prev,
    .selector-header-next,
    .selector-header-today {
      display: flex;
      width: var(--alg-spacing-xl);
      height: var(--alg-spacing-xl);
      align-items: center;
      justify-content: center;
      padding: 0;
      border-radius: 50%;
      color: var(--alg-color-icon-secondary);
      transition:
        background-color 150ms ease-in-out,
        color 150ms ease-in-out;

      &:hover {
        background-color: var(--alg-color-surface-background);
        color: var(--alg-color-icon-primary);
      }
    }
  }

  .selector-month {
    height: 100%;
    user-select: none;

    .selector-week {
      display: grid;
      overflow: hidden;
      padding: 0;
      border-radius: var(--alg-spacing-xl);
      margin-bottom: var(--alg-spacing-xs);
      gap: 2px;
      grid-template-columns: repeat(8, 1fr);

      .selector-cell {
        display: flex;
        width: var(--alg-spacing-xl);
        height: var(--alg-spacing-xl);
        align-items: center;
        justify-content: center;
        color: var(--alg-color-text-primary);
        font-size: var(--alg-font-size-s);
        font-weight: var(--alg-font-weight-bold);

        &.is-previous-or-next-month {
          color: var(--alg-color-text-secondary);
          font-weight: var(--alg-font-weight-light);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.selector-week-header {
        .selector-day {
          color: var(--alg-color-text-secondary);
          font-size: var(--alg-font-size-m);
          font-weight: var(--alg-font-weight-light);
          text-transform: capitalize;
        }
      }

      &.selector-week-body {
        transition: background-color 150ms ease-in-out;

        .selector-cell {
          border-radius: 50%;
        }

        .selector-week-number {
          color: var(--alg-color-text-highlight);
        }
      }

      .selector-button,
      &.selector-button {
        position: relative;

        &.is-today {
          background-color: var(--alg-color-button-primary-light);
        }

        .dot-container {
          position: absolute;
          bottom: var(--alg-spacing-xs);
          display: flex;
          gap: var(--alg-spacing-xs);
        }

        &.selector-week-selected {
          background-color: var(--alg-color-button-primary-light-hover);

          .selector-week-number {
            background-color: var(--alg-color-button-primary);
            color: var(--alg-color-text-on-color);
          }
        }

        &.selector-day-selected,
        &.selector-day-selected:hover {
          background-color: var(--alg-color-button-primary);
          color: var(--alg-color-text-on-color);
        }

        &:hover {
          background-color: var(--alg-color-button-primary-light-hover);
        }

        &[disabled] {
          color: var(--alg-color-text-secondary);

          .selector-cell {
            color: var(--alg-color-text-secondary);
          }

          &:hover {
            background-color: var(--alg-color-button-primary-light-disabled);
          }
        }
      }
    }
  }

  .selector-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--alg-spacing-s);

    .legend {
      display: flex;
      padding-right: var(--alg-spacing-s);
      gap: var(--alg-spacing-s);

      .item {
        display: flex;
        gap: var(--alg-spacing-xs);
      }
    }
  }
}
</style>
