<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { AlgIcon } from '../../media'
  import { MessageVariant } from '../message'

  defineOptions({
    name: 'AlgComment',
  })

  const props = withDefaults(defineProps<Props>(), {
    variant: 'primary',
    closable: false,
  })

  const emit = defineEmits<{
    (e: 'delete'): void
  }>()

  const { t } = useI18n()

  interface Props {
    name: string
    variant?: MessageVariant
    message?: string
    deletable?: boolean
    date?: string
  }

  const backgroundColor = computed(() => {
    switch (props.variant) {
    case 'primary':
      return 'var(--alg-color-surface-secondary)'
    case 'secondary':
      return 'var(--alg-color-surface-primary)'
    default:
      return 'var(--alg-color-surface-primary)'
    }
  })

  const color = computed(() => {
    switch (props.variant) {
    case 'primary':
      return 'var(--alg-color-text-primary)'
    case 'secondary':
      return 'var(--alg-color-text-primary)'
    default:
      return 'var(--alg-color-text-primary)'
    }
  })

  function handleDelete() {
    emit('delete')
  }
</script>

<template>
  <div
    class="message"
    :style="{
      backgroundColor
    }"
  >
    <div
      class="header"
      :style="{
        color
      }"
    >
      <span class="title">
        {{ props.name }}
      </span>
      <button
        v-if="props.deletable"
        :style="{ color: 'var(--alg-color-text-primary)' }"
        class="delete-button"
        type="button"
        :title="t('common.Delete')"
        @click="handleDelete"
      >
        <AlgIcon
          name="delete"
          size="s"
        />
      </button>
    </div>
    <div class="content">
      <slot>
        {{ props.message }}
      </slot>
    </div>
    <div class="footer">
      {{ props.date }}
    </div>
  </div>
</template>

<style scoped>
  .message {
    display: flex;
    flex-direction: column;
    padding: var(--alg-spacing-s);
    border-radius: var(--alg-effect-radius-m);
    gap: calc(var(--alg-spacing-s) + var(--alg-spacing-xs));

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;
        font-size: var(--alg-font-size-m);
        font-weight: var(--alg-font-weight-bold);
        gap: var(--alg-spacing-xs);
        line-height: var(--alg-font-line-height);
      }

      .delete-button {
        padding: 0;
      }
    }

    .content {
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-regular);
      line-height: var(--alg-font-line-height);
    }

    .footer {
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-s);
      font-weight: var(--alg-font-weight-regular);
      line-height: var(--alg-font-line-height);
    }
  }
</style>
