import { onUnmounted } from 'vue'

import { useMatchMedia } from './useMatchMedia'

export function useIsMobile() {
  const { remove, matches } = useMatchMedia('(max-width : 720px)')

  onUnmounted(remove)

  return matches
}
