import { fetchJson } from '@algorh/shared'
import { PeriodMode } from '@algorh/ui'

import { DailyRealTimeSchedule, DailySchedule, DailyScheduleHistory, WeeklySchedule } from '../types/Schedule'

import { GetGlobalPlanningByDayDto, GetGlobalPlanningByWeekDto, GetPlanningHistoriesDto } from './scheduling.service.type'

const BASE_URI = '/api/scheduling/customer-advisers/plannings'

const SchedulingCustomerAdvisersPlanningsApiService = {
  getPlanning(periodMode: PeriodMode, params: GetGlobalPlanningByDayDto | GetGlobalPlanningByWeekDto) {
    const { getPlanningDay, getPlanningWeek } = SchedulingCustomerAdvisersPlanningsApiService
    switch (periodMode) {
      case PeriodMode.DAY:
        return getPlanningDay(params as GetGlobalPlanningByDayDto)
      case PeriodMode.WEEK:
      case PeriodMode.MONTH:
        return getPlanningWeek(params as GetGlobalPlanningByWeekDto)
    }
  },
  getPlanningDay(params: GetGlobalPlanningByDayDto) {
    return fetchJson<DailySchedule[], null, GetGlobalPlanningByDayDto>(
      'GET', `${BASE_URI}/day`, null, params,
    )
  },
  getScheduleHistories(dto: GetPlanningHistoriesDto) {
    return fetchJson<DailyScheduleHistory[], null, GetPlanningHistoriesDto>(
      'GET', `${BASE_URI}/histories`, null, dto,
    )
  },
  getScheduleRealTimeSlots(dto: GetGlobalPlanningByDayDto) {
    return fetchJson<DailyRealTimeSchedule[], null, GetGlobalPlanningByDayDto>(
      'GET', `${BASE_URI}/real-time`, null, dto,
    )
  },
  getPlanningWeek(params: GetGlobalPlanningByWeekDto) {
    return fetchJson<WeeklySchedule[], null, GetGlobalPlanningByWeekDto>(
      'GET', `${BASE_URI}/week`, null, params,
    )
  },
}
export {
  SchedulingCustomerAdvisersPlanningsApiService,
}
