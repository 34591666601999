<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { CriticalPeriodWithQuota } from '@algorh/shared'

  import { AlgButton } from '../../button'
  import { AlgCriticalPeriodLightCard } from '../../cards'
  import { AlgPopper } from '../../popover'

  defineOptions({
    name: 'AlCriticalPeriodPanel',
  })

  const props = withDefaults(defineProps<Props>(), {
    criticalPeriods: () => [],
    allCriticalPeriods: () => [],
  })

  interface Props {
    readonly criticalPeriods?: CriticalPeriodWithQuota[]
    readonly allCriticalPeriods?: CriticalPeriodWithQuota[]
  }

  const { t } = useI18n()
</script>

<template>
  <div
    v-if="props.criticalPeriods.length"
    class="critical-period-panel"
  >
    <div class="critical-period-panel-title">
      {{ t('absences.Seizure of leave during a critical period') }}
    </div>
    <div class="critical-period-panel-content">
      <div
        class="container-start"
      >
        <template v-for="(criticalPeriod) in props.criticalPeriods">
          <div
            v-if="criticalPeriod.critical_period"
            :key="criticalPeriod.critical_period.id"
            class="card-item"
          >
            <AlgCriticalPeriodLightCard
              :critical-period="criticalPeriod.critical_period"
              :quotas="{ id: criticalPeriod.critical_period.id, ...criticalPeriod }"
            />
          </div>
        </template>
      </div>
      <div
        v-if="props.allCriticalPeriods.length"
        class="container-end"
      >
        <AlgPopper placement="bottom-end">
          <template #reference="{ toggle, isOpen }">
            <AlgButton
              :label="t('common.Show all (f)')"
              variant="primary-light"
              :icon-end="isOpen ? 'expand-less' : 'expand-more'"
              size="s"
              @click="toggle"
            />
          </template>
          <template #content>
            <div class="list-container">
              <template v-for="(criticalPeriod,) in props.allCriticalPeriods">
                <div
                  v-if="criticalPeriod.critical_period"
                  :key="criticalPeriod.critical_period.id"
                  class="list-item"
                >
                  <AlgCriticalPeriodLightCard
                    :critical-period="criticalPeriod.critical_period"
                    :quotas="{ id: criticalPeriod.critical_period.id, ...criticalPeriod }"
                  />
                </div>
              </template>
            </div>
          </template>
        </AlgPopper>
      </div>
    </div>
  </div>
</template>

<style scoped>
.critical-period-panel {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: var(--alg-spacing-s);

  .critical-period-panel-title {
    color: var(--alg-color-text-primary);
    font-size: var(--alg-font-size-m);
    font-weight: var(--alg-font-weight-bold);
    line-height: var(--alg-font-line-height);
  }

  .critical-period-panel-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    .container-start {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: var(--alg-spacing-s);
      border: 1px solid var(--alg-color-surface-border);
      border-radius: var(--alg-effect-radius-m);
      background-color: var(--alg-color-surface-primary);
      gap: var(--alg-spacing-xl);
      overflow-x: auto;
    }

    .container-end {
      padding-inline: var(--alg-spacing-m);

    }

    .card-item {
      position: relative;
    }

    .card-item + .card-item::before {
      position: absolute;
      width: 1px;
      background-color: var(--alg-color-surface-border);
      content: "";
      inset: 0;
      transform: translateX(calc(-1 * var(--alg-spacing-xl) / 2))
    }

    .list-container {
      display: flex;
      max-height: 75vh;
      flex-direction: column;
      align-items: center;
      border: 1px solid var(--alg-color-surface-border);
      border-radius: var(--alg-effect-radius-m);
      background-color: var(--alg-color-surface-primary);
      overflow-y: auto;
      padding-block: var(--alg-spacing-s);
      padding-inline: var(--alg-spacing-m);

      .list-item {
        width: 100%;
        padding-block: var(--alg-spacing-s);

      }

      .list-item + .list-item {
        border-top: 1px solid var(--alg-color-surface-border);
      }
    }
  }
}
</style>
