<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { decimalFormat } from '@algorh/shared'

  import { AlgIcon } from '../../media'

  type Props = {
    readonly desiderata?: number
    readonly initial?: number
    readonly percentage?: number
  }

  defineOptions({
    name: 'AlgPlaceCalculationCard',
  })

  const props = withDefaults(defineProps<Props>(), {
    desiderata: 0,
    initial: 0,
    percentage: 0,
  })

  const { t } = useI18n()

  const color = computed(() => {
    if (props.desiderata < props.initial) {
      return 'var(--alg-color-state-success)'
    }

    if (props.desiderata === props.initial) {
      return 'var(--alg-color-state-warning)'
    }

    if (props.desiderata > props.initial) {
      return 'var(--alg-color-state-danger)'
    }

    return 'var(--alg-color-surface-background)'
  })

  const backgroundColor = computed(() => {
    if (props.desiderata < props.initial) {
      return 'var(--alg-color-light-green)'
    }

    if (props.desiderata === props.initial) {
      return 'var(--alg-color-light-orange)'
    }

    if (props.desiderata > props.initial) {
      return 'var(--alg-color-light-red)'
    }

    return 'var(--alg-color-surface-background)'
  })
</script>

<template>
  <div class="place-calculation-card">
    <div
      class="icon-wrapper"
      :style="{
        backgroundColor: backgroundColor
      }"
    >
      <AlgIcon
        name="volunteer-activism"
        size="m"
        :color="color"
      />
    </div>
    <div class="content-wrapper">
      <div class="title">
        {{ t('absences.Place count') }}
      </div>
      <div class="place-calculation">
        <span class="values">
          {{ decimalFormat(props.desiderata) }} / {{ decimalFormat(props.initial) }}
        </span>
        <span>{{ decimalFormat(props.percentage) }}%</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .place-calculation-card {
    display: inline-flex;
    box-sizing: border-box;
    gap: var(--alg-spacing-s);

    .icon-wrapper {
      display: flex;
      width: var(--alg-spacing-xl);
      height: var(--alg-spacing-xl);
      align-items: center;
      justify-content: center;
      padding: var(--alg-spacing-xs);
      border-radius: var(--alg-effect-radius-s);
    }

    .content-wrapper {
      display: flex;
      overflow: hidden;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--alg-spacing-xs);
      line-height: var(--alg-font-line-height);
      white-space: nowrap;

      .title {
        font-size: var(--alg-font-size-s);
        font-weight: var(--alg-font-weight-regular);
      }

      .place-calculation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: var(--alg-font-size-m);
        font-weight: var(--alg-font-weight-bold);
        gap: var(--alg-spacing-s);
      }
    }
  }
</style>
