<script setup lang="ts" generic="T extends Tab<string | number> = Tab<string | number>">
  import { computed, onBeforeUnmount, ref, useSlots, watch } from 'vue'

  import { Nullable } from '@algorh/shared'

  import type { Tab } from './Tab.type'

  type Props = {
    id?: string | number
    tabs: T[]
    routed?: boolean
    vertical?: boolean
    ordered?: boolean
    border?: boolean
    separator?: boolean
    fullHeight?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    modelValue: null,
    alignment: 'left',
    vertical: false,
    ordered: false,
    routed: false,
    border: false,
    fullHeight: false,
  })

  const emit = defineEmits<{
    (e: 'updateRoute', route: { name: string, query: { tab: string } }): void
  }>()

  const model = defineModel<Nullable<T['id']>>({ default: null })

  // Ref
  const tabsRef = ref<HTMLDivElement>()
  const focused = ref<boolean>(false)

  // Computed
  const displayedTabs = computed(() => props.tabs.filter(({ displayed }) => displayed !== false))

  // Methods
  function handleTabChange(newTab: Nullable<T['id']>) {
    model.value = newTab
  }

  // Composables
  const slots = useSlots()

  watch(() => props.tabs, (t) => {
    if (!t.length || model.value !== null) {
      return
    }
    const tabAlreadySet = t.find((tab) => tab.id === model.value) ?? null
    if (tabAlreadySet) {
      handleTabChange(tabAlreadySet.id)
      return
    }
    const firstDisplayedTab = t.find((tab) => 'default' in tab ? tab.displayed !== false && tab.default === true : tab.displayed !== false)
    if (firstDisplayedTab) {
      handleTabChange(firstDisplayedTab.id)
      return
    }
  }, { immediate: true })

  onBeforeUnmount(() => {
    handleTabChange(null)
  })
</script>

<template>
  <div
    v-if="props.tabs.length > 0"
    class="tabs-wrapper"
  >
    <div
      role="tablist"
      :aria-labelledby="`${id}-title`"
      :aria-orientation="vertical ? 'vertical' : 'horizontal'"
      class="tabs-container"
    >
      <div
        class="containerScroll"
        :class="{ 'has-separator': props.separator}"
      >
        <component
          :is="ordered ? 'ol' : 'ul'"
          ref="tabsRef"
          class="tabs"
        >
          <li
            v-for="tab in displayedTabs"
            :key="tab.id"
          >
            <button
              :id="`tab-${tab.id}`"
              role="tab"
              :class="{ active: model === tab.id }"
              :aria-selected="model === tab.id"
              :aria-controls="`tabpanel-${tab.id}`"
              :tabindex="model === tab.id ? undefined : '-1'"
              type="button"
              @click="() => handleTabChange(tab.id)"
              @focus="focused = true"
              @blur="focused = false"
            >
              <slot
                :name="`tab-label(${tab.id})`"
                :tab="tab"
              >
                {{ tab.label }}
              </slot>
            </button>
          </li>
          <template v-if="slots['last-tab']">
            <li>
              <slot name="last-tab" />
            </li>
          </template>
        </component>
        <div
          v-if="border"
          class="border"
          aria-hidden="true"
        />
      </div>
      <div
        class="tabpanel"
        role="tabpanel"
        aria-labelledby="tabpanel"
      >
        <slot :name="`tab(${model})`" />
      </div>
    </div>
  </div>
  <slot
    v-else
    :name="`tab(${model})`"
  />
</template>

<style scoped>
  .tabs-wrapper {
    display: flex;
    min-width: 0;
    min-height: 0;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .tabs-container {
    position: relative;
    display: flex;
    min-width: 0;
    min-height: 0;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .containerScroll {
    position: relative;
    overflow: auto hidden;
    flex: 0 0 auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    ::-webkit-scrollbar {
      display: none;
    }

    .border {
      position: absolute;
      z-index: -1;
      right: 0;
      bottom: 10px;
      left: 0;
      border-bottom: 1px solid var(--alg-color-surface-border);
    }

    &.has-separator::after {
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--alg-color-surface-border);
      content: '';
    }
  }

  .tabs {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: var(--alg-spacing-m);
    gap: 24px;

    li {
      position: relative;
      display: flex;
      flex: 0 0 auto;
      align-items: center;
    }

    button {
      padding: 0;
      padding-bottom: 4px;
      border-bottom: 2px solid var(--alg-color-transparent);
      color: var(--alg-color-text-secondary);
      font-size: 16px;
      font-weight: var(--alg-font-weight-bold);
      transition: color 150ms ease-in-out;

      &:hover {
        color: var(--alg-color-text-light);
      }

      &.active {
        border-bottom-color: var(--alg-color-surface-highlight);
        color: var(--alg-color-text-primary);
      }
    }

  }

  .tabpanel {
    display: flex;
    min-height: 0;
    flex: 1 1 auto;
  }
</style>
