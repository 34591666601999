import { computed, ComputedRef, Ref, ref } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import {
  DateString,
  DateTimeString,
  dayjs,
  Nullable,
  ShortTimeString,
} from '@algorh/shared'

import { ActivityCategory } from '@/core/enums/Activity'
import { InternalTaskInstance, PlannedInternalTaskInstance } from '@/core/types/InternalTask'
import { ProjectActivity as ProjectProjectActivity, ProjectActivityForPlanifiable } from '@/core/types/Project'

import { Schedule as CalendarSchedule } from '@/sections/calendar/types/Schedule'
import { CommonSlot, getSlots } from '@/sections/scheduling/utils/calendar'
import { ProjectActivity as SettingsProjectActivity } from '@/sections/settings/types/Project'
import { Schedule as TrainingSchedule } from '@/sections/training/types/Trainer'

import { useCalendarBoundaries } from './useCalendarBoundaries'
import { useConfigs } from './useConfigs'

export interface PutSlotWarning {
  id: string
  start_time: ShortTimeString
  end_time: ShortTimeString
  datetimes: DateTimeString[]
  date: DateString
  warningType: ActivityCategory
  newActivityId: number | null
  oldActivityId?: number | null
  redirect?: RouteLocationRaw
}

type ProjectActivity = ProjectActivityForPlanifiable | SettingsProjectActivity | ProjectProjectActivity

const useTrainerCalendar = (
  date: Ref<DateString>,
  schedule: ComputedRef<Nullable<CalendarSchedule | TrainingSchedule>>,
  projectActivities: ComputedRef<Nullable<ProjectActivity[]>>,
  internalTaskInstances: ComputedRef<Nullable<(InternalTaskInstance | PlannedInternalTaskInstance)[]>>,
) => {
  const { slotDuration } = useConfigs()
  const { daySlotTimes, weekDays } = useCalendarBoundaries(date)

  const selectedActivity = ref<Nullable<ProjectActivity>>(null)
  const errorSlots = ref<DateTimeString[]>([])
  const forbiddenSlots = ref<DateTimeString[]>([])
  const loadingSlots = ref<DateTimeString[]>([])
  const loadingActivityId = ref<Nullable<number>>(null)
  const isDeleting = ref(false)

  const daySchedule = (day: DateString): CommonSlot[] => {
    const scheduleSlots = schedule.value?.working_schedule ?? []
    let additionalSlots = []
    for (const loadingSlot of [...loadingSlots.value, ...forbiddenSlots.value]) {
      if (scheduleSlots.find((s) => s.datetime === loadingSlot)) {
        continue
      }
      additionalSlots.push({
        datetime: loadingSlot,
        project_activity_id: selectedActivity.value?.id ?? null,
      })
    }
    return [...(scheduleSlots ?? []), ...(additionalSlots ?? [])]
      .filter((s) => dayjs(s.datetime).isSame(day, 'day'))
      .map((s) => {
        const loading = loadingSlots?.value?.includes(s.datetime)

        const warning = false
        const error = errorSlots?.value?.includes(s.datetime) ?? false
        const forbidden = forbiddenSlots?.value?.includes(s.datetime) ?? false
        const notWorked = !forbidden && s.project_activity_id === null

        const project_activity_id = loading ? loadingActivityId.value ?? null : s.project_activity_id

        return {
          ...s,
          loading,
          notWorked,
          project_activity_id,
          deleting: loading && isDeleting.value,
          warning,
          error,
          forbidden,
          real_start: s.datetime,
          real_end: s.datetime,
        }
      })
  }

  const dayInternalTaskInstances = (day: DateString) => {
    return internalTaskInstances?.value?.filter((task) => dayjs(task.start_date).isSame(day, 'day'),
    ) ?? []
  }

  const weekData = computed(() => {
    if (!schedule.value) {
      return []
    }
    return weekDays.value.map((day) => ({
      day,
      schedules: [{
        name: '',
        activitySlots: getSlots(
          daySlotTimes.value,
          projectActivities.value ?? [],
          dayInternalTaskInstances(day),
          daySchedule(day),
          slotDuration.value ?? 30,
        ).map((slot) => slot),
      }],
    }))
  })

  return {
    forbiddenSlots,
    loadingSlots,
    selectedActivity,
    weekData,
    isDeleting,
    loadingActivityId,
  }
}

export { useTrainerCalendar }
