<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable, sanitizeString } from '@algorh/shared'

  import { AlgErrors } from '../../../feedback'
  import { AlgLabel } from '../../label'
  import { InputVariant } from '../Input.type'

  type Props = {
    readonly id: string
    readonly modelValue: Nullable<string>
    readonly label?: string
    readonly sublabel?: string
    readonly placeholder?: string
    readonly autocomplete?: string
    readonly autocapitalize?: string
    readonly spellcheck?: boolean
    readonly inline?: boolean
    readonly required?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    readonly autofocus?: boolean
    readonly maxlength?: number
    readonly errored?: boolean
    readonly errors?: string[]
    readonly variant?: InputVariant
    readonly resize?: 'none' | 'both' | 'horizontal' | 'vertical'
    readonly rows?: number
  }

  defineOptions({
    name: 'AlgTextarea',
  })

  const props = withDefaults(defineProps<Props>(), {
    autocapitalize: 'off',
    autocomplete: 'off',
    spellcheck: false,
    inline: false,
    required: false,
    disabled: false,
    readonly: false,
    errored: false,
    variant: 'primary',
    resize: 'vertical',
    rows: 4,
  })

  const emit = defineEmits<{
    (e: 'update:modelValue', value: Nullable<string>): void
  }>()

  const { t } = useI18n()

  const textarea = ref<HTMLTextAreaElement>()

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  // Methods
  function handleInput(event: Event) {
    const { value } = event.target as HTMLInputElement
    emit('update:modelValue', value)
  }

  onMounted(() => {
    if (props.autofocus && textarea.value) {
      textarea.value.focus()
    }
  })
</script>

<template>
  <div
    class="field-wrapper"
    :class="{ inline: props.inline}"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :html-for="props.id"
      :inline="props.inline"
      :required="props.required"
      :errored="hasErrors"
    />
    <div class="field-content">
      <div class="input-wrapper">
        <textarea
          :id="props.id"
          ref="textarea"
          class="input textarea"
          :class="[
            `variant-${props.variant}`,
            {
              errored: hasErrors,
            }
          ]"
          :style="{ resize: props.resize }"
          :name="props.id"
          :placeholder="props.placeholder"
          :autocomplete="props.autocomplete"
          :autocapitalize="props.autocapitalize"
          :spellcheck="props.spellcheck"
          :required="props.required"
          :disabled="props.disabled"
          :readonly="props.readonly"
          :maxlength="props.maxlength"
          :autofocus="props.autofocus"
          :rows="props.rows"
          :value="props.modelValue"
          @input="handleInput"
        />
        <span
          v-if="props.modelValue && props.maxlength"
          class="input-counter"
        >
          {{
            t(
              'common.{0} / {1}',
              [
                sanitizeString(props.modelValue).length,
                props.maxlength,
              ],
            )
          }}
        </span>
      </div>
      <AlgErrors
        v-if="props.errors && props.errors.length"
        :errors="props.errors"
      />
    </div>
  </div>
</template>

<style src="../index.css" scoped />

<style scoped>
.field-wrapper {
  .field-content {
    .input-wrapper {
      .input {
        min-height: 80px;
        padding-top: var(--alg-spacing-xs);
        padding-bottom: var(--alg-spacing-xl);
        font-size: var(--alg-font-size-m);

        & ~ .input-counter {
          top: initial;
          right: calc(var(--alg-spacing-l) - var(--alg-spacing-s));
          bottom: 1px;
          left: 1px;
          justify-content: flex-end;
          padding: var(--alg-spacing-s);
          background-color: var(--alg-color-surface-primary);
          border-bottom-left-radius: var(--alg-effect-radius-m);
          border-bottom-right-radius: var(--alg-effect-radius-m);
        }
      }
    }
  }
}
</style>
