import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'

import { AuthApiService, CommonApiService } from '@/core/services'

import { useAppInit } from './useAppInit'

export function useConfigs() {
  const { isTrainer, isAuthenticated } = useAppInit()

  const { data: authConfigData, isLoading: isLoadingAuthConfig } = useQuery({
    queryKey: ['auth', 'config'],
    queryFn: AuthApiService.getConfig,
    staleTime: 60 * 60 * 1000, // 1H
  })

  const { data: config, isLoading: isLoadingConfig } = useQuery({
    queryKey: ['common', 'config'],
    queryFn: CommonApiService.getConfig,
    enabled: isAuthenticated,
    staleTime: 60 * 60 * 1000, // 1H
  })

  const generalConfig = computed(() => config.value?.data?.general ?? null)
  const rgpdEnabled = computed(() => config.value?.data?.general.rgpd ?? false)
  const notificationsEnabled = computed(() => config.value?.data?.general.notifications ?? false)
  const documentationEnabled = computed(() => config.value?.data?.general.documentation ?? false)
  const supportEnabled = computed(() => config.value?.data?.general?.support ?? false)
  const noticeEnabled = computed(() => config.value?.data?.general.notice ?? false)
  const companiesEnabled = computed(() => config.value?.data?.general.companies ?? false)

  const gtaConfig = computed(() => config.value?.data?.gta ?? null)
  const absencesEnabled = computed(() => (!isTrainer.value && config.value?.data?.gta.absences) ?? false)
  const autocompleteUserInfos = computed(() => config.value?.data?.gta.autocomplete_user_infos ?? null)
  const leaveBalancesImportEnabled = computed(() => config.value?.data?.gta?.leave_balances_import ?? false)

  const authConfig = computed(() => authConfigData.value?.data ?? null)
  const planificationConfig = computed(() => config.value?.data?.planification ?? null)
  const schedulingEnabled = computed(() => config.value?.data?.planification.scheduling ?? false)
  const slotDuration = computed(() => config.value?.data?.planification.slot_duration)
  const hyperPeriodEnabled = computed(() => config.value?.data?.planification.hyper_period ?? false)
  const workCycleEnabled = computed(() => config.value?.data?.planification.work_cycle ?? false)
  const trainerEnabled = computed(() => config.value?.data?.planification.trainer ?? false)
  const criticalPeriodEnabled = computed(() => config.value?.data?.planification.critical_period ?? false)
  const realTimeModeEnabled = computed(() => config.value?.data?.planification.real_time_mode ?? false)
  const predefinedModeEnabled = computed(() => config.value?.data?.planification.predefined_mode ?? false)
  const nonProductiveActivityQuotaEnabled = computed(() => config.value?.data?.planification.non_productive_activity_quota ?? false)
  const levelEnabled = computed(() => config.value?.data?.planification.skills_with_level ?? false)
  const velocityEnabled = computed(() => config.value?.data?.planification.skills_with_velocity ?? false)
  const stockExchangesEnabled = computed(() => config.value?.data?.planification.stock_exchanges.enabled ?? false)

  return {
    authConfig,
    planificationConfig,
    gtaConfig,
    generalConfig,
    slotDuration,
    realTimeModeEnabled,
    autocompleteUserInfos,
    velocityEnabled,
    levelEnabled,
    workCycleEnabled,
    predefinedModeEnabled,
    nonProductiveActivityQuotaEnabled,
    supportEnabled,
    hyperPeriodEnabled,
    criticalPeriodEnabled,
    leaveBalancesImportEnabled,
    documentationEnabled,
    noticeEnabled,
    notificationsEnabled,
    schedulingEnabled,
    trainerEnabled,
    absencesEnabled,
    stockExchangesEnabled,
    rgpdEnabled,
    companiesEnabled,
    isLoadingAuthConfig,
    isLoadingConfig,
  }
}
