import { Ref, watch } from 'vue'

export function useAutoScroll(
  root: Ref<HTMLElement | null>,
  enabled: Ref<boolean>,
  inset: number | [number, number] = 0,
) {
  let top = 0
  let bottom = 0
  let clientY = 0

  let left = 0
  let right = 0
  let clientX = 0

  const insetTop = typeof inset === 'number' ? inset : inset[0]
  const insetBottom = typeof inset === 'number' ? inset : inset[1]
  const insetLeft = typeof inset === 'number' ? inset : inset[0]
  const insetRight = typeof inset === 'number' ? inset : inset[1]

  function setRootRect(e: MouseEvent) {
    clientY = e.pageY
    clientX = e.pageX
  }

  watch(
    () => [enabled.value, root.value?.getBoundingClientRect()] as [boolean, DOMRect | undefined],
    ([enabled, rect = { top: 0, bottom: 0, left: 0, right: 0 }]) => {
      if (enabled) {
        top = rect.top + insetTop
        bottom = rect.bottom - insetBottom
        left = rect.left + insetLeft
        right = rect.right - insetRight
        document.removeEventListener('mousemove', setRootRect)
        document.addEventListener('mousemove', setRootRect)
      }
      tick()
    })

  const speed = 4 / 16
  let lastFrame = -1

  function tick() {
    requestAnimationFrame((time) => {
      const frameTime = lastFrame === -1 ? 16 : time - lastFrame
      lastFrame = time
      if (enabled.value) {
        if (clientY < top) {
          root.value?.scrollBy({
            top: speed * -1 * frameTime,
            left: 0,
          })
        } else if (clientY > bottom) {
          root.value?.scrollBy({
            top: speed * frameTime,
            left: 0,
          })
        }
        if (clientX < left) {
          root.value?.scrollBy({
            top: 0,
            left: speed * -1 * frameTime,
          })
        }
        if (clientX > right) {
          root.value?.scrollBy({
            top: 0,
            left: speed * frameTime,
          })
        }
      }
      tick()
    })
  }
}
