<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { Nullable, ShortTimeString } from '@algorh/shared'

  import { AlgIconButton } from '../../icon-button'

  import { InternalTaskGlobalInformationCardKind } from './InternalTaskSlotInformationCard.types'

  type Props = {
    kind: InternalTaskGlobalInformationCardKind
    date: string
    quota: Nullable<number>
    startTime: ShortTimeString
    endTime: ShortTimeString
    location: Nullable<string>
    link: Nullable<string>
    teacher?: Nullable<string>
    updateAvailable?: boolean
    nonProductiveActivityQuotaEnabled?: boolean
  }

  defineOptions({
    name: 'AlgInternalTaskSlotInformationCard',
  })

  const props = defineProps<Props>()

  const emit = defineEmits<{
    (e: 'update', p: Event): void
  }>()

  const { t } = useI18n()
</script>

<template>
  <div
    class="internal-task-global-information-card internal-task-global-information-card-${props.kind}"
  >
    <div class="internal-task-global-information-card-header">
      <span class="internal-task-global-information-card-header-title">
        {{ t('scheduling.Slot informations') }}
      </span>
      <AlgIconButton
        v-if="updateAvailable"
        id="internal-task-global-information-card-edit"
        icon="stylus"
        variant="transparent"
        :title="t('common.Edit')"
        @click="(e) => emit('update', e)"
      />
    </div>
    <div class="internal-task-global-information-card-content">
      <div class="internal-task-global-information-card-content-group">
        <div
          class="internal-task-global-information-card-content-item internal-task-global-information-card-content-date"
        >
          <div class="internal-task-global-information-card-font-title">
            {{ t('common.Date') }}
          </div>
          <div class="internal-task-global-information-card-font-summary">
            {{ props.date }}
          </div>
        </div>
        <div
          class="internal-task-global-information-card-content-item internal-task-global-information-card-content-slot"
        >
          <div class="internal-task-global-information-card-font-title">
            {{ t('scheduling.Slot') }}
          </div>
          <div class="internal-task-global-information-card-font-summary">
            {{
              t('datetime.{start}, {end}', {
                start: props.startTime,
                end: props.endTime
              })
            }}
          </div>
        </div>
        <div
          v-if="props.nonProductiveActivityQuotaEnabled && props.quota"
          class="internal-task-global-information-card-content-item internal-task-global-information-card-content-internal-task-rate"
        >
          <div class="internal-task-global-information-card-font-title">
            {{ t('scheduling.Internal task rate') }}
          </div>
          <div class="internal-task-global-information-card-font-summary">
            {{ props.quota }}%
          </div>
        </div>
      </div>
      <div
        v-if="props.kind === 'training' && props.teacher"
        class="internal-task-global-information-card-content-group"
      >
        <div class="internal-task-global-information-card-content-item internal-task-global-information-card-content-video-trainer">
          <div class="internal-task-global-information-card-font-title">
            {{ t('scheduling.Trainer') }}
          </div>
          <div class="internal-task-global-information-card-font-summary">
            {{ props.teacher }}
          </div>
        </div>
      </div>
      <div class="internal-task-global-information-card-content-group">
        <div
          v-if="props.link"
          class="internal-task-global-information-card-content-item internal-task-global-information-card-content-videoconference-link"
        >
          <div class="internal-task-global-information-card-font-title">
            {{ t('scheduling.Videoconference link') }}
          </div>
          <div class="internal-task-global-information-card-font-summary">
            <AlgIconButton
              id="internal-task-global-videoconference-link"
              icon="link"
              variant="transparent"
            />
            <a
              :href="props.link"
              target="_blank"
            >
              {{ t('scheduling.Access to video conference') }}
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="props.location"
        class="internal-task-global-information-card-content-group"
      >
        <div
          class="internal-task-global-information-card-content-item internal-task-global-information-card-content-meeting-venue"
        >
          <div class="internal-task-global-information-card-font-title">
            <template v-if="props.kind === 'meeting'">
              {{ t('scheduling.Meeting venue') }}
            </template>
            <template v-else-if="props.kind === 'training'">
              {{ t('scheduling.Training venue') }}
            </template>
          </div>
          <div class="internal-task-global-information-card-font-summary">
            {{ props.location }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .internal-task-global-information-card {
    position: relative;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-effect-radius-m);
    padding-block: var(--alg-spacing-m) var(--alg-spacing-m);
    padding-inline: var(--alg-spacing-l);

    .internal-task-global-information-card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--alg-color-button-primary);
      font-size: var(--alg-font-size-xxs);
      font-weight: var(--alg-font-weight-bold);
      gap: var(--alg-spacing-s);
      padding-block-end: calc(var(--alg-spacing-l) / 2);
      text-transform: uppercase;
    }

    .internal-task-global-information-card-header-title {
      flex: 1;
    }

    .internal-task-global-information-card-font-title {
      color: var(--alg-color-text-primary);
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-bold);
    }

    .internal-task-global-information-card-font-summary {
      display: flex;
      align-items: center;
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-regular);
      gap: var(--alg-spacing-xs);
      line-height: var(--alg-font-line-height);

      a {
        color: inherit;
      }
    }

    .internal-task-global-information-card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--alg-spacing-s);
      padding-block-end: var(--alg-spacing-l);
    }

    .internal-task-global-information-card-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: var(--alg-spacing-l);
    }

    .internal-task-global-information-card-content-group {
      display: flex;
      flex-direction: row;
    }

    .internal-task-global-information-card-content-item {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: var(--alg-spacing-xs);
    }

    .internal-task-global-information-card-content-videoconference-link {
      flex: 2;
    }

    .internal-task-global-information-card-participant-list {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 0;
      margin: 0;
    }

    .internal-task-global-information-card-participant-list-item {
      display: flex;
      flex-direction: row;
      gap: var(--alg-spacing-s);
      line-height: var(--alg-font-line-height);

      &::before {
        content: '\25CF';
        margin-inline: var(--alg-spacing-xs);
      }
    }

    .internal-task-global-information-card-participant-list-item-id {
      margin-left: auto;
    }
  }
</style>
