<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgButton } from '../../button'

  defineOptions({
    name: 'AlgSchedulingPanel',
  })

  const props = withDefaults(defineProps<Props>(), {
    manualSchedulingVisible: false,
    requirementsAdjustmentDisabled: false,
    requirementsAdjustmentVisible: false,
  })

  const emit = defineEmits<{
    (e: 'planManually', payload?: Event): void
    (e: 'adjustRequirements', payload?: Event): void
  }>()

  interface Props {
    readonly manualSchedulingVisible?: boolean
    readonly manualSchedulingDisabled?: boolean
    readonly requirementsAdjustmentDisabled?: boolean
    readonly requirementsAdjustmentVisible?: boolean
  }

  const { t } = useI18n()
</script>

<template>
  <div class="scheduling-panel">
    <div class="cards">
      <slot />
    </div>
    <div
      v-if="props.manualSchedulingVisible || props.requirementsAdjustmentVisible"
      class="buttons"
    >
      <AlgButton
        v-if="props.manualSchedulingVisible"
        :disabled="props.manualSchedulingDisabled"
        :label="t('scheduling.Plan manually')"
        size="m"
        @click="(e) => emit('planManually', e)"
      />
      <AlgButton
        v-if="props.requirementsAdjustmentVisible"
        :disabled="props.requirementsAdjustmentDisabled"
        :label="t('scheduling.Adjust requirements')"
        variant="secondary"
        size="m"
        @click="(e) => emit('adjustRequirements', e)"
      />
    </div>
  </div>
</template>

<style scoped>
  .scheduling-panel {
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: row;
    gap: var(--alg-spacing-s);

    .cards {
      display: flex;
      flex: 1 1 auto;
      flex-flow: row nowrap;
      gap: var(--alg-spacing-s);
      overflow-x: auto;
    }
  }

  .buttons {
    display: flex;
    width: 175px;
    flex: 0 1 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--alg-spacing-s);

    .button{
      width: 100%;
    }
  }
</style>
