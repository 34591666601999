<script setup lang="ts" generic="T extends { id: number | string }">
  import { computed, nextTick, onMounted, ref, watch } from 'vue'

  import { unique } from '@algorh/shared'

  import { AlgChip } from '../../chip'
  import { AlgDisclosure } from '../../disclosure'
  import { AlgDot } from '../../dot'
  import { AlgCheckbox } from '../checkbox'
  import { AlgRadio } from '../radio'

  import { ChoiceTreeData } from './ChoiceTree.type'

  type Props = {
    data: ChoiceTreeData<T>
    modelValue: T['id'][]
    open?: boolean
    level?: number
    id?: string
    multiple?: boolean
    readonly?: boolean
    parent?: ChoiceTreeData<T>
    parentSelected?: boolean
    recursiveSelect?: boolean
    autoScroll?: boolean
    color?: string
  }

  defineOptions({
    name: 'ChoiceTree',
  })

  const props = withDefaults(defineProps<Props>(), {
    id: '',
    open: false,
    level: 0,
    multiple: false,
    readonly: false,
    parentSelected: false,
    recursiveSelect: false,
    autoScroll: false,
  })

  const emit = defineEmits<{
    (e: 'update:model-value', payload: T['id'][]): void
  }>()

  // Refs
  const isOpen = ref(false)

  // Computed
  const computedId = computed(() => `${props.data.name}-checkbox-${props.data.id}-${props.id}`)

  const selected = computed(() => props.modelValue.includes(props.data.id))

  const indeterminate = computed(() => !selected.value ? props.data.children.some((c) => props.modelValue.includes(c.id)) : false)

  const hasSelectedChildren = computed(() => childrenIds.value.some((id) => props.modelValue.includes(id)))

  const childrenIds = computed(() => childrenIdsRecursive(props.data))

  const selectedChildrenCount = computed(() => {
    if (props.recursiveSelect && props.parentSelected) {
      return props.data.children.length
    }

    return childrenIds.value.filter((id) => id !== -1 && props.modelValue.includes(id)).length
  })

  // Methods
  function childrenIdsRecursive(data: ChoiceTreeData<T>): T['id'][] {
    return data.children.map((child) => {
      if (child.children.length) {
        return [child.id, ...childrenIdsRecursive(child)]
      }

      return child.id
    }).flat() as T['id'][]
  }

  function handleTreeLeaveCheckboxChange(value: boolean) {
    if (value) {
      const currentValue = props.recursiveSelect && props.data.children.length
        ? props.modelValue.filter((id) => !childrenIds.value.includes(id))
        : props.modelValue
      emit('update:model-value', [...currentValue, props.data.id])
    } else {
      emit(
        'update:model-value',
        props.modelValue.filter((id) => id !== props.data.id),
      )
    }
  }

  function handleTreeLeaveRadioChange(value: T['id']) {
    if (props.modelValue.includes(value)) {
      emit('update:model-value', [])
    } else {
      emit('update:model-value', [value])
    }
  }

  function handleUpdate(payload: T['id'][]) {
    emit('update:model-value', unique(payload))
  }

  function handleOnOpenDisclosure(value: boolean) {
    isOpen.value = value
  }

  onMounted(() => {
    isOpen.value = props.open || hasSelectedChildren.value
  })

  watch(() => props.modelValue, (val) => {
    // if (isOpen.value && !hasSelectedChildren.value) {
    //   isOpen.value = false
    // }
    if (props.open && !isOpen.value && hasSelectedChildren.value) {
      isOpen.value = true
    }

    if (props.autoScroll && val.includes(props.data.id)) {
      nextTick(() => {
        const node = document.getElementById(`${props.data.name}-checkbox-${props.data.id}`)
        node?.scrollIntoView({ block: 'center', behavior: 'auto' })
      })
    }
  }, { immediate: true })
</script>

<template>
  <div
    class="choice-tree"
    :class="{
      'last-level': !props.data.children || props.data.children && !props.data.children.length
    }"
  >
    <AlgDisclosure
      v-if="props.data.children?.length"
      rounded
      :open="isOpen"
      bordered
      :background-color="
        props.level % 2 === 0 ?
          'var(--alg-color-surface-background)' :
          'var(--alg-color-surface-background-highlight)'
      "
      @update:model-value="handleOnOpenDisclosure"
    >
      <template #trigger>
        <div
          class="choice-tree-trigger"
          :class="{
            'parent-selected': !props.multiple && props.recursiveSelect && props.parentSelected
          }"
        >
          <template
            v-if="props.multiple"
          >
            <AlgCheckbox
              :id="computedId"
              :model-value="selected || props.recursiveSelect && props.parentSelected"
              :indeterminate="indeterminate"
              :inactive="props.readonly"
              :disabled="props.recursiveSelect && props.parentSelected"
              :label="props.data.name"
              label-font-weight="bold"
              size="s"
              @update:model-value="handleTreeLeaveCheckboxChange"
              @click.stop
            />
            <div class="slot">
              <AlgChip
                v-if="selectedChildrenCount && !selected && !isOpen"
                :id="`${props.data.id}-chip`"
                :label="`${selectedChildrenCount}`"
                size="s"
                :color="props.color"
              />
              <AlgDot
                v-if="!props.multiple && !props.parentSelected && selectedChildrenCount && !selected && !isOpen"
                color="var(--alg-color-icon-highlight)"
                size="s"
              />
              <slot
                name="default"
                :item="data"
              />
            </div>
          </template>
          <template v-else>
            <AlgRadio
              :id="computedId"
              :inactive="props.readonly"
              :label="props.data.name"
              :value="props.data.id"
              :model-value="selected || props.recursiveSelect && props.parentSelected ? props.data.id : false"
              name="choice-tree"
              label-font-weight="bold"
              size="s"
              class="radio"
              @update:model-value="handleTreeLeaveRadioChange(props.data.id)"
              @click.stop
            />
            <div class="slot">
              <AlgChip
                v-if="selectedChildrenCount && props.multiple && !selected && !isOpen"
                :id="`${props.data.id}-chip`"
                :label="`${selectedChildrenCount}`"
                size="s"
                :color="props.color"
              />
              <AlgDot
                v-if="!props.parentSelected && selectedChildrenCount && !selected && !isOpen"
                color="var(--alg-color-icon-highlight)"
                size="s"
              />
              <slot
                name="default"
                :item="data"
              />
            </div>
          </template>
        </div>
      </template>
      <div class="choice-tree-content">
        <div
          v-for="subitem in props.data.children"
          :key="subitem.name"
        >
          <ChoiceTree
            :readonly="props.readonly"
            :data="subitem"
            :parent-selected="selected || props.recursiveSelect && props.parentSelected"
            :parent="props.data"
            :model-value="props.modelValue"
            :level="props.level + 1"
            :open="props.open"
            :multiple="props.multiple"
            :recursive-select="props.recursiveSelect"
            :auto-scroll="props.autoScroll"
            @update:model-value="handleUpdate"
          >
            <template #default="{ item: childItem }">
              <slot
                name="default"
                :item="childItem"
              />
            </template>
          </ChoiceTree>
        </div>
      </div>
    </AlgDisclosure>
    <div
      v-else
      class="choice-tree-content"
      :class="{
        'parent-selected': props.recursiveSelect && props.parentSelected
      }"
    >
      <div class="leave">
        <AlgCheckbox
          v-if="props.multiple"
          :id="computedId"
          :model-value="selected || props.recursiveSelect && props.parentSelected"
          :inactive="props.readonly"
          :disabled="props.recursiveSelect && props.parentSelected"
          :label="props.data.name"
          label-font-weight="bold"
          size="s"
          @update:model-value="handleTreeLeaveCheckboxChange"
        />
        <AlgRadio
          v-else
          :id="computedId"
          :inactive="props.modelValue.includes(props.data.id)"
          :label="props.data.name"
          :value="props.data.id"
          :model-value="selected || props.recursiveSelect && props.parentSelected
            ? props.data.id
            : false
          "
          :class="props.recursiveSelect && props.parentSelected"
          name="choice-tree"
          label-font-weight="bold"
          size="s"
          @update:model-value="handleTreeLeaveRadioChange(props.data.id)"
        />
        <slot
          name="default"
          :item="data"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.disclosure) {
  summary {
    padding: var(--alg-spacing-s);
  }

  .details-wrapper {
    padding: var(--alg-spacing-s) var(--alg-spacing-s)  var(--alg-spacing-s) 0;
  }
}

.choice-tree {
  display: flex;
  flex-direction: column;
  gap: var(--alg-spacing-s);

  .choice-tree-trigger {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;
    padding: var(--alg-spacing-xs) 0;
    gap: var(--alg-spacing-s);
  }

  .choice-tree-content {
    display: flex;
    flex-direction: column;
    padding: var(--alg-spacing-xs) 0 0 var(--alg-spacing-s);
    gap: var(--alg-spacing-s);

    .leave {
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
    }
  }

  .choice-tree-trigger,
  .choice-tree-content {
    &.parent-selected {
      opacity: 0.5;
    }
  }

  .radio {
    cursor: pointer;
  }

  .slot {
    display: flex;
    align-items: center;
    gap: var(--alg-spacing-s);
  }

  &.last-level {
    .choice-tree-content {
      padding-bottom: var(--alg-spacing-s);
    }
  }
}
</style>
