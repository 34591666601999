<script setup lang="ts">
  import { RouteLocation, RouteLocationRaw } from 'vue-router'

  import type { IconName, LinkVariant } from '#/types'

  import { AlgIcon } from '../media'

  type Props = {
    readonly to: RouteLocation | RouteLocationRaw | string
    readonly label?: string
    readonly variant?: LinkVariant
    readonly disabled?: boolean
    readonly iconStart?: IconName
    readonly iconEnd?: IconName
  }

  defineOptions({
    name: 'AlgButton',
  })

  const props = withDefaults(defineProps<Props>(), {
    variant: 'primary',
    disabled: false,
  })

  const emit = defineEmits<{
    (e: 'click'): void
  }>()

  // Methods
  function handleClick() {
    if (props.disabled) {
      return
    }

    emit('click')
  }
</script>

<template>
  <RouterLink
    class="link"
    :class="[`variant-${props.variant}`, { disabled: props.disabled }]"
    :to="props.to"
    @click="handleClick"
  >
    <AlgIcon
      v-if="props.iconStart"
      :name="props.iconStart"
      size="s"
    />
    {{ props.label }}
    <AlgIcon
      v-if="props.iconEnd"
      :name="props.iconEnd"
      size="s"
    />
  </RouterLink>
</template>

<style scoped>
  .link {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: var(--alg-font-size-m);
    font-weight: var(--alg-font-weight-bold);
    gap: var(--alg-spacing-s);
    text-decoration: none;
    text-underline-offset: var(--alg-spacing-xs);
    user-select: none;

    &:hover {
      text-decoration: underline;
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      text-decoration: none;
    }

    &.variant-primary {
      color: var(--alg-color-button-primary);

      &:hover {
        color: var(--alg-color-button-primary-hover);
      }

      &.disabled {
        color: var(--alg-color-button-primary-disabled);
      }
    }

    &.variant-secondary {
      color: var(--alg-color-text-secondary);

      &:hover {
        color: var(--alg-color-text-primary);
      }

      &.disabled {
        color: var(--alg-color-button-secondary-disabled);
      }
    }
  }
</style>
