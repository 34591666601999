import { computed, onBeforeMount, Ref, ref } from 'vue'
import { LocationQuery, LocationQueryValue, useRouter } from 'vue-router'

import { FilterOptionsFilters, FilterValue, Nullable } from '../types'

function useFilters<
  T extends Record<K, V>,
  K extends string = string,
  V extends FilterValue = FilterValue,
  Dto extends Record<string, unknown> = Record<string, unknown>,
>(
  router: Nullable<ReturnType<typeof useRouter>>,
  defaultFilters: T,
  filtersOptions: FilterOptionsFilters<K, Dto>,
) {
  const filters = ref<T>(defaultFilters) as Ref<T>
  const keys = Object.keys(filtersOptions)
  const queryEntries = computed(() => router ? Object.entries(router.currentRoute.value.query) : [])
  const testQueryInKeys = (q: string) => keys.map((k) => RegExp(`\\[?${k}\\]?`, 'g').test(q))

  async function setFilters(f: T, updateRoute = true) {
    const entries = Object.entries(f)
      .map((entry): [K, V] => {
        const [key, value] = entry as [K, V]
        const parser = filtersOptions[key]?.parser
        if (!parser || !value) {
          return [key, value]
        }
        return [key, (Array.isArray(value) ? value.map(parser) : parser(value)) as V]
      })
      .filter(([, value]: [K, V]) => Array.isArray(value) ? value.length > 0 : value !== undefined)
    filters.value = Object.fromEntries(entries) as T
    if (router && updateRoute) {
      await router.replace({
        params: router.currentRoute.value.params,
        query: createQueryFromFilters(),
      })
    }
  }

  /**
   *
   * @param f
   */
  function resetFilters(f?: Partial<T>) {
    setFilters({ ...defaultFilters, ...f })
  }

  /**
   * Extract filters ONLY from current query
   * @param query
   * @returns
   */
  function extractFiltersFromQuery<T extends Record<string, FilterValue>>(): T {
    const regExp = /\[(\w*)\]/

    const reducer = (acc: T, [key, value]: [string, LocationQueryValue | LocationQueryValue[]]) => {
      const match = key.match(regExp)

      if (!value) {
        return acc
      }

      const values = Array.isArray(value) ? value : (match !== null ? value.split(',') : value)

      if (!values) {
        return acc
      }

      return Array.isArray(values) && match !== null
        ? { ...acc, [match[1]]: values.map((v) => v === '' ? null : v) }
        : { ...acc, [key]: values }
    }

    const filteredList = queryEntries.value.filter(([q]) => testQueryInKeys(q).some((v) => v))
    return filteredList.reduce<T>(reducer, {} as T)
  }

  function createQueryFromFilters(): LocationQuery {
    const filteredList = queryEntries.value.filter(([q]) => testQueryInKeys(q).every((v) => !v))
    const nonFiltersQuery = Object.fromEntries(filteredList)

    const reducer = (acc: LocationQuery, [key, value]: [string, V]) => {
      if (value === null || (Array.isArray(value) && value.every((v) => v === null))) {
        return acc
      }
      return { ...acc, [Array.isArray(value) ? `[${key}]` : key]: Array.isArray(value) ? value.join(',') : `${value}` }
    }
    const filtersQuery = Object.entries<V>(filters.value).reduce<LocationQuery>(reducer, {})
    return { ...nonFiltersQuery, ...filtersQuery }
  }

  onBeforeMount(() => {
    setFilters({
      ...defaultFilters,
      ...extractFiltersFromQuery(),
    }, false)
  },
  )

  return { filters, setFilters, resetFilters, createQueryFromFilters, extractFiltersFromQuery }
}

export { useFilters }
