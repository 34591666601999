import { Dto, fetchJson, Nullable } from '@algorh/shared'

import { Mode } from '@/core/enums/Schedule'
import { GlobalPlanningSlot } from '@/core/types/Schedule'

import { Adherence, CurrentLoad, LoadCurve, Risk } from '../types/Planning'
import { User } from '../types/User'

import {
  GetCustomerAdvisersDto,
  GetCustomerAdvisersForModeDto,
  GetPlanningProjectActivityTotalLoadDto,
  PutLoadAdjustmentDto,
} from './plannings.service.type'

const BASE_URI = '/api/scheduling/plannings'

const SchedulingPlanningsApiService = {
  getAdherences(planningId: number) {
    return fetchJson<Adherence[]>(
      'GET', `${BASE_URI}/${planningId}/adherence`,
    )
  },
  putLoadAdjustment(planningId: number, dto: PutLoadAdjustmentDto) {
    return fetchJson(
      'PUT', `${BASE_URI}/${planningId}/adjust-load`, dto,
    )
  },
  stopAdviserInput(planningId: number) {
    return fetchJson(
      'PUT', `${BASE_URI}/${planningId}/close-for-input`,
    )
  },
  getCustomerAdvisers(planningId: number, dto: Dto<GetCustomerAdvisersDto>) {
    return fetchJson(
      'GET', `${BASE_URI}/${planningId}/customer-advisers`, null, dto,
    ) as Promise<{ data: User[], total: number }>
  },
  getCustomerAdvisersForMode(planningId: Nullable<number>, mode: Mode, dto: Dto<GetCustomerAdvisersForModeDto>) {
    if (!planningId) {
      throw Error(`planningID not defined (${planningId})`)
    }
    return fetchJson<User[], null, Dto<GetCustomerAdvisersForModeDto>>(
      'GET', `${BASE_URI}/${planningId}/customer-advisers/${mode}`, null, dto,
    )
  },
  getModeCount(planningId: number) {
    return fetchJson<{ undefined: number, user_defined: number, predefined: number, freestyle: number }>(
      'GET', `${BASE_URI}/${planningId}/mode-count`,
    )
  },
  startAdviserInput(planningId: number) {
    return fetchJson(
      'PUT', `${BASE_URI}/${planningId}/open-for-input`,
    )
  },
  getProjectActivityForecast(planningId: number, projectActivityId: number) {
    return fetchJson<{ counts: CurrentLoad[], risk: Nullable<Risk>, load_curve: Nullable<LoadCurve> }>(
      'GET', `${BASE_URI}/${planningId}/project-activities/${projectActivityId}/forecast`,
    )
  },
  getProjectActivityTotalLoad(planningId: number, projectActivityId: number, dto: GetPlanningProjectActivityTotalLoadDto) {
    return fetchJson<{ load: number }, null, GetPlanningProjectActivityTotalLoadDto>(
      'GET', `${BASE_URI}/${planningId}/project-activities/${projectActivityId}/total-load`, null, dto,
    )
  },
  getSatisfactions(planningId: number) {
    return fetchJson<{ satisfied_slots: number, not_satisfied_slots: number }>(
      'GET', `${BASE_URI}/${planningId}/satisfactions`,
    )
  },
  startRawPlanification(planningId: number) {
    return fetchJson(
      'PUT', `${BASE_URI}/${planningId}/start-raw-planification`,
    )
  },
  startScheduler(planningId: number) {
    return fetchJson(
      'PUT', `${BASE_URI}/${planningId}/start-scheduler`,
    )
  },
  postRestoreSchedulePlanPeriod() {
    return fetchJson<{ id: number, restoration: boolean, working_schedule: GlobalPlanningSlot[] }>(
      'POST', `${BASE_URI}/plan-period/restore`,
    )
  },
}

export { SchedulingPlanningsApiService }
