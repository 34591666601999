<script setup lang="ts">
  import { computed } from 'vue'

  import { Nullable } from '@algorh/shared'

  import { AlgErrors } from '../../feedback'
  import { AlgLabel } from '../label'

  type Props = {
    readonly id: string
    readonly modelValue: Nullable<number>
    readonly label?: string
    readonly required?: boolean
    readonly disabled?: boolean
    readonly errors?: string[]
    readonly min?: number
    readonly minLabel?: string
    readonly max?: number
    readonly maxLabel?: string
  }

  defineOptions({
    name: 'AlgLevelSelect',
  })

  const props = withDefaults(defineProps<Props>(), {
    required: false,
    disabled: false,
    min: 0,
    max: 10,
  })

  const emit = defineEmits<{
    (e: 'update:model-value', value: Nullable<number>): void
  }>()

  const levels = computed(() =>
    Array.from({ length: props.max - props.min + 1 }, (_, i) => i + props.min),
  )

  function handleSelectLevel(level: number) {
    if (!props.required && props.modelValue === level) {
      emit('update:model-value', null)
    } else {
      emit('update:model-value', level)
    }
  }
</script>

<template>
  <div
    class="level-select"
    :style="{
      width: `${levels.length * 24}px`
    }"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :html-for="props.id"
      :required="props.required"
    />
    <div class="min-max">
      <span class="min">
        {{ props.min }}
      </span>
      <span class="max">
        {{ props.max }}
      </span>
    </div>
    <div class="levels">
      <button
        v-for="level in levels"
        :key="level"
        type="button"
        class="level"
        :class="{
          active: props.modelValue !== null ? level <= props.modelValue : false
        }"
        :disabled="props.disabled"
        @click="handleSelectLevel(level)"
      >
        <span
          class="level-label"
          :class="{ active: level === props.modelValue }"
        >
          {{ level }}
        </span>
      </button>
    </div>
    <div class="legend">
      <span class="min">{{ props.minLabel }}</span>
      <span class="max">{{ props.maxLabel }}</span>
    </div>
    <AlgErrors
      v-if="props.errors && props.errors.length"
      :errors="props.errors"
    />
  </div>
</template>

<style scoped>
  .level-select {
    display: flex;
    flex-direction: column;
    gap: var(--alg-spacing-s);

    .levels {
      display: flex;
      flex-direction: row;
      gap: 1px;

      .level {
        display: inline-flex;
        width: 24px;
        height: 16px;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--alg-color-surface-border);
        transition-duration: var(--alg-transition-colors-duration);
        transition-property: background-color, border-color;
        transition-timing-function: var(--alg-transition-colors-timing-function);

        .level-label {
          color: var(--alg-color-text-on-color);
          font-size: var(--alg-font-size-xxs);
          opacity: 0;
          transition-duration: var(--alg-transition-colors-duration);
          transition-property: opacity;
          transition-timing-function: var(--alg-transition-colors-timing-function);

          &.active {
            opacity: 1;
          }
        }

        &:first-child {
          border-bottom-left-radius: var(--alg-effect-radius-l);
          border-top-left-radius: var(--alg-effect-radius-l);
        }

        &:last-child {
          border-bottom-right-radius: var(--alg-effect-radius-l);
          border-top-right-radius: var(--alg-effect-radius-l);
        }

        &:hover {
          border-color: var(--alg-color-button-primary-hover);
          background-color: var(--alg-color-button-primary-hover);

          .level-label {
            opacity: 1;
          }
        }

        &.active {
          border-color: var(--alg-color-button-primary);
          background-color: var(--alg-color-button-primary);

          &:hover {
            border-color: var(--alg-color-button-primary-hover);
            background-color: var(--alg-color-button-primary-hover);
          }
        }

        &:disabled {
          border-color: var(--alg-color-surface-border);
          background-color: var(--alg-color-surface-primary);

          &.active {
            border-color: var(--alg-color-button-primary-disabled);
            background-color: var(--alg-color-button-primary-disabled);
          }
        }
      }
    }

    .min-max,
    .legend {
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-xxs);
    }

    .legend {
      text-transform: uppercase;
    }
  }
</style>
