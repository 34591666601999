<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { AlgErrors } from '../../feedback'
  import { AlgLabel } from '../label'

  defineOptions({
    name: 'AlgDaySelector',
  })
  const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    hiddenLabel: false,
    inline: false,
    modelValue: () => [],
  })
  const emit = defineEmits<{
    (e: 'input', value: string[]): void
    (e: 'update:modelValue', value: Day[]): void
  }>()

  enum Day {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday',
  }

  interface Props {
    readonly id: string
    readonly disabled?: boolean
    readonly label?: string
    readonly sublabel?: string
    readonly required?: boolean
    readonly modelValue: Day[]
    readonly hiddenLabel?: boolean
    readonly inline?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
  }

  const { t } = useI18n()

  const DAYS = [
    {
      label: t('datetime.Monday'),
      value: Day.MONDAY,
    },
    {
      label: t('datetime.Tuesday'),
      value: Day.TUESDAY,
    },
    {
      label: t('datetime.Wednesday'),
      value: Day.WEDNESDAY,
    },
    {
      label: t('datetime.Thursday'),
      value: Day.THURSDAY,
    },
    {
      label: t('datetime.Friday'),
      value: Day.FRIDAY,
    },
    {
      label: t('datetime.Saturday'),
      value: Day.SATURDAY,
    },
    {
      label: t('datetime.Sunday'),
      value: Day.SUNDAY,
    },
  ]

  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  function handleChange(e: Event) {
    const { value, checked } = e.target as HTMLInputElement
    const newValue = new Set([...props.modelValue, value as Day])
    !checked && newValue.delete(value as Day)
    emit('update:modelValue', [...new Set([...newValue])])
  }
</script>

<template>
  <div
    class="day-selector-field-wrapper"
    :class="{ inline }"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :hidden-label="props.hiddenLabel"
      :html-for="modelValue.length === 0 ? DAYS[0].value : ''"
      :inline="props.inline"
      :required="props.required"
      :errored="hasErrors"
    />

    <div class="day-selector-input-wrapper">
      <div
        v-for="day in DAYS"
        :key="day.value"
        class="day-selector-input-inner"
        :class="{ 'errored': hasErrors }"
      >
        <input
          :id="day.value"
          class="sr-only"
          :disabled="props.disabled"
          :name="props.id"
          :value="day.value"
          type="checkbox"
          :checked="modelValue && modelValue.includes(day.value)"
          @change="handleChange"
        >
        <label :for="day.value">{{ day.label.charAt(0).toUpperCase() }}</label>
      </div>
    </div>
    <AlgErrors
      v-if="props.errors"
      :errors="props.errors"
    />
  </div>
</template>

<style scoped>
  .day-selector-field-wrapper {
    display: flex;
    flex-direction: column;

    &.inline {
      flex-direction: row;
      align-items: center;

      .day-selector-input-label {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }

    .day-selector-input-label {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      color: var(--alg-color-text-primary);
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-bold);
      white-space: nowrap;
    }

    .day-selector-input-wrapper {
      position: relative;
      display: flex;
      overflow: hidden;
      flex-direction: row;
      margin-top: 2px;
      gap: var(--alg-spacing-s);

      .day-selector-input-inner {
        display: flex;
        height: 38px;
        flex-direction: row;
        color: var(--alg-color-text-secondary);
        cursor: pointer;
        font-size: var(--alg-font-size-l);
        font-weight: var(--alg-font-weight-regular);

        &.errored label {
          border-color: var(--alg-color-state-danger);
        }

        label {
          display: flex;
          width: 36px;
          height: 36px;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--alg-color-surface-border);
          border-radius: 36px;
          background-color: var(--alg-color-surface-primary);
          cursor: pointer;
          outline: none;
          text-align: center;
          text-indent: 0;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:focus,
          &:focus-visible {
            box-shadow: var(--alg-effect-shadow-m);
            outline: none;
          }
        }

        input {
          margin: 0;
        }

        input:focus + label {
          box-shadow: var(--alg-effect-shadow-m);
          outline: none;
        }

        input:disabled + label {
          background-color: var(--alg-color-surface-background);
          color: var(--alg-color-text-light);
          cursor: not-allowed;
        }

        input:checked + label {
          background-color: var(--alg-color-button-primary);
          color: var(--alg-color-text-on-color);
        }

        input:checked:disabled + label {
          background-color: var(--alg-color-button-primary-disabled);
          color: var(--alg-color-text-on-color);
        }
      }
    }
  }
</style>
