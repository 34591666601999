import { fetchJson, Nullable } from '@algorh/shared'

import { PaginatedDto } from '@/core/types'

import { DetailedInternalTaskInstance, InternalTask, UserInstance } from '../types/InternalTask'
import { CustomerAdviser } from '../types/Planning'
import { UserLite } from '../types/User'

const BASE_URI = '/api/scheduling/training-instances'

const SchedulingTrainingInstancesApiService = {
  getTrainingDetails(trainingId: number) {
    return fetchJson<{ task: InternalTask, instance: DetailedInternalTaskInstance }>(
      'GET', `${BASE_URI}/${trainingId}`, null,
    )
  },
  putTraining(trainingId: number, payload: { location: Nullable<string>, link: Nullable<string>, trainer_id?: Nullable<number> }) {
    return fetchJson(
      'PUT', `${BASE_URI}/${trainingId}`, payload,
    )
  },
  deleteTraining(trainingId: number) {
    return fetchJson(
      'DELETE', `${BASE_URI}/${trainingId}`,
    )
  },
  getTrainingCustomerAdvisers(trainingId: number, paginatedDto: PaginatedDto) {
    return fetchJson<UserInstance[], null, PaginatedDto>(
      'GET', `${BASE_URI}/${trainingId}/customer-advisers`, null, paginatedDto,
    )
  },
  deleteTrainingCustomerAdviser(trainingId: number, customerAdviserId: number) {
    return fetchJson(
      'DELETE', `${BASE_URI}/${trainingId}/customer-advisers/${customerAdviserId}`,
    )
  },
  getTrainingAvailableCustomerAdvisers(trainingId: number, params: PaginatedDto) {
    return fetchJson<CustomerAdviser[], null, PaginatedDto>(
      'GET', `${BASE_URI}/${trainingId}/customer-advisers/available`, null, params,
    )
  },
  postTrainingCustomerAdvisersBatch(trainingId: number, userIds: number[]) {
    return fetchJson(
      'POST', `${BASE_URI}/${trainingId}/customer-advisers/batch`, { user_ids: userIds },
    )
  },
  getTrainingAvailableTrainers(trainingId: number) {
    return fetchJson<UserLite[]>(
      'GET', `${BASE_URI}/${trainingId}/trainers/available`,
    )
  },
}

export { SchedulingTrainingInstancesApiService }
