<script setup lang="ts" generic="T extends Nullable<number> = null">
  import { computed } from 'vue'

  import { debounce, Nullable } from '@algorh/shared'

  import { IconName } from '#/types'

  import { AlgErrors } from '../../../feedback'
  import { AlgIcon } from '../../../media'
  import { AlgLabel } from '../../label'
  import { InputSize, InputVariant } from '../Input.type'

  type Props = {
    readonly id: string
    readonly modelValue: T
    readonly label?: string
    readonly sublabel?: string
    readonly placeholder?: string
    readonly autocomplete?: string
    readonly size?: InputSize
    readonly inline?: boolean
    readonly required?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    readonly centered?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
    readonly prefixIcon?: IconName
    readonly suffixIcon?: IconName
    readonly variant?: InputVariant
    readonly min?: number
    readonly max?: number
    readonly step?: number
  }

  defineOptions({
    name: 'AlgNumberInput',
  })

  const props = withDefaults(defineProps<Props>(), {
    autocomplete: 'off',
    size: 'm',
    inline: false,
    required: false,
    disabled: false,
    readonly: false,
    centered: false,
    errored: false,
    variant: 'primary',
    step: 1,
  })

  const emit = defineEmits<{
    (e: 'update:model-value', value: T): void
  }>()

  // Data
  const debouncedInput = debounce(handleInput, 300)

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  // Methods
  function handleInput(event: Event) {
    const { value } = event.target as HTMLInputElement

    if (value === '') {
      emit('update:model-value', null as T)
      return
    }

    if (props.min && +value < props.min) {
      emit('update:model-value', props.min as T)
      return
    }

    if (props.max && +value > props.max) {
      emit('update:model-value', props.max as T)
      return
    }

    emit('update:model-value', +value as T)
  }
</script>

<template>
  <div
    class="field-wrapper"
    :class="{ inline: props.inline}"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :html-for="props.id"
      :inline="props.inline"
      :input-size="props.size"
      :required="props.required"
      :errored="hasErrors"
    />
    <div class="field-content">
      <div class="input-wrapper">
        <input
          :id="props.id"
          type="number"
          class="input"
          :class="[
            `size-${props.size}`,
            `variant-${props.variant}`,
            {
              errored: hasErrors,
              centered: props.centered,
              'has-prefix': props.prefixIcon,
              'has-suffix': props.suffixIcon
            }
          ]"
          :name="props.id"
          :placeholder="props.placeholder"
          :autocomplete="props.autocomplete"
          :required="props.required"
          :disabled="props.disabled"
          :readonly="props.readonly"
          :min="props.min"
          :max="props.max"
          :step="props.step"
          :value="props.modelValue"
          @input="(e) => props.min || props.max ? debouncedInput(e) : handleInput(e)"
        >
        <span
          v-if="props.prefixIcon"
          class="input-prefix"
        >
          <AlgIcon
            :name="props.prefixIcon"
            :color="
              props.disabled ? 'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-secondary)'
            "
            size="s"
          />
        </span>
        <span
          v-if="props.suffixIcon"
          class="input-suffix"
        >
          <AlgIcon
            :name="props.suffixIcon"
            :color="
              props.disabled ? 'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-secondary)'
            "
            size="s"
          />
        </span>
      </div>
      <AlgErrors
        v-if="props.errors && props.errors.length"
        :errors="props.errors"
      />
    </div>
  </div>
</template>

<style src="../index.css" scoped />
