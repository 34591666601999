<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { DateString, dayjs } from '@algorh/shared'

  import { AlgIcon } from '../../media'

  type Props = {
    readonly start: DateString
    readonly end: DateString
    readonly grayed?: boolean
  }

  defineOptions({
    name: 'AlgWeekPeriod',
  })

  const props = withDefaults(defineProps<Props>(), {
    grayed: false,
  })

  const { t } = useI18n()
</script>

<template>
  <div
    class="week-period"
    :class="{
      'grayed': props.grayed,
    }"
  >
    <AlgIcon
      name="schedule"
      size="s"
      class="icon"
    />
    <span class="period">
      <template v-if="dayjs(props.start).isoWeek() === dayjs(props.end).isoWeek()">
        {{
          t('datetime.Week {week} ({year})', {
            week: dayjs(props.start).isoWeek(),
            year: dayjs(props.start).isoWeekYear()
          })
        }}
      </template>
      <template v-else>
        {{
          t('datetime.{start}, {end}', {
            start: t('datetime.Week {week} ({year})', {
              week: dayjs(props.start).isoWeek(),
              year: dayjs(props.start).isoWeekYear()
            }),
            end: t('datetime.Week {week} ({year})', {
              week: dayjs(props.end).isoWeek(),
              year: dayjs(props.end).isoWeekYear()
            })
          })
        }}
      </template>
    </span>
  </div>
</template>

<style scoped>
  .week-period {
    display: inline-flex;
    align-items: center;
    font-size: var(--alg-font-size-s);
    gap: var(--alg-spacing-xs);
    white-space: nowrap;

    .icon {
      color: var(--alg-color-icon-highlight);
    }

    .period {
      color: var(--alg-color-text-primary);
      font-weight: var(--alg-font-weight-bold);
    }

    &.grayed {
      .icon {
        color: var(--alg-color-icon-secondary);
      }

      .period {
        color: var(--alg-color-text-secondary);
        font-weight: var(--alg-font-weight-regular);
      }
    }
  }
</style>
