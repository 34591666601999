<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { dayjs } from '@algorh/shared'
  import { AlgBadge, AlgIconButton, AlgToggle, AlgWeekPeriod } from '@algorh/ui'

  import { RealSubdivisionCollectiveRule } from '@/sections/scheduling/types/Rule'
  import { CollectiveRule, RealCollectiveRuleForProject } from '@/sections/settings/types/Rule'
  import { IndividualRule } from '@/sections/users/types'

  import RuleText from '../RuleText.vue'

  import { HumanizeRule } from './HumanizeRule.type'

  type Props = {
    rule: RealCollectiveRuleForProject | IndividualRule | CollectiveRule | RealSubdivisionCollectiveRule
    focused?: boolean
    duplicable?: boolean
    editable?: boolean
    deletable?: boolean
    togglable?: boolean
    individual?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    focused: false,
    duplicable: true,
    editable: true,
    deletable: true,
    togglable: true,
    individual: false,
  })

  const emit = defineEmits<{
    (e: 'duplicate'): void
    (e: 'edit'): void
    (e: 'delete'): void
    (e: 'toggle'): void
  }>()

  const { t } = useI18n()

  const ruleStatus = computed(() => {
    const { applicable_from, applicable_to } = props.rule

    if (applicable_from != null && dayjs().isBefore(applicable_from)) {
      return 'incoming'
    }

    if (applicable_to != null && dayjs().isAfter(applicable_to)) {
      return 'done'
    }

    return 'in-progress'
  })

  const item = computed((): HumanizeRule => ({
    config: Array.isArray(props.rule.config) ? null : props.rule.config,
    other: props.rule.other?.name ?? null,
    subject: props.rule.subject?.name ?? null,
    value: props.rule.value,
    rule_template_id: props.rule.rule_template_id,
  }))

  const ruleStatusLabel = computed(() => {
    switch (ruleStatus.value) {
    case 'incoming':
      return t('common.Incoming')
    case 'done':
      return t('common.Done (f)')
    case 'in-progress':
      return t('common.In progress')
    default:
      return ''
    }
  })

  const ruleStatusVariant = computed(() => {
    switch (ruleStatus.value) {
    case 'incoming':
      return 'secondary'
    case 'done':
      return 'warning'
    case 'in-progress':
      return 'success'
    default:
      return 'secondary'
    }
  })
</script>

<template>
  <div
    class="rule-list-item"
    :class="{
      focused: props.focused,
      deactivated: !props.rule.activated,
      individual: props.individual,
    }"
  >
    <div class="content">
      <RuleText
        class="label"
        :item="item"
      />
      <AlgWeekPeriod
        v-if="props.rule.applicable_from && props.rule.applicable_to"
        class="rule-temporality"
        :start="props.rule.applicable_from"
        :end="props.rule.applicable_to"
        grayed
      />
      <div class="badges">
        <AlgBadge
          :label="ruleStatusLabel"
          :variant="ruleStatusVariant"
          size="l"
        />
        <AlgBadge
          v-if="'mandatory' in props.rule && props.rule.mandatory"
          :label="t('rules.Unbreakable')"
          size="l"
        />
      </div>
    </div>
    <div class="actions-toggle-wrapper">
      <div class="actions">
        <AlgIconButton
          v-if="props.duplicable"
          :id="`duplicate-rule-${props.rule.id}`"
          icon="content-copy"
          variant="transparent"
          size="m"
          icon-color="var(--alg-color-icon-secondary)"
          tooltip
          :label="t('common.Duplicate')"
          @click="() => emit('duplicate')"
        />
        <AlgIconButton
          v-if="props.editable"
          :id="`edit-rule-${props.rule.id}`"
          icon="stylus"
          variant="transparent"
          size="m"
          icon-color="var(--alg-color-state-info)"
          tooltip
          :label="t('common.Edit')"
          @click="() => emit('edit')"
        />
        <AlgIconButton
          v-if="props.deletable"
          :id="`delete-rule-${props.rule.id}`"
          icon="delete"
          variant="transparent"
          size="m"
          icon-color="var(--alg-color-state-danger)"
          tooltip
          :label="t('common.Delete')"
          @click="() => emit('delete')"
        />
      </div>
      <AlgToggle
        :id="`activated-${props.rule.id}`"
        :model-value="props.rule.activated"
        :disabled="!props.togglable"
        @update:model-value="() => emit('toggle')"
      />
    </div>
  </div>
</template>

<style scoped>
  .rule-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--alg-spacing-m);
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-effect-radius-m);
    color: var(--alg-color-text-primary);
    font-size: var(--alg-font-size-m);
    font-weight: var(--alg-font-weight-bold);
    gap: var(--alg-spacing-m);
    line-height: var(--alg-font-line-height);
    transition-duration: var(--alg-transition-colors-duration);
    transition-property: background-color;
    transition-timing-function: var(--alg-transition-colors-timing-function);

    .content {
      position: relative;
      display: flex;
      flex: 1;
      flex-flow: column wrap;
      justify-content: space-between;
      gap: var(--alg-spacing-s);

      .badges {
        display: flex;
        gap: var(--alg-spacing-s);
      }
    }

    .actions-toggle-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      gap: var(--alg-spacing-m);

      .actions {
        display: flex;
        flex-flow: row nowrap;
        opacity: 0;
      }
    }

    &.individual {
      border-radius: 0;
      border-top: 0;
      border-right: 0;
      border-left: 0;
    }

    &.deactivated {
      .content {
        opacity: 0.5;
      }
    }

    &:hover {
      background-color: var(--alg-color-surface-background);

      .actions-toggle-wrapper {
        .actions {
          opacity: 1;
        }
      }
    }

    &.focused {
      border: 1px solid var(--alg-color-surface-highlight);
      background-color: var(--alg-color-surface-primary);
    }
  }
</style>
