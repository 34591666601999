<script setup lang="ts">
  import { computed, onMounted, onUnmounted, ref } from 'vue'

  import { dayjs, Nullable, ShortTimeString, timeToMinutes } from '@algorh/shared'

  const props = defineProps<Props>()

  interface Props {
    readonly duration: number
    readonly startTime: ShortTimeString
    readonly first: boolean
  }

  const DAY_GRID_MINUTES = 1 // Ratio pour eviter de generer trop de colonne (1 minute par defaut)

  const now = ref(dayjs())
  const timer = ref<Nullable<ReturnType<typeof setInterval>>>(null)

  const left = computed(() => {
    const round = (minutes: number) =>
      minutes % DAY_GRID_MINUTES === 0
        ? minutes
        : minutes - (minutes % DAY_GRID_MINUTES) + DAY_GRID_MINUTES
    const minutesFromMidnight = now.value.get('minutes') + now.value.get('hours') * 60
    const minutesSpent = round(minutesFromMidnight - timeToMinutes(props.startTime))
    const minutesDuration = props.duration * 60
    return minutesDuration - minutesSpent < 0 ? 100 : (minutesSpent / minutesDuration) * 100
  })
  onMounted(() => {
    timer.value = setInterval(() => {
      now.value = dayjs()
    }, 60000)
  })
  onUnmounted(() => {
    timer.value = null
  })
</script>

<template>
  <div
    class="real-time-cursor"
    :class="{ first }"
    :style="{ left: `${left}%` }"
  />
</template>

<style scoped>
  .real-time-cursor {
    position: absolute;
    z-index: 10;
    top: calc(var(--alg-spacing-xs) * -1);
    bottom: calc(var(--alg-spacing-xs) * -1);
    display: flex;
    width: 2px;
    background-color: var(--alg-color-state-danger);
    pointer-events: none;
    transform: translateX(-1px);

    &.first::before {
      position: absolute;
      top: -10px;
      left: -8px;
      width: 0;
      height: 0;
      border-top: 16px solid var(--alg-color-state-danger);
      border-right: 9px solid var(--alg-color-transparent);
      border-left: 9px solid var(--alg-color-transparent);
      content: '';
    }
  }
</style>
