<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgButton } from '../../button'
  import { AlgCounter } from '../../feedback'
  import { AlgChoiceTree, ChoiceTreeData } from '../../form'
  import { AlgIcon } from '../../media'
  import { AlgPopper } from '../../popover'

  type Props = {
    id: string
    label: string
    modelValue: (string | number)[]
    subdivisions: ChoiceTreeData<{ id: string | number }>[]
    readonly?: boolean
    disabled?: boolean
    multiple?: boolean
    color?: string
  }

  defineOptions({
    name: 'AlgSubdivisionFilter',
  })

  const props = withDefaults(defineProps<Props>(), {
    readonly: false,
    disabled: false,
    multiple: true,
  })

  const emit = defineEmits<{
    (e: 'update:model-value', p: typeof props.modelValue): void
  }>()

  // Composables
  const { t } = useI18n()

  // Methods
  function handleToggle(toggle: () => void) {
    if (props.disabled) {
      return
    }

    toggle()
  }

  function handleUpdate(value: (string | number)[]) {
    emit('update:model-value', value)
  }
</script>

<template>
  <div class="subdivision-filter">
    <AlgPopper
      class="subdivision-filter-popper"
      placement="bottom-start"
    >
      <template #reference="{ isOpen, toggle }">
        <button
          type="button"
          class="subdivision-filter-reference"
          :class="{
            open: isOpen,
            disabled: props.disabled
          }"
          @click="() => handleToggle(toggle)"
        >
          <span class="label-wrapper">
            <span class="label">
              {{ props.label }}
            </span>
            <AlgCounter
              v-if="props.modelValue && props.modelValue.length > 0"
              :value="props.modelValue.length"
              size="xs"
              :color="props.color"
            />
          </span>
          <AlgIcon
            size="s"
            :name="isOpen ? 'expand-less' : 'expand-more'"
            :color="props.disabled ? 'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-primary)'"
          />
        </button>
      </template>
      <template #content>
        <div
          class="subdivision-filter-content"
        >
          <div
            v-if="props.subdivisions.length"
            class="subdivision-list"
          >
            <AlgChoiceTree
              v-for="subdivision in props.subdivisions"
              :key="subdivision.id"
              class="choice-tree"
              :readonly="props.readonly"
              :multiple="props.multiple"
              :data="subdivision"
              :model-value="props.modelValue"
              recursive-select
              @update:model-value="handleUpdate"
            />
          </div>
          <p
            v-else
            class="no-results"
          >
            {{ t('common.No results') }}
          </p>
          <AlgButton
            v-if="props.modelValue.length > 0"
            class="action-button"
            size="s"
            variant="link"
            :label="t('common.Clear selection')"
            @click="() => handleUpdate([])"
          />
        </div>
      </template>
    </AlgPopper>
  </div>
</template>

<style scoped>
.subdivision-filter {
  position: relative;

  .subdivision-filter-reference {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    padding: var(--alg-spacing-s) 0;
    border-bottom: 1px solid var(--alg-color-surface-border);
    cursor: pointer;
    user-select: none;

    .label-wrapper {
      display: flex;
      align-items: center;
      gap: var(--alg-spacing-xs);

      .label {
        color: var(--alg-color-text-primary);
        font-weight: var(--alg-font-weight-bold);
      }
    }

    &.open {
      border-bottom-color: var(--alg-color-text-secondary);
    }

    &.disabled {
      cursor: not-allowed;

      .label {
        color: var(--alg-color-text-light);
      }
    }
  }

  .subdivision-filter-content {
    display: flex;
    min-width: 320px;
    flex-direction: column;
    padding: var(--alg-spacing-s);
    border-radius: 0 0 var(--alg-effect-radius-s) var(--alg-effect-radius-s);
    background-color: var(--alg-color-surface-background);
    gap: var(--alg-spacing-s);

    .subdivision-list {
      display: flex;
      max-height: 400px;
      flex-direction: column;
      gap: var(--alg-spacing-s);
      overflow-y: auto
    }

    .no-results {
      margin: 0;
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-s);
    }

    .action-button {
      align-self: flex-start;
    }
  }
}
</style>
