<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgIcon, AlgModal, ModalSize } from '@algorh/ui'

  type Props = {
    readonly id: string
    readonly confirmButtonDisabled?: boolean
    readonly confirmButtonLoading?: boolean
    readonly confirmButtonText?: string
    readonly size?: ModalSize
    readonly centered?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    confirmButtonDisabled: false,
    confirmButtonLoading: false,
    size: 'm',
    centered: true,
  })

  const { t } = useI18n()
</script>

<template>
  <AlgModal
    :id="props.id"
    name="warning"
    :confirm-button-disabled="confirmButtonDisabled"
    :confirm-button-loading="confirmButtonLoading"
    :confirm-button-text="confirmButtonText ?? t('common.Continue')"
    confirm-button-variant="warning"
    :size="props.size"
  >
    <template #title>
      <span class="title-wrapper">
        <AlgIcon
          name="error-fill"
          color="var(--alg-color-state-warning)"
        />
        {{ t('common.Warning') }}
      </span>
    </template>
    <template #content>
      <div
        class="content-wrapper"
        :class="{
          centered: props.centered,
        }"
      >
        <slot />
      </div>
    </template>
  </AlgModal>
</template>

<style scoped>
  .title-wrapper {
    display: flex;
    align-items: center;
    gap: var(--alg-spacing-s);
  }

  .content-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    font-weight: var(--alg-font-weight-bold);
    line-height: var(--alg-font-line-height);

    &.centered {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
</style>
