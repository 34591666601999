import { computed, Ref } from 'vue'

import {
  DateString,
  dayjs, DTF,
  minutesToTime,
  timeStringToMinutesRegex,
} from '@algorh/shared'

import { useCalendarConfigs } from './useCalendarConfigs'

export const useCalendarBoundaries = (date: Ref<DateString>) => {
  const { startTime, endTime, slotDuration, days } = useCalendarConfigs()
  const start = dayjs(startTime.value, DTF.TIME_SHORT)
  const end = dayjs(endTime.value, DTF.TIME_SHORT)

  const daySlotLength = end
    .diff(start, 'minute') / slotDuration.value

  const daySlotTimes = computed(() =>
    Array.from({ length: daySlotLength }, (_, i) => i)
      .map((slotNumber: number) =>
        minutesToTime(timeStringToMinutesRegex(startTime.value) + slotNumber * slotDuration.value),
      ))

  const weekDays = computed(() =>
    weekDaysInRange(
      date.value,
      dayjs(date.value).add((days.value.length ?? 5) - 1, 'day').format(DTF.DATE),
    ),
  )

  function weekDaysInRange(startDate: DateString, endDate: DateString) {
    return Array.from({
      length: dayjs(endDate).diff(dayjs(startDate), 'day') + 1,
    }, (_, i) => dayjs(startDate).add(i, 'day').format(DTF.DATE))
  }

  // describe('utils / dates / weekDaysInRange()', () => {
  //   it('should return week days from a date string range', () => {
  //     const days = weekDaysInRange('03-08-1993', '03-16-1993')
  //     expectTypeOf(days).toMatchTypeOf<dayjs.Dayjs[]>()
  //     expect(Array.isArray(days)).toBe(true)
  //     expect(days.length).toBe(9)
  //   })
  // })
  return { daySlotTimes, weekDays }
}
