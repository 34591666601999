<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgButton } from '../../button'
  import { AlgIconButton } from '../../icon-button'

  defineOptions({
    name: 'AlgNotificationPanel',
  })

  const props = withDefaults(defineProps<Props>(), {
    fullscreen: false,
  })

  const emit = defineEmits<{
    (e: 'close', payload?: Event): void
    (e: 'go-to-notification-center', payload?: Event): void
  }>()

  interface Props {
    readonly fullscreen: boolean
  }

  const { t } = useI18n()
</script>

<template>
  <div
    class="notifications-panel"
    :class="{ 'notifications-panel-fullscreen': props.fullscreen }"
  >
    <header class="notifications-panel-header">
      <div class="notifications-panel-header-title">
        {{ t('notifications.Notifications') }}
      </div>
      <AlgIconButton
        id="close-notifications-panel-btn"
        size="l"
        icon="cancel"
        variant="transparent"
        placement="bottom"
        icon-color="var(--alg-color-icon-secondary)"
        :label="t('common.Close')"
        tooltip
        @click="(p) => emit('close', p)"
      />
    </header>
    <div class="notifications-panel-content">
      <slot />
    </div>
    <footer class="notifications-panel-footer">
      <AlgButton
        :label="t('notifications.Access all notifications')"
        variant="secondary"
        @click="(p) => emit('go-to-notification-center', p)"
      />
    </footer>
  </div>
</template>

<style scoped>
  .notifications-panel {
    position: relative;
    display: flex;
    width: 400px;
    flex-direction: column;
    background-color: var(--alg-color-surface-primary);
    color: var(--alg-color-text-primary);

    &:not(.notifications-panel-fullscreen) {
      width: 400px;
      border: 1px solid var(--alg-color-surface-border);
      border-radius: var(--alg-effect-radius-m);
      box-shadow: var(--alg-effect-shadow-l);
    }

    &.notifications-panel-fullscreen {
      position: fixed;
      border-top: 1px solid var(--alg-color-surface-border);
      inset: 56px 0 0;
    }

    .notifications-panel-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 0.063rem solid var(--alg-color-surface-border);
      gap: var(--alg-spacing-m);
      padding-block: var(--alg-spacing-m);
      padding-inline: var(--alg-spacing-m);
    }

    .notifications-panel-header-title {
      flex: 1 1 100%;
      font-size: var(--alg-font-size-xl);
      font-weight: var(--alg-font-weight-bold);
    }
  }

  .notifications-panel-content {
    overflow: auto;
    flex: 1 1 auto;
  }

  .notifications-panel-section {
    display: flex;
    flex-direction: column;
  }

  .notifications-panel-section-header {
    position: sticky;
    z-index: 1;
    top: 0;
    display: flex;
    height: 2.25rem;
    flex-direction: row;
    align-items: center;
    background-color: var(--alg-color-surface-background);
    line-height: 2.25rem;
    padding-inline: var(--alg-spacing-m);

    .notifications-panel-section-header-title {
      flex: 1;
      font-size: var(--alg-font-size-xs);
      font-weight: var(--alg-font-weight-bold);
      text-transform: uppercase;
    }
  }

  .notifications-panel-section-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .notifications-panel-section-list-item {
    display: flex;
    flex-direction: row;
    gap: var(--alg-spacing-m);
    margin-inline: var(--alg-spacing-m);
    padding-block: var(--alg-spacing-m);
  }

  .notifications-panel-section-list-item:not(:last-child) {
    border-bottom: 0.063rem solid var(--alg-color-surface-border);
  }

  .notifications-panel-section-list-item-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--alg-spacing-xs);
  }

  .notifications-panel-section-list-item-content-title {
    font-size: var(--alg-font-size-l);
    font-weight: var(--alg-font-weight-bold);
  }

  .notifications-panel-section-list-item-content-summary,
  .notifications-panel-section-list-item-content-empty {
    color: var(--alg-color-text-secondary);
    font-size: var(--alg-font-size-m);
  }

  .notifications-panel-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: var(--alg-spacing-s);
    border-top: 1px solid var(--alg-color-surface-border);
  }
</style>
