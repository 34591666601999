<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { decimalFormat } from '@algorh/shared'

  import { AlgProgressCircle } from '../../feedback'

  defineOptions({
    name: 'AlgQuotaCard',
  })

  const props = defineProps<Props>()

  type Props = {
    readonly progress: number
    readonly remainingDays: number
    readonly quotaCount: number
    readonly quotaTotal: number
    readonly displayRemainingDays?: boolean
  }

  const { t } = useI18n()

  const color = computed(() => {
    if (props.progress < 50) {
      return 'var(--alg-color-state-success)'
    }

    if (props.progress >= 50 && props.progress < 100) {
      return 'var(--alg-color-state-warning)'
    }

    if (props.progress >= 100) {
      return 'var(--alg-color-state-danger)'
    }

    return 'var(--alg-color-surface-background)'
  })

  const backgroundColor = computed(() => {
    if (props.progress < 50) {
      return 'var(--alg-color-light-green)'
    }

    if (props.progress >= 50 && props.progress < 100) {
      return 'var(--alg-color-light-orange)'
    }

    if (props.progress >= 100) {
      return 'var(--alg-color-light-red)'
    }

    return 'var(--alg-color-surface-background)'
  })

</script>

<template>
  <div class="quota-card">
    <div
      class="progress-wrapper"
      :style="{
        backgroundColor: backgroundColor
      }"
    >
      <AlgProgressCircle
        :progress="props.progress ?? 0"
        :size="32"
        :color="color"
        background-color="var(--alg-color-surface-primary)"
        from-bottom
        show-percentage
      />
    </div>
    <div class="content-wrapper">
      <div class="title">
        {{ t('absences.% of quota achieved') }}
      </div>
      <div class="quota">
        <span class="values">
          {{ decimalFormat(props.quotaCount) }} / {{ decimalFormat(props.quotaTotal) }}
        </span>
        <span
          v-if="props.displayRemainingDays !== undefined"
          class="time"
        >
          {{
            props.remainingDays < 0
              ? t('common.Finished (m)')
              : t('datetime.{n} left', { n: t('units.d', { count: props.remainingDays + 1 }) }, props.remainingDays + 1)
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .quota-card {
    display: inline-flex;
    box-sizing: border-box;
    gap: var(--alg-spacing-s);

    .progress-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--alg-spacing-xs);
      border-radius: var(--alg-effect-radius-s);
    }

    .content-wrapper {
      display: flex;
      overflow: hidden;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--alg-spacing-xs);
      line-height: var(--alg-font-line-height);
      white-space: nowrap;

      .title {
        font-size: var(--alg-font-size-s);
        font-weight: var(--alg-font-weight-regular);
      }

      .quota {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: var(--alg-font-size-m);
        font-weight: var(--alg-font-weight-bold);
        gap: var(--alg-spacing-s);
      }
    }
  }
</style>
