<script lang="ts" setup>
  import { computed } from 'vue'

  import { hourToTime, ShortTimeString, timeToHour } from '@algorh/shared'

  type Props = {
    slotDuration: number
    boundingBox?: {
      start_time: ShortTimeString
      end_time: ShortTimeString
    }
  }

  const props = withDefaults(defineProps<Props>(), {
    boundingBox: () => ({
      start_time: '00:00',
      end_time: '24:00',
    }),
  })

  const timeline = computed(() => {
    const startTime = timeToHour(props.boundingBox.start_time)
    const endTime = timeToHour(props.boundingBox.end_time)
    const h = props.slotDuration / 60
    return Array.from({ length: (endTime - startTime) / h }, (_, i) => ({
      fullTime: Number.isInteger(startTime + h * i),
      value: startTime + h * i,
      displayValue: hourToTime(startTime + h * i),
    }))
  })
</script>

<template>
  <div class="timeline">
    <span
      class="start time-slot full-time"
    >
      {{ props.boundingBox.start_time }}
    </span>
    <span
      v-for="(t, k) in timeline"
      :key="t.value"
      class="time-slot"
      :class="{ 'full-time': t.fullTime }"
    >
      {{ k === 0 ? '' : t.displayValue }}
    </span>
    <span
      class="end time-slot full-time"
    >
      {{ props.boundingBox.end_time === '24:00' ? '00:00' : props.boundingBox.end_time }}
    </span>
  </div>
</template>

<style scoped>
.timeline {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;

  .time-slot {
    flex: 1 1 100%;
    color: var(--alg-color-text-light);
    font-size: var(--alg-font-size-xxs);
    text-align: center;
    transform: translateX(-50%);

    &.full-time {
      color: var(--alg-color-text-secondary);
    }
  }

  .end, .start{
    position: absolute;
    top: 0;
    transform: translateX(0);
  }

  .start{
    left: 0;
  }

  .end{
    right: 0;
  }
}
</style>
