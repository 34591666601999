<script setup lang="ts"  generic="T extends string | number,  V extends Nullable<T> | T[]">
  import { computed, nextTick, ref, useSlots, watch } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable } from '@algorh/shared'

  import { AlgCheckbox } from '../form'
  import { AlgIcon } from '../media'
  import { AlgPopper } from '../popover'

  import { DropdownMenuOption, DropdownMenuOptionGroup } from './Dropdown.types'

  type Props = {
    readonly options: DropdownMenuOption<Nullable<T>>[]
    readonly allowFlip?: boolean
    readonly defaultGroupLabel?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    modelValue: null,
    allowFlip: true,
  })

  const model = defineModel<V>({ required: true })

  // Composables
  const { t } = useI18n()

  const slots = useSlots()

  // Data
  const currentCloseCallback = ref<Nullable<() => void>>(null)

  // Computed
  const groupedOptions = computed(() => {
    return props.options.reduce((acc, option) => {
      const groupId = option.groupId ?? null

      const group = acc.find((g) => g.id === groupId)

      if (group) {
        group.options.push(option)
      } else {
        acc.push({
          id: groupId,
          label: groupId ? option.groupLabel : props.defaultGroupLabel ?? t('common.Other', 1),
          options: [option],
        })
      }

      return acc
    }, [] as DropdownMenuOptionGroup<Nullable<T>>[])
  })

  // Methods
  function handleOpen(open: () => void, close: () => void) {
    if (currentCloseCallback.value) {
      currentCloseCallback.value()
    }

    open()

    currentCloseCallback.value = close
  }

  // Methods
  function handleToggleWithKeyboard(e: KeyboardEvent, open: () => void, close: () => void) {
    if (['Enter', 'ArrowRight'].includes(e.key)) {
      open()
    }

    if (['Escape', 'ArrowLeft'].includes(e.key)) {
      close()
    }
  }

  function handleSelectOption(value: Nullable<T>) {
    if (Array.isArray(model.value)) {
      if (!value) {
        model.value = [] as T[] as V
        return
      }
      model.value = model.value.includes(value)
        ? model.value.filter((v) => v !== value) as V
        : [...model.value, value] as V
    } else {
      model.value = value as V
    }
  }

  watch(model, (val: typeof model.value) => {
    if (!val) {
      return
    }
    nextTick(() => {
      const node = document.getElementById(`option-${val}`)
      if (node) {
        node.scrollIntoView({ block: 'nearest' })
      }
    })
  }, { immediate: true })
</script>

<template>
  <div class="dropdown-menu-wrapper">
    <div class="dropdown-menu">
      <div
        v-for="(optionGroup, i) in groupedOptions"
        :key="i"
        class="dropdown-menu-group"
      >
        <div
          v-if="groupedOptions.length > 1"
          class="dropdown-menu-group-label"
        >
          {{ optionGroup.label }}
        </div>
        <ul class="dropdown-menu-group-list">
          <li
            v-for="(option, key) in optionGroup.options"
            :id="`option-${option.value}`"
            :key="key"
          >
            <template v-if="option.children?.length">
              <AlgPopper
                placement="right"
                :allow-flip="props.allowFlip"
              >
                <template #reference="{ open, close }">
                  <button
                    type="button"
                    class="dropdown-menu-option"
                    :class="{
                      active: option.active || option.value === model
                    }"
                    :disabled="option.disabled"
                    @mouseenter="() => handleOpen(open, close)"
                    @focus="() => handleOpen(open, close)"
                    @mouseleave="close"
                    @blur="close"
                    @keydown="(e) => handleToggleWithKeyboard(e, open, close)"
                    @click="() => handleSelectOption(option.value)"
                  >
                    <span class="dropdown-menu-option-inner">
                      <slot
                        v-if="slots.option"
                        name="option"
                        :option="option"
                        :active="option.active || option.value === model"
                      />
                      <template v-else>
                        <AlgIcon
                          v-if="option.icon"
                          :name="option.icon"
                          :size="option.iconSize || 's'"
                        />
                        <span class="label-wrapper">
                          <span class="label">
                            {{ option.label }}
                          </span>
                          <span
                            v-if="option.sublabel"
                            class="sublabel"
                          >
                            {{ option.sublabel }}
                          </span>
                        </span>
                      </template>
                      <AlgIcon
                        name="chevron-right"
                        :color="
                          option.active || option.value === model
                            ? 'var(--alg-color-text-on-color)'
                            : 'var(--alg-color-text-primary)'
                        "
                        size="s"
                      />
                    </span>
                  </button>
                </template>
                <template #content>
                  <!-- SEULEMENT UTILISE DANS EXPORT, A SUPPRIMER APRES PASSAGE SUR NOUVEAU COMPOSANT -->
                  <DropdownMenu
                    :allow-flip="props.allowFlip"
                    :model-value="model"
                    :default-group-label="props.defaultGroupLabel"
                    :options="option.children"
                    @update:model-value="(o) => handleSelectOption(o as T)"
                  />
                </template>
              </AlgPopper>
            </template>
            <button
              v-else
              type="button"
              class="dropdown-menu-option"
              :class="{ active: option.active || option.value === model }"
              :disabled="option.disabled"
              @click="() => handleSelectOption(option.value)"
            >
              <span class="dropdown-menu-option-inner">
                <slot
                  v-if="slots.option"
                  name="option"
                  :option="option"
                  :active="option.active || option.value === model"
                />
                <template v-else>
                  <AlgCheckbox
                    v-if="Array.isArray(model) && option.value"
                    :id="`${option.value}-checked`"
                    :model-value="model.includes(option.value)"
                    name="dropdown-menu-checkbox"
                    :clickable="false"
                  />
                  <AlgIcon
                    v-if="option.icon"
                    :name="option.icon"
                    :color="
                      option.active || option.value === model
                        ? 'var(--alg-color-text-on-color)'
                        : 'var(--alg-color-text-primary)'
                    "
                    :size="option.iconSize || 's'"
                  />
                  <span class="label-wrapper">
                    <span class="label">
                      {{ option.label }}
                    </span>
                    <span
                      v-if="option.sublabel"
                      class="sublabel"
                    >
                      {{ option.sublabel }}
                    </span>
                  </span>
                </template>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .dropdown-menu-wrapper {
    box-sizing: border-box;
    padding: var(--alg-spacing-xs);
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-effect-radius-m);
    background-color: var(--alg-color-surface-primary);
    box-shadow: var(--alg-effect-shadow-m);

    .dropdown-menu {
      display: flex;
      overflow: hidden auto;
      max-height: 186px;
      flex-direction: column;
      gap: var(--alg-spacing-xs);

      .dropdown-menu-group {
        display: flex;
        flex-direction: column;
        gap: var(--alg-spacing-xs);

        .dropdown-menu-group-label {
          padding: var(--alg-spacing-xs);
          color: var(--alg-color-text-secondary);
          font-size: var(--alg-font-size-s);
          font-weight: var(--alg-font-weight-bold);
        }

        .dropdown-menu-group-list {
          display: flex;
          flex-direction: column;
          padding: 0;
          margin: 0;
          gap: var(--alg-spacing-xs);
          list-style-type: none;

          .dropdown-menu-option {
            width: 100%;
            padding: var(--alg-spacing-s);
            border-radius: var(--alg-effect-radius-m);
            color: var(--alg-color-text-primary);
            text-align: left;
            transition: background-color 150ms ease-in-out;

            .dropdown-menu-option-inner {
              display: flex;
              align-items: center;
              gap: var(--alg-spacing-s);

              .label-wrapper {
                display: flex;
                flex-direction: column;
                gap: var(--alg-spacing-xs);

                .sublabel {
                  color: var(--alg-color-text-secondary);
                  font-size: var(--alg-font-size-s);
                }
              }
            }

            &:focus {
              outline: none;
            }

            &:focus-visible,
            &:hover {
              background-color: var(--alg-color-surface-secondary);
            }

            &.active {
              background-color: var(--alg-color-surface-highlight);

              .label-wrapper {
                .label,
                .sublabel {
                  color: var(--alg-color-text-on-color);
                }
              }

              &:focus-visible,
              &:hover {
                background-color: var(--alg-color-button-primary-hover);
              }
            }
          }
        }
      }
    }
  }
</style>
