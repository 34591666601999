<script setup lang="ts">
  type Props = {
    itemMinWidth?: number
  }

  defineOptions({
    name: 'AlgDataGrid',
  })

  const props = withDefaults(defineProps<Props>(), {
    itemMinWidth: 200,
  })

  const slots = defineSlots()
</script>

<template>
  <div
    class="data-grid"
    :style="{
      gridTemplateColumns: `repeat(auto-fill, minmax(${props.itemMinWidth}px, 1fr))`
    }"
  >
    <slot />
  </div>
</template>

<style scoped>
.data-grid {
  display: grid;
  gap: var(--alg-spacing-m);
  grid-template-rows: auto;
}
</style>
