import { FileUnit } from '../types/common/file'

export function getFileSize(v: number) {
  const i = Math.floor(Math.log(v) / Math.log(1024))

  const size = (v / Math.pow(1024, i))

  const unit = Object.values(FileUnit)[i]

  return { size, unit }
}
