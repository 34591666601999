<script lang="ts" setup>
  import { AlgIllustration, IllustrationName } from '@algorh/ui'

  type Props = {
    readonly illustration?: IllustrationName
    readonly title: string
    readonly infos?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    illustration: 'faders',
    title: '',
    infos: '',
  })
</script>

<template>
  <div class="form-section">
    <div class="section-icon">
      <AlgIllustration
        v-if="props.illustration"
        :name="props.illustration"
        :size="32"
      />
    </div>
    <div class="section-content">
      <div class="section-title">
        {{ props.title }}
      </div>
      <div
        v-if="props.infos"
        class="section-info"
      >
        {{ props.infos }}
        <div class="section-errors">
          <slot name="errors" />
        </div>
      </div>
    </div>
    <div class="section-toggle">
      <slot />
    </div>
  </div>
</template>

<style scoped>
  .form-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid var(--alg-color-surface-border);

    &:first-child {
      border-top: none;
    }
  }

  .section-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 20px 0;
  }

  .section-info {
    display: flex;
    flex-direction: column;
    color: var(--alg-color-text-secondary);
    font-size: 14px;
  }

  .section-title {
    display: flex;
    margin-bottom: 4px;
    color: var(--alg-color-text-primary);
    font-size: 14px;
    font-weight: var(--alg-font-weight-bold);
  }

  .section-icon {
    width: 30px;
    margin: 20px;
  }

  .section-toggle {
    display: flex;
    width: 35px;
    margin: 26px 20px 20px;

    &:deep(label) {
      align-items: flex-start;
    }
  }

  .section-errors {
    &:deep(.message) {
      padding-top: 10px;
      padding-left: 0;
    }
  }
</style>
