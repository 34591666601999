import { DateString, Dto, fetchJson } from '@algorh/shared'
import { PeriodMode } from '@algorh/ui'

import { PlannedInternalTaskInstance } from '@/core/types/InternalTask'
import { ManualScheduling } from '@/core/types/ManuelScheduling'

import { DailySchedule, WeeklySchedule } from '../types/Schedule'
import { TrainerSchedule } from '../types/Trainer'

import {
  GetGlobalPlanningByDayDto,
  GetGlobalPlanningByWeekDto,
  GetScheduleTrainersDto,
} from './scheduling.service.type'

const BASE_URI = '/api/scheduling/trainers'

const SchedulingTrainersApiService = {
  getPlanning(periodMode: PeriodMode, params: GetGlobalPlanningByDayDto | GetGlobalPlanningByWeekDto) {
    const { getPlanningDay, getPlanningWeek } = SchedulingTrainersApiService
    switch (periodMode) {
      case PeriodMode.DAY:
        return getPlanningDay(params as GetGlobalPlanningByDayDto)
      case PeriodMode.WEEK:
      case PeriodMode.MONTH:
        return getPlanningWeek(params as GetGlobalPlanningByWeekDto)
    }
  },
  getTrainers(dto: GetScheduleTrainersDto) {
    return fetchJson<TrainerSchedule[], null, GetScheduleTrainersDto>(
      'GET', BASE_URI, null, dto,
    )
  },
  getManualSchedulings(dto: Dto<{ start_date: DateString, end_date: DateString, user_ids: number[] }>) {
    return fetchJson<ManualScheduling[]>(
      'GET', `${BASE_URI}/manual-schedulings`, null, dto,
    )
  },
  getPlanningDay(params: GetGlobalPlanningByDayDto) {
    return fetchJson<DailySchedule[], null, GetGlobalPlanningByDayDto>(
      'GET', `${BASE_URI}/plannings/day`, null, params,
    )
  },
  getPlanningWeek(params: GetGlobalPlanningByWeekDto) {
    return fetchJson<WeeklySchedule[], null, GetGlobalPlanningByWeekDto>(
      'GET', `${BASE_URI}/plannings/week`, null, params,
    )
  },
  getTrainingInstances(dto: Dto<{ start_date: DateString, end_date: DateString, user_ids: number[] }>) {
    return fetchJson<PlannedInternalTaskInstance[], null, typeof dto>(
      'GET', `${BASE_URI}/training-instances`, null, dto,
    )
  },
}

export { SchedulingTrainersApiService }
