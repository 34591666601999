import { ref } from 'vue'

function useLocalStorage() {
  const localStorage = ref<Storage>(window.localStorage)

  function get(key: string) {
    return localStorage.value.getItem(key)
  }

  function set(key: string, value: string) {
    localStorage.value.setItem(key, value)
  }

  function remove(key: string) {
    localStorage.value.removeItem(key)
  }

  function clear() {
    localStorage.value.clear()
  }

  return { get, set, remove, clear }
}

export { useLocalStorage }
