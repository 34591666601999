<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable, sanitizeSearch } from '@algorh/shared'

  import { AlgBadge } from '../../badge'
  import { AlgButton } from '../../button'
  import { AlgChip } from '../../chip'
  import { AlgRadio, AlgSearchInput } from '../../form'
  import { AlgIconButton } from '../../icon-button'
  import { AlgIcon } from '../../media'
  import { AlgPopper } from '../../popover'
  import { AlgTooltip } from '../../tooltip'

  import ConfirmDeleteCustomFilterModal from './ConfirmDeleteCustomFilterModal.vue'
  import CreateOrUpdateCustomFilterModal from './CreateOrUpdateCustomFilterModal.vue'

  type Option = { label: string, value: number, warning: Nullable<string> }

  type Props = {
    label: string
    modelValue: Nullable<number>
    options: Option[]
    placeholder?: string
    readonly?: boolean
    disabled?: boolean
  }

  defineOptions({
    name: 'AlgCombinationFilter',
  })

  const props = withDefaults(defineProps<Props>(), {
    searchable: true,
    readonly: false,
    disabled: false,
  })

  const emit = defineEmits<{
    (e: 'update:model-value', p: Nullable<number>): void
    (e: 'create', label: string): void
    (e: 'update', label: string, id: Nullable<number>): void
    (e: 'delete', id: Nullable<number>): void
    (e: 'reset'): void
  }>()

  // Composables
  const { t } = useI18n()

  // Refs
  const localCombinationId = ref<Nullable<number>>(null)
  const customFilterModalOpen = ref(false)
  const customFilterDeletionModalOpen = ref(false)
  const search = ref<Nullable<string>>(null)

  // Computed
  const selectedCustomFilter = computed((): Nullable<Option> =>
    props.options.find((o) => o.value === localCombinationId.value) ?? null,
  )

  const selectedCombination = computed(() =>
    props.options.find((o) => o.value === props.modelValue),
  )

  const filteredOptions = computed(() => {
    if (!search.value) {
      return props.options
    }

    return sanitizeSearch(search.value, props.options, 'label')
  })

  // Methods
  function handleToggle(toggle: () => void) {
    if (props.disabled) {
      return
    }

    toggle()
  }

  function handleToggleOption(value: Nullable<number>) {
    emit('update:model-value', value)
  }

  function handleDeleteCustomFilter() {
    emit('delete', localCombinationId.value)
    handleCloseCustomFilterDeletionModal()
  }

  function handleCustomFilter(label: string) {
    selectedCustomFilter.value
      ? emit('update', label, localCombinationId.value)
      : emit('create', label)
    handleCloseCustomFilterModal()
  }

  function handleOpenCustomFilterDeletionModal() {
    customFilterDeletionModalOpen.value = true
  }

  function handleEditFilter(value: number) {
    localCombinationId.value = value
    handleOpenCustomFilterModal()
  }

  function handleDeleteFilter(value: number) {
    localCombinationId.value = value
    handleOpenCustomFilterDeletionModal()
  }

  function handleCloseCustomFilterDeletionModal() {
    customFilterDeletionModalOpen.value = false
  }

  function handleOpenCustomFilterModal() {
    customFilterModalOpen.value = true
  }

  function handleCloseCustomFilterModal() {
    customFilterModalOpen.value = false
  }

  function handleSearch(value: Nullable<string>) {
    search.value = value
  }
</script>

<template>
  <div class="combination-filter">
    <AlgPopper
      class="combination-filter-popper"
      placement="bottom-start"
      full-width
    >
      <template #reference="{ isOpen, toggle }">
        <button
          type="button"
          class="combination-filter-reference"
          :class="{
            open: isOpen,
            disabled: props.disabled
          }"
          @click="() => handleToggle(toggle)"
        >
          <span class="label-wrapper">
            <span class="label">
              {{ props.label }}
            </span>
          </span>
          <span class="end-wrapper">
            <AlgChip
              v-if="selectedCombination"
              id="combination-filter-selected-label"
              :label="selectedCombination.label"
              color="var(--alg-color-purple-100)"
            />
            <AlgIcon
              size="s"
              :name="isOpen ? 'expand-less' : 'expand-more'"
              :color="props.disabled ? 'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-primary)'"
            />
          </span>
        </button>
      </template>
      <template #content>
        <div
          class="combination-filter-content"
        >
          <AlgSearchInput
            id="multiselect-search-input-combination"
            :placeholder="props.placeholder || t('common.Search')"
            :debounced="false"
            :model-value="search"
            size="s"
            @update:model-value="handleSearch"
          />
          <div
            v-if="filteredOptions.length"
            class="multiselect-list"
          >
            <div
              v-for="(option, k) in filteredOptions"
              :key="k"
              class="option"
            >
              <AlgRadio
                :id="`multiselect-checkbox-group-option-${k}`"
                dio
                name="custom-filters-group"
                :label="option.label"
                :inactive="props.readonly"
                :disabled="option.warning !== null"
                :model-value="props.modelValue || NaN"
                :value="option.value"
                size="m"
                @update:model-value="handleToggleOption"
              />
              <AlgTooltip placement="top">
                <template #reference="{ open, close }">
                  <AlgBadge
                    v-if="option.warning"
                    icon="warning"
                    variant="warning"
                    size="l"
                    @mouseenter="open"
                    @mouseleave="close"
                    @focus="open"
                    @blur="close"
                  />
                </template>
                <template #content>
                  {{ option.warning }}
                </template>
              </AlgTooltip>
              <div class="actions">
                <AlgIconButton
                  :id="`edit-${k}`"
                  variant="transparent"
                  size="m"
                  icon="stylus"
                  icon-color="var(--alg-color-icon-highlight)"
                  tooltip
                  tooltip-placement="top"
                  :label="t('common.Edit')"
                  @click.stop="() => handleEditFilter(option.value)"
                />
                <AlgIconButton
                  :id="`delete-${k}`"
                  variant="transparent"
                  size="m"
                  icon="delete"
                  icon-color="var(--alg-color-icon-highlight)"
                  tooltip
                  tooltip-placement="top"
                  :label="t('common.Delete')"
                  @click.stop="() => handleDeleteFilter(option.value)"
                />
              </div>
            </div>
          </div>
          <p
            v-else
            class="no-results"
          >
            {{ t('common.No results') }}
          </p>
        </div>
      </template>
    </AlgPopper>
    <AlgButton
      v-if="!selectedCombination"
      class="save-button"
      variant="tertiary"
      size="s"
      icon-start="bookmark"
      :label="t('common.Save custom filters')"
      @click="handleOpenCustomFilterModal"
    />
    <CreateOrUpdateCustomFilterModal
      v-if="customFilterModalOpen"
      :filter="selectedCustomFilter"
      @close="handleCloseCustomFilterModal"
      @confirm="handleCustomFilter"
    />
    <ConfirmDeleteCustomFilterModal
      v-if="customFilterDeletionModalOpen && selectedCustomFilter"
      :filter="selectedCustomFilter"
      @close="handleCloseCustomFilterDeletionModal"
      @confirm="handleDeleteCustomFilter"
    />
  </div>
</template>

<style scoped>
.combination-filter {
  position: relative;
  display: grid;
  gap: var(--alg-spacing-s);
  grid-column: 1 / -1;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  .save-button {
    grid-column-end: inherit;
    justify-self: flex-end;
  }

  .combination-filter-popper{
    grid-column: span 1;

    @media screen and (width >= 1250px) {
      grid-column: span 2;
    }
  }

  .combination-filter-reference {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    padding: var(--alg-spacing-s) 0;
    border-bottom: 1px solid var(--alg-color-surface-border);
    cursor: pointer;
    user-select: none;

    .label-wrapper {
      display: flex;
      align-items: center;
      gap: var(--alg-spacing-xs);

      .label {
        color: var(--alg-color-text-primary);
        font-weight: var(--alg-font-weight-bold);
      }
    }

    .end-wrapper {
      display: flex;
      align-items: center;
      gap: var(--alg-spacing-xs);
    }

    &.open {
      border-bottom-color: var(--alg-color-text-secondary);
    }

    &.disabled {
      cursor: not-allowed;

      .label {
        color: var(--alg-color-text-light);
      }
    }
  }

  .combination-filter-content {
    display: flex;
    flex-direction: column;
    padding: var(--alg-spacing-s);
    border-radius: 0 0 var(--alg-effect-radius-m) var(--alg-effect-radius-m);
    background-color: var(--alg-color-surface-background);
    gap: var(--alg-spacing-s);

    .multiselect-list {
      display: flex;
      max-height: 400px;
      flex-direction: column;
      gap: var(--alg-spacing-xs);
      overflow-y: auto;

      .option {
        display: flex;
        flex-direction: row;

        .tooltip {
          align-self: center;
          margin-left: var(--alg-spacing-s);
        }

        .actions {
          display: flex;
          flex-direction: row;
          padding: var(--alg-spacing-s) 0;
          margin-right: var(--alg-spacing-s);
          margin-left: auto;
          gap: var(--alg-spacing-s);
        }
      }

      .group-label {
        position: sticky;
        z-index: 10;
        top: 0;
        padding: var(--alg-spacing-s) 0;
        background-color: var(--alg-color-surface-background);
        font-size: var(--alg-font-size-s);
        font-weight: var(--alg-font-weight-bold);
      }
    }

    .no-results {
      margin: 0;
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-s);
    }

    .action-button {
      align-self: flex-start;
    }
  }
}
</style>
