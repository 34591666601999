<script lang="ts" setup>
  import { computed } from 'vue'

  import { FetchErrorObj, Nullable, ShortTimeString } from '@algorh/shared'
  import { AlgNumberInput, AlgSelect, AlgTimeInput } from '@algorh/ui'

  import { RuleMesh, RuleReference, RuleTiming } from '@/core/enums/Rule'

  import { RuleDto } from '@/sections/settings/services'

  import RuleDurationSelect from '../../inputs/RuleDurationSelect.vue'
  import RuleSubjectSelect from '../../inputs/RuleSubjectSelect.vue'
  import { selectOptions } from '../ruleForms'

  type Model = Pick<RuleDto, 'config' | 'subject_id' | 'subject_type' | 'value'>
  type Props = {
    readonly errors: Nullable<FetchErrorObj<Model>>
    readonly activityFamilies: { id: number, name: string }[]
    readonly activities: { id: number, name: string }[]
  }

  const props = defineProps<Props>()

  const model = defineModel<Model>({ required: true })

  const subject = computed(() => ({ type: model.value.subject_type, id: model.value.subject_id }))

  const hasFirstReference = computed(() =>
    model.value.config?.timing ? [RuleTiming.BEFORE, RuleTiming.AFTER, RuleTiming.BETWEEN].includes(model.value.config.timing) : false,
  )
  const hasFirstTime = computed(() => hasFirstReference.value && model.value.config?.first_reference === RuleReference.TIME)

  const hasSecondReference = computed(() => model.value.config?.timing === RuleTiming.BETWEEN)

  const hasSecondTime = computed(() => hasSecondReference.value && model.value.config?.second_reference === RuleReference.TIME)

  function handleValueUpdate(value: Nullable<number>) {
    model.value = { ...model.value, value: value ?? 0 }
  }

  function handleSubjectUpdate(s: typeof subject.value) {
    model.value = {
      ...model.value,
      subject_type: s.type,
      subject_id: s.id,
    }
  }
  function handleDurationUpdate(duration: Nullable<number>) {
    model.value = { ...model.value, config: model.value.config
      ? { ...model.value.config, comparison: undefined, duration }
      : null,
    }
  }

  function handleTimingUpdate(timing: Nullable<RuleTiming>) {
    const config = model.value.config
      ? {
        ...model.value.config,
        timing: timing ?? undefined,
        mesh: model.value.config?.mesh ?? RuleMesh.DAY,
        first_reference: model.value.config?.first_reference ?? RuleReference.LUNCH,
        second_reference: model.value.config?.second_reference ?? RuleReference.LUNCH,
      }
      : null
    model.value = { ...model.value, config }
  }

  function handleMeshUpdate(value: Nullable<RuleMesh>) {
    model.value = { ...model.value, config: model.value.config
      ? { ...model.value.config, mesh: value ?? RuleMesh.DAY }
      : null,
    }
  }

  function handleFirstReferenceUpdate(value: Nullable<RuleReference>) {
    model.value = { ...model.value, config: model.value.config
      ? { ...model.value.config, first_reference: value ?? undefined }
      : null,
    }
  }

  function handleFirstTimeUpdate(value: Nullable<ShortTimeString>) {
    model.value = { ...model.value, config: model.value.config
      ? { ...model.value.config, first_time: value ?? undefined }
      : null,
    }
  }

  function handleSecondReferenceUpdate(value: Nullable<RuleReference>) {
    model.value = { ...model.value, config: model.value.config
      ? {
        ...model.value.config,
        second_reference: value ?? undefined,
        second_time: model.value.config?.second_time ?? '12:00',
      }
      : null,
    }
  }

  function handleSecondTimeUpdate(value: Nullable<ShortTimeString>) {
    model.value = { ...model.value, config: model.value.config
      ? { ...model.value.config, second_time: value ?? undefined }
      : null,
    }
  }

</script>

<template>
  <div class="rule-form">
    <AlgNumberInput
      id="value"
      class="choice-fields has-counter"
      :errors="errors?.errors?.value"
      :model-value="model.value"
      centered
      @update:model-value="handleValueUpdate"
    />
    <RuleSubjectSelect
      name="subject"
      nullable
      class="choice-fields has-counter"
      :activity-families="props.activityFamilies"
      :activities="props.activities"
      :errors="errors?.errors?.subject_id"
      :model-value="subject"
      @update:model-value="handleSubjectUpdate"
    />
    <RuleDurationSelect
      has-any-durationvalue
      name="duration"
      class="choice-fields has-counter"
      :errors="errors?.errors?.['config.duration']"
      :model-value="model.config?.duration ?? null"
      @update:model-value="handleDurationUpdate"
    />
    <AlgSelect
      id="config-timing"
      class="choice-fields has-counter"
      centered
      required
      :options="(selectOptions('rules', Object.values(RuleTiming)))"
      :errors="errors?.errors?.['config.timing']"
      :model-value="model.config?.timing ?? null"
      @update:model-value="handleTimingUpdate"
    />
    <AlgSelect
      v-if="model.config?.timing === 'during'"
      id="config-mesh"
      class="choice-fields has-counter"
      centered
      required
      :options="(selectOptions('rules', Object.values(RuleMesh)))"
      :errors="errors?.errors?.['config.mesh']"
      :model-value="model.config?.mesh ?? null"
      @update:model-value="handleMeshUpdate"
    />
    <AlgSelect
      v-if="hasFirstReference"
      id="config-first_reference"
      class="choice-fields has-counter"
      centered
      required
      :options="(selectOptions('rules', Object.values(RuleReference)))"
      :errors="errors?.errors?.['config.first_reference']"
      :model-value="model.config?.first_reference ?? null"
      @update:model-value="handleFirstReferenceUpdate"
    />
    <AlgTimeInput
      v-if="hasFirstTime"
      id="config-first_time"
      :model-value="model.config?.first_time ?? null"
      :errors="errors?.errors?.['config.first_time']"
      centered
      @update:model-value="handleFirstTimeUpdate"
    />
    <AlgSelect
      v-if="hasSecondReference"
      id="config-second_reference"
      class="choice-fields has-counter"
      centered
      required
      :options="(selectOptions('rules', Object.values(RuleReference)))"
      :errors="errors?.errors?.['config.second_reference']"
      :model-value="model.config?.second_reference ?? null"
      @update:model-value="handleSecondReferenceUpdate"
    />
    <AlgTimeInput
      v-if="hasSecondTime"
      id="config.second_time"
      :model-value="model.config?.second_time ?? null"
      :errors="errors?.errors?.['config.second_time']"
      centered
      @update:model-value="handleSecondTimeUpdate"
    />
  </div>
</template>

<style src="@/components/RuleAmbition/rule/forms/ruleForms.css" />
