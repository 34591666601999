import { serialize } from 'object-to-formdata'

import { DateString, Dto, fetchJson, fetchUpload, futch, Nullable } from '@algorh/shared'

import { InternalTaskType } from '@/core/enums/InternalTask'
import { DayToleranceMesh } from '@/core/enums/Planning'
import { InternalTaskInstance } from '@/core/types/InternalTask'
import { UserLite } from '@/core/types/User'

import { AmbitionOverride, RealSubdivisionAmbition } from '../types/Ambition'
import { InternalTaskMeetingRequest } from '../types/InternalTask'
import { InternalTaskTrainingRequest } from '../types/InternalTask/InternalTaskTrainingRequest'
import { AutomationConfig, DefaultAutomationConfig, OperationalSubdivisionWithProject } from '../types/OperationalSubdivision'
import { CustomerAdviser, Planning } from '../types/Planning'
import { RealSubdivisionCollectiveRule, RuleOverride } from '../types/Rule'
import { MassPlanificationResult } from '../types/Schedule/MassPlanificationResult'

import {
  CustomerAdvisersForInternalTaskAutomaticDto,
  CustomerAdvisersForManualMeetingDto,
  CustomerAdvisersForManualTrainingDto,
  InternalTaskDto,
  MassPlanificationCustomerAdvisersDto,
  MassPlanificationPreValidateDto,
  PostSubdivisionAmbitionOverrideDto,
  PostSubdivisionRuleOverrideDto,
} from './operational-subdivisions.service.type'

const BASE_URI = '/api/scheduling/operational-subdivisions'

const SchedulingSubdivisionsApiService = {
  getSubdivisions() {
    return fetchJson<OperationalSubdivisionWithProject[]>(
      'GET', `${BASE_URI}`,
    )
  },
  getAmbitions(subdivisionId: number) {
    return fetchJson<RealSubdivisionAmbition[]>(
      'GET', `${BASE_URI}/${subdivisionId}/ambitions`,
    )
  },
  postSubdivisionAmbitionOverride(subdivisionId: number, ambitionId: number, dto: Nullable<PostSubdivisionAmbitionOverrideDto>) {
    return fetchJson<AmbitionOverride, Nullable<PostSubdivisionAmbitionOverrideDto>>(
      'POST', `${BASE_URI}/${subdivisionId}/ambitions/${ambitionId}/override`, dto,
    )
  },
  getAutomationConfig(subdivisionId: number) {
    return fetchJson<AutomationConfig>(
      'GET', `${BASE_URI}/${subdivisionId}/automation-config`,
    ) as Promise<{ data: AutomationConfig, default: DefaultAutomationConfig }>
  },
  getCustomerAdvisersForInternalTaskAutomatic(subdivisionId: number, payload: Dto<CustomerAdvisersForInternalTaskAutomaticDto>, signal: AbortSignal) {
    return fetchJson<CustomerAdviser[], null, Dto<CustomerAdvisersForInternalTaskAutomaticDto>>(
      'GET', `${BASE_URI}/${subdivisionId}/customer-advisers-for-internal-task-automatic`, null, payload, signal,
    )
  },
  postCustomerAdvisersFile(subdivisionId: number, file: File, callback: (loaded: number) => void) {
    const formData = new FormData()
    formData.append('file', file)
    return futch<string>(
      'POST', `${BASE_URI}/${subdivisionId}/customer-advisers-for-internal-task/import`, formData, callback,
    )
  },
  getCustomerAdvisersForManualMeeting(subdivisionId: number, payload: Dto<CustomerAdvisersForManualMeetingDto>, signal: AbortSignal) {
    return fetchJson<CustomerAdviser[], null, Dto<CustomerAdvisersForManualMeetingDto>>(
      'GET', `${BASE_URI}/${subdivisionId}/customer-advisers-for-manual-meeting`, null, payload, signal,
    )
  },
  getCustomerAdvisersForManualTraining(subdivisionId: number, payload: Dto<CustomerAdvisersForManualTrainingDto>, signal: AbortSignal) {
    return fetchJson<CustomerAdviser[], null, Dto<CustomerAdvisersForManualTrainingDto>>(
      'GET', `${BASE_URI}/${subdivisionId}/customer-advisers-for-manual-training`, null, payload, signal,
    )
  },
  putDayToleranceOverride(subdivisionId: number, values: Nullable<{ min: Nullable<number>, max: Nullable<number>, mesh: Nullable<DayToleranceMesh> }>) {
    return fetchJson<AutomationConfig, { day_tolerance: Nullable<{ min: Nullable<number>, max: Nullable<number>, mesh: Nullable<DayToleranceMesh> }> }>(
      'PUT', `${BASE_URI}/${subdivisionId}/day-tolerance`, { day_tolerance: values },
    )
  },
  getInternalTaskInstances(subdivisionId: number, type: InternalTaskType, dto: InternalTaskDto) {
    return fetchJson<InternalTaskInstance[], null, InternalTaskDto>(
      'GET', `${BASE_URI}/${subdivisionId}/internal-task-instances/${type}`, null, dto,
    )
  },
  getMassPlanificationPeriodsCustomerAdvisers(subdivisionId: number, payload: MassPlanificationCustomerAdvisersDto, signal: AbortSignal) {
    return fetchJson<CustomerAdviser[], null, MassPlanificationCustomerAdvisersDto>(
      'GET', `${BASE_URI}/${subdivisionId}/mass-planification/plan-periods/customer-advisers`, null, payload, signal,
    )
  },
  postMassPlanificationPeriodsPreValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson<MassPlanificationResult, MassPlanificationPreValidateDto>(
      'POST', `${BASE_URI}/${subdivisionId}/mass-planification/plan-periods/pre-validate`, payload,
    )
  },
  postMassPlanificationPeriodsValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson(
      'POST', `${BASE_URI}/${subdivisionId}/mass-planification/plan-periods/validate`, payload,
    )
  },
  getMassPlanificationRecurrenceCustomerAdvisers(subdivisionId: number, payload: MassPlanificationCustomerAdvisersDto, signal: AbortSignal) {
    return fetchJson<CustomerAdviser[], null, MassPlanificationCustomerAdvisersDto>(
      'GET', `${BASE_URI}/${subdivisionId}/mass-planification/plan-recurrence/customer-advisers`, null, payload, signal,
    )
  },
  postMassPlanificationRecurrencePreValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson<MassPlanificationResult, MassPlanificationPreValidateDto>(
      'POST', `${BASE_URI}/${subdivisionId}/mass-planification/plan-recurrence/pre-validate`, payload,
    )
  },
  postMassPlanificationRecurrenceValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson(
      'POST', `${BASE_URI}/${subdivisionId}/mass-planification/plan-recurrence/validate`, payload,
    )
  },
  getMassPlanificationReplaceCustomerAdvisers(subdivisionId: number, payload: MassPlanificationCustomerAdvisersDto, signal: AbortSignal) {
    return fetchJson<CustomerAdviser[], null, MassPlanificationCustomerAdvisersDto>(
      'GET', `${BASE_URI}/${subdivisionId}/mass-planification/replace-periods/customer-advisers`, null, payload, signal,
    )
  },
  postMassPlanificationReplacePreValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson<MassPlanificationResult, MassPlanificationPreValidateDto>(
      'POST', `${BASE_URI}/${subdivisionId}/mass-planification/replace-periods/pre-validate`, payload,
    )
  },
  postMassPlanificationReplaceValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson(
      'POST', `${BASE_URI}/${subdivisionId}/mass-planification/replace-periods/validate`, payload,
    )
  },
  postPreValidationMeetings(subdivisionId: number, dto: Dto<InternalTaskMeetingRequest>) {
    return fetchUpload<{ id: number }>(
      'POST', `${BASE_URI}/${subdivisionId}/meetings`, serialize(dto, { booleansAsIntegers: true, indices: true }),
    )
  },
  getPlanningByDate(subdivisionId: number, date: DateString | 'latest') {
    return fetchJson<Planning>(
      'GET', `${BASE_URI}/${subdivisionId}/plannings/${date}`,
    )
  },
  getRules(subdivisionId: number) {
    return fetchJson<RealSubdivisionCollectiveRule[]>(
      'GET', `${BASE_URI}/${subdivisionId}/rules`,
    )
  },
  postSubdivisionRuleOverride(subdivisionId: number, ruleId: number, dto: Nullable<PostSubdivisionRuleOverrideDto>) {
    return fetchJson<RuleOverride, Nullable<PostSubdivisionRuleOverrideDto>>(
      'POST', `${BASE_URI}/${subdivisionId}/rules/${ruleId}/override`, dto,
    )
  },
  putAutomationConfig(subdivisionId: number, data: { day: Nullable<string>, time: Nullable<string> }) {
    return fetchJson<AutomationConfig, Dto<{ scheduler_start_day: string, scheduler_start_time: string }>>(
      'PUT', `${BASE_URI}/${subdivisionId}/scheduler-config`, { scheduler_start_day: data.day, scheduler_start_time: data.time },
    )
  },
  putSlotToleranceOverride(subdivisionId: number, values: Nullable<{ min: number, max: number }>) {
    return fetchJson<AutomationConfig, { slot_tolerance: Nullable<{ min: Nullable<number>, max: Nullable<number> }> }>(
      'PUT', `${BASE_URI}/${subdivisionId}/slot-tolerance`, { slot_tolerance: values },
    )
  },
  getAvailableTrainers(subdivisionId: number, dto: Dto<{ start_date: DateString, end_date: DateString }>) {
    return fetchJson<UserLite[]>(
      'GET', `${BASE_URI}/${subdivisionId}/trainers`, null, dto,
    )
  },
  postPreValidationTrainings(subdivisionId: number, dto: Dto<InternalTaskTrainingRequest>) {
    return fetchUpload<{ id: number }>(
      'POST', `${BASE_URI}/${subdivisionId}/trainings`, serialize(dto, { booleansAsIntegers: true, indices: true }),
    )
  },
  postTrainingImport(file: File, subdivisionId: number, callback: (loaded: number) => void) {
    const formData = new FormData()
    formData.append('file', file)
    return futch<string>(
      'POST', `${BASE_URI}/${subdivisionId}/trainings/import`, formData, callback,
    )
  },
}

export { SchedulingSubdivisionsApiService }
