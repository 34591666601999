<script setup lang="ts"
  generic="T extends string | number | boolean | null"
>
  import { computed } from 'vue'

  import { Size, Weight } from '#/types'

  import { AlgLabel } from '../label'

  import { RadioSize } from './Radio.type'

  type Props = {
    readonly id: string
    readonly name: string
    readonly modelValue?: T
    readonly value: string | number | boolean
    readonly disabled?: boolean
    readonly inactive?: boolean
    readonly label?: string
    readonly labelPosition?: 'start' | 'end'
    readonly labelFontWeight?: Weight
    readonly size?: RadioSize
    readonly clickable?: boolean
  }

  defineOptions({
    name: 'AlgRadio',
  })

  const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    inactive: false,
    labelPosition: 'end',
    labelFontWeight: 'regular',
    size: 's',
    clickable: true,
  })

  const emit = defineEmits<{
    (e: 'update:model-value', value: T): void
  }>()

  // Computed
  const computedLabelFontSize = computed(() =>
    props.size as Size,
  )

  // Methods
  function getValue(value: string) {
    switch (typeof props.modelValue) {
    case 'number':
      return parseFloat(value)
    case 'boolean':
      return value === 'true'
    default:
      return value
    }
  }

  function handleToggle(e: Event) {
    const value = (e.target as HTMLInputElement).value
    emit('update:model-value', getValue(value) as T)
  }
</script>

<template>
  <label
    class="radio-label"
    :for="props.id"
  >
    <template v-if="props.label && props.labelPosition === 'start'">
      <AlgLabel
        :label="props.label"
        :font-size="computedLabelFontSize"
        :font-weight="props.labelFontWeight"
        :html-for="props.id"
        :disabled="props.disabled"
        inline
      />
    </template>
    <span
      class="radio"
      :class="[`size-${props.size}`, {
        checked: props.modelValue === props.value,
        disabled: props.disabled,
        inactive: props.inactive,
      }]"
    >
      <input
        :id="props.id"
        type="radio"
        tabindex="0"
        :name="props.name"
        :value="props.value"
        :aria-disabled="props.inactive || props.disabled"
        :disabled="props.inactive || props.disabled"
        @input="handleToggle"
      >
      <span
        v-if="props.modelValue === props.value"
        class="radio-icon"
      />
    </span>
    <template v-if="props.label && props.labelPosition === 'end'">
      <AlgLabel
        :label="props.label"
        :font-size="computedLabelFontSize"
        :font-weight="props.labelFontWeight"
        :html-for="props.clickable ? props.id : ''"
        :disabled="props.disabled"
        inline
      />
    </template>
  </label>
</template>

<style scoped>
.radio-label {
  display: inline-flex;
  align-items: center;
  gap: var(--alg-spacing-xs);

  .input-label {
    user-select: none;

    &:deep(.input-label-inner) {
      cursor: pointer;
      text-wrap: normal;
      white-space: wrap;
    }
  }

  .radio {
    position: relative;
    border: 1px solid var(--alg-color-icon-unselected);
    border-radius: 50%;
    background-color: var(--alg-color-surface-primary);
    cursor: pointer;

    input[type='radio'] {
      width: 100%;
      height: 100%;
      margin: 0;
      appearance: none;
      aspect-ratio: 1;
      cursor: inherit;
    }

    .radio-icon {
      position: absolute;
      display: inline-flex;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--alg-color-button-primary);
      inset: 2px;
    }

    &.size-s {
      width: 14px;
      height: 14px;
    }

    &.size-m {
      width: 18px;
      height: 18px;

      .radio-icon {
        inset: 3px;
      }
    }

    &.size-l {
      width: 24px;
      height: 24px;

      .radio-icon {
        inset: 4px;
      }
    }

    &.checked {
      color: var(--alg-color-surface-primary);
    }

    &.disabled {
      background-color: var(--alg-color-surface-border);
      cursor: not-allowed;

      &.checked {
        background-color: var(--alg-color-surface-border);
        color: var(--alg-color-surface-primary);

        .radio-icon {
          background-color: var(--alg-color-button-primary-disabled);
        }
      }
    }

    &.inactive {
      cursor: default;
    }
  }
}
</style>
