<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { AlgModal } from '../../modal'

  interface Props {
    filter: { label: string, value: number }
  }

  const props = defineProps<Props>()

  const emit = defineEmits<{
    (e: 'close'): void
    (e: 'confirm'): void
  }>()

  const { t } = useI18n()

  const modalContent = computed(() =>
    t('common.Do you really want to delete the (combination) custom filters?', {
      combination: `<strong>${props.filter.label}</strong>`,
    }),
  )

  function handleClose() {
    emit('close')
  }

  function handleConfirm() {
    emit('confirm')
  }
</script>

<template>
  <AlgModal
    name="delete-filter"
    size="xs"
    :confirm-button-text="t('common.Delete')"
    :confirm-button-loading="false"
    @close="handleClose"
    @cancel="handleClose"
    @confirm="handleConfirm"
  >
    <template #title>
      {{ t('common.Delete custom filters') }}
    </template>
    <template #content>
      <div class="content-wrapper">
        <p v-html="modalContent" />
      </div>
    </template>
  </AlgModal>
</template>

<style scoped>
  .content-wrapper {
    display: flex;
    flex: 1 1 auto;
    align-items: center;

    p {
      line-height: var(--alg-font-line-height);
      text-align: center;
    }
  }
</style>
