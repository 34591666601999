type Func<A, R> = (...args: A[]) => Promise<R> | R

export function debounce<A = unknown, R = void>(fn: Func<A, R>, ms: number) {
  // eslint-disable-next-line no-undef
  let timer: NodeJS.Timeout

  const debouncedFunc = (...args: A[]): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer)
      }

      timer = setTimeout(async () => {
        resolve(await fn(...args))
      }, ms)
    })

  return debouncedFunc
}
