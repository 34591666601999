<script setup lang="ts">
  import { Nullable } from '@algorh/shared'

  import { IconActivityNames, IconName } from '#/types'

  import { AlgErrors } from '../../feedback'
  import { AlgIcon } from '../../media'
  import { AlgLabel } from '../label'

  type Props = {
    readonly id: string
    readonly icons: IconActivityNames
    readonly modelValue: Nullable<IconName>
    readonly label?: string
    readonly noneLabel?: string
    readonly required?: boolean
    readonly disabled?: boolean
    readonly errors?: string[]
  }

  defineOptions({
    name: 'AlgIconSelect',
  })

  const props = withDefaults(defineProps<Props>(), {
    required: false,
    disabled: false,
  })

  const emit = defineEmits<{
    (e: 'update:modelValue', value: Nullable<IconName>): void
  }>()

  function handleSelectIcon(i: Nullable<IconName>) {
    emit('update:modelValue', i)
  }
</script>

<template>
  <div class="icon-select">
    <AlgLabel
      v-if="props.label"
      :id="props.id"
      :required="props.required"
      :label="props.label"
    />
    <ul class="icon-select-list">
      <li
        v-for="(icon, k) in props.icons"
        :key="k"
      >
        <button
          type="button"
          class="icon-select-option"
          :class="{ active: icon === props.modelValue }"
          :disabled="props.disabled"
          @click="() => handleSelectIcon(icon)"
        >
          <AlgIcon :name="icon" />
        </button>
      </li>
      <li>
        <button
          v-if="!props.required"
          type="button"
          class="icon-select-option"
          :class="{ active: props.modelValue === null }"
          :disabled="props.disabled"
          @click="() => handleSelectIcon(null)"
        >
          {{ props.noneLabel }}
        </button>
      </li>
    </ul>
    <AlgErrors
      v-if="props.errors && props.errors.length"
      :errors="props.errors"
    />
  </div>
</template>

<style scoped>
.icon-select {
  .icon-select-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    gap: var(--alg-spacing-xs);
    list-style: none;

    .icon-select-option {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      padding: var(--alg-spacing-s);
      border: 1px solid var(--alg-color-surface-border);
      border-radius: var(--alg-effect-radius-m);
      background-color: var(--alg-color-surface-primary);
      color: var(--alg-color-icon-secondary);
      transition-duration: var(--alg-transition-colors-duration);
      transition-property: background-color, border-color;
      transition-timing-function: var(--alg-transition-colors-timing-function);

      &:focus-visible,
      &:hover {
        background-color: var(--alg-color-surface-background-highlight);
      }

      &.active {
        border-color: var(--alg-color-button-primary);
        background-color: var(--alg-color-button-primary);
        color: var(--alg-color-text-on-color);
      }

      &:disabled {
        color: var(--alg-color-button-secondary-disabled);

        &:hover {
          border-color: var(--alg-color-surface-border);
          background-color: var(--alg-color-surface-background);
        }

        &.active {
          background-color: var(--alg-color-button-primary-disabled);
        }
      }
    }
  }
}
</style>
