<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { DateString, Nullable } from '@algorh/shared'
  import { AlgBadge, AlgIcon, AlgModal, AlgNoData, AlgWeekPeriod } from '@algorh/ui'

  import { Loader } from '@/components'

  interface Props {
    readonly rules: {
      label: string
      individual: boolean
      applicable_from: Nullable<DateString>
      applicable_to: Nullable<DateString>
      mandatory?: boolean
    }[]
    readonly title: string
    readonly loading?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    loading: false,
  })

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  // Composables
  const { t } = useI18n()

  // Computed
  const individualRules = computed(() => props.rules.filter((rule) => rule.individual))

  const collectiveRules = computed(() => props.rules.filter((rule) => !rule.individual))

  function handleClose() {
    emit('close')
  }
</script>

<template>
  <AlgModal
    name="rules"
    size="xl"
    :footer="false"
    @close="handleClose"
  >
    <template #title>
      <div class="title-wrapper">
        <AlgIcon
          name="balance"
          size="m"
        />
        {{ props.title }}
      </div>
    </template>
    <template #content>
      <div class="content-wrapper">
        <h2 class="subtitle">
          {{ t('rules.Individual rules') }}
        </h2>
        <div class="rules-wrapper">
          <div
            v-if="props.loading"
            class="loader"
          >
            <Loader />
          </div>
          <template v-else>
            <ul
              v-if="individualRules.length"
              class="rules"
            >
              <li
                v-for="(rule, k) in individualRules"
                :key="k"
                class="rule"
              >
                <p class="rule-title">
                  {{ rule.label }}
                </p>
                <span
                  v-if="(rule.applicable_from && rule.applicable_to) || rule.mandatory"
                  class="rule-footer"
                >
                  <span
                    v-if="rule.mandatory"
                    class="badge-wrapper"
                  >
                    <AlgBadge

                      :label="t('rules.Unbreakable')"
                    />
                  </span>
                  <AlgWeekPeriod
                    v-if="rule.applicable_from && rule.applicable_to"
                    :start="rule.applicable_from"
                    :end="rule.applicable_to"
                    grayed
                  />
                </span>
              </li>
            </ul>
            <AlgNoData
              v-else
              illustration="not-found"
              :title="t('rules.No individual rules')"
            />
          </template>
        </div>
        <h2 class="subtitle">
          {{ t('rules.Collective rules') }}
        </h2>
        <div class="rules-wrapper">
          <div
            v-if="props.loading"
            class="loader"
          >
            <Loader />
          </div>
          <template v-else>
            <ul
              v-if="collectiveRules.length"
              class="rules"
            >
              <li
                v-for="(rule, k) in collectiveRules"
                :key="k"
                class="rule"
              >
                <p class="rule-title">
                  {{ rule.label }}
                </p>
                <span
                  v-if="(rule.applicable_from && rule.applicable_to) || rule.mandatory"
                  class="rule-footer"
                >
                  <span
                    v-if="rule.mandatory"
                    class="badge-wrapper"
                  >
                    <AlgBadge

                      :label="t('rules.Unbreakable')"
                    />
                  </span>
                  <AlgWeekPeriod
                    v-if="rule.applicable_from && rule.applicable_to"
                    :start="rule.applicable_from"
                    :end="rule.applicable_to"
                    grayed
                  />
                </span>
              </li>
            </ul>
            <AlgNoData
              v-else
              illustration="not-found"
              :title="t('rules.No collective rules')"
            />
          </template>
        </div>
      </div>
    </template>
  </AlgModal>
</template>

<style scoped>
  .title-wrapper {
    display: flex;
    align-items: center;
    gap: var(--alg-spacing-s);
  }

  .content-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: var(--alg-spacing-m);
    overflow-y: auto;

    .subtitle {
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-s);
      font-weight: var(--alg-font-weight-bold);
      text-transform: uppercase;
    }

    .rules-wrapper {
      margin-bottom: var(--alg-spacing-m);

      .loader {
        display: flex;
        height: 200px;
        align-items: center;
        justify-content: center;
      }

      .rules {
        display: flex;
        flex-direction: column;
        gap: var(--alg-spacing-s);

        .rule {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: var(--alg-spacing-m);
          border-radius: var(--alg-effect-radius-m);
          background-color: var(--alg-color-surface-primary);
          box-shadow: var(--alg-effect-shadow-l);
          font-size: var(--alg-font-size-m);
          font-weight: var(--alg-font-weight-light);
          gap: var(--alg-spacing-s);

          .rule-title {
            line-height: var(--alg-font-line-height);
          }

          .rule-footer {
            display: flex;
            align-items: center;
            gap: var(--alg-spacing-s);
          }
        }
      }

      .no-data {
        margin: var(--alg-spacing-l) 0;
      }
    }
  }
</style>
