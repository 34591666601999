<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { AlgBadge } from '../../badge'
  import { AlgSpinner } from '../../feedback'
  import { AlgIcon } from '../../media'

  import { SchedulingStepCardAvailability, SchedulingStepCardState, SchedulingStepCardVariant } from './SchedulingStepCard.type'

  interface Props {
    title: string
    variant?: SchedulingStepCardVariant
    availability?: SchedulingStepCardAvailability
    state?: SchedulingStepCardState
    label?: string
    disabled?: boolean
  }

  defineOptions({
    name: 'AlgSchedulingStepCard',
  })

  const props = withDefaults(defineProps<Props>(), {
    variant: 'purple',
    disabled: false,
    availability: 'none',
    state: 'none',
  })

  const { t } = useI18n()

  const computedAvailability = computed(() => {
    switch (props.availability) {
    case 'available':
      return t('common.Available')
    case 'unavailable':
      return t('common.Unavailable')
    default:
      return undefined
    }
  })
</script>

<template>
  <div
    class="scheduling-step-card"
    :class="[
      `variant-${props.variant}`,
      `state-${props.state}`,
      {
        disabled: props.disabled
      },
    ]"
  >
    <div class="header">
      <div class="header-title">
        {{ props.title }}
      </div>
      <div
        v-if="props.availability !== 'none'"
        class="header-badge"
      >
        <AlgBadge
          v-if="computedAvailability"
          :label="computedAvailability"
          :variant="props.availability === 'available' ? 'success' : 'secondary'"
          size="s"
        />
      </div>
    </div>
    <div class="content">
      <slot name="default" />
    </div>
    <div class="footer">
      <span v-if="props.label">{{ props.label }}</span>
      <AlgIcon
        v-if="['warning', 'error', 'success'].includes(props.state)"
        :name="
          props.state === 'success'
            ? 'check'
            : ['warning', 'error'].includes(props.state)
              ? 'warning'
              : 'block'
        "
        size="xs"
      />
      <AlgSpinner
        v-else-if="props.state === 'loading'"
        size="xs"
      />
    </div>
  </div>
</template>

<style scoped>
  .scheduling-step-card,
  .header-title,
  .footer {
    transition-duration: var(--alg-transition-colors-duration);
    transition-property: var(--alg-transition-colors-property);
    transition-timing-function: var(--alg-transition-colors-timing-function);
  }

  .scheduling-step-card {
    display: inline-flex;
    width: 100%;
    min-height: 5.625rem; /* 90px */
    box-sizing: border-box;
    flex-direction: column;
    border: 0.063rem solid var(--alg-color-surface-border); /* 1px */
    border-radius: var(--alg-effect-radius-s);
    background-color: var(--alg-color-surface-primary);
    border-inline-start-width: var(--alg-spacing-xs);
    gap: var(--alg-spacing-xs);
    padding-block: var(--alg-spacing-xs) var(--alg-spacing-s);
    padding-inline: var(--alg-spacing-s) var(--alg-spacing-xs);

    &.disabled {
      background-color: var(--alg-color-surface-background);
    }

    &.variant-purple {
      border-inline-start-color: var(--alg-color-purple-100);

      .header-title {
        color: var(--alg-color-purple-100);
      }
    }

    &.variant-yellow {
      border-inline-start-color: var(--alg-color-yellow-100);

      .header-title {
        color: var(--alg-color-yellow-100);
      }
    }

    &.variant-cyan {
      border-inline-start-color: var(--alg-color-cyan-100);

      .header-title {
        color: var(--alg-color-cyan-100);
      }
    }

    &.state-success {
      .footer {
        color: var(--alg-color-state-success);
      }
    }

    &.state-loading {
      .footer {
        color: var(--alg-color-state-warning);
      }
    }

    &.state-warning {
      .footer {
        color: var(--alg-color-state-warning);
      }
    }

    &.state-error {
      .footer {
        color: var(--alg-color-state-danger);
      }
    }

    .header {
      display: flex;
      height: var(--alg-font-size-m);
      flex-direction: row;
      padding-bottom: var(--alg-spacing-xs);
      border-bottom: 0.063rem solid var(--alg-color-surface-border);
      gap: var(--alg-spacing-xs);
      line-height: var(--alg-font-size-m);
    }

    .header-title {
      overflow: hidden;
      height: var(--alg-font-size-xxs);
      flex: 1;
      font-size: var(--alg-font-size-xxs);
      font-weight: var(--alg-font-weight-bold);
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
    }

    .header-badge {
      display: flex;
      flex-direction: row;
      gap: var(--alg-spacing-xs);
    }

    .content {
      display: flex;
      flex: 1;
      flex-direction: row;
      gap: var(--alg-spacing-xs);
    }

    .footer {
      display: flex;
      height: var(--alg-font-size-l);
      flex-direction: row;
      align-items: center;
      color: var(--alg-color-text-light);
      font-size: var(--alg-font-size-xxs);
      font-weight: var(--alg-font-weight-bold);
      gap: var(--alg-spacing-xs);
      line-height: var(--alg-font-size-l);
    }
  }
</style>
