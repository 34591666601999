export enum ActivityConflictReason {
  ABSENCE = 'absence',
  ACTIVITY_NOT_PRACTICED = 'activity_not_practiced',
  DAY_OFF = 'day_off',
  INTERNAL_TASK = 'internal_task',
  LUNCH_BREAK = 'lunch_break',
  MANUAL_SCHEDULING = 'manual_scheduling',
  NO_SCHEDULABLE_PRODUCTIVE_ACTIVITY = 'no_schedulable_productive_activity',
  OPERATIONAL_SUBDIVISION_MISMATCH = 'operational_subdivision_mismatch',
  RULE = 'rule',
  SKILL_MISSING = 'skill_missing',
  WORK_CYCLE = 'work_cycle',
}
