<script setup lang="ts">
  import { onMounted } from 'vue'

  import { BRAND_NAMES, ILLUSTRATION_NAMES } from '#/constants'
  import { BrandName, IllustrationName } from '#/types'

  import SvgBrandSprites from '../../../../public/assets/brands/sprites.svg?raw'
  import SvgIconSprites from '../../../../public/assets/icons/sprites.svg?raw'
  import SvgIllustrationSprites from '../../../../public/assets/illustrations/sprites.svg?raw'

  defineOptions({
    name: 'AlgIllustration',
  })

  const props = withDefaults(defineProps<Props>(), {
    prefix: 'illustration',
    size: 96,
    alt: '',
  })

  interface Props {
    name: IllustrationName | BrandName
    prefix?: 'illustration' | 'brand'
    size?: [number, number] | number
    alt?: string
  }

  onMounted(() => {
    if (!document.body.querySelector('#icon-sprites')) {
      document.body.insertAdjacentHTML('beforeend', SvgIconSprites)
    }

    if (props.prefix === 'brand') {
      if (!document.body.querySelector('#brand-sprites')) {
        document.body.insertAdjacentHTML('beforeend', SvgBrandSprites)
      }
    }

    if (props.prefix === 'illustration') {
      if (!document.body.querySelector('#illustration-sprites')) {
        document.body.insertAdjacentHTML('beforeend', SvgIllustrationSprites)
      }
    }
  })
</script>

<template>
  <span
    class="illustration-wrapper"
    :style="{
      width: typeof props.size === 'number' ? `${props.size}px` : `${props.size[0]}px`,
      height: typeof props.size === 'number' ? `${props.size}px` : `${props.size[1]}px`
    }"
  >
    <svg
      class="illustration"
      role="img"
      focusable="false"
      :aria-label="props.alt"
      :aria-hidden="!props.alt.length"
    >
      <use
        v-if="[...ILLUSTRATION_NAMES, ...BRAND_NAMES].includes(props.name)"
        :href="`#${props.prefix}-${props.name}`"
      />
      <use
        v-else
        href="#icon-block"
      />
    </svg>
  </span>
</template>

<style scoped>
  .illustration-wrapper {
    position: relative;
    display: inline-block;

    .illustration {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      transition-duration: var(--alg-transition-colors-duration);
      transition-property: var(--alg-transition-colors-property);
      transition-timing-function: var(--alg-transition-colors-timing-function);
    }
  }
</style>
