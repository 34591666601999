<script lang="ts" setup>
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { minuteToStringDuration, Nullable } from '@algorh/shared'
  import { AlgSelect } from '@algorh/ui'

  import { useConfigs } from '@/composables/useConfigs'

  type Props = {
    readonly name: string
    readonly errors?: string[]
    readonly hasAnyDurationvalue?: boolean
  }

  const props = defineProps<Props>()
  const model = defineModel<Nullable<number>>({ required: true })

  const { t } = useI18n()

  const { planificationConfig } = useConfigs()

  const timeOptions = computed(() => {
    const options = []

    options.push({
      value: props.hasAnyDurationvalue ? null : 0,
      label: props.hasAnyDurationvalue ? t('rules.parts.any_duration') : '00:00',
    })
    for (let i = 1; i < (planificationConfig.value?.slot_duration ?? 30) * 2 + 1; i++) {
      const option = i * 30

      options.push({
        value: option,
        label: minuteToStringDuration(option),
      })
    }

    return options
  })

</script>

<template>
  <div class="rule-activity-input-wrapper">
    <AlgSelect
      :id="name"
      v-model="model"
      centered
      required
      :options="timeOptions"
      :errors="errors"
    />
  </div>
</template>
