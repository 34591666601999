<script setup lang="ts">
  import { computed, CSSProperties } from 'vue'

  import { AlgDot } from '@algorh/ui'

  export interface Legend {
    color: string
    label: string
  }

  type Props = {
    readonly direction?: 'vertical' | 'horizontal'
    readonly columns?: number
    readonly legends?: Legend[]
  }

  const props = withDefaults(defineProps<Props>(), {
    direction: 'vertical',
    columns1: 1,
    legends: () => [],
  })

  const style = computed<CSSProperties>(() => {
    if (props.direction === 'vertical') {
      return {
        display: 'grid',
        gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
      }
    }

    if (props.direction === 'horizontal') {
      return {
        display: 'flex',
        flexDirection: 'row',
      }
    }

    return {}
  })
</script>

<template>
  <div
    class="chart-caption"
    :style="style"
  >
    <AlgDot
      v-for="(legend, key) in props.legends"
      :key="key"
      size="xs"
      :color="legend.color"
      :label="legend.label"
    />
  </div>
</template>

<style scoped>
  .chart-caption {
    align-items: center;
    justify-content: center;
    margin: var(--alg-spacing-m) 0;
    color: var(--alg-color-text-primary);
    font-size: var(--alg-font-size-s);
    gap: var(--alg-spacing-s);
  }
</style>
