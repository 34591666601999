<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgModal } from '@algorh/ui'

  const emit = defineEmits<{
    (e: 'close'): void
    (e: 'cancel'): void
    (e: 'confirm'): void
  }>()

  const { t } = useI18n()

  function handleClose() {
    emit('close')
  }

  function handleCancel() {
    emit('cancel')
  }

  function handleConfirm() {
    emit('confirm')
  }
</script>

<template>
  <AlgModal
    name="reset"
    size="m"
    @close="handleClose"
    @cancel="handleCancel"
    @confirm="handleConfirm"
  >
    <template #title>
      {{ t('common.Reset') }}
    </template>
    <template #content>
      <slot />
    </template>
  </AlgModal>
</template>
