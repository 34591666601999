<script setup lang="ts" generic="T extends Nullable<DateString>">
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { DateString, Nullable, useIsMobile } from '@algorh/shared'

  import { AlgErrors } from '../../../feedback'
  import { AlgIcon } from '../../../media'
  import { AlgCalendarPopper } from '../../../popover'
  import { AlgLabel } from '../../label'
  import { InputSize, InputVariant } from '../Input.type'

  type Props = {
    readonly id: string
    readonly label?: string
    readonly sublabel?: string
    readonly placeholder?: string
    readonly noneLabel?: string
    readonly size?: InputSize
    readonly inline?: boolean
    readonly required?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    readonly centered?: boolean
    readonly searchable?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
    readonly minValue?: DateString
    readonly maxValue?: DateString
    readonly allowedDays?: number[] // 1-7
    readonly closeOnSelect?: boolean
    readonly variant?: InputVariant
    readonly dailyCounts?: Nullable<Record<DateString, {
      absence: boolean
      day_off: boolean
    }>>
  }

  defineOptions({
    name: 'AlgDateInput',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 'm',
    inline: false,
    required: false,
    disabled: false,
    readonly: false,
    centered: false,
    searchable: false,
    errored: false,
    closeOnSelect: true,
    variant: 'primary',
  })

  const emit = defineEmits<{
    (e: 'update:month', value: Nullable<DateString>): void
    (e: 'blur'): void
  }>()

  // Composables
  const { t } = useI18n()

  const isMobile = useIsMobile()

  const model = defineModel<T>({ required: true })

  // Refs
  const inputRef = ref <Nullable<HTMLInputElement>>(null)

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  // Methods
  function handleDateInput(event: Event) {
    const { value } = event.target as HTMLInputElement
    model.value = value as T
  }

  function handleDateChange(date: DateString) {
    model.value = date as T
    emit('blur')
  }

  function handleMonthChange(date: DateString) {
    emit('update:month', date)
  }

  function handleClear() {
    model.value = null as T
  }

  function handleClick(toggle: () => void) {
    try {
      if (isMobile.value && inputRef.value?.showPicker) {
        inputRef.value.showPicker()
      } else {
        toggle()
      }
    } catch {
      toggle()
    }
  }
</script>

<template>
  <div
    class="field-wrapper"
    :class="{ inline: props.inline}"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :html-for="props.id"
      :inline="props.inline"
      :input-size="props.size"
      :required="props.required"
      :errored="hasErrors"
    />
    <div class="field-content">
      <AlgCalendarPopper
        :close-on-select="props.closeOnSelect"
        :model-value="model"
        :min-value="props.minValue"
        :max-value="props.maxValue"
        :allowed-days="props.allowedDays"
        :daily-counts="props.dailyCounts"
        @update:model-value="handleDateChange"
        @update:month="handleMonthChange"
      >
        <template #reference="{ toggle, isOpen }">
          <div class="input-wrapper">
            <input
              :id="props.id"
              ref="inputRef"
              type="date"
              class="input has-suffix"
              :class="[
                `size-${props.size}`,
                `variant-${props.variant}`,
                {
                  errored: hasErrors,
                  clearable: !props.disabled && !props.required && model,
                  centered: props.centered,
                }
              ]"
              :name="props.id"
              :placeholder="props.placeholder ?? t('datetime.placeholder.date')"
              :min="props.minValue"
              :max="props.maxValue"
              :required="props.required"
              :disabled="props.disabled"
              :readonly="props.readonly"
              :value="model"
              step="1"
              @input="handleDateInput"
              @blur="emit('blur')"
            >
            <span
              v-if="!props.disabled && !props.required && !props.readonly && model"
              class="input-suffix clear-button"
            >
              <button
                type="button"
                :title="t('common.Clear')"
                @click="handleClear"
              >
                <AlgIcon
                  name="cancel"
                  size="s"
                />
              </button>
            </span>
            <span class="input-suffix">
              <button
                type="button"
                :title="isOpen ? t('common.Close calendar') : t('common.Open calendar')"
                :disabled="props.disabled || props.readonly"
                @click="() => handleClick(toggle)"
              >
                <AlgIcon
                  name="calendar-today"
                  :color="
                    props.disabled
                      ? 'var(--alg-color-icon-unselected)'
                      : 'var(--alg-color-icon-highlight)'
                  "
                  size="s"
                />
              </button>
            </span>
          </div>
        </template>
      </AlgCalendarPopper>
      <AlgErrors
        v-if="props.errors && props.errors.length"
        :errors="props.errors"
      />
    </div>
  </div>
</template>

<style src="../index.css" scoped />

<style scoped>
  .input::-webkit-calendar-picker-indicator {
    display: none;
  }
</style>
