<script setup lang="ts">
  import type { IconName } from '#/types'

  import { AlgIcon } from '../media'

  import { BadgeSize, BadgeVariant } from './Badge.types'

  interface Props {
    label?: string
    variant?: BadgeVariant
    size?: BadgeSize
    icon?: IconName
    uppercase?: boolean
    rounded?: boolean
  }

  defineOptions({
    name: 'AlgBadge',
  })

  const props = withDefaults(defineProps<Props>(), {
    variant: 'primary',
    size: 'm',
    uppercase: true,
    rounded: false,
  })

</script>

<template>
  <div
    class="badge"
    :class="[
      `variant-${props.variant}`,
      `size-${props.size}`,
      {
        uppercase: props.uppercase,
        rounded: props.rounded,
      }
    ]"
  >
    <AlgIcon
      v-if="props.icon"
      :name="props.icon"
      size="xs"
    />
    <slot v-if="!label" />
    <template v-else>
      {{ props.label }}
    </template>
  </div>
</template>

<style scoped>
  .badge {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    border-radius: var(--alg-effect-radius-s);
    font-weight: var(--alg-font-weight-bold);
    gap: calc(var(--alg-spacing-xs) / 2);
    padding-inline: var(--alg-spacing-xs);
    white-space: nowrap;

    &.rounded {
      border-radius: var(--alg-effect-radius-l);
    }

    &.uppercase {
      text-transform: uppercase;
    }

    &.size-s {
      height: var(--alg-font-size-m);
      font-size: var(--alg-font-size-xxs);
      line-height: var(--alg-font-size-m);
    }

    &.size-m {
      height: var(--alg-font-size-l);
      font-size: var(--alg-font-size-xs);
      line-height: var(--alg-font-size-l);
    }

    &.size-l {
      height: var(--alg-font-size-xl);
      font-size: var(--alg-font-size-s);
      line-height: var(--alg-font-size-xl);
    }

    &.size-xl {
      height: var(--alg-font-size-xl);
      font-size: var(--alg-font-size-m);
      line-height: var(--alg-font-size-xl);
    }

    &.variant-primary {
      background-color: var(--alg-color-surface-background-highlight);
      color: var(--alg-color-text-highlight);
    }

    &.variant-secondary {
      background-color: var(--alg-color-light-grey);
      color: var(--alg-color-text-secondary);
    }

    &.variant-info {
      background-color: var(--alg-color-light-blue);
      color: var(--alg-color-state-info);
    }

    &.variant-success {
      background-color: var(--alg-color-light-green);
      color: var(--alg-color-state-success);
    }

    &.variant-warning {
      background-color: var(--alg-color-light-orange);
      color: var(--alg-color-state-warning);
    }

    &.variant-danger {
      background-color: var(--alg-color-light-red);
      color: var(--alg-color-state-danger);
    }

    &.variant-purple {
      background-color: var(--alg-color-light-purple);
      color: var(--alg-color-purple-100);
    }
  }
</style>
