<script setup lang="ts">
  import { TabSize, TabVariant } from './Tab.types'

  defineOptions({
    name: 'AlgTabList',
  })

  const props = withDefaults(defineProps<Props>(), {
    selected: false,
    size: 'm',
    variant: 'plain',
  })

  const emit = defineEmits<{
    (e: 'click', p: number | string): void
  }>()

  interface Props {
    id: number | string
    label?: string
    selected?: boolean
    size?: TabSize
    variant?: TabVariant
  }
</script>

<template>
  <button
    class="tab"
    :class="[
      `tab-variant-${props.variant}`,
      `tab-size-${props.size}`,
      {
        'tab-selected': props.selected,
      }
    ]"
    type="button"
    @click="emit('click', props.id)"
  >
    <template v-if="props.label">
      {{ props.label }}
    </template>
    <slot v-else />
  </button>
</template>

<style scoped>
  .tab {
    position: relative;
    display: flex;
    display: inline-flex;
    overflow: hidden;
    width: auto;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 0;
    appearance: none;
    background-color: var(--alg-color-transparent);
    color: var(--alg-color-text-secondary);
    cursor: pointer;
    font: inherit;
    font-size: var(--alg-font-size-s);
    font-weight: var(--alg-font-weight-bold);
    text-overflow: ellipsis;
    white-space: nowrap;

    &.tab-size-m {
      font-size: var(--alg-font-size-s);
    }

    &.tab-size-l {
      font-size: var(--alg-font-size-l);
    }

    &.tab-variant-plain {
      border-radius: var(--alg-effect-radius-s);
      padding-block: var(--alg-spacing-xs);
      padding-inline: var(--alg-spacing-s);

      &.tab-selected {
        background-color: var(--alg-color-surface-background-highlight);
        color: var(--alg-color-surface-highlight);
      }
    }

    &.tab-variant-bordered {
      border-block-end: 2px solid var(--alg-color-transparent);
      margin-inline: var(--alg-spacing-s);
      padding-block-end: var(--alg-spacing-m);
      padding-inline: var(--alg-spacing-xs);

      &.tab-selected {
        border-block-end: 2px solid var(--alg-color-surface-highlight);
        color: var(--alg-color-text-primary);
      }
    }
  }
</style>
