<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { Size, Weight } from '#/types'

  import { AlgErrors } from '../../feedback'
  import { AlgLabel } from '../label'

  import { ToggleSize } from './Toggle.type'

  type Props = {
    readonly id: string
    readonly modelValue?: boolean
    readonly disabled?: boolean
    readonly label?: string
    readonly labelPosition?: 'start' | 'end'
    readonly labelFontSize?: Size
    readonly labelFontWeight?: Weight
    readonly size?: ToggleSize
    readonly errors?: string[]
  }

  defineOptions({
    name: 'AlgToggle',
  })

  const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    labelPosition: 'start',
    labelFontSize: 'm',
    labelFontWeight: 'bold',
    size: 'm',
  })

  const emit = defineEmits<{
    (e: 'update:model-value', value: boolean): void
  }>()

  // Composables
  const { t } = useI18n()

  // Methods
  function handleToggle() {
    emit('update:model-value', !props.modelValue)
  }
</script>

<template>
  <div class="toggle-label">
    <template v-if="props.label && props.labelPosition === 'start'">
      <AlgLabel
        :label="props.label"
        :font-size="props.labelFontSize"
        :font-weight="props.labelFontWeight"
        :html-for="props.id"
        :disabled="props.disabled"
        inline
      />
    </template>
    <button
      :id="props.id"
      type="button"
      role="switch"
      :class="`size-${props.size}`"
      :aria-checked="props.modelValue"
      :disabled="props.disabled"
      :title="props.modelValue ? t('common.Disable') : t('common.Enable')"
      @click="handleToggle"
    />
    <template v-if="props.label && props.labelPosition === 'end'">
      <AlgLabel
        :label="props.label"
        :font-size="props.labelFontSize"
        :font-weight="props.labelFontWeight"
        :html-for="props.id"
        :disabled="props.disabled"
        inline
      />
    </template>
  </div>
  <AlgErrors
    v-if="props.errors && props.errors.length"
    :errors="props.errors"
  />
</template>

<style scoped>
  .toggle-label {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--alg-spacing-s);

    [role='switch'] {
      position: relative;
      display: block;
      border: 1px solid var(--alg-color-surface-border);
      border-radius: var(--alg-effect-radius-l);
      background-color: var(--alg-color-surface-primary);

      &.size-s {
        width: 30px;
        height: 16px;

        &::after {
          width: 9px;
          height: 9px;
          transform: translate(calc(-100% + 6px), -50%);
        }
      }

      &.size-m {
        width: 36px;
        height: 20px;

        &::after {
          width: 11px;
          height: 11px;
          transform: translate(calc(-100% + 8px), -50%);
        }
      }

      &.size-l {
        width: 42px;
        height: 24px;

        &::after {
          width: 13px;
          height: 13px;
          transform: translate(calc(-100% + 10px), -50%);
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        display: block;
        border-radius: 50%;
        background-color: var(--alg-color-state-danger);
        content: '';
        transition: transform 200ms 40ms ease;
      }

      &[aria-checked='true'] {
        &::after {
          background-color: var(--alg-color-state-success);
        }

        &.size-s {
          &::after {
            transform: translate(9px, -50%);
          }
        }

        &.size-m {
          &::after {
            transform: translate(13px, -50%);
          }
        }

        &.size-l {
          &::after {
            transform: translate(17px, -50%);
          }
        }
      }

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:focus-visible {
        border-color: var(--alg-color-surface-highlight);
        box-shadow: var(--alg-effect-shadow-l);
        outline: none;
      }
    }
  }
</style>
