<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { DateString, dayjs, DTF } from '@algorh/shared'

  import { AlgProgressCircle } from '../../feedback'

  interface Props {
    readonly progress: number
    readonly label: string
    readonly date: DateString
  }

  defineOptions({
    name: 'AlgStatisticCard',
  })

  const props = defineProps<Props>()

  const { t } = useI18n()

  const color = computed(() => {
    if (props.progress <= 30) {
      return 'var(--alg-color-state-danger)'
    }

    if (props.progress <= 70) {
      return 'var(--alg-color-state-warning)'
    }

    if (props.progress <= 100) {
      return 'var(--alg-color-state-success)'
    }

    return 'var(--alg-color-state-info)'
  })

  const backgroundColor = computed(() => {
    if (props.progress <= 30) {
      return 'var(--alg-color-light-red)'
    }

    if (props.progress <= 70) {
      return 'var(--alg-color-light-orange)'
    }

    if (props.progress <= 100) {
      return 'var(--alg-color-light-green)'
    }

    return 'var(--alg-color-light-blue)'
  })
</script>

<template>
  <div class="statistic-card">
    <div
      class="progress-wrapper"
      :style="{
        backgroundColor: backgroundColor
      }"
    >
      <AlgProgressCircle
        :progress="props.progress ?? 0"
        :size="48"
        :color="color"
        background-color="var(--alg-color-surface-primary)"
        from-bottom
        show-percentage
      />
    </div>
    <div class="content-wrapper">
      <div class="title">
        {{ props.label }}
      </div>
      <div class="description">
        {{
          t('datetime.Since {date}', { date: dayjs(props.date).format(t(`datetime.${DTF.DATE}`)) })
        }}
      </div>
    </div>
  </div>
</template>

<style scoped>
  .statistic-card {
    display: inline-flex;
    padding: var(--alg-spacing-s);
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-effect-radius-m);
    background-color: var(--alg-color-surface-primary);
    gap: var(--alg-spacing-s);

    .progress-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--alg-spacing-s);
      border-radius: var(--alg-effect-radius-s);
    }

    .content-wrapper {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      gap: var(--alg-spacing-s);

      .title {
        font-size: var(--alg-font-size-m);
      }

      .description {
        font-size: var(--alg-font-size-s);
      }
    }
  }
</style>
