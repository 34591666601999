<script lang="ts" setup>
  import { DateString, dayjs, DTF, type I18n } from '@algorh/shared'

  import { PeriodMode } from '#/types'

  import { AlgIcon } from '../media'

  interface Props {
    periodMode: PeriodMode
    date: DateString
    i18n: I18n<'week'>
  }

  const props = defineProps<Props>()
</script>

<template>
  <div class="breadcrumb">
    <span class="year">{{ dayjs(props.date).format(DTF.YEAR) }}</span>
    <span class="month">
      <AlgIcon
        class="icon"
        name="chevron-right"
        size="s"
      />
      <span :class="{ highlight: periodMode === PeriodMode.MONTH }">
        {{ dayjs(props.date).format(DTF.MONTH_NAME) }}
      </span>
    </span>
    <span
      v-if="periodMode === PeriodMode.WEEK || periodMode === PeriodMode.DAY"
      class="week"
    >
      <AlgIcon
        class="icon"
        name="chevron-right"
        size="s"
      />
      <span :class="{ highlight: periodMode === PeriodMode.WEEK }">
        {{ i18n.week }}&nbsp;{{ dayjs(props.date).isoWeek() }}
      </span>
    </span>
    <span
      v-if="periodMode === PeriodMode.DAY"
      class="day"
    >
      <AlgIcon
        class="icon"
        name="chevron-right"
        size="s"
      />
      <span :class="{ highlight: periodMode === PeriodMode.DAY }">
        {{ dayjs(props.date).format(DTF.DAY_NAME_WITH_ZERO_NUMBER) }}
      </span>
    </span>
  </div>
</template>

<style scoped>
  .breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--alg-spacing-s);
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-spacing-s);
    background-color: var(--alg-color-surface-primary);

    .year,
    .month,
    .week,
    .day {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--alg-color-text-primary);
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-regular);
      text-transform: capitalize;

      .highlight {
        padding: var(--alg-spacing-xs) var(--alg-spacing-s);
        border-radius: var(--alg-effect-radius-s);
        background-color: var(--alg-color-surface-background-highlight);
        color: var(--alg-color-text-highlight);
        font-weight: var(--alg-font-weight-regular);
      }

      .icon {
        margin: 0 var(--alg-spacing-xs);
      }
    }
  }
</style>
