<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { AlgButton } from '../button'

  type Props = {
    readonly items: string[]
    readonly showMoreLabel?: string
    readonly showLessLabel?: string
  }

  const props = defineProps<Props>()

  const { t } = useI18n()

  const showMore = ref(false)

  const itemsCount = computed(() => props.items.length - 1)

  watch(() => props.items, () => {
    showMore.value = false
  })
</script>

<template>
  <div class="foldable-list">
    <template v-if="showMore">
      <ul>
        <li
          v-for="(item, key) in props.items"
          :key="key"
        >
          {{ item }}
        </li>
      </ul>
      <AlgButton
        class="show-less-button"
        variant="link"
        size="s"
        :label="props.showLessLabel ?? t('common.Show less')"
        @click.stop="showMore = false"
      />
    </template>
    <template v-else>
      <template v-if="props.items.length > 1">
        {{
          t('common.{first} and {n} other', {
            first: props.items[0],
            n: `${itemsCount}`
          }, itemsCount)
        }}
        <AlgButton
          variant="link"
          size="s"
          :label="props.showMoreLabel ?? t('common.Show more')"
          @click.stop="showMore = true"
        />
      </template>
      <template v-else>
        {{ items[0] }}
      </template>
    </template>
  </div>
</template>

<style scoped>
  .foldable-list {
    font-size: var(--alg-font-size-s);
    line-height: var(--alg-font-line-height);

    ul {
      position: relative;
      padding-left: var(--alg-spacing-s);
      margin: 0;

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 2px;
        height: 100%;
        border-radius: 2px;
        background-color: var(--alg-color-surface-highlight);
        content: '';
      }

      li {
        margin-bottom: var(--alg-spacing-xs);
        list-style: none;
      }
    }

    .show-less-button {
      margin-top: var(--alg-spacing-xs);
    }
  }
</style>
