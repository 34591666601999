import { Nullable } from '@algorh/shared'

export enum ActivityCategory {
  ABSENCE = 'absence',
  DAY_OFF = 'day_off',
  LUNCH_BREAK = 'lunch_break',
  NON_PRODUCTION = 'non_production',
  PRODUCTION = 'production',
  UNKNOWN = 'unknown',
  TRAINING = 'training',

}
export interface PlanningActivityCardActivity {
  id: number
  icon: Nullable<string>
  name: string
  category: ActivityCategory
  selectable: Nullable<boolean>
  schedulable: Nullable<boolean>
  primary_color: Nullable<string>
  secondary_color: Nullable<string>
}
