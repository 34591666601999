<script setup lang="ts">
  interface Props {
    progress: number
    size?: number
    color?: string
    backgroundColor?: string
    showPercentage?: boolean
    fromBottom?: boolean
  }

  defineOptions({
    name: 'AlgProgressCircle',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 24,
    color: 'var(--alg-color-surface-highlight)',
    backgroundColor: 'var(--alg-color-surface-background-highlight)',
    showPercentage: false,
    fromBottom: false,
  })
</script>

<template>
  <div
    class="progress-circle"
    :style="{
      width: `${props.size}px`,
      height: `${props.size}px`
    }"
  >
    <svg
      :width="props.size"
      :height="props.size"
      :viewBox="`0 0 120 120`"
      :style="`transform: rotate(${fromBottom ? '90' : '-90'}deg)`"
    >
      <circle
        cx="60"
        cy="60"
        r="54"
        fill="none"
        :stroke="props.backgroundColor"
        :stroke-width="6"
      />
      <circle
        :style="{
          'stroke-dasharray': 100,
          'stroke-dashoffset': `calc(100 - ${props.progress <= 100 ? props.progress : 100})`
        }"
        cx="60"
        cy="60"
        r="54"
        fill="none"
        :stroke="props.color"
        :stroke-width="6"
        pathLength="100"
      />
    </svg>
    <div
      v-if="props.showPercentage"
      class="percentage-wrapper"
      :style="{ color: props.color }"
    >
      <div class="percentage">
        {{ props.progress }}
      </div>
    </div>
  </div>
</template>

<style scoped>
  .progress-circle {
    position: relative;

    .percentage-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      inset: 0;

      .percentage {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2px;
        font-size: var(--alg-font-size-xs);
        font-weight: var(--alg-font-weight-bold);

        .percent {
          display: inline-flex;
          margin-top: 2px;
          font-size: var(--alg-font-size-xxs);
        }
      }
    }
  }
</style>
