import { fetchJson, Nullable } from '@algorh/shared'

import { TrainingDomain, TrainingType } from '../types/InternalTask'

const BASE_URI = '/api/scheduling'

const SchedulingApiService = {
  putInternalTaskInstanceGlobalDetails(id: number, payload: { name: string, color: string, code: Nullable<string>, description: Nullable<string> }) {
    return fetchJson(
      'PUT', `${BASE_URI}/internal-tasks/${id}`, payload)
  },
  getTrainingDomains() {
    return fetchJson<TrainingDomain[]>(
      'GET', `${BASE_URI}/training-domains`,
    )
  },
  getTrainingTypes() {
    return fetchJson<TrainingType[]>(
      'GET', `${BASE_URI}/training-types`,
    )
  },
}

export { SchedulingApiService }
