<script setup lang="ts">
  import { DotSize } from './Dot.types'

  type Props = {
    label?: string
    color?: string
    size?: DotSize
    bordered?: boolean
  }

  defineOptions({
    name: 'AlgDot',
  })

  const props = withDefaults(defineProps<Props>(), {
    color: 'var(--alg-color-icon-unselected)',
    size: 'm',
    bordered: false,
  })
</script>

<template>
  <div :class="`dot-wrapper size-${props.size}`">
    <span
      class="dot"
      :class="{ bordered }"
      :style="{ backgroundColor: props.color }"
    />
    {{ props.label }}
  </div>
</template>

<style scoped>
  .dot-wrapper {
    display: flex;
    align-items: center;
    gap: var(--alg-spacing-xs);

    &.size-xxs {
      font-size: var(--alg-font-size-xs);
      gap: var(--alg-spacing-xxs);
      line-height: var(--alg-line-height-xs);

      .dot {
        width: 0.25rem;
        height: 0.25rem;
      }
    }

    &.size-xs {
      font-size: var(--alg-font-size-s);
      line-height: var(--alg-line-height-s);

      .dot {
        width: var(--alg-font-size-xxs);
        height: var(--alg-font-size-xxs);
      }
    }

    &.size-s {
      font-size: var(--alg-font-size-s);
      line-height: var(--alg-line-height-s);

      .dot {
        width: var(--alg-font-size-s);
        height: var(--alg-font-size-s);
      }
    }

    &.size-m {
      font-size: var(--alg-font-size-m);
      line-height: var(--alg-line-height-m);

      .dot {
        width: var(--alg-font-size-m);
        height: var(--alg-font-size-m);
      }
    }

    &.size-l {
      font-size: var(--alg-font-size-l);
      line-height: var(--alg-line-height-l);

      .dot {
        width: var(--alg-font-size-l);
        height: var(--alg-font-size-l);
      }
    }

    .dot {
      border-radius: 50%;

      &.bordered {
        border: 1px solid var(--alg-color-surface-border);
      }
    }
  }
</style>
