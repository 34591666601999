import { computed, MaybeRef, Ref, toRef } from 'vue'

import { DotNestedKeys, Maybe } from '../types'
import { orderByKey } from '../utils'

export type OrderByKeyOrder = Maybe<'asc' | 'desc'>

export type OrderByKeyKey<T> = Maybe<keyof T>

export interface OrderByKeyReturn<T> {
  data: Ref<T[]>
  key: Ref<OrderByKeyKey<T>>
  order: Ref<OrderByKeyOrder>
}

export interface OrderByKeyOptions<T> {
  data: MaybeRef<T[]>
  key: MaybeRef<OrderByKeyKey<T>>
  order: MaybeRef<OrderByKeyOrder>
}

export function useOrderByKey<T>(options: OrderByKeyOptions<T>): OrderByKeyReturn<T> {
  const _data = toRef(options.data)
  const _key = toRef(options.key)
  const order = toRef(options.order)

  const key = computed<OrderByKeyKey<T>>({
    get() {
      return _key.value as OrderByKeyKey<T>
    },
    set(v) {
      _key.value = v
    },
  })

  const data = computed<T[]>({
    get() {
      if (order.value === undefined)
        return _data.value as T[]

      return orderByKey<T>(
        _data.value as T[],
        _key.value as DotNestedKeys<T>,
        order.value,
      )
    },
    set(v) {
      _data.value = v
    },
  })

  return {
    // Mutable state
    data,
    key,
    order,
  }
}
