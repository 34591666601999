import { computed, nextTick, onMounted, type Ref, ref, watch } from 'vue'

import { checkOverflow } from '../utils'

import { useResizeObserver } from './useResizeObserver'

export function useIsOverflowing(
  element: Ref<HTMLElement | undefined>,
  content: Ref<string>,
) {
  const isOverflowing = ref<boolean>(false)

  const { disconnect: disconnectResizeObserver } = useResizeObserver(
    computed(() => element.value?.parentNode as HTMLElement),
    handleCheckOverflow,
  )

  watch(content, () => nextTick(handleCheckOverflow), { immediate: true })

  function handleCheckOverflow() {
    return isOverflowing.value = checkOverflow(
      element.value,
      true,
      0,
    )
  }

  onMounted(() => {
    handleCheckOverflow()
    return () => {
      disconnectResizeObserver()
    }
  })

  return isOverflowing
}
