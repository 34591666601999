<script setup lang="ts" generic="T extends string | number, V extends Nullable<T> | T[]">
  import { computed, ref, useSlots } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { FloatingPlacement, Nullable, useIsMobile } from '@algorh/shared'

  import { AlgDropdownMenu, DropdownMenuOption } from '../../../dropdown'
  import { AlgErrors } from '../../../feedback'
  import { AlgIcon } from '../../../media'
  import { AlgPopper } from '../../../popover'
  import { AlgLabel } from '../../label'

  import { SelectSize, SelectVariant } from './Select.type'

  type Props = {
    readonly id: string
    readonly options: DropdownMenuOption<Nullable<T>>[]
    readonly label?: string
    readonly sublabel?: string
    readonly placeholder?: string
    readonly noneLabel?: string
    readonly size?: SelectSize
    readonly variant?: SelectVariant
    readonly inline?: boolean
    readonly required?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    readonly centered?: boolean
    readonly searchable?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
    readonly placement?: FloatingPlacement
  }

  defineOptions({
    name: 'AlgSelect',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 'm',
    variant: 'primary',
    inline: false,
    required: false,
    disabled: false,
    readonly: false,
    centered: false,
    searchable: false,
    errored: false,
    placement: () => 'bottom-start',
  })

  const model = defineModel<V>({ required: true })

  // Composables
  const { t } = useI18n()

  const isMobile = useIsMobile()

  const slots = useSlots()

  // Refs
  const input = ref<Nullable<HTMLDivElement>>(null)

  const query = ref('')

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  const filteredOptions = computed<typeof props.options>(() => {
    const options = [...props.options]

    if (!props.required && !Array.isArray(model.value)) {
      options.unshift({
        label: props.noneLabel ?? t('common.None (f)'),
        value: null,
      })
    }

    return options.filter((option) => option.label.toLowerCase().includes(query.value.toLowerCase()))
  })

  const computedLabel = computed(() => {
    if (Array.isArray(model.value)) {
      const array = model.value
      if (array.length === 0) {
        return null
      }
      const firstOptionLabel = props.options.find((o) => o.value === array[0])?.label ?? ''
      return array.length > 1 ? `${firstOptionLabel} ${t('common.+ {n} other', { n: `${array.length - 1}` }, array.length - 1)}` : firstOptionLabel
    } else {
      const value = model.value

      return props.options.find((o) => o.value === value)?.label ?? null
    }
  })

  // Methods
  function handleSearch(e: Event) {
    query.value = (e.target as HTMLInputElement).value
  }

  function handleClearSearch() {
    query.value = ''
  }

  function handleFocus(open: () => void) {
    if (props.disabled) {
      return
    }

    open()
  }

  function handleToggle(toggle: () => void) {
    if (props.disabled) {
      return
    }

    toggle()
  }

  function handleSelect(value: typeof model.value, close: () => void) {
    model.value = value
    if (Array.isArray(model.value)) {
      handleClearSearch()
    } else {
      handleClearSearch()
      close()
    }
  }

  function handlePopperToggle(isOpen: boolean, toggle: () => void) {
    toggle()

    if (!isMobile.value && !isOpen && props.searchable && input.value) {
      input.value.focus()
    }
  }

  function handlePopperClose() {
    handleClearSearch()
  }
</script>

<template>
  <div
    class="field-wrapper"
    :class="{ inline: props.inline }"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :html-for="props.id"
      :inline="props.inline"
      :input-size="props.size"
      :required="props.required"
      :errored="hasErrors"
    />
    <div class="field-content">
      <div
        class="input-wrapper"
        :class="{ disabled: props.disabled, [props.variant]: true }"
      >
        <AlgPopper
          :placement="placement"
          strategy="absolute"
          full-width
          @close="handlePopperClose"
        >
          <template #reference="{ isOpen, open, toggle }">
            <input
              v-if="props.searchable"
              :id="props.id"
              ref="input"
              class="input"
              :class="[`size-${props.size}`, { errored: hasErrors, centered: props.centered }]"
              :name="props.id"
              autocomplete="off"
              :placeholder="isOpen ? t('common.Search') : props.placeholder ?? t('common.Select')"
              :disabled="props.disabled"
              :readonly="props.readonly"
              :value="isOpen ? query : computedLabel"
              @focus="() => handleFocus(open)"
              @input="(e) => handleSearch(e)"
            >
            <button
              v-else
              type="button"
              class="input fake-input"
              :class="[`size-${props.size}`, { errored: hasErrors, centered: props.centered }]"
              :disabled="props.disabled"
              @click="() => handleToggle(toggle)"
            >
              <span
                v-if="computedLabel"
                class="label"
              >
                {{ computedLabel }}
              </span>
              <span
                v-else
                class="placeholder"
              >
                {{ props.placeholder ?? t('common.Select') }}
              </span>
            </button>
            <span
              v-if="isOpen && query"
              class="clear-search"
            >
              <button
                type="button"
                :title="t('common.Clear')"
                @click="handleClearSearch"
              >
                <AlgIcon
                  name="cancel"
                  size="s"
                />
              </button>
            </span>
            <span class="select-arrow">
              <button
                type="button"
                :disabled="props.disabled"
                @click="() => handlePopperToggle(isOpen, toggle)"
              >
                <AlgIcon :name="isOpen ? 'expand-less' : 'expand-more'" />
              </button>
            </span>
          </template>
          <template #content="{ close }">
            <AlgDropdownMenu
              v-if="filteredOptions.length"
              :id="`${props.id}-dropdown`"
              :options="filteredOptions"
              :model-value="model"
              @update:model-value="(value) => handleSelect(value, close)"
            >
              <template
                v-if="slots.option"
                #option="{ option, active }"
              >
                <slot
                  name="option"
                  :option="option"
                  :active="active"
                />
              </template>
            </AlgDropdownMenu>
            <div
              v-else
              class="popper-content-wrapper"
            >
              <p class="no-results-message">
                {{ query ? t('common.No results') : t('common.No options available') }}
              </p>
            </div>
          </template>
        </AlgPopper>
      </div>
      <AlgErrors
        v-if="props.errors && props.errors.length"
        :errors="props.errors"
      />
    </div>
  </div>
</template>

<style src="../index.css" scoped />

<style scoped>
  .field-wrapper {
    .field-content {
      .input-wrapper {
        &.secondary {
          .input {
            border: 1px solid var(--alg-color-surface-secondary);
            background-color: var(--alg-color-surface-secondary);
          }

          .select-arrow {
            button {
              background-color: var(--alg-color-surface-secondary);
              color: var(--alg-color-text-primary);
            }
          }
        }

        .input {
          padding-right: calc(var(--alg-spacing-xl) + var(--alg-spacing-s));
        }

        .clear-search {
          position: absolute;
          top: 50%;
          right: calc(var(--alg-spacing-xl) + var(--alg-spacing-s));
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(-50%);

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            color: var(--alg-color-icon-unselected);

            &:hover {
              color: var(--alg-color-icon-secondary);
            }
          }
        }

        .select-arrow {
          position: absolute;
          top: 50%;
          right: var(--alg-spacing-s);
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(-50%);

          button {
            width: 24px;
            height: 24px;
            padding: 0;
            border-radius: var(--alg-effect-radius-m);
            background-color: var(--alg-color-button-primary);
            color: var(--alg-color-text-on-color);

            &:focus-visible {
              background-color: var(--alg-color-button-primary-hover);
              outline: none;
            }
          }
        }

        &.disabled {
          .select-arrow {
            button {
              background-color: var(--alg-color-button-primary-disabled);
            }
          }

          .input {
            .label {
              color: var(--alg-color-text-light);
            }
          }
        }
      }
    }

    .popper-content-wrapper {
      box-sizing: border-box;
      padding: var(--alg-spacing-xs);
      border: 1px solid var(--alg-color-surface-border);
      border-radius: var(--alg-effect-radius-m);
      background-color: var(--alg-color-surface-primary);
      box-shadow: var(--alg-effect-shadow-m);

      .no-results-message {
        padding: var(--alg-spacing-s);
        margin: 0;
        color: var(--alg-color-text-secondary);
        font-size: var(--alg-font-size-s);
        text-align: center;
      }
    }
  }
</style>
