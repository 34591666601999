import { fetchJson } from '@algorh/shared'

import { Notification, User } from '../types/Me'
import { OperationalSubdivision, OperationalSubdivisionNamesTree } from '../types/OperationalSubdivision'

import { PutPasswordDto } from './me.service.type'

const BASE_URI = '/api/me'

const MeApiService = {
  getMe() {
    return fetchJson<User>(
      'GET', BASE_URI,
    )
  },
  putPassword(dto: PutPasswordDto) {
    return fetchJson(
      'PUT', `${BASE_URI}/password`, dto,
    )
  },
  getSubdivisions() {
    return fetchJson<OperationalSubdivision[]>(
      'GET', `${BASE_URI}/operational-subdivisions`,
    )
  },
  getSubdivisionsNamesTree() {
    return fetchJson<OperationalSubdivisionNamesTree[]>(
      'GET', `${BASE_URI}/operational-subdivisions/tree`,
    )
  },
  getScopedSubdivisionsNamesTree(subdivisionId: number) {
    return fetchJson<OperationalSubdivisionNamesTree[]>(
      'GET', `${BASE_URI}/operational-subdivisions/${subdivisionId}/tree`,
    )
  },
  getEffectiveSubordinateSubdivisionsInLineage() {
    return fetchJson<OperationalSubdivision[]>(
      'GET', `${BASE_URI}/subordinate-subdivisions/effectives-in-lineage`,
    )
  },
  // Notifications
  getNotifications() {
    return fetchJson<Notification[]>(
      'GET', `${BASE_URI}/notifications`,
    )
  },
  putNotificationsAsRead(notification_ids: string[]) {
    return fetchJson(
      'PUT', `${BASE_URI}/notifications/mark-as-read`, { notification_ids },
    )
  },
  deleteNotifications(notification_ids: string[]) {
    return fetchJson(
      'DELETE', `${BASE_URI}/notifications`, { notification_ids },
    )
  },
}

export { MeApiService }
