<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgButton } from '../button'

  type Props = {
    readonly count?: number
  }

  defineOptions({
    name: 'AlgSelectionChip',
  })

  const props = withDefaults(defineProps<Props>(), {
    count: 0,
  })

  const emit = defineEmits<{
    (e: 'cancel', p: MouseEvent): void
  }>()

  const { t } = useI18n()
</script>

<template>
  <div class="selection-chip">
    <div class="selection-chip-label">
      {{ t('common.{n} items selected', { n: props.count }) }}
    </div>
    <div class="selection-chip-clear">
      <AlgButton
        :label="t('common.Cancel selection')"
        variant="link"
        size="s"
        :disabled="props.count === 0"
        @click="(e)=> emit('cancel', e)"
      />
    </div>
  </div>
</template>

<style scoped>
.selection-chip {
  display: flex;
  width: max-content;
  height: var(--alg-spacing-xl);
  align-items: center;
  padding: 0 var(--alg-spacing-m);
  border-radius: var(--alg-effect-radius-xl);
  background-color: var(--alg-color-light-grey);
  gap: var(--alg-spacing-m);

  .selection-chip-label {
    flex: 1 1 auto;
    font-size: var(--alg-font-size-m);
    font-weight: var(--alg-font-weight-bold);
  }
}
</style>
