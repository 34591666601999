import { DateTimeString } from '@algorh/shared'

import { InternalTaskInstance } from '@/core/types/InternalTask'

enum ScheduleType {
  WORKING_SCHEDULE = 'working_schedule',
  USER_DEFINED_SCHEDULE = 'user_defined_schedule',
  PREDEFINED_SCHEDULE = 'predefined_schedule',
  FINAL_SCHEDULE = 'final_schedule',
}

interface ActivitySlot {
  project_activity_id: number | null
  datetime: DateTimeString
  duration: number
  editable: boolean
  isLoading: boolean
  hasWarning: boolean
  hasError: boolean
  nonWorkedSlot: boolean
  lunchBreak: boolean
  forbidden: boolean
  internalTask: InternalTaskInstance | null
  real_start: DateTimeString | null
  real_end: DateTimeString | null
}

interface DateTimeRisk {
  datetime: DateTimeString
  value: number
}

export { ScheduleType, ActivitySlot, DateTimeRisk }
