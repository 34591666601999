<script setup lang="ts">
  import { computed, onMounted } from 'vue'

  import { ICON_NAMES } from '#/constants'
  import { IconName, IconSize } from '#/types'
  import { calcIconSize } from '#/utils'

  import SvgIconSprites from '../../../../public/assets/icons/sprites.svg?raw'

  import { IconBadgeState } from './Icon.types'

  defineOptions({
    name: 'AlgIcon',
  })

  const props = withDefaults(defineProps<Props>(), {
    name: 'block',
    size: 'm',
    color: 'currentColor',
    flipH: false,
    flipV: false,
    rotate: 0,
    alt: '',
    badge: false,
    badgeState: 'info',
  })

  interface Props {
    name: IconName
    size?: IconSize
    color?: string
    flipH?: boolean
    flipV?: boolean
    rotate?: number
    alt?: string
    badge?: boolean
    badgeState?: IconBadgeState
  }

  const wrapperStyle = computed(() => {
    const size = calcIconSize(props.size)

    return {
      width: size,
      height: size,
    }
  })

  const iconStyle = computed(() => {
    const color = props.color

    const rotate = `rotate(${props.rotate}deg)`
    const flipH = `scaleX(${props.flipH ? -1 : 1})`
    const flipV = `scaleY(${props.flipV ? -1 : 1})`

    return {
      color: color,
      fill: color,
      transform: `${rotate} ${flipH} ${flipV}`,
    }
  })

  const badgeColor = computed(() => {
    switch (props.badgeState) {
    case 'info':
      return 'var(--alg-color-state-info)'
    case 'success':
      return 'var(--alg-color-state-success)'
    case 'warning':
      return 'var(--alg-color-state-warning)'
    case 'danger':
      return 'var(--alg-color-state-danger)'
    default:
      return ''
    }
  })

  onMounted(() => {
    if (!document.body.querySelector('#icon-sprites')) {
      document.body.insertAdjacentHTML('beforeend', SvgIconSprites)
    }
  })
</script>

<template>
  <span
    class="wrapper"
    :style="wrapperStyle"
  >
    <svg
      :fill="props.color"
      class="icon"
      :aria-label="props.alt.length ? props.alt : undefined"
      :aria-hidden="!props.alt.length"
      role="graphics-symbol"
      focusable="false"
      :data-name="name"
      :style="iconStyle"
    >
      <use
        v-if="ICON_NAMES.includes(props.name)"
        :href="`#icon-${props.name}`"
      />
      <use
        v-else
        href="#icon-block"
      />
    </svg>
    <span
      v-if="props.badge"
      class="badge"
      :style="{
        backgroundColor: badgeColor
      }"
    />
  </span>
</template>

<style scoped>
  .wrapper {
    position: relative;
    display: inline-block;

    .icon {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      transition-duration: var(--alg-transition-colors-duration);
      transition-property: var(--alg-transition-colors-property);
      transition-timing-function: var(--alg-transition-colors-timing-function);
    }

    .badge {
      position: absolute;
      top: 0;
      right: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--alg-color-button-primary);
    }
  }
</style>
