<script setup lang="ts">
  type Props = {
    label: string
  }

  defineOptions({
    name: 'AlgDataGridItem',
  })

  const props = defineProps<Props>()
</script>

<template>
  <div class="data-grid-item">
    <div class="data-grid-item-label">
      {{ props.label }}
    </div>
    <div class="data-grid-item-content">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.data-grid-item {
  display: flex;
  flex-direction: column;
  gap: var(--alg-spacing-m);

  .data-grid-item-label {
    color: var(--alg-color-text-secondary);
    font-size: var(--alg-font-size-s);
  }

  .data-grid-item-content {
    color: var(--alg-color-text-primary);
    font-size: var(--alg-font-size-m);
  }
}
</style>
