<script setup lang="ts" generic="T extends string | number | boolean">
  import { computed, ref } from 'vue'

  import { Nullable, useHotkey } from '@algorh/shared'

  import { AlgErrors } from '../../feedback'
  import { AlgLabel } from '../label'

  type Props = {
    readonly id: string
    readonly disabled?: boolean
    readonly label?: string
    readonly sublabel?: string
    readonly required?: boolean
    readonly options: { label: string, value: T }[]
    readonly modelValue?: Nullable<T>
    readonly hiddenLabel?: boolean
    readonly inline?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
  }

  defineOptions({
    name: 'AlgSwitch',
  })

  const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    hiddenLabel: false,
    inline: false,
  })

  const emit = defineEmits<{
    (e: 'input', value: string): void
    (e: 'update:modelValue', value: T): void
  }>()

  const focused = ref<boolean>(false)

  useHotkey('ArrowLeft', () => handleArrowKeyPress('left'))
  useHotkey('ArrowRight', () => handleArrowKeyPress('right'))

  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  function handleChange(e: Event) {
    const { value } = e.target as HTMLInputElement

    let newValue: string | number | boolean

    switch (typeof props.modelValue) {
    case 'boolean':
      newValue = value === 'true'
      break
    case 'number':
      newValue = Number(value)
      break
    case 'string':
    default:
      newValue = value
      break
    }
    emit('update:modelValue', newValue as T)
  }

  function handleArrowKeyPress(direction: 'left' | 'right') {
    if (!focused.value) return
    const currentIndexValue = props.options.findIndex(({ value }) => value === props.modelValue)

    let newValue: { value: any, label?: string }

    switch (direction) {
    case 'left':
      newValue = props.options[currentIndexValue - 1]
    case 'right':
      newValue = props.options[currentIndexValue + 1]
    default:
      newValue = props.options[currentIndexValue]
    }
    emit('update:modelValue', newValue.value)
  }
</script>

<template>
  <div
    class="switch-text-field-wrapper"
    :class="{ inline }"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :hidden-label="props.hiddenLabel"
      :html-for="options[0] ? `select-with-label-${options[0].value.toString()}` : ''"
      :inline="props.inline"
      :required="props.required"
      :errored="hasErrors"
    />
    <div
      class="switch-text-input-wrapper"
      :class="[{ errored: hasErrors }]"
      tabindex="0"
      @focus="focused = true"
      @blur="focused = false"
    >
      <div
        v-for="option in options"
        :key="String(option.value)"
        class="switch-text-input-inner"
      >
        <input
          :id="`${props.id}-${option.value}`"
          class="sr-only"
          :disabled="props.disabled"
          :name="props.id"
          :value="option.value"
          type="radio"
          :checked="modelValue === option.value"
          tabindex="-1"
          @change="handleChange"
        >
        <label :for="`${props.id}-${option.value}`">{{ option.label }}</label>
      </div>
    </div>
    <AlgErrors
      v-if="props.errors"
      :errors="props.errors"
    />
  </div>
</template>

<style scoped>
  .switch-text-field-wrapper {
    display: flex;
    flex-direction: column;

    &.inline {
      flex-direction: row;
      align-items: center;
      gap: var(--alg-spacing-s);
    }

    .switch-text-input-wrapper {
      position: relative;
      display: flex;
      overflow: hidden;
      flex-direction: row;
      border: 1px solid var(--alg-color-surface-border);
      border-radius: var(--alg-effect-radius-m);
      background-color: var(--alg-color-surface-primary);

      &.errored {
        border-color: var(--alg-color-state-danger);
      }

      &:focus,
      &:focus-visible {
        box-shadow: var(--alg-effect-shadow-m);
        outline: none;
      }

      .switch-text-input-inner + .switch-text-input-inner {
        &::before {
          display: block;
          height: 100%;
          border-left: 1px solid var(--alg-color-surface-border);
          margin-left: -0.5px;
          content: '';
        }
      }

      .switch-text-input-inner {
        display: flex;
        height: 40px;
        flex: 1;
        flex-direction: row;
        color: var(--alg-color-text-primary);
        cursor: pointer;
        font-size: var(--alg-font-size-m);
        font-weight: var(--alg-font-weight-regular);

        label {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          padding: 0 12px;
          cursor: pointer;
          outline: none;
          text-align: center;
          text-indent: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        input {
          margin: 0;
        }

        input:disabled + label {
          background-color: var(--alg-color-surface-background);
          color: var(--alg-color-text-light);
          cursor: not-allowed;
        }

        input:checked + label {
          background-color: var(--alg-color-button-primary);
          color: var(--alg-color-text-on-color);
        }

        input:checked:disabled + label {
          background-color: var(--alg-color-button-primary-disabled);
          color: var(--alg-color-text-on-color);
        }

      }
    }
  }
</style>
