<template>
  <div class="floating-actions">
    <div
      ref="floating"
      class="actions"
    >
      <slot name="actions" />
    </div>
    <div>
      <slot name="reference" />
    </div>
  </div>
</template>

<style scoped>

.floating-actions {
  position: relative;
  z-index: 10;
  display: flex;

  &:hover {
    .actions {
      display: flex;
      opacity: 1;
      transform: translate(0, -50%);
      visibility: visible;
    }
  }

  .actions {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 100%;
    display: flex;
    flex-direction: row;
    border-radius: var(--alg-effect-radius-m);
    background-color: var(--alg-color-button-primary);
    opacity: 0;
    transform: translate(-4px, -50%) ;
    transition: opacity 0.2s ease-in-out,  visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
    visibility: hidden;

  }
}
</style>
