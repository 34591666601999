<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgIconButton } from '../../icon-button'

  type Props = {
    updateAvailable?: boolean
    members?: { id: number, firstname: string, lastname: string, identifier: string }[]
  }

  defineOptions({
    name: 'AlgInternalTaskParticipantsInformationCard',
  })

  const props = withDefaults(defineProps<Props>(), {
    updateAvailable: false,
    members: () => [],
  })

  const emit = defineEmits<{
    (e: 'update', p: Event): void
  }>()

  const { t } = useI18n()
</script>

<template>
  <div class="internal-task-global-information-card">
    <div class="internal-task-global-information-card-header">
      <span class="internal-task-global-information-card-header-title">
        {{ t('scheduling.List of slot participants') }}
      </span>
      <AlgIconButton
        v-if="updateAvailable"
        id="internal-task-global-information-card-edit"
        icon="stylus"
        variant="transparent"
        :title="t('common.Edit')"
        @click="(e) => emit('update', e)"
      />
    </div>
    <div
      class="internal-task-global-information-card-content-item internal-task-global-information-card-content-leave-request"
    >
      <div class="internal-task-global-information-card-font-title">
        {{ t('scheduling.List of participants') }}
      </div>
      <div class="internal-task-global-information-card-font-summary">
        <ol
          v-if="props.members.length"
          class="internal-task-global-information-card-participant-list"
        >
          <li
            v-for="member in props.members"
            :key="member.id"
            class="internal-task-global-information-card-participant-list-item"
          >
            <span
              class="internal-task-global-information-card-participant-list-item-lastname"
            >
              {{ member.lastname }}
            </span>
            <span
              class="internal-task-global-information-card-participant-list-item-firstname"
            >
              {{ member.firstname }}
            </span>
            <span class="internal-task-global-information-card-participant-list-item-id">
              {{ member.identifier }}
            </span>
          </li>
        </ol>
        <template v-else>
          {{ t('scheduling.No participant') }}
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .internal-task-global-information-card {
    position: relative;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-effect-radius-m);
    padding-block: var(--alg-spacing-m) var(--alg-spacing-m);
    padding-inline: var(--alg-spacing-l);

    .internal-task-global-information-card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--alg-color-button-primary);
      font-size: var(--alg-font-size-xxs);
      font-weight: var(--alg-font-weight-bold);
      gap: var(--alg-spacing-s);
      padding-block-end: calc(var(--alg-spacing-l) / 2);
      text-transform: uppercase;
    }

    .internal-task-global-information-card-header-title {
      flex: 1;
    }

    .internal-task-global-information-card-font-title {
      color: var(--alg-color-text-primary);
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-bold);
    }

    .internal-task-global-information-card-font-summary {
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-regular);
      line-height: var(--alg-font-line-height);
    }

    .internal-task-global-information-card-content-item {
      display: flex;
      flex-direction: column;
      gap: var(--alg-spacing-xs);
    }

    .internal-task-global-information-card-participant-list {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 0;
      padding-block-start: var(--alg-spacing-xs);
    }

    .internal-task-global-information-card-participant-list-item {
      display: flex;
      flex-direction: row;
      gap: var(--alg-spacing-s);
      line-height: var(--alg-font-line-height);

      &::before {
        content: '\25CF';
        margin-inline: var(--alg-spacing-xs);
      }
    }

    .internal-task-global-information-card-participant-list-item-id {
      margin-left: auto;
    }
  }
</style>
