type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`

type DotNestedKeys<T> = (
  T extends object
    ? { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}` }[Exclude<
        keyof T,
        symbol
      >]
    : ''
) extends infer D
  ? Extract<D, string>
  : never

function getNestedValue<T>(object: T, path: DotNestedKeys<T>) {
  const keys = (path as string).split('.')
  let result = object
  for (const key of keys) {
    result = (result as unknown as Record<string, unknown>)[key] as T
  }
  return result
}

export type { DotPrefix, DotNestedKeys }
export { getNestedValue }
