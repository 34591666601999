<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable, sanitizeSearch } from '@algorh/shared'

  import { AlgButton } from '../../button'
  import { AlgCounter } from '../../feedback'
  import { AlgRadio, AlgSearchInput } from '../../form'
  import { AlgIcon } from '../../media'
  import { AlgPopper } from '../../popover'

  type Option = { label: string, value: string | number | boolean, groupId?: number }
  type OptionGroup = { id: number, label: string }

  type Props = {
    id: string
    label: string
    modelValue?: Nullable<string | number | boolean>
    options: Option[]
    optionGroups?: OptionGroup[]
    searchable?: boolean
    placeholder?: string
    color?: string
    readonly?: boolean
    disabled?: boolean
  }

  defineOptions({
    name: 'AlgSelectFilter',
  })

  const props = withDefaults(defineProps<Props>(), {
    searchable: true,
    readonly: false,
    disabled: false,
    modelValue: null,
  })

  const emit = defineEmits<{
    (e: 'update:model-value', p: Nullable<string | number | boolean>): void
  }>()

  // Composables
  const { t } = useI18n()

  // Refs
  const search = ref<Nullable<string>>(null)

  // Computed
  const filteredOptions = computed(() => {
    if (!props.searchable || !search.value) {
      return props.options
    }

    return sanitizeSearch(search.value, props.options, 'label')
  })

  const groupedOptions = computed(() => {
    const groups: Record<number | string, Option[]> = {}

    filteredOptions.value.forEach((option) => {
      const key = option.groupId || 'others'

      if (!groups[key]) {
        groups[key] = []
      }

      groups[key].push(option)
    })

    return groups
  })

  // Methods
  function handleToggle(toggle: () => void) {
    if (props.disabled) {
      return
    }

    toggle()
  }

  function handleSearch(value: Nullable<string>) {
    search.value = value
  }

  function handleToggleOption(value: Nullable<string | number | boolean>) {
    emit('update:model-value', value)
  }

  function handlePopperClosed() {
    search.value = null
  }
</script>

<template>
  <div class="select-filter">
    <AlgPopper
      class="select-filter-popper"
      placement="bottom"
      full-width
      @closed="handlePopperClosed"
    >
      <template #reference="{ isOpen, toggle }">
        <button
          type="button"
          class="select-filter-reference"
          :class="{
            open: isOpen,
            disabled: props.disabled
          }"
          @click="() => handleToggle(toggle)"
        >
          <span class="label-wrapper">
            <span class="label">
              {{ props.label }}
            </span>
            <AlgCounter
              v-if="props.modelValue !== null"
              :value="1"
              size="xs"
              :color="props.color"
            />
          </span>
          <AlgIcon
            size="s"
            :name="isOpen ? 'expand-less' : 'expand-more'"
            :color="props.disabled ? 'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-primary)'"
          />
        </button>
      </template>
      <template #content>
        <div
          class="select-filter-content"
        >
          <AlgSearchInput
            v-if="props.searchable && !props.readonly"
            :id="`select-search-input-${props.id}`"
            :placeholder="props.placeholder || t('common.Search')"
            :debounced="false"
            :model-value="search"
            size="s"
            @update:model-value="handleSearch"
          />
          <div
            v-if="filteredOptions.length"
            class="select-list"
          >
            <template v-if="props.optionGroups">
              <template
                v-for="(group, k) in groupedOptions"
                :key="k"
              >
                <div class="group-label">
                  {{ props.optionGroups.find(({id}) => id === k)?.label || t('common.Other', 2) }}
                </div>
                <AlgRadio
                  v-for="(option, l) in group"
                  :id="`select-checkbox-group-option-${props.id}-${k}-${l}`"
                  :key="l"
                  :name="`select-checkbox-group-${props.id}`"
                  :value="option.value"
                  :label="option.label"
                  :inactive="props.readonly"
                  :model-value="props.modelValue"
                  @update:model-value="handleToggleOption"
                />
              </template>
            </template>
            <template v-else>
              <AlgRadio
                v-for="(option, k) in filteredOptions"
                :id="`select-checkbox-group-option-${props.id}-${k}`"
                :key="k"
                :name="`select-checkbox-group-${props.id}`"
                :value="option.value"
                :label="option.label"
                :inactive="props.readonly"
                :model-value="props.modelValue"
                @update:model-value="handleToggleOption"
              />
            </template>
          </div>
          <p
            v-else
            class="no-results"
          >
            {{ t('common.No results') }}
          </p>
          <AlgButton
            v-if="!props.readonly && props.modelValue !== null"
            class="action-button"
            size="s"
            variant="link"
            :label="t('common.Clear selection')"
            @click="() => emit('update:model-value', null)"
          />
        </div>
      </template>
    </AlgPopper>
  </div>
</template>

<style scoped>
.select-filter {
  position: relative;

  .select-filter-reference {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    padding: var(--alg-spacing-s) 0;
    border-bottom: 1px solid var(--alg-color-surface-border);
    cursor: pointer;
    user-select: none;

    .label-wrapper {
      display: flex;
      align-items: center;
      gap: var(--alg-spacing-xs);

      .label {
        color: var(--alg-color-text-primary);
        font-weight: var(--alg-font-weight-bold);
      }
    }

    &.open {
      border-bottom-color: var(--alg-color-text-secondary);
    }

    &.disabled {
      cursor: not-allowed;

      .label {
        color: var(--alg-color-text-light);
      }
    }
  }

  .select-filter-content {
    display: flex;
    flex-direction: column;
    padding: var(--alg-spacing-s);
    border-radius: 0 0 var(--alg-effect-radius-s) var(--alg-effect-radius-s);
    background-color: var(--alg-color-surface-background);
    gap: var(--alg-spacing-s);

    .select-list {
      display: flex;
      overflow: hidden auto;
      min-height: 40px;
      max-height: 400px;
      flex-direction: column;
      padding-right: var(--alg-spacing-s);
      gap: var(--alg-spacing-xs);

      .group-label {
        position: sticky;
        z-index: 10;
        top: 0;
        padding: var(--alg-spacing-s) 0;
        background-color: var(--alg-color-surface-background);
        font-size: var(--alg-font-size-s);
        font-weight: var(--alg-font-weight-bold);
      }
    }

    .no-results {
      margin: 0;
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-s);
    }

    .action-button {
      align-self: flex-start;
    }
  }
}
</style>
