<script setup lang="ts">
  import { computed } from 'vue'

  import { DateString, dayjs, DTF } from '@algorh/shared'

  import { DAY_LABELS, DAY_SHORT_LABELS, MONTH_LABELS, MONTH_SHORT_LABELS } from '#/constants'
  import { PeriodMode } from '#/types'

  import { AlgIcon } from '../../media'

  interface Period {
    label: string
    title: string
    value: DateString
    displayedValue: string
    selected: ConstrainBoolean
    current: boolean
  }

  interface Props {
    readonly modelValue: DateString
    readonly mode?: PeriodMode
    readonly disabled?: boolean
    readonly sticky?: boolean
    readonly controls?: 'hover' | 'fixed'
    readonly displayedDay?: number
  }

  defineOptions({
    name: 'AlgCircularPeriodSelector',
  })

  const props = withDefaults(defineProps<Props>(), {
    mode: PeriodMode.DAY,
    displayedDay: 7,
  })

  const emit = defineEmits<{
    (e: 'update:modelValue', value: DateString): void
  }>()

  const date = computed(() => dayjs(props.modelValue))
  const DAYS = [1, 2, 3, 4, 5, 6, 0]

  const data = computed(() => {
    switch (props.mode) {
    case 'day':
      return Array.from({ length: props.displayedDay }, (_, i): Period => {
        const day = dayjs(date.value).isoWeekday(1).add(i, 'day')
        const dayNumber = day.date().toString()
        const title = `${DAY_LABELS[i]} ${dayNumber === '1' ? `${dayNumber}er` : dayNumber}`
        return {
          label: DAY_SHORT_LABELS[i],
          title: day.isToday() ? `${title} (Aujourd’hui)` : title,
          value: day.format(DTF.DATE),
          displayedValue: dayNumber,
          selected: dayjs(date.value).date() === day.date(),
          current: day.isToday(),
        }
      })
    case 'week':
      return [
        ...new Map(
          Array.from({ length: dayjs(props.modelValue).daysInMonth() }, (_, i) => {
            const day = dayjs(date.value)
              .set('date', i + 1)
              .set('day', date.value.get('day'))
            const weekNumber = day.isoWeek()
            const selected = date.value.isoWeek() === weekNumber
            const current = dayjs().isoWeek() === weekNumber
            return {
              label: 'Sem',
              title: `Semaine ${weekNumber}`,
              value: day.format(DTF.DATE),
              displayedValue: weekNumber,
              selected,
              current,
            }
          }).map((item) => [item.displayedValue, item]),
        ).values(),
      ]
    case 'month':
      return Array.from({ length: 12 }, (_, i): Period => {
        const day = dayjs(date.value).set('month', i)
        const current = dayjs().month() === day.month()
        return {
          label: '',
          title: MONTH_LABELS[i],
          value: day.format(DTF.DATE) as DateString,
          displayedValue: MONTH_SHORT_LABELS[i],
          selected: day.get('month') === date.value.get('month'),
          current,
        }
      })
    default:
      return []
    }
  })

  function handleNext() {
    const DELTA = 7 - props.displayedDay
    const day = dayjs(date.value)
    switch (props.mode) {
    case PeriodMode.DAY:
    {
      const correctedIndex = DAYS.indexOf(day.day())
      const shouldJumpToMonday = correctedIndex + 1 === props.displayedDay
      emit('update:modelValue', day.add(shouldJumpToMonday ? DELTA + 1 : 1, 'day').format(DTF.DATE))
      break
    }
    case PeriodMode.WEEK:
      emit('update:modelValue', day.add(1, 'week').format(DTF.DATE))
      break
    case PeriodMode.MONTH:
      emit('update:modelValue', dayjs(date.value).add(1, 'month').format(DTF.DATE))
      break
    }
  }

  function handlePrev() {
    const day = dayjs(date.value)
    const DELTA = 7 - props.displayedDay
    switch (props.mode) {
    case PeriodMode.DAY:{
      const correctedIndex = DAYS.indexOf(day.day())
      const shouldJumpToLastDisplayedDay = correctedIndex === 0
      emit('update:modelValue', day.subtract(shouldJumpToLastDisplayedDay ? DELTA + 1 : 1, 'day').format(DTF.DATE))
      break
    }
    case PeriodMode.WEEK:
      emit('update:modelValue', day.subtract(1, 'week').format(DTF.DATE))
      break
    case PeriodMode.MONTH:
      emit('update:modelValue', dayjs(date.value).subtract(1, 'month').format(DTF.DATE))
      break
    }
  }

  function handleClick(value: DateString): void {
    emit('update:modelValue', value)
  }
</script>

<template>
  <div
    class="circular-period-selector"
    :class="{sticky:props.sticky}"
  >
    <button
      v-if="props.controls"
      class="controls"
      :class="{hover:props.controls === 'hover'}"
      :disabled="props.disabled"
      @click="handlePrev"
    >
      <AlgIcon
        size="s"
        name="chevron-left"
      />
    </button>
    <div class="dates">
      <button
        v-for="({ label, title, value, displayedValue, selected, current }, k) in data"
        :key="k"
        class="period"
        type="button"
        :title="title"
        :class="{ selected, current }"
        :disabled="props.disabled"
        @click="() => handleClick(value)"
      >
        <span class="label">
          {{ label }}
        </span>
        <span class="value">
          {{ displayedValue }}
        </span>
        <div class="badge">
          <slot :name="`badge-${dayjs(value).format(DTF.DATE)}`" />
        </div>
      </button>
    </div>
    <button
      v-if="props.controls"
      class="controls"
      :class="{hover:props.controls === 'hover'}"
      :disabled="props.disabled"
      @click="handleNext"
    >
      <AlgIcon
        size="s"
        name="chevron-right"
      />
    </button>
  </div>
</template>

<style scoped>
  .circular-period-selector {
    display: inline-flex;
    align-items: center;

    &.sticky {
      position: sticky;
      z-index: 100;
      bottom: 0;
      height: 64px;
      box-sizing: border-box;
      flex: 0 0 auto;
      align-self: center;
      padding: var(--alg-spacing-s);
      border-radius: 50px;
      margin: var(--alg-spacing-m) 0 var(--alg-spacing-s);
      background-color: var(--alg-color-surface-primary);
      box-shadow: var(--alg-effect-shadow-l);
    }

    .dates {
      display: inline-flex;
      align-items: center;
      gap: var(--alg-spacing-s);
    }

    button.period {
      position: relative;
      display: inline-flex;
      width: 48px;
      height: 48px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--alg-color-surface-border);
      border-radius: 50%;
      background-color: var(--alg-color-surface-secondary);
      cursor: pointer;
      transition-duration: var(--alg-transition-colors-duration);
      transition-property: background-color;
      transition-timing-function: var(--alg-transition-colors-timing-function);

      .label,
      .value {
        font-weight: var(--alg-font-weight-bold);
        transition-duration: var(--alg-transition-colors-duration);
        transition-property: color;
        transition-timing-function: var(--alg-transition-colors-timing-function);
      }

      .label {
        color: var(--alg-color-text-light);
        font-size: var(--alg-font-size-xxs);
        text-transform: uppercase;
      }

      .value {
        color: var(--alg-color-text-secondary);
        font-size: var(--alg-font-size-m);
      }

      &.current {
        .value {
          color: var(--alg-color-text-highlight);
        }
      }

      &.selected {
        background-color: var(--alg-color-button-primary);

        .label,
        .value {
          color: var(--alg-color-text-on-color);
        }
      }

      &:hover,
      &.selected:hover {
        background-color: var(--alg-color-button-primary-hover);

        .label,
        .value {
          color: var(--alg-color-text-on-color);
        }
      }

      &:disabled {
        background-color: var(--alg-color-surface-secondary);
        cursor: not-allowed;

        .label,
        .value {
          color: var(--alg-color-text-light);
        }

        &.selected,
        &.selected:hover {
          background-color: var(--alg-color-button-primary-disabled);

          .label,
          .value {
            color: var(--alg-color-text-on-color);
          }
        }
      }

      .badge {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    button.controls {
      display: flex;
      box-sizing: content-box;
      align-items: center;
      justify-content: center;
      margin: 0 var(--alg-spacing-s);
      color: var(--alg-color-text-secondary);
      transition: width ease-in-out 200ms, padding ease-in-out 200ms, margin ease-in-out 200ms;

      &.hover{
        width:0;
        padding: 0;
        margin: 0;
      }
    }

    &:hover{
      button.controls {
        &.hover{
          width: 16px;
          padding: inherit;
          margin: inherit;
        }
      }
    }
  }
</style>
