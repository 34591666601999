<script setup lang="ts">
  import { computed, ref } from 'vue'

  import { FloatingPlacement, useIsOverflowing } from '@algorh/shared'

  import AlgTooltip from './Tooltip.vue'

  interface Props {
    id: string
    content: string
    placement?: FloatingPlacement
  }

  defineOptions({
    name: 'AlgOverflowTooltip',
  })

  const props = withDefaults(defineProps<Props>(), {
    placement: () => 'top',
  })

  const container = ref<HTMLElement>()

  const isOverflowing = useIsOverflowing(
    container,
    computed(() => props.content),
  )
</script>

<template>
  <AlgTooltip
    v-if="isOverflowing"
    :id="props.id"
    :placement="props.placement"
  >
    <template #reference>
      <span
        ref="container"
        class="overflow-tooltip-content"
        v-html="props.content"
      />
    </template>
    <template #content>
      <span v-html="props.content" />
    </template>
  </AlgTooltip>
  <div
    v-else
    ref="container"
    class="overflow-tooltip-content"
    v-html="props.content "
  />
</template>

<style scoped>
.overflow-tooltip-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
