<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { dayjs, DTF, Nullable } from '@algorh/shared'
  import { AlgBadge, AlgButton, AlgMessage } from '@algorh/ui'

  import { Alert } from '@/sections/users/types'

  interface Props {
    readonly absenceConflicts?: Alert[]
    readonly collectiveAlerts?: Alert[]
    readonly individualAlerts?: Alert[]
    readonly pendingAcceptAlerts?: Record<number, boolean>
    readonly notice?: boolean
    readonly readonly?: boolean
    readonly showStatus?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    notice: true,
    showStatus: true,
    readonly: false,
    absenceConflicts: () => [],
    collectiveAlerts: () => [],
    individualAlerts: () => [],
  })

  const emit = defineEmits<{
    (e: 'acceptCollectiveAlert', alertId: number): void
    (e: 'hover-alert', alert: Nullable<Alert>): void
  }>()

  // Composables
  const { t } = useI18n()

  // Data
  const noticeFirstItem = t(
    'calendar.{0}If you do not accept the collective rule break{1}, then it will not be taken into account during scheduling',
    ['<span class="text-bold">', '</span>'],
  )

  const noticeSecondItem = t(
    'calendar.{0}No individual rule can be broken{1}: if one of the input activity slots breaks an individual rule, then the algorithm will not take into account the activity entered for this slot during arbitration',
    ['<span class="text-bold">', '</span>'],
  )

  // Refs
  const showNotice = ref(true)

  const alertDetails = ref<Record<string, boolean>>({})

  // Computed
  const sortedCollectiveAlerts = computed(() =>
    [...props.collectiveAlerts].sort((a, b) =>
      dayjs(a.datetimes[0]).isAfter(b.datetimes[0]) ? 1 : -1,
    ),
  )

  function handleCloseNotice() {
    showNotice.value = false
  }

  function handleToggleAlertDetails(key: string) {
    if (!alertDetails.value[key]) {
      alertDetails.value[key] = true
    } else {
      alertDetails.value[key] = !alertDetails.value[key]
    }
  }

  function handleAcceptCollectiveAlert(alertId: number) {
    emit('acceptCollectiveAlert', alertId)
  }

  onMounted(() => {
    showNotice.value = props.notice
  })
</script>

<template>
  <div class="broken-rules">
    <AlgMessage
      v-if="showNotice"
      :title="t('common.Important notice')"
      closable
      @close="handleCloseNotice"
    >
      <ul>
        <li v-html="noticeFirstItem" />
        <li v-html="noticeSecondItem" />
      </ul>
    </AlgMessage>
    <AlgMessage
      v-if="individualAlerts.length && showNotice"
      :title="t('calendar.Input not taken into account')"
      :message="
        t(
          'calendar.An individual rule has been broken when you input the data, so the slot in question will not be taken into account during arbitration'
        )
      "
    />
    <div
      v-for="(alert, k) in absenceConflicts"
      :key="k"
      role="button"
      tabindex="0"
      class="alerts"
      @mouseover="() => emit('hover-alert', alert)"
      @mouseleave="() => emit('hover-alert', null)"
      @focus="() => emit('hover-alert', alert)"
      @blur="() => emit('hover-alert', null)"
    >
      <AlgMessage
        variant="danger"
        icon="cancel"
        :title="t('calendar.Absence conflict')"
      >
        <div class="alert-content">
          {{
            alert.datetimes.length === 1
              ? t('calendar.The slot on {date} at {time} is breaking an individual rule:', {
                date: dayjs(alert.datetimes[0]).format(DTF.LITERAL_DATE),
                time: dayjs(alert.datetimes[0]).format(DTF.TIME_SHORT)
              })
              : t('calendar.{n} slots on {date} are breaking an individual rule:', {
                n: alert.datetimes.length,
                date: dayjs(alert.datetimes[0]).format(DTF.LITERAL_DATE)
              })
          }}
          <p class="rule">
            {{ alert.rule }}
          </p>
        </div>
      </AlgMessage>
    </div>
    <div
      v-for="(alert, k) in individualAlerts"
      :key="k"
      role="button"
      tabindex="0"
      class="alerts"
      @mouseover="() => emit('hover-alert', alert)"
      @mouseleave="() => emit('hover-alert', null)"
      @focus="() => emit('hover-alert', alert)"
      @blur="() => emit('hover-alert', null)"
    >
      <AlgMessage
        variant="danger"
        icon="cancel"
        :title="t('calendar.Individual rule')"
      >
        <div class="alert-content">
          {{
            alert.datetimes.length === 1
              ? t('calendar.The slot on {date} at {time} is breaking an individual rule:', {
                date: dayjs(alert.datetimes[0]).format(DTF.LITERAL_DATE),
                time: dayjs(alert.datetimes[0]).format(DTF.TIME_SHORT)
              })
              : t('calendar.{n} slots on {date} are breaking an individual rule:', {
                n: alert.datetimes.length,
                date: dayjs(alert.datetimes[0]).format(DTF.LITERAL_DATE)
              })
          }}
          <p class="rule">
            {{ alert.rule }}
          </p>
          <div class="alert-footer">
            <div class="actions-wrapper">
              <AlgButton
                v-if="alert.datetimes.length > 0"
                variant="link"
                :label="t('common.Show detail')"
                :icon-end="alertDetails[`individual-${k}`] ? 'expand-less' : 'expand-more'"
                @click="() => handleToggleAlertDetails(`individual-${k}`)"
              />
            </div>
            <p
              v-if="alertDetails[`individual-${k}`]"
              class="alert-details"
            >
              {{
                alert.datetimes.map((datetime) => dayjs(datetime).format(DTF.TIME_SHORT)).join(', ')
              }}
            </p>
          </div>
        </div>
      </AlgMessage>
    </div>
    <div
      v-for="(alert, k) in sortedCollectiveAlerts"
      :key="k"
      role="button"
      tabindex="0"
      class="alerts"
      @mouseover="() => emit('hover-alert', alert)"
      @mouseleave="() => emit('hover-alert', null)"
      @focus="() => emit('hover-alert', alert)"
      @blur="() => emit('hover-alert', null)"
    >
      <AlgMessage
        :variant="alert.accepted ? 'secondary' : 'warning'"
        icon="warning"
        :title="t('calendar.Collective rule')"
      >
        <div class="alert-content">
          {{
            alert.datetimes.length === 1
              ? t('calendar.The slot on {date} at {time} is breaking a collective rule:', {
                date: dayjs(alert.datetimes[0]).format(DTF.LITERAL_DATE),
                time: dayjs(alert.datetimes[0]).format(DTF.TIME_SHORT)
              })
              : t('calendar.{n} slots on {date} are breaking a collective rule:', {
                n: alert.datetimes.length,
                date: dayjs(alert.datetimes[0]).format(DTF.LITERAL_DATE)
              })
          }}
          <p class="rule">
            {{ alert.rule }}
          </p>
          <div class="alert-footer">
            <div class="actions-wrapper">
              <AlgButton
                v-if="alert.datetimes.length > 0"
                variant="link"
                :label="t('common.Show detail')"
                :icon-end="alertDetails[`collective-${k}`] ? 'expand-less' : 'expand-more'"
                @click="() => handleToggleAlertDetails(`collective-${k}`)"
              />
              <div
                v-if="showStatus"
                class="action"
              >
                <AlgBadge
                  v-if="alert.accepted"
                  variant="success"
                  size="m"
                  :label="t('common.Accepted (m)')"
                />
                <template v-else>
                  <AlgBadge
                    v-if="props.readonly"
                    variant="danger"
                    size="m"
                    :label="t('common.Not accepted (m)')"
                  />
                  <AlgButton
                    v-else
                    variant="secondary"
                    size="s"
                    :label="t('common.Accept')"
                    :loading="pendingAcceptAlerts && pendingAcceptAlerts[alert.id]"
                    @click="() => handleAcceptCollectiveAlert(alert.id)"
                  />
                </template>
              </div>
            </div>
            <p
              v-if="alertDetails[`collective-${k}`]"
              class="alert-details"
            >
              {{
                alert.datetimes.map((datetime) => dayjs(datetime).format(DTF.TIME_SHORT)).join(', ')
              }}
            </p>
          </div>
        </div>
      </AlgMessage>
    </div>
  </div>
</template>

<style scoped>
  .broken-rules {
    display: flex;
    flex-direction: column;
    gap: var(--alg-spacing-s);

    ul > li {
      margin-bottom: var(--alg-spacing-s);
    }

    .alerts {
      .alert-content {
        display: flex;
        flex-direction: column;
        gap: var(--alg-spacing-s);

        .alert-footer {
          display: flex;
          flex-direction: column;

          .actions-wrapper {
            display: flex;
            align-items: flex-end;

            .action {
              margin-left: auto;
              justify-self: flex-end;
            }
          }

          .alert-details {
            margin-top: var(--alg-spacing-s);
            line-height: var(--alg-font-line-height);
          }
        }
      }
    }
  }
</style>
