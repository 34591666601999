<script lang="ts" setup>
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { FetchErrorObj, Nullable } from '@algorh/shared'
  import { AlgSelect } from '@algorh/ui'

  import { RuleRelation, RuleRelationTiming } from '@/core/enums/Rule'

  import { RuleDto } from '@/sections/settings/services'

  import RuleSubjectSelect from '../../inputs/RuleSubjectSelect.vue'

  type Model = Pick<RuleDto, 'subject_id' | 'subject_type' | 'other_type' | 'other_id' | 'config'>
  type Props = {
    readonly errors: Nullable<FetchErrorObj<Model>>
    readonly activityFamilies: { id: number, name: string }[]
    readonly activities: { id: number, name: string }[]
  }

  const props = defineProps<Props>()

  const model = defineModel<Model>({ required: true })

  const subject = computed(() => ({
    type: model.value.subject_type,
    id: model.value.subject_id,
  }))

  const other = computed(() => ({
    type: model.value.other_type,
    id: model.value.other_id,
  }))

  const { t } = useI18n()

  const relationOptions = Object.values(RuleRelation).map((value) => ({
    value,
    label: t(`rules.parts.${value}`),
  }))

  const relationTimingOptions = Object.values(RuleRelationTiming).map((value) => ({
    value,
    label: t(`rules.parts.relation-timing.${value}`),
  }))

  function handleUpdateRelation(relation: Nullable<RuleRelation>) {
    model.value = { ...model.value, config: model.value.config
      ? { ...model.value.config, relation: relation ?? undefined }
      : null,
    }
  }

  function handleUpdateRelationTiming(relation_timing: Nullable<RuleRelationTiming>) {
    model.value = { ...model.value, config: model.value.config
      ? { ...model.value.config, relation_timing: relation_timing ?? undefined }
      : null,
    }
  }

  function handleSubjectUpdate(s: typeof subject.value) {
    model.value = {
      ...model.value,
      subject_type: s.type,
      subject_id: s.id,
    }
  }

  function handleOtherUpdate(o: typeof other.value) {
    model.value = {
      ...model.value,
      other_type: o.type,
      other_id: o.id,
    }
  }

</script>

<template>
  <div class="rule-form">
    <RuleSubjectSelect
      name="subject"
      class="choice-fields has-counter"
      nullable
      :activity-families="props.activityFamilies"
      :activities="props.activities"
      :errors="errors?.errors?.subject_id || errors?.errors?.subject_type"
      :model-value="subject"
      @update:model-value="handleSubjectUpdate"
    />
    <AlgSelect
      id="config-relation"
      class="choice-fields has-counter"
      centered
      required
      :options="relationOptions"
      :model-value="model.config?.relation ?? null"
      :errors="errors?.errors?.['config.relation']"
      @update:model-value="handleUpdateRelation"
    />
    <AlgSelect
      id="config-relation-timing"
      class="choice-fields has-counter"
      centered
      required
      :options="relationTimingOptions"
      :errors="errors?.errors?.['config.relation_timing']"
      :model-value="model.config?.relation_timing ?? null"
      @update:model-value="handleUpdateRelationTiming"
    />
    <RuleSubjectSelect
      name="other"
      class="choice-fields has-counter"
      nullable
      :activity-families="props.activityFamilies"
      :activities="props.activities"
      :errors="errors?.errors?.other_id || errors?.errors?.other_type"
      :model-value="other"
      @update:model-value="handleOtherUpdate"
    />
  </div>
</template>

<style src="@/components/RuleAmbition/rule/forms/ruleForms.css" />
