import { fetchJson } from '@algorh/shared'

import { Config } from '../types/Config'
import { OperationalSubdivision, OperationalSubdivisionNamesTree } from '../types/OperationalSubdivision'
import { ProjectActivity } from '../types/Project'
import { Role } from '../types/User'

const BASE_URI = '/api/common'

const CommonApiService = {
  getConfig() {
    return fetchJson<Config>('GET', `${BASE_URI}/config`)
  },
  getProjectActivities() {
    return fetchJson<ProjectActivity[]>('GET', `${BASE_URI}/project-activities`)
  },
  getSubdivisions() {
    return fetchJson<OperationalSubdivision[]>('GET', `${BASE_URI}/operational-subdivisions`)
  },
  getSubdivisionsNamesTree() {
    return fetchJson<OperationalSubdivisionNamesTree[]>('GET', `${BASE_URI}/operational-subdivisions/tree`)
  },
  getEffectiveSubdivisions() {
    return fetchJson<OperationalSubdivision[]>('GET', `${BASE_URI}/operational-subdivisions/effective`)
  },
  getRoles() {
    return fetchJson<Role[]>('GET', `${BASE_URI}/roles`)
  },
}

export { CommonApiService }
