<script setup lang="ts">
  import { TabSize, TabVariant } from './Tab.types'
  import AlgTab from './Tab.vue'

  defineOptions({
    name: 'AlgTabList',
  })

  const props = withDefaults(defineProps<Props>(), {
    items: () => [],
    size: 'm',
    variant: 'plain',
  })

  const emit = defineEmits<{
    (e: 'update:modelValue', p: number | string): void
  }>()

  interface Props {
    items?: { id: number | string, label?: string, selected?: boolean }[]
    modelValue?: number | string
    size?: TabSize
    variant?: TabVariant
  }
</script>

<template>
  <div class="tab-list">
    <AlgTab
      v-for="item in props.items"
      :key="item.id"
      v-bind="item"
      :selected="modelValue === item.id"
      :variant="props.variant"
      :size="props.size"
      @click="(id) => emit('update:modelValue', id)"
    >
      <slot
        :name="`tab(${item.id})`"
        :item="item"
      >
        {{ item.label }}
      </slot>
    </AlgTab>
  </div>
</template>

<style scoped>
  .tab-list {
    position: relative;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    padding-block: var(--alg-spacing-xs);
    padding-inline: var(--alg-spacing-xs);
  }
</style>
