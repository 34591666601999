import { Ref, ref, watch } from 'vue'

import { useAppStore } from '@/appStore'

interface UseTitleOptions {
  document?: Document
  titleTemplate?: string
  primarySeparator?: string
  secondarySeparator?: string
}
/**
 * MaybeRef
 * @desc Type helper for Vue composable refs: `Ref<T> | T
 * @example
 *   type Sample = 'a' | 'b';
 *
 *   // Expect: Ref<'a' | 'b'> | 'a' | 'b'
 *   MaybeRef<Sample>;
 */
 type MaybeRef<T> = Ref<T> | T

export function useTitle(
  newTitle: MaybeRef<string | null | undefined> | string[] = null,
  options: UseTitleOptions = {
    primarySeparator: '•',
    secondarySeparator: '-',
  },
) {
  const { appName = 'AlgoRH' } = useAppStore()

  const { document = window.document, titleTemplate = '%s' } = options

  const formattedTitle = newTitle instanceof Array ? newTitle.join(` ${options.secondarySeparator} `) : newTitle

  const title = ref(formattedTitle ?? document.title ?? null)

  watch(
    title,
    (t, o) => {
      if (typeof t === 'string' && t !== o && document) {
        document.title = titleTemplate.replace('%s', `${t} ${options.primarySeparator} ${appName}`)
      }
    },
    { immediate: true },
  )

  return { title }
}
