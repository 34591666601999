import { computed, MaybeRef, Ref, ref, toRef, watch } from 'vue'

import { isNumber } from '../types'
import { clamp } from '../utils'

export type PaginationControl = () => void

export interface PaginationResult {
  size: Ref<number>
  total: Ref<number>
  current: Ref<number>
  offset: Ref<number>
  lastPage: Readonly<Ref<number>>
  next: PaginationControl
  prev: PaginationControl
  first: PaginationControl
  last: PaginationControl
}

export interface PaginationOptions {
  size: MaybeRef<number>
  total: MaybeRef<number>
  current: MaybeRef<number>
}

export function usePagination(options: PaginationOptions): PaginationResult {
  const _current = toRef(options.current)
  const _size = toRef(options.size)
  const _offset = ref(0)
  const total = toRef(options.total)

  const offset = computed<number>({
    get() {
      return _offset.value
    },
    set(v) {
      _offset.value = Math.min(v, total.value)
    },
  })

  const current: Ref<number> = computed<number>({
    get() {
      return _current.value
    },
    set(v) {
      if (!isNumber(v)) {
        return
      }
      _current.value = clamp(v, 1, lastPage.value)
      offset.value = (_current.value - 1) * size.value
    },
  })

  const size = computed<number>({
    get() {
      return _size.value
    },
    set(v) {
      if (!isNumber(v)) {
        return
      }
      _size.value = v
    },
  })

  const lastPage = computed(() => Math.ceil(total.value / size.value))

  const prev = () => --current.value
  const next = () => ++current.value
  const first = () => (current.value = 1)
  const last = () => (current.value = lastPage.value)

  watch(
    [total, size],
    () => {
      if (current.value > lastPage.value) {
        current.value = lastPage.value
      }
    },
    { immediate: false },
  )

  return {
    // Mutable state
    size,
    total,
    current,
    offset,

    // Computed
    lastPage,

    // Functions
    next,
    prev,
    first,
    last,
  }
}
