<script lang="ts" setup>
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { CriticalPeriod, dayjs } from '@algorh/shared'

  import { AlgBadge } from '../../badge'
  import { AlgIcon } from '../../media'
  import { AlgAbsencePeriod } from '../shared'

  defineOptions({
    name: 'AlgCriticalPeriodCard',
  })

  const props = defineProps<Props>()

  type Props = {
    readonly criticalPeriod: CriticalPeriod
  }

  enum Availability {
    Incoming = 'incoming',
    Ongoing = 'ongoing',
    Finished = 'finished',
  }

  const { t } = useI18n()

  const availability = computed(() => {
    const now = dayjs()

    const start = dayjs(props.criticalPeriod.input_start_date)

    if (now.isBefore(start)) {
      return Availability.Incoming
    }

    if (now.isAfter(start) && now.isBefore(dayjs(props.criticalPeriod.input_end_date))) {
      return Availability.Ongoing
    }

    if (now.isAfter(dayjs(props.criticalPeriod.input_end_date))) {
      return Availability.Finished
    }

    return null
  })

  const availabilityBadgeVariant = computed(() => {
    switch (availability.value) {
    case Availability.Incoming:
      return 'info'
    case Availability.Ongoing:
      return 'success'
    case Availability.Finished:
      return 'warning'
    default:
      return 'secondary'
    }
  })

  const availabilityBagdeLabel = computed(() => {
    switch (availability.value) {
    case Availability.Incoming:
      return t('common.Incoming')
    case Availability.Ongoing:
      return t('common.Ongoing')
    case Availability.Finished:
      return t('common.Finished (m)')
    default:
      return ''
    }
  })
</script>

<template>
  <div
    class="critical-period-card"
    :class="{ disabled: availability !== Availability.Ongoing }"
  >
    <div class="icon-wrapper">
      <AlgIcon
        name="stylus"
        size="l"
      />
    </div>
    <div class="content-wrapper">
      <div class="title">
        {{ t('absences.Critical leave input period') }}
      </div>
      <div class="request-period-wrapper">
        <AlgAbsencePeriod
          :start-date="criticalPeriod.input_start_date"
          :end-date="criticalPeriod.input_end_date"
        />
        <AlgAbsencePeriod
          with-icon
          :start-date="Array.isArray(criticalPeriod.start_date)
            ? criticalPeriod.start_date[0]
            : criticalPeriod.start_date"
          :end-date="Array.isArray(criticalPeriod.end_date) ? criticalPeriod.end_date[0] : criticalPeriod.end_date"
        />
      </div>
      <div class="absence-period-wrapper">
        <span
          v-if="criticalPeriod.operational_subdivision"
          class="subdivision-name"
        >
          {{ criticalPeriod.operational_subdivision }}
        </span>
        <div class="badge">
          <AlgBadge
            v-if="availability"
            :variant="availabilityBadgeVariant"
            :label="availabilityBagdeLabel"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .critical-period-card {
    display: inline-flex;
    min-width: 18rem;
    max-width: 20rem;
    height: 80px;
    box-sizing: border-box;
    padding: var(--alg-spacing-s);
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-effect-radius-m);
    background-color: var(--alg-color-surface-primary);
    gap: var(--alg-spacing-s);

    .icon-wrapper {
      display: flex;
      width: 64px;
      height: 64px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      padding: var(--alg-spacing-s);
      border-radius: var(--alg-effect-radius-s);
      background-color: var(--alg-color-light-purple);
      color: var(--alg-color-purple-100);
    }

    .content-wrapper {
      display: flex;
      overflow: hidden;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--alg-spacing-xs);

      .title {
        font-size: var(--alg-font-size-m);
        font-weight: var(--alg-font-weight-regular);
      }

      .request-period-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .absence-period-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: var(--alg-spacing-m);

        .subdivision-name {
          overflow: hidden;
          font-size: var(--alg-font-size-xs);
          font-weight: var(--alg-font-weight-regular);
          text-overflow: ellipsis;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .badge {
          display: flex;
          flex: 1 1 auto;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }

    &.disabled {
      color: var(--alg-color-text-secondary);

      .icon-wrapper {
        background-color: var(--alg-color-surface-background);
        color: var(--alg-color-text-secondary);
      }
    }
  }
</style>
