<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable } from '@algorh/shared'

  import { AlgButton } from '../../button'
  import { AlgColorIndicator } from '../../color-indicator'
  import { AlgIconButton } from '../../icon-button'

  import { InternalTaskGlobalInformationCardKind } from './InternalTaskGlobalInformationCard.types'

  type Props = {
    title?: string
    description?: Nullable<string>
    format?: 'self_training' | 'guided'
    blocksAbsences?: boolean
    kind: InternalTaskGlobalInformationCardKind
    color?: string
    updateAvailable?: boolean
  }

  defineOptions({
    name: 'AlgInternalTaskGlobalInformationCard',
  })

  const props = defineProps<Props>()

  const emit = defineEmits<{
    (e: 'update', p: Event): void
    (e: 'show-customer-advisers', p: Event): void
  }>()

  // Composables
  const { t } = useI18n()

  // Refs
  const showFullDescription = ref(false)

  // Computed
  const computedTitle = computed(() => props.title || t('common.No data'))

  const computedDescription = computed(() => props.description || t('common.No data'))

  const computedFormat = computed(() => {
    switch (props.format) {
    case 'self_training':
      return t('scheduling.Self-training')
    case 'guided':
      return t('scheduling.Accompanied training')
    default:
      return ''
    }
  })

  const computedBlocksAbsences = computed(() =>
    props.blocksAbsences ? t('scheduling.Blocking') : t('scheduling.Non blocking'),
  )

  const computedDescriptionNeedToBeCutting = computed(() => computedDescription.value.length > 140)

  const computedDescriptionTruncated = computed(
    () =>
      `${computedDescription.value.slice(0, 140)}${
        computedDescriptionNeedToBeCutting.value ? t('typography.ellipsis') : ''
      } `,
  )

</script>

<template>
  <div
    class="internal-task-global-information-card"
    :class="`internal-task-global-information-card-${props.kind}`"
  >
    <div class="internal-task-global-information-card-header">
      <span class="internal-task-global-information-card-header-title">
        <template v-if="props.kind === 'training'">
          {{ t('scheduling.General information about the training') }}
        </template>
        <template v-else-if="props.kind === 'meeting'">
          {{ t('scheduling.General information about the meeting') }}
        </template>
      </span>
      <AlgIconButton
        v-if="updateAvailable"
        id="internal-task-global-information-card-edit"
        icon="stylus"
        variant="transparent"
        :title="t('common.Edit')"
        @click="(e) => emit('update', e)"
      />
    </div>
    <div
      class="internal-task-global-information-card-title internal-task-global-information-card-font-title"
    >
      <AlgColorIndicator :color="props.color ?? 'var(--alg-color-surface-primary)'" />
      {{ computedTitle }}
    </div>
    <div
      class="internal-task-global-information-card-content"
      :class="{
        'internal-task-global-information-card-content-no-desc': !props.description
      }"
    >
      <div
        v-if="props.description"
        class="internal-task-global-information-card-content-item internal-task-global-information-card-content-description"
      >
        <div class="internal-task-global-information-card-font-title">
          {{ t('common.Description') }}
        </div>
        <div class="internal-task-global-information-card-font-summary">
          <p>
            <template v-if="showFullDescription">
              {{ computedDescription }}
            </template>
            <template v-else>
              {{ computedDescriptionTruncated }}
            </template>
            <span>
              <AlgButton
                v-if="computedDescriptionNeedToBeCutting"
                :label="showFullDescription ? t('common.Show less') : t('common.Show more')"
                variant="link"
                @click="showFullDescription = !showFullDescription"
              />
            </span>
          </p>
        </div>
      </div>
      <div
        v-if="props.kind === 'training' && props.format"
        class="internal-task-global-information-card-content-item internal-task-global-information-card-content-format"
      >
        <div class="internal-task-global-information-card-font-title">
          {{ t('common.Format') }}
        </div>
        <div class="internal-task-global-information-card-font-summary">
          {{ computedFormat }}
        </div>
      </div>
      <div
        class="internal-task-global-information-card-content-item internal-task-global-information-card-content-leave-request"
        :style="{
          gridColumn: props.kind !== 'training' || !props.format ? '1 / 4' : '3 / 4'
        }"
      >
        <div class="internal-task-global-information-card-font-title">
          {{ t('scheduling.Leave of absence') }}
        </div>
        <div class="internal-task-global-information-card-font-summary">
          {{ computedBlocksAbsences }}
        </div>
      </div>
      <div
        class="internal-task-global-information-card-content-item internal-task-global-information-card-content-list"
      >
        <div class="internal-task-global-information-card-font-title">
          <template v-if="props.kind === 'training'">
            {{ t('scheduling.List of CA concerned by the training') }}
          </template>
          <template v-else-if="props.kind === 'meeting'">
            {{ t('scheduling.List of CA concerned by the meeting') }}
          </template>
        </div>
        <div class="internal-task-global-information-card-font-summary">
          <AlgButton
            :label="t('common.Display the list')"
            variant="link"
            @click="(e) => emit('show-customer-advisers', e)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .internal-task-global-information-card {
    position: relative;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-effect-radius-m);
    padding-block: var(--alg-spacing-m) var(--alg-spacing-m);
    padding-inline: var(--alg-spacing-l);

    .internal-task-global-information-card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--alg-color-button-primary);
      font-size: var(--alg-font-size-xxs);
      font-weight: var(--alg-font-weight-bold);
      gap: var(--alg-spacing-s);
      padding-block-end: calc(var(--alg-spacing-l) / 2);
      text-transform: uppercase;
    }

    .internal-task-global-information-card-header-title {
      flex: 1;
    }

    .internal-task-global-information-card-font-title {
      color: var(--alg-color-text-primary);
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-bold);
    }

    .internal-task-global-information-card-font-summary {
      color: var(--alg-color-text-secondary);
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-regular);
      line-height: var(--alg-font-line-height);
    }

    .internal-task-global-information-card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--alg-spacing-s);
    }

    .internal-task-global-information-card-content {
      display: grid;
      width: 100%;
      grid-gap: var(--alg-spacing-l);
      grid-template-columns: 1fr;
      grid-template-rows: min-content auto;
      padding-block-start: var(--alg-spacing-l);
    }

    &.internal-task-global-information-card-training {
      .internal-task-global-information-card-content {
        grid-template-areas:
          'description description description description'
          'format      format      format      leave-request'
          'list        list        list        list';
      }

      .internal-task-global-information-card-content-no-desc {
        grid-template-areas:
          'format      format      format      leave-request'
          'list          list          list          list';
      }
    }

    &.internal-task-global-information-card-meeting {
      .internal-task-global-information-card-content {
        grid-template-areas:
          'description   description   description   description'
          'leave-request leave-request leave-request leave-request'
          'list          list          list          list';
      }

      .internal-task-global-information-card-content-no-desc {
        grid-template-areas:
          'leave-request leave-request leave-request leave-request'
          'list          list          list          list';
      }
    }

    .internal-task-global-information-card-content-item {
      display: flex;
      flex-direction: column;
      gap: var(--alg-spacing-xs);
    }

    .internal-task-global-information-card-content-description {
      grid-area: description;
    }

    .internal-task-global-information-card-content-format {
      grid-area: format;
    }

    .internal-task-global-information-card-content-leave-request {
      grid-area: leave-request;
    }

    .internal-task-global-information-card-content-list {
      grid-area: list;
    }
  }
</style>
