import { Nullable } from '@algorh/shared'

/**
 * animateValue
 * @param start
 * @param end
 * @param fn
 * @param duration
 */
export function animateValue(start: number, end: number, fn: (state: number) => void, duration: number) {
  let startTimestamp: Nullable<number> = null
  const step = (timestamp: number) => {
    if (!startTimestamp) startTimestamp = timestamp
    const progress = Math.min((timestamp - startTimestamp) / duration, 1)
    fn(Math.floor(progress * (end - start) + start))
    if (progress < 1) {
      window.requestAnimationFrame(step)
    }
  }
  window.requestAnimationFrame(step)
}
