<script setup lang="ts">
  import { decimalFormat, Nullable, round } from '@algorh/shared'

  interface Props {
    readonly title: string
    readonly count: number
    readonly total?: Nullable<number>
  }

  const props = withDefaults(defineProps<Props>(), {
    total: null,
  })
</script>

<template>
  <div
    class="counter"
    :class="{
      'with-total': props.total !== null && props.total !== 0
    }"
  >
    <span class="counter-title">
      {{ props.title }}
    </span>
    <span class="counter-count">
      <span
        :class="{
          valid: props.total !== null && props.total !== 0 && props.count === props.total,
          invalid: props.total !== null && props.total !== 0 && props.count !== props.total
        }"
      >
        {{ decimalFormat(round(props.count, 1)) }}
      </span>
      <span
        v-if="props.total !== null && props.total !== 0"
        class="counter-total"
      >
        / {{ decimalFormat(round(props.total, 1)) }}
      </span>
    </span>
  </div>
</template>

<style scoped>
  .counter {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 16px;
    gap: 8px;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 2px;
      height: 100%;
      border-radius: 2px;
      background-color: var(--alg-color-surface-border);
      content: '';
    }

    .counter-title {
      color: var(--alg-color-text-light);
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-regular);
      text-transform: uppercase;
    }

    .counter-count {
      color: var(--alg-color-text-primary);
      font-size: var(--alg-font-size-l);
      font-weight: var(--alg-font-weight-bold);

      .valid {
        color: var(--alg-color-state-success);
      }

      .invalid {
        color: var(--alg-color-state-danger);
      }

      .counter-total {
        color: var(--alg-color-text-secondary);
        font-size: var(--alg-font-size-m);
      }
    }

    &.with-total {
      &::before {
        background-color: var(--alg-color-surface-highlight);
      }

      .counter-title {
        color: var(--alg-color-text-highlight);
      }
    }
  }
</style>
