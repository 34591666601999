<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable } from '@algorh/shared'
  import { AlgIconButton, AlgToggle } from '@algorh/ui'

  import { AmbitionAction } from '@/core/enums/Ambition'
  import { AmbitionConfig } from '@/core/types/Ambition'

  import RuleText from '../RuleText.vue'

  import { HumanizeAmbition } from './HumanizeAmbition.type'

  type Props = {
    ambition: {
      id: number
      score: number
      action: AmbitionAction
      activated: boolean
      ambition_template_id: number
      config: AmbitionConfig
      subject: Nullable<{ name?: string }>
      other: Nullable<{ name?: string }>
    }

    focused?: boolean
    duplicable?: boolean
    editable?: boolean
    deletable?: boolean
    togglable?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    focused: false,
    duplicable: true,
    editable: true,
    deletable: true,
    togglable: true,
  })

  const emit = defineEmits<{
    (e: 'duplicate'): void
    (e: 'edit'): void
    (e: 'delete'): void
    (e: 'toggle'): void
  }>()

  const { t } = useI18n()

  const item = computed((): HumanizeAmbition => ({
    action: props.ambition.action,
    config: props.ambition.config,
    other: props.ambition.other?.name ?? null,
    score: props.ambition.score,
    subject: props.ambition.subject?.name ?? null,
    ambition_template_id: props.ambition.ambition_template_id,
  }))
</script>

<template>
  <div
    class="ambition-list-item"
    :class="{
      focused: props.focused,
      deactivated: !props.ambition.activated,
    }"
  >
    <div class="score">
      {{ props.ambition.score }}
    </div>
    <div class="content">
      <RuleText :item="item" />
    </div>
    <div class="actions-toggle-wrapper">
      <div class="actions">
        <AlgIconButton
          v-if="props.duplicable"
          :id="`duplicate-ambition-${props.ambition.id}`"
          icon="content-copy"
          variant="transparent"
          size="m"
          icon-color="var(--alg-color-icon-secondary)"
          tooltip
          :label="t('common.Duplicate')"
          @click="() => emit('duplicate')"
        />
        <AlgIconButton
          v-if="props.editable"
          :id="`edit-ambition-${props.ambition.id}`"
          icon="stylus"
          variant="transparent"
          size="m"
          icon-color="var(--alg-color-state-info)"
          tooltip
          :label="t('common.Edit')"
          @click="() => emit('edit')"
        />
        <AlgIconButton
          v-if="props.deletable"
          :id="`delete-ambition-${props.ambition.id}`"
          icon="delete"
          variant="transparent"
          size="m"
          icon-color="var(--alg-color-state-danger)"
          tooltip
          :label="t('common.Delete')"
          @click="() => emit('delete')"
        />
      </div>
      <AlgToggle
        :id="`activated-${props.ambition.id}`"
        :model-value="props.ambition.activated"
        :disabled="!props.togglable"
        @update:model-value="() => emit('toggle')"
      />
    </div>
  </div>
</template>

<style scoped>
  .ambition-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--alg-spacing-m);
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-effect-radius-m);
    color: var(--alg-color-text-primary);
    font-size: var(--alg-font-size-m);
    font-weight: var(--alg-font-weight-bold);
    gap: var(--alg-spacing-m);
    line-height: var(--alg-font-line-height);
    transition-duration: var(--alg-transition-colors-duration);
    transition-property: background-color;
    transition-timing-function: var(--alg-transition-colors-timing-function);

    .score {
      display: flex;
      width: 120px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--alg-color-surface-border);
      border-radius: var(--alg-effect-radius-m);
      background-color: var(--color-light);
      font-weight: var(--alg-font-weight-bold);
      text-align: center;
    }

    .content {
      position: relative;
      display: flex;
      flex: 1;
      flex-flow: column wrap;
      justify-content: space-between;
    }

    .actions-toggle-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      gap: var(--alg-spacing-m);

      .actions {
        display: flex;
        flex-flow: row nowrap;
        opacity: 0;
      }
    }

    &.deactivated {
      .content {
        opacity: 0.5;
      }
    }

    &:hover {
      background-color: var(--alg-color-surface-background);

      .actions-toggle-wrapper {
        .actions {
          opacity: 1;
        }
      }
    }

    &.focused {
      border: 1px solid var(--alg-color-surface-highlight);
      background-color: var(--alg-color-surface-primary);
    }
  }
</style>
