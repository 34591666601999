<script setup lang="ts">
  import { CounterSize, CounterVariant } from './Counter.types'

  type Props = {
    readonly value: number
    readonly variant?: CounterVariant
    readonly size?: CounterSize
    readonly limit?: number
    readonly color?: string
  }

  defineOptions({
    name: 'AlgCounter',
  })

  const props = withDefaults(defineProps<Props>(), {
    variant: 'primary',
    size: 'm',
    limit: 99,
  })

</script>

<template>
  <div
    class="counter"
    :class="[
      `variant-${props.variant}`,
      `size-${props.size}`
    ]"
    :style="props.color ? {'background-color': props.color} : {}"
  >
    {{ props.value > props.limit ? `+${props.limit}` : props.value }}
  </div>
</template>

<style scoped>
  .counter {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    color: var(--alg-color-text-on-color);
    font-weight: var(--alg-font-weight-bold);
    padding-inline: var(--alg-spacing-xs);
    white-space: nowrap;

    &.size-xs {
      min-width: var(--alg-font-size-s);
      height: var(--alg-font-size-s);
      font-size: var(--alg-font-size-xxs);
      line-height: var(--alg-font-size-s);
    }

    &.size-s {
      min-width: var(--alg-font-size-m);
      height: var(--alg-font-size-m);
      font-size: var(--alg-font-size-xs);
      line-height: var(--alg-font-size-m);
    }

    &.size-m {
      min-width: var(--alg-font-size-xl);
      height: var(--alg-font-size-xl);
      font-size: var(--alg-font-size-s);
      line-height: var(--alg-font-size-s);
    }

    &.variant-primary {
      background-color: var(--alg-color-text-highlight);
    }

    &.variant-success {
      background-color: var(--alg-color-state-success);
    }

    &.variant-warning {
      background-color: var(--alg-color-state-warning);
    }

    &.variant-danger {
      background-color: var(--alg-color-state-danger);
    }

    &.variant-secondary {
      background-color: var(--alg-color-text-secondary);
    }
  }
</style>
