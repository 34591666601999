<script setup lang="ts">
  import { Nullable } from '@algorh/shared'

  import { AlgPlanningActivityCard } from '../../cards'
  import { PlanningActivityCardActivity } from '../../cards/planning-activity-card/PlanningActivityCard.type'

  defineOptions({
    name: 'AlgActivityPreview',
  })

  const props = withDefaults(defineProps<Props>(), {
    showName: false,
    size: 'xxl',
    inactive: false,
    bold: true,
  })

  interface Props {
    activity: Nullable<PlanningActivityCardActivity>
    showName?: boolean
    size?: 'm' | 'l' | 'xl' | 'xxl'
    inactive?: boolean
    bold?: boolean
  }

</script>

<template>
  <div class="activity-preview-wrapper">
    <div
      class="activity"
      :class="[
        {
          inactive: props.inactive
        },
        `size-${props.size}`
      ]"
    >
      <AlgPlanningActivityCard
        v-if="props.activity"
        :activity="props.activity"
        :inactive="props.inactive"
        :size="props.size"
      />
    </div>
    <span
      v-if="props.showName && props.activity"
      class="activity-name"
      :class="{
        bold: props.bold
      }"
    >
      {{ props.activity.name }}
    </span>
  </div>
</template>

<style scoped>
  .activity-preview-wrapper {
    display: flex;
    overflow: hidden;
    align-items: center;
    font-size: var(--alg-font-size-s);
    gap: var(--alg-spacing-s);

    .activity {
      width: 1.5rem;
      height: 1.5rem;
      box-sizing: border-box;
      flex: 0 0 auto;

      &.size-l {
        width: 2rem;
        height: 2rem;
      }

      &.size-xl {
        width: 2.5rem;
        height: 2.5rem;
      }

      &.size-xxl {
        width: 3rem;
        height: 3rem;
      }
    }

    .activity-name {
      display: inline-block;
      overflow: hidden;
      flex: 1 1 auto;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.bold {
        font-weight: var(--alg-font-weight-bold);
      }
    }
  }
</style>
