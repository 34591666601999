import { computed, ComputedRef, Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw } from 'vue-router'

import { DateString, DateTimeString, dayjs, DTF, Nullable, ShortTimeString } from '@algorh/shared'

import { ScheduleType } from '@/components/calendar/CalendarWeek.types'
import { ActivityCategory } from '@/core/enums/Activity'
import { PlanningState } from '@/core/enums/Planning'
import { Mode } from '@/core/enums/Schedule'
import { InternalTaskInstance, PlannedInternalTaskInstance } from '@/core/types/InternalTask'
import { ProjectActivity as ProjectProjectActivity, ProjectActivityForPlanifiable } from '@/core/types/Project'
import { WorkingSlot } from '@/core/types/Schedule'

import { Planning as CalendarPlanning } from '@/sections/calendar/types/Planning'
import { Schedule as CalendarSchedule } from '@/sections/calendar/types/Schedule'
import { CommonSlot, getSlots } from '@/sections/scheduling/utils/calendar'
import { ProjectActivity as SettingsProjectActivity } from '@/sections/settings/types/Project'
import { Planning as TrainingPlanning, Schedule as TrainingSchedule } from '@/sections/training/types/Trainer'
import { WeeklyScheduleHistory } from '@/sections/users/types'

import { useCalendarBoundaries } from './useCalendarBoundaries'
import { useConfigs } from './useConfigs'

export interface PutSlotWarning {
  id: string
  start_time: ShortTimeString
  end_time: ShortTimeString
  datetimes: DateTimeString[]
  date: DateString
  warningType: ActivityCategory
  newActivityId: number | null
  oldActivityId?: number | null
  redirect?: RouteLocationRaw
}

const {
  WAITING,
  LOAD_CURVES_GENERATED,
  RAW_PLANNING_RUNNING,
  RAW_PLANNING_SCHEDULED,
  EDITABLE,
  CLOSED,
  RUNNING,
  SCHEDULED,
} = PlanningState

const { FREESTYLE, USER_DEFINED, PREDEFINED, UNDEFINED } = Mode
const { USER_DEFINED_SCHEDULE, PREDEFINED_SCHEDULE, WORKING_SCHEDULE, FINAL_SCHEDULE } = ScheduleType

type ProjectActivity = ProjectActivityForPlanifiable | SettingsProjectActivity | ProjectProjectActivity
const useCustomerAdviserCalendar = (
  date: Ref<DateString>,
  schedule: ComputedRef<Nullable<CalendarSchedule | TrainingSchedule>>,
  planning: ComputedRef<Nullable<CalendarPlanning | TrainingPlanning>>,
  projectActivities: ComputedRef<Nullable<ProjectActivity[]>>,
  internalTaskInstances: ComputedRef<Nullable<(PlannedInternalTaskInstance | InternalTaskInstance)[]>>,
  isInEditMode?: Ref<boolean>,
  customWarnings?: Ref<DateTimeString[]>,
  hideWorkedActivities?: Ref<boolean>,
  historySchedule?: ComputedRef<Nullable<WeeklyScheduleHistory>>,
) => {
  const { t } = useI18n()
  const { workCycleEnabled, predefinedModeEnabled, slotDuration } = useConfigs()
  const { daySlotTimes, weekDays } = useCalendarBoundaries(date)
  const displayedScheduleTypes = ref<ScheduleType[]>([])

  const selectedActivity = ref<Nullable<ProjectActivity>>(null)
  const errorSlots = ref<DateTimeString[]>([])
  const forbiddenSlots = ref<DateTimeString[]>([])
  const loadingSlots = ref<DateTimeString[]>([])
  const warningSlots = ref<DateTimeString[]>([])
  const focusedWarning = ref<Nullable<PutSlotWarning>>(null)
  const loadingActivityId = ref<Nullable<number>>(null)
  const isDeleting = ref(false)

  const availableSchedules = computed(() => {
    if (!schedule.value || !planning.value || !('state' in planning.value)) {
      return []
    }

    if (isInEditMode?.value) {
      return [WORKING_SCHEDULE]
    }

    if (workCycleEnabled.value) {
      switch (planning.value.state) {
        case WAITING:
        case LOAD_CURVES_GENERATED:
        case RAW_PLANNING_RUNNING:
        case RAW_PLANNING_SCHEDULED:
          return [WORKING_SCHEDULE]

        case EDITABLE:
          if (predefinedModeEnabled.value) {
            return [WORKING_SCHEDULE, USER_DEFINED_SCHEDULE, PREDEFINED_SCHEDULE]
          }
          return [WORKING_SCHEDULE, USER_DEFINED_SCHEDULE]

        case CLOSED:
        case RUNNING:
        case SCHEDULED:
          if (!('mode' in schedule.value)) {
            return [WORKING_SCHEDULE]
          }
          switch (schedule.value.mode) {
            case FREESTYLE:
            case USER_DEFINED:
              if (predefinedModeEnabled.value) {
                return [
                  PREDEFINED_SCHEDULE,
                  USER_DEFINED_SCHEDULE,
                  WORKING_SCHEDULE,
                ]
              }
              return [USER_DEFINED_SCHEDULE, WORKING_SCHEDULE]
            case PREDEFINED:
              return [WORKING_SCHEDULE, PREDEFINED_SCHEDULE]
            case UNDEFINED:
              return [WORKING_SCHEDULE]
          }
      }
    }
    switch (planning.value.state) {
      case EDITABLE:
        if (predefinedModeEnabled.value) {
          return [WORKING_SCHEDULE, USER_DEFINED_SCHEDULE, PREDEFINED_SCHEDULE]
        }
        return [WORKING_SCHEDULE, USER_DEFINED_SCHEDULE]
      case CLOSED:
      case RUNNING:
        if (!('mode' in schedule.value)) {
          return []
        }
        switch (schedule.value.mode) {
          case FREESTYLE:
          case USER_DEFINED:
            return [USER_DEFINED_SCHEDULE]
          case PREDEFINED:
            return [PREDEFINED_SCHEDULE]
        }
        return []
      case SCHEDULED:
        if (!('mode' in schedule.value)) {
          return []
        }
        switch (schedule.value.mode) {
          case FREESTYLE:
          case USER_DEFINED:
            if (predefinedModeEnabled.value) {
              return [
                PREDEFINED_SCHEDULE,
                USER_DEFINED_SCHEDULE,
                FINAL_SCHEDULE,
              ]
            }
            return [USER_DEFINED_SCHEDULE, FINAL_SCHEDULE]
          case PREDEFINED:
            return [FINAL_SCHEDULE, PREDEFINED_SCHEDULE]
          case UNDEFINED:
            return [FINAL_SCHEDULE]
          default:
            return []
        }
      default:
        return []
    }
  })

  const defaultSchedule = computed(() => {
    const preferedSchedule = [FINAL_SCHEDULE, WORKING_SCHEDULE, USER_DEFINED_SCHEDULE, PREDEFINED_SCHEDULE]

    const pref = preferedSchedule.find((ps) => availableSchedules.value.includes(ps))

    return pref ? [pref] : availableSchedules.value
  })

  const scheduleName = (type: ScheduleType) => {
    switch (type) {
      case ScheduleType.WORKING_SCHEDULE:
        return t('calendar.type.Scheduled Planning')
      case ScheduleType.USER_DEFINED_SCHEDULE:
        return t('calendar.type.User Input')
      case ScheduleType.PREDEFINED_SCHEDULE:
        return t('calendar.type.Predefined Planning')
      case ScheduleType.FINAL_SCHEDULE:
        return t('calendar.type.Arbitrated Planning')
    }
  }

  const scheduleAbbr = (type: ScheduleType) => {
    switch (type) {
      case ScheduleType.WORKING_SCHEDULE:
        return t('calendar.type.SP')
      case ScheduleType.USER_DEFINED_SCHEDULE:
        return t('calendar.type.UI')
      case ScheduleType.PREDEFINED_SCHEDULE:
        return t('calendar.type.PP')
      case ScheduleType.FINAL_SCHEDULE:
        return t('calendar.type.AP')
    }
  }

  const daySchedule = (day: DateString, type: ScheduleType, editable = false): CommonSlot[] => {
    const scheduleType = type === ScheduleType.WORKING_SCHEDULE ? (schedule.value as TrainingSchedule)?.[type] : (schedule.value as CalendarSchedule)?.[type] ?? []
    let additionalSlots = []
    for (const loadingSlot of [...loadingSlots.value, ...forbiddenSlots.value]) {
      if (scheduleType.find((s) => s.datetime === loadingSlot)) {
        continue
      }
      additionalSlots.push({
        datetime: loadingSlot,
        project_activity_id: selectedActivity.value?.id ?? null,
      })
    }
    return [...(scheduleType ?? []), ...(additionalSlots ?? [])]
      .filter((s) => dayjs(s.datetime).isSame(day, 'day'))
      .map((s) => {
        const loading = loadingSlots?.value?.includes(s.datetime)
        //    && !forbiddenActivities?.includes(s.project_activity_id ?? -1)

        const warning = focusedWarning?.value?.datetimes.includes(s.datetime)
          ?? [...(warningSlots?.value ?? []), ...(customWarnings?.value ?? [])].includes(s.datetime) ?? false
        const error = errorSlots?.value?.includes(s.datetime) ?? false
        const forbidden = forbiddenSlots?.value?.includes(s.datetime) ?? false
        const notWorked = !forbidden && s.project_activity_id === null

        const project_activity_id = loading ? loadingActivityId.value ?? null : s.project_activity_id

        return {
          ...s,
          loading,
          notWorked,
          project_activity_id,
          deleting: loading && isDeleting.value,
          warning,
          error,
          forbidden,
          editable,
        }
      })
  }

  const dayInternalTaskInstances = (day: DateString) => {
    return internalTaskInstances?.value?.filter((task) => dayjs(task.start_date).isSame(day, 'day'),
    ) ?? []
  }

  const weekData = computed(() => weekDays.value.map((day) => ({
    day,
    schedules: displayedScheduleTypes.value
      .map((type) => ({
        name: scheduleAbbr(type),
        activitySlots: getSlots(
          daySlotTimes.value,
          projectActivities.value ?? [],
          dayInternalTaskInstances(day),
          daySchedule(day, type, !('state' in planning)),
          slotDuration.value ?? 30,
        )
          .map(
            (slot) => hideWorkedActivities?.value ?? false
              ? ({
                  ...slot,
                  projectActivity: slot.projectActivity?.category !== ActivityCategory.PRODUCTION
                    ? slot.projectActivity
                    : null,
                })
              : slot,
          ),
      })),
  })))

  const historyWeekData = computed(() => {
    const slots = historySchedule?.value?.working_schedule
    return slots
      ? weekDays.value.map((day) => ({ day, schedules: [{ name: t('calendar.type.PH'), activitySlots: getSlots(daySlotTimes.value, projectActivities.value ?? [], dayInternalTaskInstances(day), dayHistorySchedule(day, slots), slotDuration.value ?? 30) }] }))
      : []
  })

  const dayHistorySchedule = (day: DateString, slots: WorkingSlot[]): CommonSlot[] => slots
    .filter((s) => dayjs(s.datetime).isSame(day, 'day'))
    .map((s) => ({
      datetime: s.datetime,
      project_activity_id: s.project_activity_id,
      loading: false,
      notWorked: s.project_activity_id === null,
      warning: false,
      error: false,
      forbidden: false,
    }))

  const splitedSlotGroups = computed(() => {
    let currentStartTime = null
    let currentDate = null
    const promises = []

    for (let i = 0; i < loadingSlots.value.length; i++) {
      const loadingSlot = loadingSlots.value[i]
      const nextLoadingSlot = loadingSlots.value[i + 1]

      const loadingSlotDayjs = dayjs(loadingSlot)
      const loadingSlotDate = dayjs(loadingSlot).format(DTF.DATE) as DateString
      if (currentDate === null) {
        currentDate = loadingSlotDate
      }
      if (currentStartTime === null) {
        currentStartTime = loadingSlotDayjs.format(DTF.TIME_FULL)
      }
      const nextLoadingSlotDayjs = nextLoadingSlot ? dayjs(nextLoadingSlot) : null
      const nextLoadingSlotDate = nextLoadingSlotDayjs?.format(DTF.DATE) ?? null
      const isSameDay = nextLoadingSlotDate === currentDate
      const areSlotConsecutive = nextLoadingSlotDayjs?.diff(loadingSlotDayjs, 'minute') === slotDuration.value

      if (nextLoadingSlot && isSameDay && areSlotConsecutive) {
        continue
      }
      promises.push(
        {
          date: currentDate,
          start_time: currentStartTime,
          end_time: loadingSlotDayjs.format(DTF.TIME_FULL),
        },
      )
      currentStartTime = null
      currentDate = null
    }
    return promises
  })

  return {
    errorSlots,
    forbiddenSlots,
    loadingSlots,
    warningSlots,
    focusedWarning,
    selectedActivity,
    availableSchedules,
    daySlotTimes,
    displayedScheduleTypes,
    weekDays,
    weekData,
    historyWeekData,
    scheduleName,
    scheduleAbbr,
    defaultSchedule,
    isDeleting,
    loadingActivityId,
    splitedSlotGroups,
  }
}

export { useCustomerAdviserCalendar }
