import { onBeforeUnmount, onMounted, ref } from 'vue'

export function useResize(
) {
  const width = ref(0)
  const height = ref(0)

  const onResize = () => {
    width.value = window.innerWidth
    height.value = window.innerHeight
  }

  onMounted(() => {
    window.addEventListener('resize', onResize)
    onResize()
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize)
  })

  return {
    width,
    height,
  }
}
