import { DateTimeString, dayjs, DTF, minutesToTime, Nullable, ShortTimeString, TimeString, timeStringToMinutesRegex } from '@algorh/shared'

import { ActivityCategory } from '@/core/enums/Activity'
import { Slot } from '@/core/types'
import { InternalTaskInstance, PlannedInternalTaskInstance } from '@/core/types/InternalTask'
import { ProjectActivity as ProjectProjectActivity, ProjectActivityForPlanifiable } from '@/core/types/Project'
import { RealTimeSlot } from '@/core/types/Schedule'

import { ProjectActivity as SettingsProjectActivity } from '@/sections/settings/types/Project'

import { DateTimeRange } from './calendar.type'

export interface CommonSlot {
  loading?: boolean
  warning?: boolean
  forbidden?: boolean
  editable?: boolean
  error?: boolean
  project_activity_id: Nullable<number>
  deleting?: boolean
  datetime: DateTimeString
  manual_scheduling_id?: Nullable<number>
  real_start?: Nullable<DateTimeString>
  real_end?: Nullable<DateTimeString>
  notWorked?: boolean
}
type ProjectActivity = ProjectActivityForPlanifiable | SettingsProjectActivity | ProjectProjectActivity
const slotsFromDateTimeRange = (error: DateTimeRange, slotDuration = 30): DateTimeString[] =>
  error.dates.reduce((acc, date) => {
    const start = dayjs(`${date} ${error.startTime}`)
    const end = dayjs(`${date} ${error.endTime}`)
    const diff = end.diff(start, 'm') / slotDuration
    return acc.concat(Array.from({ length: diff }, (_, i) => start.add(i * (slotDuration), 'm').format(DTF.DATETIME)))
  }, [] as DateTimeString[])

const isSameSlot = (curr: Slot, previous: Slot): boolean => {
  const matchEditable = curr.editable === previous.editable
  const matchIsLoading = curr.loading === previous.loading
  const matchNotWorked = curr.notWorked === previous.notWorked
  const matchForbidden = curr.forbidden === previous.forbidden
  const matchDeleting = curr.deleting === previous.deleting
  const matchWarning = curr.warning === previous.warning
  const matchError = curr.error === previous.error
  const matchManualSchedulingId = curr.manualSchedulingId === previous.manualSchedulingId
  const hasSameProjectActivity
    = curr.projectActivity && previous.projectActivity
      ? curr.projectActivity.id === previous.projectActivity.id
      : curr.projectActivity === previous.projectActivity

  const hasSameInternalTask = curr.internalTask && previous.internalTask
    ? curr.internalTask.id === previous.internalTask.id
    : curr.internalTask === previous.internalTask
  return hasSameProjectActivity
    && matchEditable
    && matchIsLoading
    && matchNotWorked
    && hasSameInternalTask
    && matchForbidden
    && matchWarning
    && matchError
    && matchDeleting
    && matchManualSchedulingId
}
const generateSlotsFromBoundingBox = (
  slotDuration = 30,
  boundingBox: { end_time: ShortTimeString, start_time: ShortTimeString }): ShortTimeString[] => {
  const end = timeStringToMinutesRegex(boundingBox.end_time)
  const start = timeStringToMinutesRegex(boundingBox.start_time)
  const diff = (end - start) / slotDuration + 1
  return Array.from({ length: diff }, (_, i) => minutesToTime(start + (i * (slotDuration))))
}

const getDuration = (start: Nullable<DateTimeString>, end: Nullable<DateTimeString>): number => end && start
  ? dayjs(end)
    .diff(dayjs(start), 'm') / 60
  : 0

const matchingInternalTask = (
  internalTaskInstances: (PlannedInternalTaskInstance | InternalTaskInstance)[],
  slotsMinutes: number,
): Nullable<PlannedInternalTaskInstance | InternalTaskInstance> => internalTaskInstances.find(({ start_date, end_date }) => {
  const startMinutes = timeStringToMinutesRegex(start_date)
  const endMinutes = timeStringToMinutesRegex(end_date)
  return slotsMinutes >= startMinutes && slotsMinutes < endMinutes
}) ?? null

const getRealTimeSlots = (
  projectActivities: ProjectActivity[],
  internalTaskInstances: PlannedInternalTaskInstance[], realTimeSlots: RealTimeSlot[]): Slot[] => {
  const data: Slot[] = []
  for (let baseSlot of realTimeSlots.map((a) => timeStringToMinutesRegex(a.start_at))) {
    const datetime = baseSlot
    const now = dayjs().format(DTF.DATETIME)

    // Slot matching
    const slot = realTimeSlots.find((s) => timeStringToMinutesRegex(s.start_at) === datetime) ?? null
    const projectActivity = projectActivities.find((a) => a.id === slot?.project_activity_id) ?? null
    const realStart = slot?.start_at ?? null
    const realEnd = slot?.end_at ?? now
    const isLunchBreak = projectActivity?.category === ActivityCategory.LUNCH_BREAK

    // internalTask
    const internalTask = projectActivity?.category === ActivityCategory.NON_PRODUCTION
      ? matchingInternalTask(internalTaskInstances, baseSlot)
      : null

    let current: Slot = {
      datetime: minutesToTime(baseSlot) as TimeString,
      duration: 1,
      internalTask,
      projectActivity,
      realStart,
      realEnd,
      isLunchBreak,
      notWorked: slot === null,
      loading: false,
      error: false,
      forbidden: false,
      editable: false,
      warning: false,
      deleting: false,
    }

    data.push(current)
  }
  return data
}
const getSlots = (
  slotsTimes: ShortTimeString[],
  projectActivities: ProjectActivity[],
  internalTaskInstances: (PlannedInternalTaskInstance | InternalTaskInstance)[],
  slots: CommonSlot[],
  slotDuration: number,
  selectionStart?: number | null,
  selectionEnd?: number | null,
  selectedActivityId?: number | null,
  nonEditableActivityIds?: number[] | null,
  selectionNonEditable = false,
): Slot[] => {
  const data: Slot[] = []
  for (let slotTime of slotsTimes) {
    const slotTimeMinute = timeStringToMinutesRegex(slotTime)
    const datetime = slotTime + ':00' as TimeString
    const now = dayjs().format(DTF.DATETIME)

    // Slot matching
    const slot = slots.find((s) => s.datetime.split(' ')[1] === datetime) ?? null
    const {
      forbidden = false,
      warning = false,
      error = false,
      loading: slotLoading = false,
      deleting: slotDeleting = false,
    } = slot ?? {}

    const slotProjectActivity = projectActivities.find((a) => a.id === slot?.project_activity_id) ?? null
    const selectedProjectActivity = projectActivities.find((p) => p.id === selectedActivityId) ?? null

    // internalTask
    const internalTask = slotProjectActivity?.category && [ActivityCategory.NON_PRODUCTION, ActivityCategory.TRAINING].includes(slotProjectActivity.category)
      ? matchingInternalTask(internalTaskInstances, slotTimeMinute)
      : null

    const isInternalTask = internalTask !== null && slotProjectActivity?.category && [ActivityCategory.NON_PRODUCTION, ActivityCategory.TRAINING].includes(slotProjectActivity.category)
    const isNotSelectable = nonEditableActivityIds?.includes(slotProjectActivity?.id ?? 0) ?? false
    const realStart = slot?.real_start ? slot.real_start : slot?.datetime ?? null
    const slotDate = slot?.datetime.split(' ')[0]
    const realEnd = slot?.real_end ?? `${slotDate} ${minutesToTime(slotTimeMinute + slotDuration)}:00` as DateTimeString
    const manualSchedulingId = slot?.manual_scheduling_id ?? null

    const isLunchBreak = !forbidden && slotProjectActivity?.category === ActivityCategory.LUNCH_BREAK
    const notWorked = slot === null
    const editable = isInternalTask ? false : slot?.editable ?? false

    const loading = selectionStart != null && selectionEnd != null
      && slotTimeMinute >= selectionStart && slotTimeMinute < selectionEnd

    const projectActivity = slot !== null
      && loading
      && !isNotSelectable
      && !selectionNonEditable
      ? selectedProjectActivity
      : slotProjectActivity

    const deleting = (loading && selectedActivityId === null) || slotDeleting

    let current: Slot = {
      manualSchedulingId,
      datetime,
      duration: 1,
      internalTask,
      projectActivity,
      realStart,
      realEnd,
      isLunchBreak,
      notWorked,
      loading: loading || slotLoading,
      deleting,
      error,
      forbidden: forbidden || (loading && selectionNonEditable && projectActivity === null),
      editable,
      warning,
    }

    const previous = data.length ? data[data.length - 1] : null
    const isSameAsPrevious = previous ? isSameSlot(current, previous) : false

    if (previous && isSameAsPrevious) {
      previous.duration = previous.duration + 1
      previous.realEnd = current.realStart && !current.realEnd ? now : current.realEnd
    } else {
      data.push(current)
    }
  }
  return data
}

export {
  getSlots,
  generateSlotsFromBoundingBox,
  getDuration,
  matchingInternalTask,
  slotsFromDateTimeRange,
  getRealTimeSlots,
}
export type { Slot, DateTimeRange, DateTimeRange as ErrorDateTimeRange }
