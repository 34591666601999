import { DateString, Dto, fetchJson } from '@algorh/shared'

import { PlannedInternalTaskInstance } from '@/core/types/InternalTask'
import { ManualScheduling } from '@/core/types/ManuelScheduling'

import { CustomerAdviserSchedule } from '../types/Schedule'

import { GetCustomerAdvisersDto } from './scheduling.service.type'

const BASE_URI = '/api/scheduling/customer-advisers'

const SchedulingCustomerAdvisersApiService = {
  getUsers(dto: GetCustomerAdvisersDto) {
    return fetchJson<CustomerAdviserSchedule[], null, GetCustomerAdvisersDto>(
      'GET', BASE_URI, null, dto,
    )
  },
  getInternalTaskInstances(dto: Dto<{ start_date: DateString, end_date: DateString, user_ids: number[] }>) {
    return fetchJson<PlannedInternalTaskInstance[], null, typeof dto>(
      'GET', `${BASE_URI}/internal-task-instances`, null, dto,
    )
  },
  getManualSchedulings(dto: Dto<{ start_date: DateString, end_date: DateString, user_ids: number[] }>) {
    return fetchJson<ManualScheduling[], null, typeof dto>(
      'GET', `${BASE_URI}/manual-schedulings`, null, dto,
    )
  },
}

export { SchedulingCustomerAdvisersApiService }
