<script setup lang="ts">
  import { AlgPopper } from '@algorh/ui'

  interface Props {
    readonly ancestors: string[]
    readonly subdivision: string
  }

  const props = defineProps<Props>()
</script>

<template>
  <AlgPopper
    v-if="props.ancestors.length"
    placement="bottom-start"
    rounded
    shadowed
  >
    <template #reference="{ toggle }">
      <button
        class="subdivision-popper-reference"
        type="button"
        @click.stop="toggle"
      >
        {{ props.subdivision }}
      </button>
    </template>
    <template #content>
      <div class="subdivision-popper-content">
        <div
          v-for="(ancestor, k) in props.ancestors.concat([props.subdivision])"
          :key="k"
          class="item"
          :style="{
            marginLeft: `${(k === 1 ? 12 : 16) * k}px`
          }"
        >
          {{ ancestor }}
        </div>
      </div>
    </template>
  </AlgPopper>
  <template v-else>
    {{ props.subdivision }}
  </template>
</template>

<style scoped>
  .subdivision-popper-reference {
    padding: 0;
    color: inherit;
    font-weight: inherit;
    text-align: left;
    text-decoration: underline;
    text-underline-offset: var(--alg-spacing-xs);
  }

  .subdivision-popper-content {
    padding: var(--alg-spacing-m);
    border-radius: var(--alg-spacing-xs);
    background-color: var(--alg-color-surface-primary);
    box-shadow: var(--alg-effect-shadow-m);
    color: var(--alg-color-text-secondary);

    .item {
      position: relative;
      padding: 4px 0;
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-light);

      &:not(:first-child) {
        padding-left: var(--alg-spacing-m);
        margin-top: var(--alg-spacing-s);

        &::before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          display: block;
          width: 1px;
          height: 100%;
          border-radius: 1px;
          background-color: var(--alg-color-text-secondary);
          content: '';
        }
      }

      &:last-child {
        color: var(--alg-color-text-primary);

        &::before {
          background-color: var(--alg-color-text-primary);
        }
      }
    }
  }
</style>
