import { computed, ComputedRef } from 'vue'

import { ShortTimeString } from '@algorh/shared'

import { useConfigs } from './useConfigs'

export const useCalendarConfigs = () => {
  const { planificationConfig } = useConfigs()
  const defaultConfig = {
    slot_duration: 30,
    bounding_box: {
      start_time: '08:00',
      end_time: '18:00',
      days: [0, 1, 2, 3, 4],
    } }

  const slotHeight = 50

  const startTime = computed(() => planificationConfig.value?.bounding_box?.start_time || defaultConfig.bounding_box.start_time)
  const endTime = computed(() => planificationConfig.value?.bounding_box?.end_time || defaultConfig.bounding_box.end_time)
  const days = computed(() => planificationConfig.value?.bounding_box?.days || defaultConfig.bounding_box.days)
  const slotDuration = computed(() => planificationConfig.value?.slot_duration || defaultConfig.slot_duration)

  return {
    startTime,
    endTime,
    days,
    slotDuration,
    slotHeight,
  } as {
    startTime: ComputedRef<ShortTimeString>
    endTime: ComputedRef<ShortTimeString>
    days: ComputedRef<number[]>
    slotDuration: ComputedRef<number>
    slotHeight: number
  }
}
